import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import FilestoreClient from "../../FileStoreClient";
import DateField from "@sapkk/app/components/DateField";
import SearchResult from "../SearchResult";
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";

class AdatModositasKereses extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            data: {},
            params: (this.props.history.location.state || {}).params || [],
            param_data: (this.props.history.location.state || {}).param_data || {},
            save_in_progress: false,

            skip_update: false,
            parameters: {
                limit: 20,
                ...((this.props.history.location.state || {}).parameters || {})
            },
            order: (this.props.history.location.state || {}).order || [],
            result: (this.props.history.location.state || {}).result || undefined,
            disable_menu: true,
        }
    }

    componentDidMount() {
        if (this.props.history.location.pathname!='/iskola-kezelese/' + this.state.school + '/adatmodositasok') {
            this.props.history.replace('/iskola-kezelese/' + this.state.school + '/adatmodositasok')
        }
        this.loadData()
    }

    loadData() {
        this.setState({
            loaded: false,
            error: false
        }, () => {
            this.setState({
                params: [
                    {id: 6,name: 'student_name', operator: 0, type: 'STRING', required: false, multiple: false, order: 0, url: null, alias: 'Tanuló neve', focus: true, default_method: null, required_params: []},
                    {id: 1,name: 'om', operator: 0, type: 'STRING', required: false, multiple: false, order: 1, url: null, alias: 'Tanuló oktatási azonosítója', focus: false, default_method: null, required_params: []},
                    {id: 3,name: 'creator_type', operator: 9, type: 'STRING', required: false, multiple: false, order: 3, url: 'CanteenService/MealRequest/getCreatorTypeAlias', alias: 'Típus', focus: false, default_method: null, required_params: []},
                    {id: 4,name: 'creator_name', operator: 0, type: 'STRING', required: false, multiple: false, order: 4, url: null, alias: 'Módosító neve', focus: false, default_method: null, required_params: []},
                    {id: 6,name: 'min_created', field:'created', operator: 7, type: 'DATE', required: false, multiple: false, order: 5, alias: 'Módosítás időpontja (-tól)', focus: false, default_method: null, required_params: []},
                    {id: 7,name: 'max_created', field:'created', operator: 4, type: 'DATE', required: false, multiple: false, order: 5, alias: 'Módosítás időpontja (-ig)', focus: false, default_method: null, required_params: []},
                    {id: 2,name: 'state', operator: 9, type: 'STRING', required: false, multiple: false, order: 2, url: 'CanteenService/Student/getStateAlias', alias: 'Státusz', focus: false, default_method: null, required_params: []},
                ]
            }, () => {
                let callables = this.state.params.filter((val) => val.url && val.required_params.length === 0)
                let data = {}
                if (callables.length) {
                    this.props.promise_error_handler(Promise.all(
                        callables.map(call => CanteenClient.reportCall(call.url))
                    ).then(vals => {
                        vals.map((val, i) => {
                            this.setState((prevState) => ({
                                param_data: {
                                    ...prevState.param_data,
                                    [callables[i].id]: val
                                }
                            }), () => {
                                if (val.length === 1) {
                                    this.handleDataChange({ target: { value: val[0].id } }, callables[i].name)
                                } else{
                                    let default_value = val.find(v => v.default)
                                    if(default_value){
                                        this.handleDataChange({ target: { value: default_value.id } }, callables[i].name)
                                    }
                                }
                            })
                        })
                    }))
                }

                this.state.params.map(param => {
                    if (param.type == "BOOL") {
                        data[param.name] = false
                    }
                })
                this.setState((prevState) =>({
                    data: {
                        ...prevState.data,
                        ...data
                    },
                    showed: true
                }), () => {
                    let callback = () => {
                        this.setState({
                            disable_menu: false
                        }, () => {
                        })
                    }
                    callback()
                })
            })
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.school_id !== state.school_id) {
            return {
                school_id: props.match.params.school_id,
            }
        }
        if (props.history.location.state && !state.skip_update) {
            return {
                order: props.history.location.state.order,
                result: props.history.location.state.result,
                data: {
                    ...props.history.location.state.data
                },
                param_data: props.history.location.state.param_data,
                params: props.history.location.state.params
            }
        } else {
            return null
        }
    }


    handleChangeOrder(order) {
        this.setState({
            skip_update: true,
            order: order
        }, () => {
            this.setState({
                skip_update: false
            })
            this.handleSearch(0)
        })
    }

    handleSearch = (page) => {
        this.setState({
            search_in_progress: true,
            skip_update: true
        }, () => {
            let conditions = Object.keys(this.state.data).filter(key => this.state.data[key]).reduce((prev, curr) => {
                let param = this.state.params.find(p=>p.name==curr)
                let actual_condition = prev[param.field || param.name] || []
                actual_condition.push({operator: param.operator, value: this.state.data[curr]})
                return {
                    ...prev,
                    [param.field || param.name]: actual_condition
                }
            }, {})
            conditions.school_id = [{operator: 9, value: this.state.school}]
            this.props.promise_error_handler(CanteenClient.listStudentDataByStudentInformationCondition( conditions, this.state.parameters.limit, page * this.state.parameters.limit, this.state.order).then(result => {
                this.setState({
                    search_in_progress: false,
                    result: {
                        result: result.result || [],
                        result_summary: {
                            ...result.result_summary,
                            page: page
                        },
                        headers: result.headers
                    }
                }, () => {

                    this.props.clearMessages()
                    if (!this.props.history.location.state) {
                        this.props.history.push(this.props.history.location.pathname)
                    }
                    this.props.history.replace(this.props.history.location.pathname, {
                        ...this.props.history.location.state,
                        order: this.state.order,
                        result: this.state.result,
                        parameters: this.state.parameters,
                        data: this.state.data,
                        param_data: this.state.param_data,
                        params: this.state.params
                    })
                })
            })).then(() =>
                this.setState({
                    search_in_progress: false,
                    skip_update: false
                })
            )
        })
    }

    clearParamList = (param, clear_param_data) => {
        this.state.params.filter((val) => val.required_params.find(rp => rp.required_param_id === param.id)).map(p => this.clearParamList(p, true))
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [param.name]: "",
            },
            ...(clear_param_data ? {
                param_data: {
                    ...prevState.param_data,
                    [param.id]: []
                }
            } : {})
        }))
    }

    handleDataChange(event, name) {
        let value = ""
        if (event.target.multiple) {
            value = [].filter.call(event.target.options, o => o.selected).map(o => o.value)
        } else if (event.target.type != 'checkbox') {
            value = event.target.value
        } else {
            value = event.target.checked
        }
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            },
        }), () => {
            let param = this.state.params.find(p => p.name === name) || {}
            let callables = this.state.params.filter((val) => val.required_params.find(rp => rp.required_param_id === param.id))
            callables.map(p => this.clearParamList(p, true))
            callables = callables.filter((c) => c.url && c.required_params.every(rp => this.state.data[(this.state.params.find(p => p.id == rp.required_param_id) || {}).name]))
            if (value) {
                this.props.promise_error_handler(Promise.all(
                    callables.map(call => CanteenClient.reportCall(call.url,
                        call.required_params.reduce((prev, curr) => ({ ...prev, [curr.alias]: this.state.data[(this.state.params.find(p => p.id === curr.required_param_id) || {}).name] }), {})
                    ))
                ).then(vals => {
                    vals.map((val, i) => {
                        this.setState((prevState) => ({
                            param_data: {
                                ...prevState.param_data,
                                [callables[i].id]: val
                            }
                        }),() => {
                            if (val.length === 1) {
                                this.handleDataChange({ target: { value: val[0].id } }, callables[i].name)
                            } else{
                                let default_value = val.find(v => v.default)
                                if(default_value){
                                    this.handleDataChange({ target: { value: default_value.id } }, callables[i].name)
                                }
                            }
                        })
                    })
                }).catch(error => {
                    this.props.clearMessages()
                    throw error
                }))
            }
        })
    }

    handleDownload = () => {
        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.reportDownload(this.state.report.url, this.state.data).then((result) => {
                return FilestoreClient.download(result.hash)
            }).catch(error => {
                this.props.clearMessages()
                throw error
            })).then(() => {
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    getInput = (param) => {
        let params = {}
        params['value'] = this.state.data[param.name]
        params['onChange'] = event => this.handleDataChange(event, param.name)
        if(param.focus){
            params['autoFocus'] = true
        }
        switch (param.type) {
            case 'DATE':
                params['placeholder'] = 'éééé.hh.nn'
                return <DateField {...params} />
            case 'INTEGER':
                params['type'] = 'number'
                break
            case 'REAL':
                params['type'] = 'number'
                break
            case 'BOOL':
                params['type'] = 'checkbox'
                break
        }
        return <SimpleField {...params} />
    }

    
    getLinkForStudent = (row) => {
        return '/tanulo-kezelese/T' + (-1 * row.student_id) + '/adatok'
    }

    getLinkForRequest = (row) => {
        return '/tanulo-kezelese/T' + (-1 * row.student_id) + '/adatok/modositastortenet/'+row.id+'/'+this.state.school
    }

    render() {
        if(this.state.search_in_progress){
            return <Loading loading_text=''/>
        }
        if (this.state.result && this.props.history.location.state) {
            return <SearchResult
                result={this.state.result}
                parameters={this.state.parameters}
                conditions={this.state.data}
                order={this.state.order}
                params={this.state.params}
                param_data={this.state.param_data}
                changeOrder={order => this.handleChangeOrder(order)}
                search={page => this.handleSearch(page)}
                linkMethods={{
                    created: this.getLinkForRequest,
                    name: this.getLinkForStudent,
                }}
            />
        }
        return <form onSubmit={(event) => { event.preventDefault(); this.handleSearch(0)}}>
            {
                this.state.disable_menu ? <Loading loading_text='' /> : (
                    this.state.showed ? <React.Fragment>
                        {
                            this.state.params.length ? <React.Fragment>
                                <div className="page-subtitle">Gyorskeresés</div>
                                {
                                    this.state.params.filter(param => param.name=='student_name' || param.name=='om'|| param.name=='id').map(param => {
                                        return <div className="block block-lg-1-1">
                                            <label className={"label label-long" + (param.required ? ' required' : '')}>{param.alias}</label>
                                            {
                                                param.url ? <select multiple={param.multiple} className="field field-simple" value={this.state.data[param.name]} onChange={event => this.handleDataChange(event, param.name)}>
                                                    <option value="">Kérem válasszon!</option>
                                                    {(this.state.param_data[param.id] || []).map(school => <option value={school.id}>{school.name}</option>)}
                                                </select> :
                                                    this.getInput(param)
                                            }
                                        </div>
                                    })
                                }
                                <div className="page-subtitle">Részletes keresés</div>
                                {
                                    this.state.params.filter(param => param.name!='student_name' &&param.name!='id' && param.name!='student_id' && param.name != 'om').map(param => {
                                        return <React.Fragment>
                                            {
                                                param.name=='bank_account_number' ? <div className="block"></div> : null
                                            }
                                            <div className={"block " + (param.name !== 'school_id' ? 'block-lg-1-2' : '')}>
                                                <label className={"label label-long" + (param.required ? ' required' : '')}>{param.alias}</label>
                                                {
                                                    param.url ? <select multiple={param.multiple} className="field field-simple" value={this.state.data[param.name]} onChange={event => this.handleDataChange(event, param.name)}>
                                                        <option value="">Kérem válasszon!</option>
                                                        {(this.state.param_data[param.id] || []).map(school => <option value={school.id}>{school.name}</option>)}
                                                    </select> :
                                                        this.getInput(param)
                                                }
                                            </div>
                                        </React.Fragment>
                                    })
                                }
                            </React.Fragment> : null
                        }
                        <div className="actions">
                            <LoadingButton Loading={this.state.downloading_in_progress} type="submit">Keresés</LoadingButton>
                        </div>
                    </React.Fragment> : null
                )
            }
        </form>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        user: state.user,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdatModositasKereses))