import React, { Component } from 'react'
import { setGuide, setTitle, addAction, removeAction} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import { withRouter } from "react-router"
import DateTimeField from "@sapkk/app/components/DateTimeField";
import PopUp from "../components/PopUp";
import {formatDate} from "../functions";

class Menunaptar extends Component {
    constructor(props) {
        super(props)

        this.menu_table = React.createRef()
        this.state = {
            meal_group: this.props.meal_group,
            student: this.props.student || null,
            menu_item_for_period: this.props.menu_item_for_period,
            student_menu_item: this.props.student_menu_item,
            created: this.props.created,
            closed: this.props.closed,
            popup_info: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.created !== state.created || props.closed !== state.closed || props.student_menu_item !== state.student_menu_item  || props.meal_group !== state.meal_group || props.student !== state.student || props.menu_item_for_period !== state.menu_item_for_period || props.closed !== state.closed || props.with_next_button !== state.with_next_button) {
            return {
                student_menu_item: props.student_menu_item,
                meal_group: props.meal_group,
                student: props.student,
                menu_item_for_period: props.menu_item_for_period,
                created: props.created,
                closed: props.closed
            }
        }
        return null
    }

    openInfo = (date, menu_item_id) => {
        this.setState({
            popup_info: {
                date: date,
                menu_item_id: menu_item_id
            }
        })
    }

    closeInfo = () => {
        this.setState({
            popup_info: null
        })
    }

    render() {
        let first_day = new Date(this.state.menu_item_for_period.year, this.state.menu_item_for_period.month - 1, 1)
        let last_day = new Date(this.state.menu_item_for_period.year, this.state.menu_item_for_period.month, 0)
        let prev_month_last_day = new Date(this.state.menu_item_for_period.year, this.state.menu_item_for_period.month - 1, 0)
        let menu_items = this.state.meal_group.menu_items.filter(mi => Object.keys(this.state.menu_item_for_period.menu_item_for_period_days).find(day => mi.id in this.state.menu_item_for_period.menu_item_for_period_days[day]))
        let week_day_names  = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat']
        return <React.Fragment>
            {this.state.popup_info ? <PopUp title={formatDate(this.state.popup_info.date) +' (' + menu_items.find(mi => mi.id === this.state.popup_info.menu_item_id).item_name + ' menü)'} close={this.closeInfo} buttons={[{action: ()=>this.closeInfo(), text: 'Bezárás'}]}>
                {(()=>{
                    let disabled = this.props.isDisabled(this.state.popup_info.date,  this.state.popup_info.menu_item_id)
                    let handleOnChange = disabled || !this.props.handleOnChange ? undefined : (e, name) =>  this.props.handleOnChange(this.state.popup_info.date, this.state.popup_info.menu_item_id, name, e.currentTarget.innerText)
                    return <div className="menunaptar-info-popup">
                        <div className={"block"}>
                            <label className={"label"}>Tápanyagok</label>
                            <div className={"description"}>{<div contentEditable={!!handleOnChange} onBlur={!!handleOnChange ? (e) =>handleOnChange(e, 'nutrient_info') : null}>{this.state.menu_item_for_period.menu_item_for_period_days[this.state.popup_info.date]?.[this.state.popup_info.menu_item_id]?.nutrient_info || ''}</div>}</div>
                        </div>
                        <div className={"block"}>
                            <label className={"label"}>Allergének</label>
                            <div className={"description"}>{<div contentEditable={!!handleOnChange} onBlur={!!handleOnChange ? (e) =>handleOnChange(e, 'allergenic_info') : null}>{this.state.menu_item_for_period.menu_item_for_period_days[this.state.popup_info.date]?.[this.state.popup_info.menu_item_id]?.allergenic_info || ''}</div>}</div>
                        </div>
                    </div>
                })()}
            </PopUp> : null}
            <div className={"table-container"}><table className="menunaptar-tabla mobil" ref={this.menu_table}>
                <tbody>
                    {Array.from(Array(last_day.getDate()).keys()).map(i => i + 1).map(day_id => {
                        let today = new Date(this.state.menu_item_for_period.year, this.state.menu_item_for_period.month - 1, day_id)
                        let thursday = new Date(this.state.menu_item_for_period.year, this.state.menu_item_for_period.month - 1, day_id + (3 - today.getDay()))
                        let act_date = this.state.menu_item_for_period.year + '-' + ('' + this.state.menu_item_for_period.month).padStart(2, '0') + '-' + ('' + day_id).padStart(2, '0')

                        return <React.Fragment>
                            <tr>
                                <th colSpan={menu_items.length}>{act_date} ({week_day_names[today.getDay()]})</th>
                            </tr>
                            <tr>
                                {menu_items.map((mi, item_index) => {
                                    let disabled = this.props.isDisabled(act_date, mi.id)

                                    let onClick = disabled || !this.props.changeChosenMenuItem ? undefined : () => this.props.changeChosenMenuItem(act_date, mi.id)
                                    let handleOnChange = disabled || !this.props.handleOnChange ? undefined : (e) =>  this.props.handleOnChange(act_date, mi.id, 'description', e.currentTarget.innerText)
                                    let active = this.state.student_menu_item?.[act_date]?.menu_item_id === mi.id
                                    let have_info = !!handleOnChange || this.state.menu_item_for_period.menu_item_for_period_days[act_date]?.[mi.id]?.nutrient_info || this.state.menu_item_for_period.menu_item_for_period_days[act_date]?.[mi.id]?.allergenic_info
                                    let changed = this.state.student_menu_item?.[act_date]?.original_menu_item_id != null && this.state.student_menu_item?.[act_date]?.original_menu_item_id !== this.state.student_menu_item?.[act_date]?.menu_item_id && (this.state.student_menu_item?.[act_date]?.menu_item_id === mi.id || this.state.student_menu_item?.[act_date]?.original_menu_item_id === mi.id)
                                    return <td className={[
                                        disabled ? 'disabled-day' : null,
                                        active ? 'active' : null,
                                        onClick ? 'clickable' : null,
                                        changed ? 'changed' : null
                                    ].join(' ')} onClick={onClick}>
                                        <div>
                                            <div className={"day"}>
                                                <div>{mi.item_name}</div>
                                                <div className={"helper-icons"}>
                                                    <div className={active ? 'active' : null}></div>
                                                    <div className={have_info ? 'info' : null} onClick={have_info ? (e)=> {e.stopPropagation();this.openInfo(act_date, mi.id)} : undefined}></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"description"}>{<div  contentEditable={!!handleOnChange} onBlur={handleOnChange}>{this.state.menu_item_for_period.menu_item_for_period_days[act_date]?.[mi.id]?.description || ''}</div>}</div>
                                    </td>
                                })}
                            </tr>
                        </React.Fragment>
                    })}
                </tbody>
            </table></div>

            <div className={"table-container"}><table className="menunaptar-tabla" ref={this.menu_table}>
                <thead>
                <tr>
                    <th></th><th>Menü</th>
                    <th><span className="full-name">Hétfő</span><span className="short-name">H</span></th>
                    <th><span className="full-name">Kedd</span><span className="short-name">K</span></th>
                    <th><span className="full-name">Szerda</span><span className="short-name">Sz</span></th>
                    <th><span className="full-name">Csütörtök</span><span className="short-name">Cs</span></th>
                    <th><span className="full-name">Péntek</span><span className="short-name">P</span></th>
                    <th><span className="full-name">Szombat</span><span className="short-name">Sz</span></th>
                    <th><span className="full-name">Vasárnap</span><span className="short-name">V</span></th>
                </tr>
                </thead>
                <tbody>
                {[0, 1, 2, 3, 4, 5].map(row => {
                        return menu_items.map((mi, item_index) => {
                            let day_id = row * 7 + 3 - (first_day.getDay() !== 1 ? first_day.getDay() : 8) + 2
                            if(day_id - 3 > last_day.getDate() || day_id + 3 <= 0){
                                return null
                            }
                            let thursday = new Date(this.state.menu_item_for_period.year, this.state.menu_item_for_period.month - 1, day_id)
                            let first_week = new Date(thursday.getFullYear(), 0, 4)
                            let week_number =  1 + Math.round(((thursday.getTime() - first_week.getTime()) / 86400000 - 3 + (first_week.getDay() + 6) % 7) / 7)
                            return <tr className={item_index === 0 ? 'new-menu-row' : null}>
                                {item_index === 0 ? <td className={"week-column"} rowSpan={menu_items.length}>{week_number}. hét</td> : null}
                                <td className={"menu-item-name"}>
                                    <div>
                                        <div className={"day"}/>
                                        <div>{mi.item_name}</div>
                                    </div>
                                </td>
                                {
                                    [0, 1, 2, 3, 4, 5, 6].map(col => {

                                        let day_id = row * 7 + col - (first_day.getDay() !== 1 ? first_day.getDay() : 8) + 2
                                        let act_date = undefined
                                        let day_num

                                        if (day_id <= 0) {
                                            day_num = prev_month_last_day.getDate() + day_id
                                        } else if (day_id > last_day.getDate()) {
                                            day_num = (day_id - 1) % last_day.getDate() + 1
                                        } else {
                                            day_num = day_id
                                            act_date = this.state.menu_item_for_period.year + '-' + ('' + this.state.menu_item_for_period.month).padStart(2, '0') + '-' + ('' + day_num).padStart(2, '0')
                                        }
                                        let disabled = this.props.isDisabled(act_date, mi.id)

                                        let onClick = disabled || !this.props.changeChosenMenuItem ? undefined : () => this.props.changeChosenMenuItem(act_date, mi.id)
                                        let handleOnChange = disabled || !this.props.handleOnChange ? undefined : (e) =>  this.props.handleOnChange(act_date, mi.id, 'description', e.currentTarget.innerText)
                                        let active = this.state.student_menu_item?.[act_date]?.menu_item_id === mi.id
                                        let have_info = !!handleOnChange || this.state.menu_item_for_period.menu_item_for_period_days[act_date]?.[mi.id]?.nutrient_info || this.state.menu_item_for_period.menu_item_for_period_days[act_date]?.[mi.id]?.allergenic_info
                                        let changed = this.state.student_menu_item?.[act_date]?.original_menu_item_id != null && this.state.student_menu_item?.[act_date]?.original_menu_item_id !== this.state.student_menu_item?.[act_date]?.menu_item_id && (this.state.student_menu_item?.[act_date]?.menu_item_id === mi.id || this.state.student_menu_item?.[act_date]?.original_menu_item_id === mi.id)
                                        return <td className={[
                                            disabled ? 'disabled-day' : null,
                                            active ? 'active' : null,
                                            onClick ? 'clickable' : null,
                                            changed ? 'changed' : null
                                        ].join(' ')} onClick={onClick}>
                                            <div>
                                                <div className={"day"}>
                                                    <div>{item_index === 0 ? day_num : null}</div>
                                                    <div className={"helper-icons"}>
                                                        <div className={active ? 'active' : null}></div>
                                                        <div className={have_info ? 'info' : null} onClick={have_info ? (e)=> {e.stopPropagation();this.openInfo(act_date, mi.id)} : undefined}></div>
                                                    </div>
                                                </div>
                                                <div className={"description"}>{<div contentEditable={!!handleOnChange} onBlur={handleOnChange}>{this.state.menu_item_for_period.menu_item_for_period_days[act_date]?.[mi.id]?.description || ''}</div>}</div>
                                            </div>
                                        </td>
                                    })}
                            </tr>
                        })
                    }
                )}
                </tbody>
            </table></div>
            <div className={"block block-1-2 meal-group-menu-state meal-group-menu-state-created"}>{this.state.created ? <div className={"block"}><label className={"label label-long"}>Létrehozás időpontja</label><DateTimeField  className={"text-container"} readOnly={true} value={this.state.created}/></div> : null}</div>
            <div className={"block block-1-2 meal-group-menu-state meal-group-menu-state-closed"}>{this.state.closed? <div className={"block"}><label className={"label label-long"}>Lezárás időpontja</label><DateTimeField className={"text-container"} readOnly={true} value={this.state.closed}/></div> :  null}</div>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    addAction: addAction,
    removeAction: removeAction
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(Menunaptar))
