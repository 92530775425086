import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import DateField from "@sapkk/app/components/DateField"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import SelectField from "@sapkk/app/components/SelectField";
import LoadingButton from "../../components/LoadingButton";
import SeparatedNumberField from "@sapkk/app/components/SeparatedNumberFields"

class EtkezesFelveteleTanulohoz extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school || null,
            class: this.props.class || null,
            student: this.props.student || null,
            year: this.props.year || null,
            readOnly: this.props.readOnly,
            ...(this.props.initial_value ? this.props.initial_value : {
                reimbursements: [],
                transfer_orders: [],
                meal_groups: [],
                periods: [],
                data: undefined,
                error: false,
                loaded: false,
                school_year: undefined    
            })
        }
    }

    componentDidMount() {
        if(!this.props.initial_value){
            this.loadData()
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(props.school !== state.school || props.year !== state.year){
            return {
                year: props.year,
                school: props.school
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.school !==this.state.school || prevState.year !== this.state.year){
            this.loadData()
        }
    }

    setStateByProps = (new_state, callback= ()=>{}) => {
        if(this.props.setParentState){
            this.setState(new_state, ()=>{
                this.props.setParentState(this.state, callback)
            })
        } else{
            this.setState(new_state, callback)
        }
    }

    loadData() {
        this.setStateByProps({
            loaded: false,
            error: false,
            readOnly: this.props.readOnly
        }, () => {
            if(!this.state.school){
                this.setState({
                    loaded: true
                })
            } else{
                this.props.promise_error_handler(Promise.all([
                    CanteenClient.getReimbursements(),
                    CanteenClient.getSchoolYearByYearAndSchool(this.state.year, this.state.school),
                    CanteenClient.getMealGroupsForSchool(this.state.school)
                ]).then(([reimbursements, school_year, meal_groups]) => {
                    this.props.promise_error_handler(CanteenClient.getPeriodsForSchoolYear(school_year.id).then(periods => {
                        this.setStateByProps({
                            reimbursements: reimbursements,
                            loaded: true,
                            school_year: school_year.id,
                            meal_groups: meal_groups,
                            periods: periods
                        }, () => {
                            this.handleFetch()
                        })
                    }).catch(error => {
                        this.props.clearMessages()
                        this.setStateByProps({
                            error: true,
                            loaded: true
                        })
                        throw error
                    }))
                }).catch(error => {
                    this.props.clearMessages()
                    this.setStateByProps({
                        error: true,
                        loaded: true
                    })
                    throw error
                }))
            }
        })
    }




    handleFetch(readOnly = null) {
        this.setState({
            loaded: false
        },()=>{
            this.props.clearMessages()
            var date = new Date();
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getDiningsForStudentInSchoolAndYear(this.state.student, this.state.school, this.state.year, !!this.props.cancellingDiningStudent ),
                CanteenClient.getStudentReimbursementsForStudentForSchoolAndYear(this.state.student, this.state.school, this.state.year),
                CanteenClient.getTransfersForStudentForSchoolAndYear(this.state.student, this.state.school, this.state.year),
                CanteenClient.getFirstDayOfMonthForStudentByYearAndSchool(this.state.year, this.state.school, this.state.student, date.getFullYear(), date.getMonth() + 1),
                CanteenClient.getLastDiningForStudentForYear(this.state.student,this.state.year,this.state.school)
            ]).then(([dinings, reimbursement_students, transfer_orders, meal_group_start, last_dinings]) => {
                this.setStateByProps({
                    readOnly: readOnly!== null ? readOnly : this.state.readOnly,
                    key: (this.state.key || 0) + 1,
                    dinings: dinings,
                    reimbursement_students: reimbursement_students,
                    deleteable_dining_student_ids: [],
                    transfer_orders: transfer_orders,
                    data: {
                        meal_group_id: this.props.default_meal_group_id || '',
                        meal_group_start: this.props.default_meal_group_start || meal_group_start
                    },
                    last_dinings: last_dinings,
                    save_in_progress: false,
                    loaded: true
                })
            }))
        })
    }


    handleDataChange(name, value) {
        this.setStateByProps(prevState => ({
            data: {
                ...prevState.data,
                [name]: value !== '' ? value : null,
            }
        }))
    }

    handleSave() {
        this.props.clearMessages()

        this.setStateByProps({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.createReimbursementAndDiningsWithoutRequest(this.state.student, this.state.school_year, this.state.data.meal_group_id, this.state.data.meal_group_start, this.state.transfer_orders, this.state.reimbursement_students, this.state.deleteable_dining_student_ids).then((result) => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                result.messages.map(message => {
                    this.props.addMessage(message, 'warning')
                })
                var date = new Date();
                this.props.promise_error_handler(Promise.all([
                    CanteenClient.getDiningsForStudentInSchoolAndYear(this.state.student, this.state.school, this.state.year, !!this.props.cancellingDiningStudent ),
                    this.props.onlyShowDinings ? new Promise((resolve, reject) => resolve(null)) : CanteenClient.getStudentReimbursementsForStudentForSchoolAndYear(this.state.student, this.state.school, this.state.year),
                    this.props.onlyShowDinings ? new Promise((resolve, reject) => resolve(null)) : CanteenClient.getFirstDayOfMonthForStudentByYearAndSchool(this.state.year, this.state.school, this.state.student, date.getFullYear(), date.getMonth() + 1),
                    this.props.onlyShowDinings ? new Promise((resolve, reject) => resolve(null)) : CanteenClient.getTransfersForStudentForSchoolAndYear(this.state.student, this.state.school, this.state.year),
                    CanteenClient.getLastDiningForStudentForYear(this.state.student,this.state.year,this.state.school)
                ]).then(([dinings, reimbursement_students, meal_group_start, transfer_orders, last_dinings]) => {
                    this.setStateByProps({
                        save_in_progress: false,
                        key: (this.state.key || 0) + 1,
                        dinings: dinings,
                        reimbursement_students: reimbursement_students,
                        deleteable_dining_student_ids: [],
                        transfer_orders: transfer_orders,
                        last_dinings: last_dinings,
                        readOnly: true,
                        data: {
                            meal_group_id: '',
                            meal_group_start: meal_group_start,
                        }
                    })
                }))
            })).then(() => {
                this.setStateByProps({
                    save_in_progress: false,
                })
            })
        })
    }

    handleCancel = ()=>{
        this.handleFetch(true)
    }

    handleEdit = () =>{
        this.setState({
            readOnly: false
        })
    }

    handleAddReimbursementStudentLine() {
        this.setStateByProps((prevState, props) => ({
            reimbursement_students: prevState.reimbursement_students.concat({ reimbursement_id: "", date_from: "", date_to: "" })
        }))
    }

    handleRemoveReimbursementStudentLine(id) {
        this.setStateByProps((prevState, props) => ({
            reimbursement_students: prevState.reimbursement_students.slice(0, id).concat(prevState.reimbursement_students.slice(id - (-1)))
        }))
    }

    handleRowReimbursementStudentChange(id, name, value) {
        this.setStateByProps((prevState, props) => ({
            reimbursement_students: prevState.reimbursement_students.slice(0, id).concat([{ ...prevState.reimbursement_students[id], [name]: value }]).concat(prevState.reimbursement_students.slice(id - (-1)))
        }))
    }

    handleAddTransferOrderStudentLine() {
        this.setStateByProps((prevState, props) => ({
            transfer_orders: prevState.transfer_orders.concat({amount: "", date_from: "", date_to: "" })
        }))
    }

    handleRemoveTransferOrderStudentLine(id) {
        this.setStateByProps((prevState, props) => ({
            transfer_orders: prevState.transfer_orders.slice(0, id).concat(prevState.transfer_orders.slice(id - (-1)))
        }))
    }

    handleRowTransferOrderStudentChange(id, name, value) {
        this.setStateByProps((prevState, props) => {
            return {
                transfer_orders: prevState.transfer_orders.slice(0, id).concat([{ ...prevState.transfer_orders[id], [name]: value }]).concat(prevState.transfer_orders.slice(id - (-1)))
            }
        })
    }

    handleDeleteMealGroup = (id)=> {
        this.setStateByProps((prevState) => ({
            deleteable_dining_student_ids: prevState.deleteable_dining_student_ids.concat([id])
        }))
    }

    render() {
        if (!this.state.loaded || this.state.error) {
            return <Loading loading_text=""/>
        }
        let today = (new Date()).toISOString().split('T')[0]
        let write_transfer_order = this.props.rights_for_student.includes('WRITE_TRANSFER_ORDER')
        let current_date = new Date(Date.now())
        let current_timestamp = current_date.toISOString()?.replace('T', ' ')?.split('.')?.[0]
        return <React.Fragment>
            {this.state.data !== undefined ? <React.Fragment>
                <div className="page-subtitle">Étkezési csoport</div>
                <div className="page-subsubtitle">{this.props.onlyShowDinings ? 'Megrendelt étkezés' : 'Már felvett étkezési csoportok'}</div>
                <table className="dining-table modify-table">
                    <thead>
                        <tr>
                            <th>Étkezési csoport</th>
                            <th>Étkezés kezdete</th>
                            <th>Étkezés vége</th>
                            <th className="col-actions"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.dinings.length != 0 ? this.state.dinings.map(dining => {
                                let deleted = this.state.deleteable_dining_student_ids.includes(dining.id)
                                return <tr className={[dining.id == this.state.last_dinings.dining_student.id ? 'active-dining' : null, deleted ? 'deleted': null].filter(v => v).join(' ')}>
                                    <td>{(this.state.meal_groups.find(mg => mg.id == dining.meal_group_id) || {}).name}</td>
                                    <td><DateField readOnly={true} value={dining.date_from} /></td>
                                    <td><DateField readOnly={true} value={dining.date_to} /></td>
                                    <td className="col-actions">{this.state.readOnly || deleted ? null : <button className="remove" onClick={()=>this.handleDeleteMealGroup(dining.id)}/>}</td>
                                </tr>
                            }) : <tr className="table-empty-indicator"><td colSpan={3}>Nincs felvett étkezés</td></tr>
                        }
                    </tbody>
                </table>
                {
                    !this.state.readOnly ? <React.Fragment>
                        <div className="page-subsubtitle">Új étkezési csoport felvétele</div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long required">Étkezési csoport</label>
                            <select className="field field-simple" value={this.state.data.meal_group_id} onChange={event => this.handleDataChange('meal_group_id', event.target.value)}>
                                <option value="">Nincs módosítás</option>
                                <option value="-1">Nincs étkezési csoport</option>
                                {this.state.meal_groups.filter(meal_group => meal_group.active).map(meal_group => <option value={meal_group.id}>{meal_group.name}</option>)}
                            </select>
                        </div>
                        {
                            this.state.data.meal_group_id ? <div className="block block-lg-1-2">
                                <label className="label label-long required">Étkezés kezdete</label>
                                <DateField required={true} max={'9999-12-31'} placeholder="éééé.hh.nn" onChange={event => this.handleDataChange('meal_group_start', event.target.value)} value={this.state.data.meal_group_start} />
                            </div> : null
                        }
                        {
                            this.props.default_meal_group_id ? <div className={"messages warning"}>Az étkezési csoport és az igénylés dátuma automatikusan az igénylés alapján került kitöltésre.</div> : null
                        }
                    </React.Fragment> : null
                }
                {!this.props.onlyShowDinings && this.props.read_meal_price && this.state.readOnly ? <React.Fragment>
                    <div className="page-subtitle">Étkezés egységára (nap)</div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Érvényeség kezdete</label>
                            <DateField className="field field-simple" readOnly={true} value={this.state.last_dinings.meal_price_interval.from}/>
                        </div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Érvényeség vége</label>
                            <DateField className="field field-simple" readOnly={true} value={this.state.last_dinings.meal_price_interval.to}/>
                        </div>
                        <div className="table-container">
                            <table className="">
                                <thead>
                                    <tr>
                                        <th>Étkezés neve</th>
                                        <th>Egységár (Ft)</th>
                                    </tr>
                                </thead>
                                { this.state.last_dinings.meals.length ? <React.Fragment>
                                    <tbody>
                                    {this.state.last_dinings.meals.map(meal => 
                                        <tr>
                                            <td>{meal.meal.name}</td>
                                            <td>{meal.price.amount}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Összesen</td>
                                            <td>{this.state.last_dinings.meals.reduce((prev,curr) => prev + curr.price.amount,0)}</td>
                                        </tr>
                                    </tfoot>
                                </React.Fragment> : <tbody>
                                        <tr className="table-empty-indicator">
                                            <td colSpan="2">Nincs adat</td>
                                        </tr>
                                    </tbody>}
                            </table>
                        </div>
                    </React.Fragment> : null }
                {!this.props.onlyShowDinings ? <React.Fragment><div className="page-subtitle">Tanuló támogatásai</div>
                {
                    <table className="modify-table dining-table" key={'reimbursement_table.' + this.state.key}>
                        <thead>
                            <tr>
                                <th>Támogatás</th>
                                <th>Kezdete</th>
                                <th>Vége</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.reimbursement_students.map(((rs, id) => {
                                    return <tr key={'reimbursement-student-row.' + id}>
                                        <td>
                                            <SelectField readOnly={this.state.readOnly ||!!rs.id} value={rs.reimbursement_id} displayValues={Object.assign(...Array.from(this.state.reimbursements, reimbursement => ({ [reimbursement.id]: reimbursement.name })))} onChange={(event) => this.handleRowReimbursementStudentChange(id, 'reimbursement_id', event.target.value)}>
                                                <option value="">Kérem válasszon!</option>
                                                {this.state.reimbursements.filter(r=>r.active).map(reimbursement => <option value={reimbursement.id} key={"rs." + id + '.reimbursement.' + reimbursement.id}>{reimbursement.name}</option>)}
                                            </SelectField>
                                        </td>
                                        <td><DateField readOnly={this.state.readOnly ||!!rs.id} max="9999-12-31" value={rs.date_from} placeholder="éééé.hh.nn" onChange={(event) => this.handleRowReimbursementStudentChange(id, 'date_from', event.target.value)} /></td>
                                        <td><DateField readOnly={this.state.readOnly} min={rs.date_from} max="9999-12-31" value={rs.date_to} placeholder="éééé.hh.nn" onChange={(event) => this.handleRowReimbursementStudentChange(id, 'date_to', event.target.value)} /></td>
                                        {!this.state.readOnly ? <td className="col-actions"><button className="remove" onClick={() => this.handleRemoveReimbursementStudentLine(id)} /></td> : null}
                                    </tr>
                                }))
                            }
                            <tr className="table-new-row">
                                <td colSpan={3}>{this.state.reimbursement_students.length === 0 && "Nincs támogatás felvéve a tanulóhoz"}</td>
                                {!this.state.readOnly ? <td className="col-actions"><button onClick={() => this.handleAddReimbursementStudentLine()} /></td> : null}
                            </tr>
                        </tbody>
                    </table>
                }
                {this.props.rights_for_student.includes('READ_TRANSFER') ? <React.Fragment>
                    <div className="page-subtitle">Átalány</div>
                    <div className="table-container">
                        <table className="modify-table dining-table" key={'reimbursement_table.' + this.state.key}>
                            <thead>
                                <tr>
                                    <th>Összeg</th>
                                    <th>Kezdete</th>
                                    <th>Vége</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.transfer_orders.map(((to, id) => 
                                        <tr key={'transfer_order-student-row.' + id}>
                                            <td className="numeric-table-field"><SeparatedNumberField readOnly={this.state.readOnly ||!!to.id} value={to.amount} onChange={(event) => this.handleRowTransferOrderStudentChange(id, 'amount', event.target.value)}  type="number" /></td>
                                            <td><SelectField displayValues={this.state.periods.reduce((prev, curr) => ({...prev, [curr.id]: curr.name}),{})} readOnly={!write_transfer_order || this.state.readOnly ||!!to.id} value={to.period_from} onChange={(event) => this.handleRowTransferOrderStudentChange(id, 'period_from', event.target.value)}>
                                                    <option selected={true} value='' disabled={true}>Nincs megadva</option>
                                                    {this.state.periods.map(p => <option value={p.id} key={'period.'+p.id}>{p.name}</option>)}
                                                </SelectField></td>
                                            <td><SelectField displayValues={this.state.periods.reduce((prev, curr) => ({...prev, [curr.id]: curr.name}),{})} readOnly={!write_transfer_order || this.state.readOnly}  value={to.period_to} onChange={(event) => this.handleRowTransferOrderStudentChange(id, 'period_to', event.target.value)}>
                                                    <option selected={true}value='' disabled={true}>Nincs megadva</option>
                                                    {this.state.periods.filter(p => !to.period_from || to.period_from <= p.id).map(p => <option value={p.id} key={'period.'+p.id}>{p.name}</option>)}
                                                </SelectField>
                                            </td>
                                            {!this.state.readOnly && write_transfer_order ? <td className="col-actions"><button className="remove" onClick={() => this.handleRemoveTransferOrderStudentLine(id)} /></td> : null}
                                        </tr>
                                    ))
                                }
                                <tr className="table-new-row">
                                    <td colSpan={3}>{this.state.transfer_orders.length === 0 && "Nincs átalány felvéve a tanulóhoz"}</td>
                                    {!this.state.readOnly && write_transfer_order ? <td className="col-actions"><button onClick={() => this.handleAddTransferOrderStudentLine()} /></td> : null}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </React.Fragment> : null}
                </React.Fragment> : null}
                {!this.props.hide_buttons && !this.state.readOnly && (!this.props.school_year_data || this.props.school_year_data.date_to >= today) ? 
                    <div className="actions">
                        <LoadingButton loading={this.state.save_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton>
                        <button disabled={this.state.save_in_progress} onClick={() => this.handleCancel()}>Mégse</button>
                    </div> : null}
                {
                    !this.props.hide_buttons && this.state.readOnly && (!this.props.school_year_data || this.props.school_year_data.date_to >= today) ? <div className="actions">
                    <button disabled={this.state.save_in_progress} onClick={() => this.handleEdit()}>Szerkesztés</button>
                </div> : null
                }
                {this.props.cancellingDiningStudent ? ( this.state.dinings?.filter(dining => current_timestamp < dining.cancelling_border_date)?.length ? <div className={"actions"}>
                    <button onClick={this.props.cancellingDiningStudent}>Lemondás</button>
                </div> : <h3>Nincs lemondandó étkezése!</h3> ) : null }
            </React.Fragment> : (this.state.loaded ? null : <Loading loading_text=""/>)}

        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        aid_types: state.aid_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EtkezesFelveteleTanulohoz))