import React, { Component } from 'react'
import { setGuide, setTitle, removeAction, addAction, setSystemMessagesCount } from "../../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import { withRouter } from "react-router"
import Dokumentumok from "./Dokumentumok";
import DokumentumNyomtatvanyok from "./DokumentumNyomtatvanyok";

class DokumentumBurkolo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: null,
            key: 0,
            active_tabs: [],
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+(-1* this.props.student)+'/dokumentumok'){
            this.props.history.replace('/tanulo-kezelese/T'+(-1* this.props.student)+'/dokumentumok')
        }
        this.handleFetch()
    }

   
    handleFetch = () => {
        let active_tabs = this.getActiveTabs()
        let page = (this.props.history.location.state || {page: null}).page
        this.setState({
            active_tabs: active_tabs,
            page: active_tabs.length > 0 ? (page && active_tabs.findIndex(at => at.technical_name === page) !== -1 ? page : active_tabs[0].technical_name): null
        })
    }

    getActiveTabs = ()=>{
        return [
            { technical_name: 'Dokumentumok', order: 1, name: 'Dokumentumok', render: <Dokumentumok {...this.props} /> },
            { technical_name: 'Letölthető nyomtatványok', order: 2, name: 'Letölthető nyomtatványok', render: <DokumentumNyomtatvanyok {...this.props} /> },
            //nyomtatványok
        ].filter(menu_data => menu_data)
    }

    handleChangePage = (menu_data)=>{
        this.setState({
            page: menu_data.technical_name
        })
    }

    render() {
        return <React.Fragment key={'dokumentum_burkolo.' + this.state.key}>
            {this.state.active_tabs.length > 1 ? <div className="page-tabs">
                <ul>
                    {this.state.active_tabs.filter(menu => menu.name).sort((a,b) =>a.order-b.order).map(menu_data => <li onClick={()=>this.handleChangePage(menu_data)} className={menu_data.technical_name === this.state.page ? 'active' : null}>
                        {menu_data.name}
                    </li>)}
                </ul>
            </div> : null}
            {
                this.state.active_tabs.map(menu_data => {
                    if(this.state.page === menu_data.technical_name){
                        return menu_data.render
                    } else {
                        return null
                    }
                })
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        user: state.user
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    addAction: addAction,
    removeAction: removeAction,
    setSystemMessagesCount: setSystemMessagesCount,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DokumentumBurkolo))
