import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import SablonDokumentumEloallito from "./SablonDokumentumEloallito"

class Sablon extends Component {
    constructor(props) {
        super(props)

        this.file_input = React.createRef()

        this.state = {
            school: this.props.school,
            document_hash: [],
            save_in_progress: false,
            fetch_in_progress: true,
            template: {},
            edit: false,
            file_input_key: 0,
            years: [],
            key: 0
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getActiveTemplateForSchool(this.state.school,this.props.template_type),
            CanteenClient.getSchoolYearsBySchoolID(this.state.school),
        ]).then(([template,school_years]) => {
            this.setState({
                edit: template === null,
                template: template ? template : {
                        id: null,
                        document_hash: null,
                    },
                fetch_in_progress: false,
                school_years: school_years,
                year: (school_years.find(school_year => school_year.default)|| school_years[0] || {}).year_id,
            })
        }))
    }

    handleEdit = (new_template) => {
        this.setState((prevState) => ({
            edit: true,
            original_template: prevState.template,
            template: {
                ...prevState.template,
                ...(new_template ? {
                    id: null,
                    document_hash: null
                } : {}),
            },
            key: prevState.key + 1
        }))
    }

    handleCancel = () => {
        this.setState((prevState) => ({
            edit: false,
            template: prevState.original_template,
            original_template: undefined,
            key: prevState.key + 1
        }))
    }

    uploadFile() {
        if (this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            }, () => {
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0]).then(hash => {
                    this.setState(prevState => ({
                        upload_in_progress: false,
                        template: {
                            ...prevState.template,
                            document_hash: hash
                        }
                    }))
                }))
            })
        }
    }

    getDownloadMethod = (in_system,data) => {
        return this.props.getDownloadMethod(this.state, in_system, data)
    }


    handleSave = () => {
        if (this.state.edit) {
            this.props.clearMessages()

            this.setState({
                save_in_progress: true
            }, () => {
                this.props.promise_error_handler(()=>CanteenClient.createTemplate(this.state.school, this.state.template.document_hash,this.props.template_type).then(result => {
                    this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                    this.setState((prevState) => ({
                        template: result,
                        original_template: undefined,
                        edit: false,
                        file_input_key: prevState.file_input_key + 1,
                        key: prevState.key + 1,
                        save_in_progress: false
                    }))
                })).then(() => {
                    this.setState({
                        save_in_progress: false
                    })
                })
            })
        }
    }

    render() {
        let readOnly = !this.props.rights_for_school.includes('WRITE_TEMPLATE')
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
            <div className="page-subtitle">{this.props.title}</div>
                    <div className="block">
                        <label className="label label-long required">Sablon dokumentum</label>
                        {!this.state.template.id ? (readOnly ? <div className="text-container">Nincs dokumentum feltöltve</div> : <input key={'file_input_' + this.state.file_input_key} ref={this.file_input} disabled={this.state.upload_in_progress} className="field" type="file" onChange={() => this.uploadFile()} />) : <div><button onClick={() => this.props.promise_error_handler(FilestoreClient.download(this.state.template.document_hash))}>Letöltés</button></div>}
                    </div>
                    {!readOnly ? <div className="actions">
                    {this.state.edit ? <React.Fragment>
                        <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                        {this.state.original_template ? <button onClick={this.handleCancel}>Mégse</button> : null}
                    </React.Fragment> : <React.Fragment>
                            <button onClick={() => this.handleEdit(true)}>Új létrehozása</button>
                        </React.Fragment>}
                    </div>: null}
                <SablonDokumentumEloallito
                    date_title = {this.props.date_title}
                    template={this.state.template}
                    school={this.props.school}
                    rights_for_school={this.props.rights_for_school}
                    with_date={!!this.props.with_date}
                    with_zero_disable_option={!!this.props.with_zero_disable_option}
                    can_download_online={this.props.can_download_online}
                    getDownloadMethod={this.getDownloadMethod}/>
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sablon))