import React, { Component } from 'react'
import { setGuide, setTitle} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import { withRouter } from "react-router"
import Loading from '@sapkk/app/components/Loading'
import ContentWithSideMenu from './ContentWithSideMenu'
import SzamlazashozAtadottListak from './Szamlazas/SzamlazashozAtadottListak'
import SAPKulonbseg from './Szamlazas/SAPKulonbseg'
import SAPVevoBetolto from "./SAPVevoBetolto";
import NemTanuloiVevokodok from "./Szamlazas/NemTanuloiVevokodok";
import EgyebRiportok from "./Szamlazas/EgyebRiportok";

class Szamlazas extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: 0,
            loaded: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = ()=>{
        this.props.setTitle("Számlázás")
        this.setState({
            loaded: true
        })
    }

    render() {
        let menus = [
            {id:1, url: '/szamlazas/szamlazashoz-atadott-listak', name: 'Számlázáshoz átadott listák', render: <SzamlazashozAtadottListak/>},
            {id:2, url: '/szamlazas/sap-szinkronizacio', name: 'SAP szinkronizáció', render: <SAPKulonbseg/>},
            ('alkalmazasgazda' in this.props.rights.rights ? {id:3, url:  '/szamlazas/vevokodok-betoltese',name:'Vevőkódok betöltése', render: <SAPVevoBetolto/> }: null),
            ('alkalmazasgazda' in this.props.rights.rights ? {id:4, url:  '/szamlazas/nem-tanuloi-vevokodok',name:'Nem tanulói vevőkódok', render: <NemTanuloiVevokodok/> }: null),
            {id:5, url: '/szamlazas/egyeb-riportok', name: 'Egyéb riportok', render: <EgyebRiportok/>},
        ].filter(menu_data => menu_data)
        let menu_objects = [
            {title:'Számlázás', menu_elements: menus, with_route: true, active_method: (menu_data)=>menu_data.url === this.props.history.location.pathname || this.props.history.location.pathname.indexOf(menu_data.url+'/')===0}
        ]
        return <React.Fragment key={'tamogatas.'+this.state.key}>
            {
                this.state.loaded ? <ContentWithSideMenu key={this.state.keys} menus={menu_objects} default_path={'/szamlazas'} url_base={'szamlazas'}>
                </ContentWithSideMenu> : <Loading loading_text=""/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Szamlazas))
