import * as React from "react"
import {withRouter} from "react-router"
import {Component} from "react";
import {setPromptBlock} from "../actions";
import {connect} from "react-redux";


class NavigationLock extends Component {
    componentDidMount() {
        if (this.props.when) {
            this.startBlocking()
        }
    }

    componentWillUnmount() {
        this.stopBlocking()
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.when && this.props.when) {
            this.startBlocking()
        } else if (prevProps.when && !this.props.when) {
            this.stopBlocking()
        }
    }

    render() {
        return null
    }

    onBeforeUnload = (event) => {
        event.preventDefault()
        event.returnValue = ""
    }

    startBlocking = () => {
        if (!this.unblock) {
            const message = this.props.message
            this.unblock = this.props.history.block(message)
        }
        this.props.setPromptBlock(true)
        window.addEventListener("beforeunload", this.onBeforeUnload)
    }

    stopBlocking = () => {
        if (this.unblock) {
            this.unblock()
            this.unblock = undefined
        }
        this.props.setPromptBlock(false)
        window.removeEventListener("beforeunload", this.onBeforeUnload)
    }
}
const mapDispatchToProps = {
    setPromptBlock: setPromptBlock
}


export default withRouter(connect(null, mapDispatchToProps)(NavigationLock))
