import React, { Component } from 'react'
import { addMessage, clearMessages, setGuide, setTitle, setUserData } from "../actions"
import { connect } from "react-redux"
import AuthClient from "@sapkk/auth-client"
import CanteenClient from "../CanteenClient";

class Login extends Component {
    ref_username = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            logging_in: false,
            error: false
        }
    }

    componentDidMount() {
        this.props.setTitle('Bejelentkezés')
        this.props.setGuide(null)

        if (this.ref_username.current) {
            this.ref_username.current.focus()
        }
    }

    handleChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleLogin() {
        if (this.state.username === '' || this.state.password === '')
            return

        this.props.clearMessages()

        this.setState({
            logging_in: true,
            error: false
        })

        CanteenClient.login(this.state.username, this.state.password).catch(() =>
            AuthClient.elevate('login', {username: this.state.username, password: this.state.password}).then(() =>
                AuthClient.checkAuth()
            )
        ).then(user_data =>
            this.props.setUserData(user_data)
        ).catch(() => {
            this.setState({
                logging_in: false,
                error: true
            })
            this.props.addMessage('Hibás felhasználói azonosító vagy jelszó.', 'error')
        })
    }

    render() {
        return (
            <React.Fragment>
                <form onSubmit={(event) => {event.preventDefault(); this.handleLogin()}}>
                    <div className="block block-lg-1-2" onChange={event => this.handleChange(event.target.name, event.target.value)}>
                        <div className="block">
                            <label className="label label-long">Felhasználói azonosító</label>
                            <input ref={this.ref_username} name="username" disabled={this.state.logging_in}/>
                        </div>
                        <div className="block">
                            <label className="label label-long">Jelszó</label>
                            <input name="password" type="password" disabled={this.state.logging_in}/>
                        </div>
                    </div>
                    <div className="actions">
                        <button type="submit" disabled={this.state.logging_in}>Bejelentkezés</button>
                    </div>
                </form>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = {
    setUserData: setUserData,
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default connect(null, mapDispatchToProps)(Login)
