export function getObjectValue(obj, path) {
    for (var i = 0; i < path.length; i++){
        if(obj==undefined){
            return undefined;
        }
        if(Array.isArray(obj)){
            obj = obj.reduce((prev, curr) => {
                if(Array.isArray(curr)){
                    return prev.concat(curr)
                } else{
                    return prev.concat([curr])
                }
            },[]).map(o => o[path[i]])
        } else{
            obj = obj[path[i]];
        }
    }
    return obj;
}

export function convertDate(origin_date){
    if(!origin_date){
        return ''
    }
    let date_tmp =  origin_date.replace(/\./g,'-').replace(/ /g,'').replace(/^[\-]+|[\-]+$/g, "")
    let date = ''
    if(date_tmp.split("-").length - 1 == 2 && date_tmp.split("-")[2].replace(/0/g,'').length > 0){
        let d = new Date(date_tmp)
        if(d instanceof Date && !isNaN(d)){
            date = d.toISOString().split('T')[0]
        }
    }
    return date
}

export function formatDate(origin_date){
    return origin_date ? origin_date.replace(/-/g,'. ')+'.' : ''
}

export function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function getDefaultPeriod(periods){
    let date = new Date().toISOString().split('T')[0]
    let date_parts = date.split('-')
    let actual_period =periods.findIndex(p => parseInt(p.year) * 100 + parseInt(p.month) === parseInt(date_parts[0]) * 100 + parseInt(date_parts[1]))
    if(actual_period === -1){
        actual_period = periods.findIndex(p => parseInt(p.year) * 100 + parseInt(p.month) > parseInt(date_parts[0]) * 100 + parseInt(date_parts[1]))
    }
    if(actual_period === -1){
        actual_period = periods.length - 1 - periods.reverse().findIndex(p => parseInt(p.year) * 100 + parseInt(p.month) < parseInt(date_parts[0]) * 100 + parseInt(date_parts[1]))
    }
    return actual_period
}