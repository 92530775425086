import React, { Component } from 'react'
import { setGuide, setTitle } from "../../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import SimpleField from "@sapkk/app/components/SimpleField"
import DateField from "@sapkk/app/components/DateField"
import SelectField from "@sapkk/app/components/SelectField"
import { withRouter } from "react-router"

class TanuloiAdatok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school || null,
            class: this.props.class || null,
            student: this.props.student || null,
            year: this.props.year || null,
            rights: this.props.rights_for_student,
            cities: this.props.cities,
            invoice_cities: this.props.invoice_cities,
            countries: this.props.countries,
            street_types: this.props.street_types,
            student_data: this.props.student_data,
            payment_methods: this.props.payment_methods,
            read_only: this.props.readOnly,
            write_normal_data: this.props.write_normal_data,
            write_om_and_birthday_data: this.props.write_om_and_birthday_data,
            write_financial_data: this.props.write_financial_data,
            write_invoice_recipient_data: this.props.write_invoice_recipient_data,
            read_actual_financial_data: this.props.read_actual_financial_data
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.cities !== state.cities || props.invoice_cities !== state.invoice_cities ||props.student_data !== state.student_data || props.read_only !== state.read_only || props.write_normal_data !== state.write_normal_data || props.write_financial_data !== state.write_normal_data) {
            return {
                cities: props.cities,
                invoice_cities: props.invoice_cities,
                student_data: props.student_data,
                read_only: props.read_only,
                write_financial_data: props.write_financial_data,
                write_normal_data: props.write_normal_data,
                write_om_and_birthday_data: props.write_om_and_birthday_data,
                write_invoice_recipient_data: props.write_invoice_recipient_data,
                read_actual_financial_data: props.read_actual_financial_data
            }
        } else {
            return null
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.student_data.student_data.authentic === null ? <div className="not-authenticated">Jóváhagyásra vár!</div> : null
                }
                <div className="page-subtitle">Általános adatok</div>
                <div className="adatmodositas">
                    <div className={this.state.read_actual_financial_data ? "block block-lg-1-4" : "block block-lg-2-4"}>
                        <label className="label label-long">Név</label>
                        <SimpleField readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.name || "")!==(this.props.prev_student_data.student_data.name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.name || "")!==(this.props.prev_student_data.student_data.name || "") )? this.props.prev_student_data.student_data.name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange(null, 'name', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Oktatási azonosító</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_om_and_birthday_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.om || "")!==(this.props.prev_student_data.om || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.om || ""} title={ this.props.prev_student_data && ((this.state.student_data.om || "")!==(this.props.prev_student_data.om || "") )? this.props.prev_student_data.om || "" : null} onChange={event => !this.props.handleChange ? null : this.props.handleChange('om', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Születési dátum</label>
                        <DateField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_om_and_birthday_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.birthday || "")!==(this.props.prev_student_data.birthday || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.birthday || ""} title={ this.props.prev_student_data && ((this.state.student_data.birthday || "")!==(this.props.prev_student_data.birthday || "") )? this.props.prev_student_data.birthday || "" : null} onChange={event => !this.props.handleChange ? null : this.props.handleChange('birthday', event.target.value)} />
                    </div>
                    {
                        this.state.read_actual_financial_data ? <div className="block block-lg-1-4">
                            <label className="label label-long">SAP vevőkód</label>
                            <SimpleField readOnly={true} className={['field', 'field-simple'].filter(v=>v).join(' ')} value={this.state.student_data.student_data.sap_customer_code || ""}/>
                        </div> : null
                    }
                </div>
                <div className="page-subtitle">Lakcím adatok</div>
                <div className="page-subguide address-text">Kérjük, hogy a házszám, épület, lépcsőház, szint, ajtó mezők kitöltésekor a sorszám után tegyen pontot.</div>
                <div className="adatmodositas">
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Ország</label>
                        <SelectField readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.country_id || "")!==(this.props.prev_student_data.student_data.address_data.country_id || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.country_id || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.country_id || "")!==(this.props.prev_student_data.student_data.address_data.country_id || "") )? (this.state.countries.find(v => v.id==this.props.prev_student_data.student_data.address_data.country_id)||{name: ''}).name : null} displayValues={Object.assign(...Array.from(this.state.countries, country => ({ [country.id]: country.name })))} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'country_id', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {this.state.countries.map(country => <option key={country.id} value={country.id}>{country.name}</option>)}
                        </SelectField>
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Irányítószám</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.zip_code || "")!==(this.props.prev_student_data.student_data.address_data.zip_code || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.zip_code || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.zip_code || "")!==(this.props.prev_student_data.student_data.address_data.zip_code || "") )? this.props.prev_student_data.student_data.address_data.zip_code || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'zip_code', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Város/község</label>
                        {
                            this.state.cities !== undefined && this.state.cities.length > 1 && (this.state.countries.find(x => x.id == this.state.student_data.student_data.address_data.country_id || "") || {}).country_code === 'HU' ? <SelectField readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.city_name || "")!==(this.props.prev_student_data.student_data.address_data.city_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.city_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.city_name || "")!==(this.props.prev_student_data.student_data.address_data.city_name || "") )? this.props.prev_student_data.student_data.address_data.city_name || "" : null} displayValues={Object.assign(...Array.from(this.state.cities, city => ({ [city.name]: city.name })))} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'city_name', event.target.value)}>
                                <option value="" disabled={true}>Kérem válasszon!</option>
                                {this.state.cities.map(city => <option key={city.name} value={city.name}>{city.name}</option>)}
                            </SelectField> : <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data || (this.state.countries.find(x => x.id == this.state.student_data.student_data.address_data.country_id || "") || {}).country_code === 'HU'} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.city_name || "")!==(this.props.prev_student_data.student_data.address_data.city_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.city_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.city_name || "")!==(this.props.prev_student_data.student_data.address_data.city_name || "") )? this.props.prev_student_data.student_data.address_data.city_name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'city_name', event.target.value)} />
                        }
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Kerület</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.district || "")!==(this.props.prev_student_data.student_data.address_data.district || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.district || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.district || "")!==(this.props.prev_student_data.student_data.address_data.district || "") )? this.props.prev_student_data.student_data.address_data.district || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'district', event.target.value)} />
                    </div>
                    <div className="block block-lg-2-4">
                        <label className="label label-long">Közterület neve</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.street_name || "")!==(this.props.prev_student_data.student_data.address_data.street_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.street_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.street_name || "")!==(this.props.prev_student_data.student_data.address_data.street_name || "") )? this.props.prev_student_data.student_data.address_data.street_name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'street_name', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Közterület jellege</label>
                        { (this.state.countries.find(x => x.id == this.state.student_data.student_data.address_data.country_id || "") || {}).country_code === 'HU' ? <SelectField readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.street_type_name || "")!==(this.props.prev_student_data.student_data.address_data.street_type_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.street_type_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.street_type_name || "")!==(this.props.prev_student_data.student_data.address_data.street_type_name || "") )? this.props.prev_student_data.student_data.address_data.street_type_name || "" : null} displayValues={Object.assign(...Array.from(this.state.street_types, street_type => ({ [street_type.name]: street_type.name })))} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'street_type_name', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {this.state.street_types.filter(x => x.country_id == this.state.student_data.student_data.address_data.country_id).map(street_type => <option key={street_type.id} value={street_type.name}>{street_type.name}</option>)}
                        </SelectField> : <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data || (this.state.countries.find(x => x.id == this.state.student_data.student_data.address_data.country_id || "") || {}).country_code === 'HU'} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.street_type_name || "")!==(this.props.prev_student_data.student_data.address_data.street_type_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.street_type_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.city_name || "")!==(this.props.prev_student_data.student_data.address_data.street_type_name || "") )? this.props.prev_student_data.student_data.address_data.street_type_name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'street_type_name', event.target.value)} /> }
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Házszám/helyrajziszám</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.house_number || "")!==(this.props.prev_student_data.student_data.address_data.house_number || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.house_number || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.house_number || "")!==(this.props.prev_student_data.student_data.address_data.house_number || "") )? this.props.prev_student_data.student_data.address_data.house_number || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'house_number', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Épület</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.building || "")!==(this.props.prev_student_data.student_data.address_data.building || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.building || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.building || "")!==(this.props.prev_student_data.student_data.address_data.building || "") )? this.props.prev_student_data.student_data.address_data.building || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'building', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Lépcsőház</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.staircase || "")!==(this.props.prev_student_data.student_data.address_data.staircase || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.staircase || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.staircase || "")!==(this.props.prev_student_data.student_data.address_data.staircase || "") )? this.props.prev_student_data.student_data.address_data.staircase || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'staircase', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Szint</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.floor || "")!==(this.props.prev_student_data.student_data.address_data.floor || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.floor || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.floor || "")!==(this.props.prev_student_data.student_data.address_data.floor || "") )? this.props.prev_student_data.student_data.address_data.floor || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'floor', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Ajtó</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.address_data.door || "")!==(this.props.prev_student_data.student_data.address_data.door || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.address_data.door || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.address_data.door || "")!==(this.props.prev_student_data.student_data.address_data.door || "") )? this.props.prev_student_data.student_data.address_data.door || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('address_data', 'door', event.target.value)} />
                    </div>
                </div>
                <div className="page-subtitle">Fizetési adatok</div>
                <div className="adatmodositas">
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Fizetésmód</label>
                        <SelectField readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.payment_method_id || "")!==(this.props.prev_student_data.student_data.payment_method_id || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.payment_method_id || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.payment_method_id || "")!==(this.props.prev_student_data.student_data.payment_method_id || "") )? (this.state.payment_methods.find(v => v.id==this.props.prev_student_data.student_data.payment_method_id)||{alias: ''}).alias : null} displayValues={Object.assign(...Array.from(this.state.payment_methods, payment_method => ({ [payment_method.id]: payment_method.alias })))} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange(null, 'payment_method_id', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {this.state.payment_methods.map(payment_method => <option key={payment_method.id} value={payment_method.id}>{payment_method.alias}</option>)}
                        </SelectField>
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Tulajdonos (szülő) neve:</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.payers_name || "")!==(this.props.prev_student_data.student_data.payers_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.payers_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.payers_name || "")!==(this.props.prev_student_data.student_data.payers_name || "") )? this.props.prev_student_data.student_data.payers_name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange(null, 'payers_name', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Számlaszámtípus:</label>
                        <SelectField readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.bank_account_type || "")!==(this.props.prev_student_data.student_data.bank_account_type || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.bank_account_type || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.bank_account_type || "")!==(this.props.prev_student_data.student_data.bank_account_type || "") )? (this.props.bank_account_types[this.props.prev_student_data.bank_account_type]||{name: ''}).name : null} displayValues={Object.keys(this.props.bank_account_types).reduce((prev,curr)=>({...prev,[curr]:this.props.bank_account_types[curr].name}),{})} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange(null, 'bank_account_type', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {Object.keys(this.props.bank_account_types).map(bank_account_type => <option key={bank_account_type} value={bank_account_type}/>)}
                        </SelectField>
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Bankszámlaszám</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_normal_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.account_number || "")!==(this.props.prev_student_data.student_data.account_number || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.account_number || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.account_number || "")!==(this.props.prev_student_data.student_data.account_number || "") )? this.props.prev_student_data.student_data.account_number || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange(null, 'account_number', event.target.value)} placeholder="xxxxxxxx-xxxxxxxx-xxxxxxxx" />
                    </div>
                </div>
                <div className="page-subtitle">Számlázási adatok</div>
                { (!this.state.student_data.student_data.invoice_recipient_data || this.state.write_invoice_recipient_data) && <div className="block">
                    { this.state.write_invoice_recipient_data && <input id="same_address" disabled={this.state.read_only || !this.state.write_invoice_recipient_data} className={[this.props.prev_student_data && ((!!this.state.student_data.student_data.invoice_recipient_data)!==(!!this.props.prev_student_data.student_data.invoice_recipient_data) )? 'diff' : null].filter(v=>v).join(' ')} title={ this.props.prev_student_data && ((!!this.state.student_data.student_data.invoice_recipient_data)!==(!!this.props.prev_student_data.student_data.invoice_recipient_data) )? (!this.props.prev_student_data.student_data.invoice_recipient_data ? 'Igen' : 'Nem') : null} type="checkbox" checked={!this.state.student_data.student_data.invoice_recipient_data} onChange={event => !this.props.handleSameAddressCheckboxChange ? null : this.props.handleSameAddressCheckboxChange(event.target.checked)} /> }
                    <label htmlFor="same_address" style={{ paddingLeft: '10px' }}>A számlázási adatok megegyeznek a lakcím adatokkal.</label>
                </div> }
                { this.state.student_data.student_data.invoice_recipient_data &&
                <div className="adatmodositas">
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Név</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data).name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data).name || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data).name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'name', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Adószám</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.tax_number || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).tax_number || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.tax_number || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.tax_number || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).tax_number || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).tax_number || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'tax_number', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Ország</label>
                        <SelectField readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.country_id || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).country_id || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.country_id || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.country_id || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).country_id || "") )? (this.state.countries.find(v => v.id==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).country_id))||{name: ''}).name : null} displayValues={Object.assign(...Array.from(this.state.countries, country => ({ [country.id]: country.name })))} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'country_id', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {this.state.countries.map(country => <option key={country.id} value={country.id}>{country.name}</option>)}
                        </SelectField>
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Irányítószám</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.zip_code || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).zip_code || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.zip_code || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.zip_code || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).zip_code || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).zip_code || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'zip_code', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Város/község</label>
                        {
                            this.state.invoice_cities !== undefined && this.state.invoice_cities.length > 1 && (this.state.countries.find(x => x.id == this.state.student_data.student_data.invoice_recipient_data.country_id || "") || {}).country_code === 'HU' ? <SelectField readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.city_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).city_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.city_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.city_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).city_name || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).city_name || "" : null} displayValues={Object.assign(...Array.from(this.state.invoice_cities, city => ({ [city.name]: city.name })))} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'city_name', event.target.value)}>
                                <option value="" disabled={true}>Kérem válasszon!</option>
                                {this.state.invoice_cities.map(city => <option key={city.name} value={city.name}>{city.name}</option>)}
                            </SelectField> : <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data || (this.state.countries.find(x => x.id == this.state.student_data.student_data.invoice_recipient_data.country_id || "") || {}).country_code === 'HU'} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.city_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).city_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.city_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.city_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).city_name || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).city_name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'city_name', event.target.value)} />
                        }
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Kerület</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.district || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).district || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.district || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.district || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).district || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).district || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'district', event.target.value)} />
                    </div>
                    <div className="block block-lg-2-4">
                        <label className="label label-long">Közterület neve</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.street_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.street_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.street_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_name || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'street_name', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Közterület jellege</label>
                        { (this.state.countries.find(x => x.id == this.state.student_data.student_data.invoice_recipient_data.country_id || "") || {}).country_code === 'HU' ?
                        <SelectField readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-select',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.street_type_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_type_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.street_type_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.street_type_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_type_name || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_type_name : null} displayValues={Object.assign(...Array.from(this.state.street_types, street_type => ({ [street_type.name]: street_type.name })))} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'street_type_name', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {this.state.street_types.filter(x => x.country_id == this.state.student_data.student_data.invoice_recipient_data.country_id).map(street_type => <option key={street_type.id} value={street_type.name}>{street_type.name}</option>)}
                        </SelectField> : <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.street_type_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_type_name || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.street_type_name || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.street_type_name || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_type_name || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).street_type_name || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'street_type_name', event.target.value)} /> }
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Házszám/helyrajziszám</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.house_number || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).house_number || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.house_number || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.house_number || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).house_number || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).house_number || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'house_number', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Épület</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.building || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).building || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.building || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.building || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).building || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).building || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'building', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Lépcsőház</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.staircase || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).staircase || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.staircase || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.staircase || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).staircase || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).staircase || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'staircase', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Szint</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.floor || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).floor || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.floor || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.floor || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).floor || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).floor || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'floor', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-4">
                        <label className="label label-long">Ajtó</label>
                        <SimpleField onKeyUp={this.props.capsLockDetector} readOnly={this.state.read_only || !this.state.write_invoice_recipient_data} className={['field', 'field-simple',this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.door || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).door || "") )? 'diff' : null].filter(v=>v).join(' ')} value={this.state.student_data.student_data.invoice_recipient_data.door || ""} title={ this.props.prev_student_data && ((this.state.student_data.student_data.invoice_recipient_data.door || "")!==((this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).door || "") )? (this.props.prev_student_data.student_data.invoice_recipient_data || this.props.prev_student_data.student_data.address_data).door || "" : null} onChange={event => !this.props.handleDataChange ? null : this.props.handleDataChange('invoice_recipient_data', 'door', event.target.value)} />
                    </div>
                </div>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        bank_account_types: state.bank_account_types
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloiAdatok))
