import React, { Component } from 'react'
import { setGuide, setTitle, setMasquaradedStudent, removeAction, addAction, setSystemMessagesCount } from "../../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import { withRouter } from "react-router"
import TanuloiAdatok from './TanuloiAdatok'
import KapcsolattartasiAdatok from './KapcsolattartasiAdatok'
import SAPAdat from './SAPAdat'
import SzamlaFormatum from "./SzamlaFormatum";

class Adat extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.id,
            student_register: this.props.student_register,
            rights: this.props.rights_for_student,
            page: null,
            key: 0,
            active_tabs: [],
            read_only: !(this.props.history.location.state || {edit: false}).edit,
            date_from: (this.props.history.location.state || {date_from: null}).date_from
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+this.state.id+'/adatok/torzsadat'){
            this.props.history.replace(
                '/tanulo-kezelese/T'+this.state.id+'/adatok/torzsadat',{
                    edit: !this.state.read_only,
                    date_from: this.state.date_from
            })
        }
        this.handleFetch()
    }

   
    handleFetch = () => {
        let active_tabs = this.getActiveTabs()
        let page = (this.props.history.location.state || {page: null}).page
        this.setState({
            active_tabs: active_tabs,
            page: active_tabs.length > 0 ? (page && active_tabs.findIndex(at => at.technical_name === page) !== -1 ? page : active_tabs[0].technical_name): null
        })
    }

    getActiveTabs = ()=>{
        return [
            this.state.rights.includes('READ_DATA') ? { technical_name: 'adatok', order: 1, name: 'Törzsadat', render: <TanuloiAdatok student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} year={this.state.student_register.year.id} class={this.state.student_register.class.id} rights_for_student={this.state.rights} /> } : null,
            this.state.rights.includes('READ_NOTIFICATION_DATA') ? { technical_name: 'kapcsolattartasi-adatok', order: 2, name: 'Kapcsolattartási adatok', render: <KapcsolattartasiAdatok student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} year={this.state.student_register.year.id} class={this.state.student_register.class.id} rights_for_student={this.state.rights} /> } : null,
            this.state.rights.includes('READ_DISABLED_E_INVOICE') ? { technical_name: 'szamlaformatum', order: 3, name: 'Számlaformátum', render: <SzamlaFormatum student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} year={this.state.student_register.year.id} class={this.state.student_register.class.id} rights_for_student={this.state.rights} /> } : null,
            this.state.rights.includes('WRITE_FINANCIAL') ? { technical_name: 'sap-adatok', order: 4, name: 'SAP adatok', render: <SAPAdat  student={this.state.student_register.student.id} rights_for_student={this.state.rights} /> } : null,
        ].filter(menu_data => menu_data)
    }

    handleChangePage = (menu_data)=>{
        this.setState({
            page: menu_data.technical_name
        })
    }

    render() {
        return <React.Fragment key={'tanulo.' + this.state.key}>
            {this.state.active_tabs.length > 1 ? <div className="page-tabs">
                <ul>
                    {this.state.active_tabs.filter(menu => menu.name).sort((a,b) =>a.order-b.order).map(menu_data => <li onClick={()=>this.handleChangePage(menu_data)} className={menu_data.technical_name === this.state.page ? 'active' : null}>
                        {menu_data.name}
                    </li>)}
                </ul>
            </div> : null}
            {
                this.state.active_tabs.map(menu_data => {
                    if(this.state.page === menu_data.technical_name){
                        return menu_data.render
                    } else {
                        return null
                    }
                })
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        user: state.user
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    setMasquaradedStudent: setMasquaradedStudent,
    addAction: addAction,
    removeAction: removeAction,
    setSystemMessagesCount: setSystemMessagesCount,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Adat))
