import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import SearchResult from "../SearchResult";
import Loading from "@sapkk/app/components/Loading"

class KezelendoAdatmodositasiKerelmek extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            page: (this.props.history.location.state || {}).page || null,
            skip_update: false,
            parameters: {
                limit: 20
            },
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/iskola-kezelese/'+this.state.school+'/feldolgozatlan-torzsadatmodositasok'){
            this.props.history.replace('/iskola-kezelese/'+this.state.school+'/feldolgozatlan-torzsadatmodositasok')
        }
        this.handleSearch(this.state.page || 0)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.history.location.state && !state.skip_update) {
            return {
                page: props.history.location.state.page
            }
        } else {
            return null
        }
    }

    handleSearch = (page) =>{
        this.props.clearMessages()

        this.setState({
            search_in_progress: true,
            skip_update: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.listStudentDataInformation({
                authentic: [{operator: 3}],
                school_id_at_creation: [{operator: 9, value: this.state.school}],
                school_id: [{operator: 9, value: this.state.school}]
            }, {
                info: true,
                limit: this.state.parameters.limit,
                offset: page* this.state.parameters.limit
            },[],['original_school_id','school_id','student_id','authentic']).then(result => {
                this.setState({
                    search_in_progress: false,
                    result: {
                        result: result.result || [],
                        result_summary: {
                            ...result.result_summary,
                            page: page
                        },
                        headers: [
                            {name: 'student_name', alias: 'Tanuló neve', childrens: [], depth: 1, level: 1, order: 1, path: ['student_name'],parent_header_id: null, mergeable: false},
                            {name: 'created', alias: 'Adatmódosítás időpontja', childrens: [], depth: 1, level: 1, order: 1, path: ['created'],parent_header_id: null, mergeable: false},
                        ]
                    }
                }, () => {
                    this.props.history.replace(this.props.history.location.pathname, {
                        ...this.props.history.location.state,
                        page: this.state.result.result_summary.page
                    })
                })
            })).then(() =>
                this.setState({
                    search_in_progress: false,
                    skip_update: false
                })
            )
        })
    }

    getLinkForRequest = (row={}) =>{
        return '/iskola-kezelese/' + this.state.school + '/feldolgozatlan-torzsadatmodositasok/T'+(-1*row.student_id)
    }

    render() {
        if (this.state.result && this.props.history.location.state) {
            return <SearchResult
                title={'Feldolgozásra váró törzsadatok'}
                not_found_text={'Nincs feldolgozásra váró törzsadat'}
                result={this.state.result}
                parameters={this.state.parameters}
                search={page => this.handleSearch(page)}
                linkMethods= {{
                    student_name: this.getLinkForRequest
                }}
            />
        }
        return <Loading loading_text=""/>
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KezelendoAdatmodositasiKerelmek))