import React, { Component } from 'react'
import { addMessage, clearMessages, setPageData } from "../actions"
import { connect } from "react-redux"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"

class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            schools: [],
            topics: [],
            name: props.user.id > 0 ? props.user.full_name : '',
            email: props.user.id > 0 ? props.user.email: '',
            phone_number: '',
            school: '',
            topic: '',
            message: '',
            privacy_policy_accepted: false,
            send_in_progress: false
        }
    }

    componentDidMount() {
        this.props.setPageData({
            title: 'Támogatáskérés'
        })

        CanteenClient.getSupportContactInfo().then(result =>
            this.setState({
                schools: result.schools,
                topics: result.topics,
                school: result.schools.length === 1 ? result.schools[0].id : '',
                topic: result.topics.length === 1 ? result.topics[0].id : ''
            })
        ).catch(() => {
        })
    }

    handleChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleSend() {
        if (this.props.support_info.privacy_policy && !this.state.privacy_policy_accepted) {
            this.props.clearMessages()
            this.props.addMessage("Az üzenet elküldéséhez az adatkezelési hozzájárulás szükséges.", 'error')
            return
        }

        this.setState({
            send_in_progress: true
        })

        this.props.promise_error_handler(CanteenClient.sendSupportContactMessage(this.state.name, this.state.email, this.state.phone_number, this.state.school, this.state.topic, this.state.message).then(() => {
            this.props.history.push('')
        })).then(() => {
            this.setState({
                send_in_progress: false
            })
        })
    }

    render() {
        return (
            <div onChange={event => this.handleChange(event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value)}>
                <div className="page-subtitle">Személyes adatok</div>
                <div className="block">
                    <label className="label label-long required">Név</label>
                    <input name="name" value={this.state.name}/>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long required">E-mail-cím</label>
                    <input name="email" value={this.state.email}/>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Telefonszám</label>
                    <input name="phone_number" value={this.state.phone_number}/>
                </div>
                <div className="page-subtitle">Üzenet</div>
                <div className="block">
                    <label className="label label-long required">Iskola</label>
                    <select name="school" value={this.state.school}>
                        <option disabled={true} value="">Kérem válasszon!</option>
                        { this.state.schools.map(school => <option key={school.id} value={school.id}>{school.name}</option>) }
                    </select>
                </div>
                <div className="block">
                    <label className="label label-long required">Tárgy</label>
                    <select name="topic" value={this.state.topic}>
                        <option disabled={true} value="">Kérem válasszon!</option>
                        { this.state.topics.map(topic => <option key={topic.id} value={topic.id}>{topic.name}</option>) }
                    </select>
                </div>
                <div className="block">
                    <label className="label label-long required">Üzenet</label>
                    <textarea rows={5} name="message" value={this.state.message}/>
                </div>
                { this.props.support_info.privacy_policy &&
                <React.Fragment>
                    <div className="page-subtitle">Adatkezelési hozzájárulás</div>
                    <div className="block">
                        <input id="privacy_policy_accepted" name="privacy_policy_accepted" type="checkbox" checked={this.state.privacy_policy_accepted}/>
                        <label htmlFor="privacy_policy_accepted" className="required" style={{paddingLeft: '10px'}}>Az <a target="_blank" rel="noopener noreferrer" href={this.props.support_info.privacy_policy}>Adatkezelési tájékoztatót</a> megismerve hozzájárulok adataim felhasználásához.</label>
                    </div>
                </React.Fragment>
                }
                <div className="actions">
                    <button disabled={this.state.send_in_progress} onClick={() => this.handleSend()}>Küldés</button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        user: state.user,
        support_info: state.support_info
    }
}

const mapDispatchToProps = {
    setPageData: setPageData,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact))
