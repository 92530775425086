import { combineReducers } from 'redux'
import {
    APP_CHANGE, RIGHTS_CHANGE, SET_SYSTEM_MESSAGES_COUNT, ADD_ACTION, REMOVE_ACTION, CHANGE_PROMPT_CALLBACK, SET_PROMPT_BLOCK
} from './actions'

import { messages, page as old_page, support_info, user } from "@sapkk/app/reducers"

export function app(
    state = {
        promise_error_handler: undefined
    },
    action
) {
    switch (action.type) {
        case APP_CHANGE:
            return {
                promise_error_handler: action.promise_error_handler
            }
        default:
            return state
    }
}

function aid_types(
    state = {
        DAILY_AMOUNT: {
            name: 'Napi összeg',
            unit: 'Ft'
        },
        DAILY_PERCENT: {
            name: 'Napi százalék',
            unit: '%'
        },
        MONTHLY_AMOUNT: {
            name: 'Havi összeg',
            unit: 'Ft'
        },
        MONTHLY_PERCENT: {
            name: 'Havi százalék',
            unit: '%'
        }
    },
    action
) {
    return state
}


function bank_account_types(
    state = {
        HU: {
            name: 'Magyar'
        },
        IBAN: {
            name: 'IBAN'
        }
    },
    action
) {
    return state
}

function billing_types(
    state = {
        LUMP_SUM: {
            name: 'Átalány'
        },
        REAL_BILLING: {
            name: 'Valós számlázás'
        }
    },
    action
) {
    return state
}

function class_types(
    state = {
        KOZEPISKOLA: {
            name: 'Középiskola'
        },
        ALTALANOS_ISKOLA: {
            name: 'Általános iskola'
        },
        OVODA: {
            name: 'Óvoda'
        }
    },
    action
) {
    return state
}

function field_types(
    state = {
        STRING: {
            name: 'Szöveges',
        },
        INTEGER: {
            name: 'Egész szám',
        },
        REAL: {
            name: 'Szám',
        },
        BOOL: {
            name: 'Igaz/hamis',
        },
        DATE: {
            name: 'Dátum'
        },
        FILE: {
            name: 'Fájl'
        }
    },
    action
) {
    return state
}
function text_styles(
    state = {
        ['page-subtitle']: {
            name: 'Alcím',
        },
        ['block-lg-1-2']: {
            name: 'Nagy monitoron 50% szélességű',
        },
        ['canteen-text-style-bold']: {
            name: 'Félkövér'
        },
        ['canteen-text-style-16-font-size']: {
            name: 'Nagy'
        },
        ['canteen-text-style-18-font-size']: {
            name: 'Nagyobb'
        },
        ['canteen-text-style-italic']: {
            name: 'Dőlt'
        },
        ['canteen-text-bold-text-in-field-column']: {
            name: 'Mezőoszlopban megjelenő félkövér'
        }
    },
    action
) {
    return state
}

function meal_request_states(
    state = {
        CREATED: {name: 'Jóváhagyásra vár'},
        REJECTED: {name: 'Elutasított'},
        ACCEPTED: {name: 'Elfogadott'},
        UNDONE: {name: 'Visszavont'},
        UPDATED: {name: 'Jóváhagyásra vár (módosított)'}
    },
    action
) {
    return state
}

function student_data_request_states(
    state = {
        WAITING: {name: 'Jóváhagyásra vár'},
        ACCEPTED: {name: 'Elfogadva'},
        REJECTED: {name: 'Elutasítva'},
    },
    action
) {
    return state
}

function creator_types(
    state = {
        STUDENT: {name: 'Tanuló'},
        ADMINISTRATOR: {name: 'Ügyintéző'},
    },
    action
) {
    return state
}

function rights(
    state = {
        rights: [],
        schools: [],
        students: []
    },
    action
) {
    switch (action.type) {
        case RIGHTS_CHANGE:
            return {
                rights: action.rights,
                schools: action.schools,
                students: action.students,
                can_have_children: action.can_have_children
            }
        default:
            return state
    }
}

function system_messages(state={count: null}, action){
    switch (action.type) {
        case SET_SYSTEM_MESSAGES_COUNT:
            return {
                count: action.system_messages_count
            }
        default:
            return state
    }
}

function page(state = {
        title: null,
        guide: null,
        actions: []
    },
    action){
        switch (action.type) {
            case ADD_ACTION:
                var tmp_actions = state.actions.filter(v=>v.id!=action.action.id)
                tmp_actions.push(action.action)
                tmp_actions.sort((a,b) => (a.order||0)-(b.order||0))
                return {
                    ...state,
                    actions: tmp_actions.filter((v,i) => tmp_actions.map(a=>a.id).indexOf(v.id) === i)
                }
            case REMOVE_ACTION:
                var tmp_actions = state.actions.filter(v=>v.id!=action.action_id)
                return {
                    ...state,
                    actions: tmp_actions.filter((v,i) => tmp_actions.map(a=>a.id).indexOf(v.id) === i)
                }
            default:
                return old_page(state,action);
        }
}

function prompt_info(
    state = {
        callback: ()=>{},
        block: false
    },
    action
) {
    switch (action.type) {
        case CHANGE_PROMPT_CALLBACK:
            return {
                ...state,
                callback: action.callback
            }
        case SET_PROMPT_BLOCK:
            return {
                ...state,
                block: action.block
            }
        default:
            return state
    }
}


const appReducer = combineReducers({
    app,
    aid_types,
    class_types,
    user,
    rights,
    page,
    messages,
    support_info,
    field_types,
    text_styles,
    meal_request_states,
    student_data_request_states,
    system_messages,
    creator_types,
    bank_account_types,
    billing_types,
    prompt_info
})

export default appReducer
