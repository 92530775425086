import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import SimpleField from "@sapkk/app/components/SimpleField";
import SelectField from "@sapkk/app/components/SelectField";
import LoadingButton from "../components/LoadingButton";

class JelszoModositasa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            student_id: this.props.student || null,
            old_password: '',
            new_password: '',
            new_password_again: '',
            save_in_progress: false
        }
    }

    handleSave = ()=>{
        this.props.clearMessages()
        let messages = []
        if(this.state.new_password!==this.state.new_password_again){
            messages.push('Nem egyezik meg a jelszó')
        }
        if(this.state.new_password.length < 6){
            messages.push('Minimum hat karaktert adjon meg jelszónak')
        }
        if(messages.length > 0){
            messages.forEach(message => {
                this.props.addMessage(message, 'error')
            })
        } else{
            this.setState({
                save_in_progress: false
            },()=>{
                this.props.promise_error_handler(CanteenClient.changePasswordForStudent(this.state.student_id,this.state.old_password,this.state.new_password).then(result => {
                    this.setState({
                        old_password: '',
                        new_password: '',
                        new_password_again: ''
                    },()=>{
                        this.props.addMessage('A jelszó sikeresen módosítva','success')
                    })
                })).then(()=>{
                    this.setState({
                        save_in_progress: false
                    })
                })
            })
        }
    }

    handleChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    render() {
        return <React.Fragment>
            <form onSubmit={(event) => {event.preventDefault();this.handleSave()}}>
                <div className="block">
                    <div className="block">
                        <label className="label label-long">Felhasználó:</label>
                        <SelectField name="username" className="field field-simple" readOnly={true}  value={ this.state.student_id } onChange={event => this.handleChange('student_id', event.target.value)} displayValues={this.props.rights.students.reduce((prev, curr) => ({
                            ...prev,
                            [curr.id]: curr.name + ' ('+curr.om+')'
                        }),{})}>
                            <option value="">Kérem válasszon!</option>
                            {this.props.rights.students.map(student => <option value={student.id}/>)}
                        </SelectField>
                    </div>
                    <div className="block">
                        <label className="label label-long">Régi jelszó</label>
                        <SimpleField className="field field-simple" value={ this.state.old_password } onChange={event => this.handleChange('old_password', event.target.value)} type="password"/>
                    </div>
                    <div className="block">
                        <label className="label label-long">Új jelszó</label>
                        <SimpleField name="password" className="field field-simple" value={ this.state.new_password } onChange={event => this.handleChange('new_password', event.target.value)} type="password"/>
                    </div>
                    <div className="block">
                        <label className="label label-long">Új jelszó megerősítése</label>
                        <SimpleField className="field field-simple" value={ this.state.new_password_again } onChange={event => this.handleChange('new_password_again', event.target.value)} type="password"/>
                    </div>
                    <div className="actions">
                        <LoadingButton type="submit" loading={this.state.save_in_progress}>Mentés</LoadingButton>
                    </div>
                </div>
            </form>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JelszoModositasa))