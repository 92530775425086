import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import SearchResult from "../SearchResult";
import Loading from "@sapkk/app/components/Loading"
import { addAction, removeAction } from "../../actions"
import PopUp from "../../components/PopUp"

class AdatModositasok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            student: this.props.student,
            page: (this.props.history.location.state || {}).page || null,
            rights: this.props.rights_for_student || [],
            skip_update: false,
            parameters: {
                limit: 20
            },
        }
    }

    componentDidMount() {
        if (this.props.pathname && this.props.history.location.pathname !== this.props.pathname) {
            this.props.history.replace(this.props.pathname)
        }
        this.getUnseenStudentDataID()
        this.handleSearch(this.state.page || 0)
        if(!this.props.embded){
            if (Object.keys(this.props.rights.rights).length === 0) {
                this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
            }
        }
    }

    getUnseenStudentDataID = ()=>{
        this.props.promise_error_handler(CanteenClient.getUnseenStudentData(this.state.student).then(ids => {
            this.setState({
                unseen_student_data_ids: ids,
            })
        }))
    }

    componentWillUnmount(){
        if(!this.props.embded){
            this.props.removeAction('help')
        }
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.history.location.state && !state.skip_update) {
            return {
                page: props.history.location.state.page
            }
        } else {
            return null
        }
    }

    getClasesForCreated = (row) => {
        if((this.state.unseen_student_data_ids||[]).find(id => id==row.id)){
            return ['unseen']
        }
        return []
    }

    handleSearch = (page) => {
        this.props.clearMessages()

        this.setState({
            search_in_progress: true,
            skip_update: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.listStudentDataInformation({
                ...(this.props.conditions || {}),
                student_id: [{ operator: 9, value: this.state.student }],
                ...(this.state.school ? {school_id: [{operator: 9, value: this.state.school}]} : {})
            }, {
                info: true,
                limit: this.state.parameters.limit,
                offset: page * this.state.parameters.limit
            }, [
                { field: 'max_created', order: 'DESC' }, { field: 'school_ids_equals', order: 'ASC'}
            ], ['original_school_id','school_id', 'student_id', 'prev_id', 'id', 'authentic']).then(result => {
                this.setState({
                    search_in_progress: false,
                    result: {
                        result: result.result.map(r => ({ ...r, state: (this.props.student_data_request_states[r.state] || {}).name })) || [],
                        result_summary: {
                            ...result.result_summary,
                            page: page
                        },
                        headers: [
                            this.props.school ? null : { name: 'school_abbr', alias: 'Iskola', childrens: [], depth: 1, level: 1, order: 1, path: ['school_abbr'], parent_header_id: null, mergeable: false },
                            { name: 'created', class_method: this.getClasesForCreated, alias: 'Létrehozás időpontja', childrens: [], depth: 1, level: 1, order: 1, path: ['created'], parent_header_id: null, mergeable: false },
                            { name: 'state', alias: 'Állapot', childrens: [], depth: 1, level: 1, order: 1, path: ['state'], parent_header_id: null, mergeable: false },
                        ].filter(v=>v)
                    }
                }, () => {
                    this.props.history.replace(this.props.history.location.pathname, {
                        ...this.props.history.location.state,
                        page: this.state.result.result_summary.page,
                        school: this.state.school
                    })
                })
            })).then(() =>
                this.setState({
                    search_in_progress: false,
                    skip_update: false
                })
            )
        })
    }

    render() {
        return <React.Fragment>
            { this.state.popup_help_open && (
                <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                    <p>
                        <div>Az <span style={{fontWeight: 'bold'}}>„Adatok”</span> -&gt; <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>„Módosítástörténet”</span> menüpontban a tanuló törzsadataival kapcsolatban minden adatmódosítási kezdeményezés látható, akár az iskolai ügyintéző, akár a szülő kezdeményezte.</div>
                        <div>A táblázatban megtekinthető, hogy az adatmódosítás mikor került rögzítésre, és az Állapot oszlopban, hogy az iskolai ügyintéző által elfogadásra, elutasításra került, vagy jóváhagyásra vár a törzsadatmódosítás.</div>
                        <div>A törzsadatmódosítás létrehozásának időpontjára kattintva a megjelenő képernyőn – narancssárgával jelölve – látszik, hogy milyen törzsadatmódosítás lett kezdeményezve.</div>
                        <div>Amennyiben a törzsadatmódosítás az ügyintéző által elutasításra került, a képernyő alján az iskolai ügyintéző által küldött üzenet is látható. Elutasítás esetén a módosítás nem kerül rögzítésre az „Adatok” -&gt; „Törzsadat” menüpontban. Az ügyintézővel történő szülői egyeztetés után, amennyiben szükséges új törzsadat módosítást kell kezdeményezni.</div>
                    </p>
                </PopUp>
            ) }
            {this.state.result && this.props.history.location.state ? <SearchResult
                result={this.state.result}
                parameters={this.state.parameters}
                search={page => this.handleSearch(page)}
                title={'Módosítástörténet'}
                not_found_text={'Nincs adatmódosítás'}
                tableClassName= {'student-data'}
                linkMethods={{
                    created: this.props.getLinkForRequest
                }}
            /> : <Loading loading_text=""/>}
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        rights: state.rights,
        student_data_request_states: state.student_data_request_states,
        creator_types: state.creator_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,    
    addAction: addAction,
    removeAction: removeAction,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdatModositasok))