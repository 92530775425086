class SAPServicesClient {
    static getCalendarSpecialDaysByInterval(date_from, date_to) {
        return fetch(process.env.REACT_APP_SERVICE_URL + '/SAPServices/Calendar/getCalendarSpecialDaysByInterval', {
            credentials: 'include',
            method: "post",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                begin_date: date_from,
                end_date: date_to
            })
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }
}
export default SAPServicesClient
