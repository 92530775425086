import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage, setActions } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import SelectField from "@sapkk/app/components/SelectField"
import FilestoreClient from "../../FileStoreClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import LoadingButton from "../../components/LoadingButton"
import PopUp from "../../components/PopUp"
import { addAction, removeAction } from "../../actions"
import { Link } from "react-router-dom"

class TanuloiIgenylesek extends Component {
    constructor(props) {
        super(props)

        this.file_input = React.createRef()

        this.state = {
            school: this.props.school || null,
            student: this.props.student,
            meal_requests: [],
            fetch_in_progress: true,
            empty: false
        }
    }

    componentDidMount() {
        if (this.props.history.location.pathname !== '/tanulo-kezelese/T' + (-1 * this.state.student) + '/tanuloi-igenylesek/korabbi') {
            this.props.history.replace(
                '/tanulo-kezelese/T' + (-1 * this.state.student) + '/tanuloi-igenylesek/korabbi', {
                edit: !this.state.read_only,
                date_from: this.state.date_from
            })
        }
        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
        this.props.promise_error_handler(Promise.all([
            CanteenClient.listStudentMealRequests(this.state.student),
        ]).then(([meal_requests]) => {
            this.setState({
                meal_requests: meal_requests,
                fetch_in_progress: false
            })
        }))
    }

    componentWillUnmount() {
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    render() {
        let pdf_upload = this.props.rights_for_student.includes('WRITE_MEAL_REQUEST_WITH_PDF')
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
                {
                    false && this.props.school_data.meal_request_text ? <div className="page-guide meal-request-text">{this.props.school_data.meal_request_text}</div> : null
                }
                { this.state.popup_help_open && (
                    <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                        <p>
                            <div>Az <span style={{fontWeight: 'bold'}}>„Étkezés igénylése”</span> menüpontban a szülő a gyerek részére új étkezési igényt adhat le, vagy az étkezést lemondhatja (megszüntetheti), valamint lehetőség van a korábban leadott igénylések megtekintésére.</div>
                        </p>
                        <p>
                            <div style={{fontWeight: 'bold'}}>Étkezés megrendelése</div>
                            <div>Az <span style={{fontWeight: 'bold'}}>„Új igénylés – online”</span> blokkban lévő „Létrehozás” ikonra kattintva van lehetőség az online megrendelés létrehozására.</div>
                        </p>
                        <p>
                            <div style={{fontWeight: 'bold'}}>Étkezés lemondása</div>
                            <div>A korábban megrendelt étkezést lemondani egy új igénylés létrehozásával lehet, ahol a tanév kiválasztása után az Igénylés kezdetének a <span style={{textDecoration: 'underline'}}>lemondás dátumát</span> az igényelt étkezési csoportnál az „<span style={{textDecoration: 'underline'}}>étkezést nem kérek</span>” opciót kell kiválasztani, majd az igénylést elmenteni.</div>
                        </p>
                    </PopUp>
                ) }
                <div className="page-subtitle">Korábbi igénylések</div>
                <div className="table-container">
                    <table className="striped modify-table meal-requests-table">
                        <thead>
                            <tr>
                                <th>Tanév</th>
                                <th>Iskola</th>
                                <th>Azonosító</th>
                                <th>Létrehozva</th>
                                <th>Online igénylés</th>
                                <th>Státusz</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.meal_requests.map((data, id) => {
                                return <tr>
                                    <td>{data.year_name}</td>
                                    <td>{data.school_abbr}</td>
                                    <td><Link to={this.props.history.location.pathname + '/' + data.id}>{data.id}</Link></td>
                                    <td>{data.creation_time} {data.unseen_message_count ? <span className="unread-message-count"> ({data.unseen_message_count})</span> : null}</td>
                                    <td>{data.in_system ? 'Igen' : 'Nem'}</td>
                                    <td>{(this.props.meal_request_states[Object.keys(this.props.meal_request_states).find(mrs => data.state === mrs)] || {}).name}</td>
                                </tr>
                            }
                            )}
                            {this.state.meal_requests.length === 0  || this.props.rights_for_student.includes('WRITE_MEAL_REQUEST') && (new Date()).toISOString().split('T')[0] <= (this.props.student_register||{}).date_to ?<tr className="table-new-row">
                                <td colSpan={7}>{this.state.meal_requests.length === 0 && "A tanulóhoz nem tartozik még igénylés."}</td>
                            </tr> : null}
                            {
                                pdf_upload && (new Date()).toISOString().split('T')[0] <= (this.props.student_register||{}).date_to ? <tr className="table-new-row">
                                    <td colSpan={7}></td>
                                </tr> : null
                            }
                        </tbody>
                    </table>
                </div>
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        meal_request_states: state.meal_request_states,
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    setActions: setActions,
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloiIgenylesek))