import React, { Component } from 'react'
import Loading from '@sapkk/app/components/Loading';

class LoadingButton extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        let props = {...this.props}
        props['disabled'] = props['disabled'] || props['loading']
        return <button {...Object.keys(props).filter(key => key!=='children' && key!=='loading').reduce((prev, curr) => ({...prev, [curr]: props[curr]}),{})}>
            {
                this.props.loading ? <Loading loading_text =''/> : this.props.children
            }
        </button>
    }
}

export default LoadingButton
