import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import Field from "./Field"
import Text from "./Text"
import FilestoreClient from "../../FileStoreClient"
import LoadingButton from "../../components/LoadingButton"

class Igenyles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            class: this.props.class,
            student: this.props.student,
            year: this.props.year,
            template: this.props.template,
            meal_request: this.props.meal_request,
            edit: !this.props.meal_request,
            save_in_progress: false,
            fetch_in_progress: true,
            fields: []
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            this.state.template ?
                CanteenClient.listFieldsWithPossibleValues(this.state.template.template_objects.filter(to=>to.is_field).map(to=>to.field_or_text_id),this.state.student,this.state.school,this.state.year,this.state.meal_request.cancelling_dining_student)
                : Promise.all([
                    CanteenClient.listMealRequestFields({name:[{operator: 9, value: 'meal_group_id'},{operator: 9, value: 'date_from'}]}),
                    CanteenClient.listMealRequestFields({type:[{operator: 9, value: 'FILE'}]})
                ]).then(([fields_1, fields_2]) => CanteenClient.listFieldsWithPossibleValues(fields_1.map(f => f.id).concat(fields_2.map(f => f.id)),this.state.student,this.state.school,this.state.year, this.state.meal_request.cancelling_dining_student)),
        ]).then(([fields]) => {
            this.setState((prevState) =>({
                fields: fields,
                fetch_in_progress: false
            }))
        })).then(()=>{
            this.setState({
                fetch_in_progress: false
            })
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.meal_request !== state.meal_request) {
            return {
                meal_request: props.meal_request
            }
        } else {
            return null
        }
    }

    calculateFieldProperty(data, formula) {
        let stack = []
        formula.split(' ').forEach(o => {
            if (o === '&&' || o === '||' || o === '==' || o === '!=' || o === 'in') {
                var a = stack.pop()
                var b = stack.pop()
                if (o === '&&'){
                    stack.push(a && b)
                } else if (o === '||') {
                    stack.push(a || b)
                } else if (o === '==') {
                    stack.push(a == b)
                } else if (o === '!=') {
                    stack.push(a != b)
                } else if (o === 'in') {
                    stack.push((b || []).map(v => ''+v).includes(a))
                }
            } else if (o === 'not') {
                stack.push(!stack.pop())
            } else if (o === 'length') {
                stack.push((stack.pop()||[]).length)
            } else if (o === '#') {
                let field_name = stack.pop()
                stack.push(data[field_name].value)
            } else {
                stack.push(o)
            }
        })
        return stack.pop()
    }


    render() {
        return <React.Fragment>
            {
                !this.state.fetch_in_progress ? <React.Fragment>
                    {
                        !this.state.meal_request.in_system ? <React.Fragment>
                            <div className="block">
                                <label className="label label-long required">Igénylésdokumentum</label>
                                <div className="text-container">
                                {this.state.meal_request.document_hash ? <a onClick={()=>this.props.promise_error_handler(FilestoreClient.download(this.state.meal_request.document_hash))}>Letöltés</a> : <span className='text-container'>Nincs megadva</span>}
                                {!this.props.readOnly && this.props.file_input ? <React.Fragment>
                                    <LoadingButton loading={this.props.upload_in_progress} onClick={()=>{this.props.file_input.current.click()}}>Feltöltés</LoadingButton>
                                </React.Fragment> : null}
                                </div>
                            </div>
                        </React.Fragment> : null
                    }
                    {
                        this.state.meal_request.in_system ? this.state.template.template_objects.map(to => {
                            if(to.is_field){
                                let field = this.state.fields.find(f => f.id==to.field_or_text_id)
                                if(!field){
                                    return null
                                }
                                if(to.required_formula){
                                    field = {
                                        ...field,
                                        required: this.calculateFieldProperty(this.state.meal_request.data,to.required_formula)
                                    }
                                }
                                if(to.multiple_formula){
                                    field = {
                                        ...field,
                                        multiple: this.calculateFieldProperty(this.state.meal_request.data,to.multiple_formula)
                                    }
                                }
                                if(to.disabled_value_formula && field.possible_values){
                                    field = {
                                        ...field,
                                        possible_values: field.possible_values.map(pv => {
                                            return {
                                                ...pv,
                                                disabled: this.calculateFieldProperty({
                                                    ...this.state.meal_request.data,
                                                    _value: {value: pv.value}
                                                },to.disabled_value_formula)
                                            }
                                        })
                                    }
                                }
                                return <Field is_cancelling={this.state.meal_request.cancelling_dining_student} documents={this.state.meal_request.documents} value={this.state.meal_request.data[field.name]} readOnly={this.props.readOnly} field={field} handleFileUpload={this.props.handleFileUpload} handleAddLine={this.props.handleAddLine} handleChange={this.props.handleChange} handleBlur={this.props.handleBlur} border_date={this.props.border_date} acceptable_date={this.props.acceptable_date} handleRemoveRow={this.props.handleRemoveRow}/>
                            } else{
                                return <Text {...to.text_data} data={this.state.meal_request.data} fields={this.state.fields}/>
                            }
                        }) : <React.Fragment>
                            {
                                this.state.fields.sort((a, b) => a.required ? (b.required ? 0 : -1) : (b.required ? 1 : 0)).map(field => {
                                    let value = (this.state.meal_request.data[field.name]||{})
                                    return <Field is_cancelling={this.state.meal_request.cancelling_dining_student} documents={this.state.meal_request.documents} value={value} readOnly={this.props.readOnly} field={field} handleFileUpload={this.props.handleFileUpload} handleAddLine={this.props.handleAddLine} handleChange={this.props.handleChange} handleBlur={this.props.handleBlur} border_date={this.props.border_date} acceptable_date={this.props.acceptable_date} handleRemoveRow={this.props.handleRemoveRow}/>
                                })                                
                            }
                        </React.Fragment>
                    }
                </React.Fragment> : <Loading loading_text=''/>
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Igenyles))