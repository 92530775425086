import React, { Component } from 'react'
import { addAction, removeAction, setGuide, setTitle } from "../actions"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { withRouter } from "react-router"
import PopUp from "../components/PopUp"

class Home extends Component {
    constructor(props) {
        super(props)
        this.state= {
            menus: undefined,
            popup_help_open: false
        }
    }

    componentDidMount(){
        this.props.setTitle('Kezdőoldal')
        this.props.setGuide(null)
        this.loadData()
        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
    }

    componentWillUnmount() {
        this.props.removeAction('help')
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.rights !== this.props.rights) {
            this.loadData()
        }
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    loadData = ()=>{
        let rights = Object.keys(this.props.rights.rights)
        let menus = this.props.user.id ? [
            rights.length === 0 ? null : {url: 'tanulo-kezelese', name: 'Tanulói törzsadatok'},
            rights.length === 0 && (this.props.rights.students.length > 0 ) ? {url: 'tanuloi-adatok', name: 'Tanulói adatok'} : null,
            rights.length === 0 && (this.props.rights.students.length > 0 ) ? {url: 'menzanaptar', name: 'Hiányzás jelentés / menzanaptár'} : null,
            rights.length === 0 && (this.props.rights.students.length > 0 ) ? {url: 'tanuloi-igenylesek', name: 'Étkezés igénylése / étkezés végleges lemondása'} : null,
            rights.length === 0 && (this.props.rights.students.length > 0 ) ? {url: 'dokumentumok', name: 'Dokumentumok'} : null,
            this.props.rights.students.length > 0 ? {url: 'jelszo-modositasa', name: 'Jelszó módosítása'}: null,
            rights.length === 0 && this.props.rights.can_have_children ? {url: 'uj-gyerek-hozzarendelese', name: 'Új gyerek hozzárendelése'} : null,
            rights.length === 0 && (this.props.rights.students.length > 0 ) ? {url: 'szamlak', name: 'Számlák'} : null,
            rights.length === 0 && (this.props.rights.students.findIndex(st => st.have_menu) !== -1 ) ? {url: 'menu', name: 'Menü - Étlap'} : null,
            rights.length > 0 ? {url: 'iskola-kezelese', name: 'Iskola kezelése'} :null,
            //(rights.includes('alkalmazasgazda') || rights.includes('penzugyi_ugyintezo')) ? {url: 'befizetesek-feltoltese', name: 'Befizetések feltöltése'} : null,
            //(rights.includes('alkalmazasgazda') || rights.includes('ugyintezo')) ? {url: 'szamlak-feltoltese', name: 'Számlák feltöltése'} : null,
            rights.length > 0  ? {url: 'riportok', name: 'Riportok'} : null,
            rights.includes('alkalmazasgazda') /*|| rights.includes('penzugyi_ugyintezo')*/ ? {url: 'szamlazas', name: 'Számlázás'} : null,
            rights.includes('alkalmazasgazda') ? {url: 'iskola-kezelese/uj', name: 'Új iskola felvétele', alkalmazasgazda: true} : null,
            rights.includes('alkalmazasgazda') ? {url: 'tanev-aktivalasa', name: 'Tanév aktiválása', alkalmazasgazda: true} : null,
            rights.includes('alkalmazasgazda') ? {url: 'etkezesi-tipus-kezelese', name: 'Étkezési típus', alkalmazasgazda: true} : null,
            rights.includes('alkalmazasgazda') ? {url: 'tamogatas-kezelese', name: 'Támogatások', alkalmazasgazda: true} : null,
            rights.includes('alkalmazasgazda') ? {url: 'igenylesmezok-kezelese', name: 'Igénylésmezők kezelése', alkalmazasgazda: true} : null,
            rights.includes('alkalmazasgazda') ? {url: 'nemregiben-aktiv-felhasznalok', name: 'Nemrégiben aktív felhasználók', alkalmazasgazda: true} : null,
        ].filter(menu => menu) : []
        if(menus.length==1){
            this.props.history.replace('/'+menus[0].url)
        } else{
            this.setState({
                menus: menus
            })
        }
    }

    render() {
        let rights = Object.keys(this.props.rights.rights)
        if(!this.state.menus || this.state.menus.length===0){
            return null
        }
        return (
            <div id="dashboard">
                { this.state.popup_help_open && (
                    <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                        <p>A rendszerben lehetőség van a Debreceni Egyetem köznevelési intézményeiben tanuló diákok részére az intézmény által biztosított étkezés megrendelésére/lemondására.</p>
                        <p>Gyerekének (az étkezés megrendeléséhez szükséges) adatait a <span style={{fontWeight: 'bold'}}>„TANULÓI ADATOK”</span> menüpont alatt tudja megadni, módosítani. Amennyiben ezekben változás következett be, azt 8 napon belül kérjük a rendszerben módosítani, vagy bejelenteni az Iskolai ügyintéző részére. Ezen menüpont „Kapcsolattartási adatok” fülén megadhatja az e-mail-címét, így a rendszer a felületre érkező értesítésekről e-mailben is tájékoztatást küld (étkezés igényléssel, a tanuló törzsadataiban bekövetkezett változással, a rendszerbe feltöltött elektronikus számlával kapcsolatban).</p>
                        <p>A megrendelt étkezés – iskolai hiányzás miatt – lemondását a <span style={{fontWeight: 'bold'}}>„MENZANAPTÁR”</span> menüpont alatt tudja megtenni.</p>
                        <p>Az <span style={{fontWeight: 'bold'}}>„ÉTKEZÉS IGÉNYLÉSE”</span> menüpont alatt gyereke részére étkezést igényelhet vagy lemondhatja azt.</p>
                        {this.props.rights.students.findIndex(st => st.have_menu) !== -1 ? <React.Fragment>
                            <p style={{marginBottom: 0}}>A <span style={{fontWeight: 'bold'}}>„MENÜ-ÉTLAP”</span> menüpontban lehetősége van az <span style={{fontStyle: 'italic'}}>„A”</span> és <span style={{fontStyle: 'italic'}}>„B”</span> menü közül választania. A menü kiválasztására a tárgyhónapot megelőző hónap 15-25 közötti időszakban van lehetőség.</p>
                            <p style={{marginTop: 0}}>Amennyiben nem történik menüválasztás, úgy automatikusan <span style={{fontStyle: 'italic'}}>„A”</span> menü kerül megrendelésre. Diétás és vegetáriánus étkezőknek nincs lehetőség választani.</p>
                        </React.Fragment> : null}
                        <p>A <span style={{fontWeight: 'bold'}}>„DOKUMENTUMOK”</span> menüpontban tekintheti meg a korábban rendszer által generált, illetve manuálisan – Ön vagy az ügyintéző által – feltöltött dokumentumokat. Valamint itt van lehetősége további – étkezési igényléshez kapcsolódó – dokumentumok feltöltésére.</p>
                        <p>A <span style={{fontWeight: 'bold'}}>„JELSZÓ MÓDOSÍTÁSA”</span> menüpont alatt lehetősége van a jelszavát módosítani.</p>
                        <p>A <span style={{fontWeight: 'bold'}}>„SZÁMLÁK”</span> menüpont alatt megtekintheti az étkezési díjról kiállított számlák adatait (számlaszám, összeg, kiegyenlítés összege), illetve ha elektronikus számlát kap, akkor azt megtekintheti, letöltheti.</p>
                    </PopUp>
                ) }
                <React.Fragment>
                    {
                        this.state.menus.filter(menu_data => !menu_data.alkalmazasgazda).map(menu_data => <div className="block block-sm-1-2 block-md-1-3 block-lg-1-4">
                        <div className="menu">
                            <Link to={'/'+menu_data.url}>
                                <div className={"dashboard-logo "+menu_data.url.replace('/','-')+"-logo"} />
                                <div className="dashboard-title">{menu_data.name}</div>
                            </Link>
                        </div>
                    </div>)
                    }{rights.length === 0 &&
                <div className="welcome-description">
                    <h3 className="welcome">Tisztelt Szülő/Gondviselő!</h3>
                    <p>Az étkezés online felületen történő sikeres megrendeléséhez szükséges</p>
                    <ul>
                        <li>gyereke – rendszerben lévő – adatainak ellenőrzése, aktualizálása,</li>
                        <li>a számlázásra vonatkozó Nyilatkozat elfogadása,</li>
                        <li>étkezés igénylés létrehozása (támogatás igénybevételéhez határozatok csatolása).</li>
                    </ul>
                    <p>A megrendelt étkezés térítési díja, átalánydíjként kerül megállapításra, melyről minden hónap elején (tárgyhó 08-ig), 10 hónapon (szeptember-június) keresztül számla készül a tanuló nevére.  Az átalánydíj összege tanév elején kerül megállapításra, mely év közben csak akkor módosul, ha a család életében év közben olyan változás áll be, mely alapján az étkezési térítési díjra 50% vagy 100% mértékű támogatás jár, vagy az étkezés napi gyakorisága változik.</p>
                    <p>Az átalánydíj összegének változása az igénylés hónapját követő hónaptól vehető figyelembe.</p>

                    <p>Az évközben kiállított – átalány – számlák és a ténylegesen igénybe vett étkezések térítési díjának elszámolása a tanév végén történik, mely alapján a túlfizetések visszautalásra kerülnek, míg az esetleges hátralékot Önnek szükséges rendezni.</p>
                    <p>A címsor jobb oldalán található <span className="help-icon"/> súgó ikonra kattintva segítséget talál a rendszer használatához az aktuális menüpontra vonatkozóan.</p>
                </div>}
                </React.Fragment>
                {rights.includes('alkalmazasgazda') &&
                    <React.Fragment>
                        <div className="page-subtitle">Törzsadatok</div>
                        {
                            this.state.menus.filter(menu_data => menu_data.alkalmazasgazda).map(menu_data => <div className="block block-sm-1-2 block-md-1-3 block-lg-1-4">
                                <div className="menu">
                                    <Link to={'/'+menu_data.url}>
                                        <div className={"dashboard-logo "+menu_data.url.replace('/','-')+"-logo"} />
                                        <div className="dashboard-title">{menu_data.name}</div>
                                    </Link>
                                </div>
                            </div>)
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        user: state.user
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addAction: addAction,
    removeAction: removeAction
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
