import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import SelectField from "@sapkk/app/components/SelectField"
import SimpleField from "@sapkk/app/components/SimpleField"
import DateField from "@sapkk/app/components/DateField"

class SablonDokumentumEloallito extends Component {

    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            fetch_in_progress: true,
            template: this.props.template,
            data: {
                year_id: null,
                class_id: null,
                student_ids: [],
                all_student_from_school: false,
                date: null,
                zero_disable: this.props.with_zero_disable_option ? true: null
            },
            years: [],
            classes: [],
            students: [],
            key: 0
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getYearsForSchool(this.state.school)
        ]).then(([years]) => {
            this.setState({
                fetch_in_progress: false,
                years: years
            })
        }))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.template !== this.state.template) {
            this.setState({
                data: {
                    year_id: null,
                    class_id: null,
                    student_ids: [],
                    all_student_from_school: false,
                    date: null,
                    zero_disable: this.props.with_zero_disable_option ? true: null
                },
                classes: [],
                students: [],
                key: prevState.key + 1
            })
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.template !== state.template) {
            return {
                template: props.template
            }
        }
        return null
    }

    handleDownloadFilledTemplate = (in_system) => {
        this.setState({
            downloading_in_progress: {
                in_system: in_system
            }
        }, () => {
            this.props.promise_error_handler(
                this.props.getDownloadMethod(in_system,this.state.data).then((result) => {
                return FilestoreClient.download(result.hash)
            })).then(() => {
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    handleDataChange = (event) => {
        let value = ""
        if (event.target.multiple) {
            value = [].filter.call(event.target.options, o => o.selected).map(o => o.value)
        } else if (event.target.type != 'checkbox') {
            value = event.target.value
        } else {
            value = event.target.checked
        }
        let name = event.target.name
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                class_id: name == 'year_id' || name == 'all_student_from_school' && value ? null : prevState.data.class_id,
                student_ids: name == 'year_id' || name == 'class_id' || name == 'all_student_from_school' && value ? [] : prevState.data.student_ids,
                [name]: value,
            },
            classes: name == 'year_id' ? [] : prevState.classes,
            students: name == 'year_id' || name == 'class_id' || name == 'all_student_from_school' && value ? [] : prevState.students,
            default_meal_request: null
        }), () => {
            if (name == 'year_id' && value) {
                this.props.promise_error_handler(CanteenClient.getClassesByYearAndSchool(value, this.state.school).then(classes => this.setState((prevState) => ({
                    classes: prevState.data.year_id == value ? classes : prevState.classes
                }))))
            } else if (name == 'class_id' && value) {
                this.props.promise_error_handler(CanteenClient.getLastStudents(value, this.state.data.year_id).then(students => this.setState((prevState) => ({
                    students: prevState.data.class_id == value ? students : prevState.students
                }))))
            }
            if(this.props.handleDataChange){
                this.props.handleDataChange(name, value)
            }
        })
    }

    render() {
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
                {
                    this.props.rights_for_school.includes('READ_TEMPLATE_EMPTY_PDF') && this.state.template.document_hash ? <React.Fragment key={'sablon' + this.state.key}>
                        <div className="page-subtitle">Dokumentum előállítása</div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long required">Év</label>
                            <SelectField value={this.state.data.year_id} name='year_id' onChange={this.handleDataChange} displayValues={this.state.years.reduce((prev, curr) => ({
                                ...prev,
                                [curr.id]: curr.name
                            }), {})}>
                                <option value="">Kérem válasszon!</option>
                                {this.state.years.map(year => <option value={year.id} />)}
                            </SelectField>
                        </div>
                        {
                            this.props.with_date ? <div className="block block-lg-1-2">
                                <label title={this.props.date_title} className="label label-long required">Dátum</label>
                                <DateField placeholder="éééé.hh.nn." className="field field-simple" name='date' value={this.state.data.date} onChange={this.handleDataChange}/>
                            </div> : null
                        }
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Az iskola összes tanulója</label>
                            <SimpleField name='all_student_from_school' type='checkbox' checked={this.state.data.all_student_from_school} onChange={this.handleDataChange} />
                        </div>
                        {
                            !this.state.data.all_student_from_school ? <React.Fragment>
                                <div className="block block-lg-1-2">
                                    <label className="label label-long">Osztály</label>
                                    <SelectField value={this.state.data.class_id} name='class_id' onChange={this.handleDataChange} displayValues={this.state.classes.reduce((prev, curr) => ({
                                        ...prev,
                                        [curr.id]: curr.name
                                    }), {})}>
                                        <option value="">Kérem válasszon!</option>
                                        {this.state.classes.map(school_class => <option value={school_class.id} />)}
                                    </SelectField>
                                </div>
                                <div className="block block-lg-1-2">
                                    <label className="label label-long">Tanuló</label>
                                    <SelectField multiple={true} value={this.state.data.student_ids} name='student_ids' onChange={this.handleDataChange} displayValues={this.state.students.reduce((prev, curr) => ({
                                        ...prev,
                                        [curr.id]: curr.name
                                    }), {})}>
                                        {this.state.students.map(student => <option value={student.id} />)}
                                    </SelectField>
                                </div>
                            </React.Fragment> : null
                        }
                        {
                            this.props.with_zero_disable_option ? <div className="block block-lg-1-2">
                                <label className="label label-long">Nullások kihagyása</label>
                                <SimpleField name='zero_disable' type='checkbox' checked={this.state.data.zero_disable} onChange={this.handleDataChange} />
                            </div> : null
                        }
                        <div className="actions">
                            <LoadingButton loading={this.state.downloading_in_progress && !this.state.downloading_in_progress.in_system} disabled={this.state.downloading_in_progress || !this.state.data.year_id || this.props.with_date && !this.state.data.date} onClick={()=>this.handleDownloadFilledTemplate(false)}>{this.props.can_download_online ? 'Papíralapú' : 'Letöltés'}</LoadingButton>
                            {this.props.can_download_online ? <LoadingButton loading={this.state.downloading_in_progress && this.state.downloading_in_progress.in_system} disabled={this.state.downloading_in_progress || !this.state.data.year_id || this.props.with_date && !this.state.data.date} onClick={()=>this.handleDownloadFilledTemplate(true)}>Dokumentum előállítása és mentése</LoadingButton> : null}
                        </div>
                    </React.Fragment> : null
                }
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SablonDokumentumEloallito))