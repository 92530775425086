import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import DateField from "@sapkk/app/components/DateField"
import { withRouter } from "react-router"
import FilestoreClient from "../../FileStoreClient"
import LoadingButton from "../../components/LoadingButton";

class TanuloFelvetel extends Component {
    file_input = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            page: -1,
            mode: undefined,
            years: [],
            classes: [],
            data: {
                school_id: this.props.school || '',
                year_id: '',
                class_id: "",
                name: "",
                om: "",
                birthday: "",
                hash: "",
                date_from: ""
            },
            file_input_key: 0,
            upload_in_progress: false,
            save_in_progress: false,
            last_saved_data: {}
        }
    }

    componentDidMount() {
        if (this.props.history.location.pathname!='/iskola-kezelese/' + this.state.data.school_id + '/uj-tanulo') {
            this.props.history.replace('/iskola-kezelese/' + this.state.data.school_id + '/uj-tanulo')
        }
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getSchoolClasses(this.state.data.school_id), 
            CanteenClient.getActiveYears(this.state.data.school_id)])
        .then(([classes, years]) => {
            this.setState({
                years: years,
                classes: classes
            })
        }))
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.page < 0) {
            this.setState({
                page: 0
            })
        } else if (this.state.page > 2) {
            this.setState({
                page: 2
            })
        } else if (this.state.page !== prevState.page) {
            this.setState({
                guide: this.state.page === 0 ? 'Kérem, adja meg a tanuló felvételének módját!' : (
                    this.state.page === 1 && this.state.mode === 'single' ? 'Kérem, adja meg a tanuló adatait!' : (
                        this.state.page === 1 && this.state.mode === 'multi' ? 'Kérem, töltse fel a betöltőállományt!' : null
                    )
                )
            })
        }
    }

    handleNavigateBack() {
        this.props.clearMessages()

        this.setState(prevState => ({
            page: prevState.page - 1
        }))
    }

    handleNavigateNext() {
        if(this.state.mode){
            this.props.clearMessages()

            this.setState(prevState => ({
                page: prevState.page + 1,
                data: {
                    ...prevState.data,
                    year_id: prevState.years.length == 1 ? prevState.years[0].id : "",
                    class_id: prevState.classes.length == 1 ? prevState.classes[0].id : "",
                    name: "",
                    om: "",
                    birthday: "",
                    hash: "",
                    date_from: ""
                },
                last_saved_data: {}
            }))
        }
    }

    handleDataChange(name, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }))
    }

    uploadFile() {
        if (this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            },()=>{
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0]).then(hash => {
                    this.setState(prevState => ({
                        upload_in_progress: false,
                        data: {
                            ...prevState.data,
                            hash: hash
                        }
                    }))
                }).catch(error => {
                    this.setState({
                        upload_in_progress: false
                    })
                    this.props.clearMessages()
                    throw error
                }))
            })
        }
    }

    loadMultipleStudentFromFileCheckExcel = () => {
        this.props.clearMessages()
        this.setState({
            check_excel_in_progress: true
        }, ()=>{
            this.props.promise_error_handler(CanteenClient.loadMultipleStudentFromFileCheckExcel(this.state.data.year_id,this.state.data.school_id,this.state.data.hash).then(result =>{
                return FilestoreClient.download(result.hash)
            })).then(()=>{
                this.setState({
                    check_excel_in_progress: false
                })
            })
        })
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        },() => {
            if (this.state.mode === 'single') {
                this.props.promise_error_handler(()=>CanteenClient.addStudentForSchoolYearClass(this.state.data.year_id, this.state.data.class_id, this.state.data.om, this.state.data.birthday, this.state.data.name, this.state.data.school_id, this.state.data.date_from).then((result) => {
                    this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                    this.setState(prevState => ({
                        last_saved_data: {
                            ...prevState.data,
                            ...result.student_register,
                            ...result.student.student_data,
                            ...result.student,
                            year_name: (this.state.years.find(y=>y.id==prevState.data.year_id)||{}).name,
                            class_name: (this.state.classes.find(c=>c.id==prevState.data.class_id)||{}).name,
                        },
                        data: {
                            ...prevState.data,
                            class_id: "",
                            name: "",
                            om: "",
                            birthday: "",
                            hash: "",
                            date_from: ""
                        },
                        save_in_progress: false
                    }))
                })).then(() => {
                    this.setState({
                        save_in_progress: false
                    })
                })
            } else if (this.state.mode === 'multi') {
                this.props.promise_error_handler(()=>CanteenClient.loadMultipleStudentFromFile(this.state.data.year_id,this.state.data.school_id,this.state.data.hash).then((counts) => {
                    this.props.addMessage('Az adatok elmentése megtörtént. Munkalaponként csoportosítva a felvett rekordok száma a következő: '+Object.keys(counts).map(sheet_name => sheet_name+': '+counts[sheet_name]).join(', ')+'.', 'success')

                    this.setState(prevState => ({
                        data: {
                            ...prevState.data,
                            class_id: "",
                            name: "",
                            om: "",
                            birthday: "",
                            hash: "",
                            date_from: ""
                        },
                        file_input_key: prevState.file_input_key + 1,
                        save_in_progress: false
                    }))
                })).then(() => {
                    this.setState({
                        save_in_progress: false
                    })
                })
            }
        })        
    }

    handleDownload = () => {
        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.getMultipleStudentBatchTemplateHash().then((result) => {
                return FilestoreClient.download(result.hash)
            })).then(()=>{
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    handleOpenLastStudent = ()=> {
        this.props.history.push('/tanulo-kezelese/T'+(-1* this.state.last_saved_data.student_id)+'/adatok/torzsadat',{
            edit: true,
            date_from: this.state.last_saved_data.date_from
        })
    }

    render() {
        return <React.Fragment>
            <div class="page-subtitle">Tanuló felvétel</div>
            <div className="page-guide">{this.state.guide}</div>
            { this.state.page === 0 && <React.Fragment>
                <div className="block">
                    <label className="label label-long required">Felvétel módja</label>
                    <div onChange={event => this.setState({[event.target.name]: event.target.value})}>
                        <div className="radio-container">
                            <input id="single" className="field" name="mode" type="radio" value="single" checked={this.state.mode === 'single'}/>
                            <label className="radio-label" htmlFor="single">Egyetlen tanuló felvétele adatainak megadásával</label>
                        </div>
                        <div className="radio-container">
                            <input id="multi" className="field" name="mode" type="radio" value="multi" checked={this.state.mode === 'multi'}/>
                            <label className="radio-label" htmlFor="multi">Tömeges tanulófelvétel betöltőállományból</label>
                        </div>
                    </div>
                </div>
            </React.Fragment> }
            { this.state.page === 1 && this.state.mode === 'single' && <React.Fragment>
                <div className="block block-lg-1-1">
                    <label className="label label-long required">Tanév</label>
                    <select className="field field-simple" value={this.state.data.year_id} onChange={event => this.handleDataChange('year_id', event.target.value)}>
                        <option value="">Kérem válasszon!</option>
                        { this.state.years.map(year => <option value={year.id}>{year.name}</option>) }
                    </select>
                </div>
                <div className="block block-lg-1-1">
                    <label className="label label-long required">Osztály</label>
                    <select className="field field-simple" value={this.state.data.class_id} onChange={event => this.handleDataChange('class_id', event.target.value)}>
                        <option value="">Kérem válasszon!</option>
                        { this.state.classes.map(school_class => <option value={school_class.id}>{school_class.name}</option>) }
                    </select>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long required">Név</label>
                    <SimpleField className="field field-simple" value={this.state.data.name} onChange={event => this.handleDataChange('name', event.target.value)}/>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long required">Oktatási azonosító</label>
                    <SimpleField className="field field-simple" value={this.state.data.om} onChange={event => this.handleDataChange('om', event.target.value)}/>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long required">Születési dátum</label>
                    <DateField className="field field-simple" value={this.state.data.birthday} onChange={event => this.handleDataChange('birthday', event.target.value)} placeholder="éééé.hh.nn"/>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Felvétel dátuma</label>
                    <DateField className="field field-simple" value={this.state.data.date_from} onChange={event => this.handleDataChange('date_from', event.target.value)} placeholder="éééé.hh.nn"/>
                </div>
            </React.Fragment> }
            { this.state.page === 1 && this.state.mode === 'multi' && <React.Fragment>
                <div className="block block-lg-1-1">
                    <label className="label label-long required">Tanév</label>
                    <select className="field field-simple" value={this.state.data.year_id} onChange={event => this.handleDataChange('year_id', event.target.value)}>
                        <option value="">Kérem válasszon!</option>
                        { this.state.years.map(year => <option value={year.id}>{year.name}</option>) }
                    </select>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long required">Betöltőállomány</label>
                    <input key={'file_input_'+this.state.file_input_key} ref={this.file_input} disabled={this.state.upload_in_progress} className="field" type="file" onChange={() => this.uploadFile()}/>
                </div>
            </React.Fragment> }
            <div className="actions">
                <button disabled={this.state.page === 0} onClick={() => this.handleNavigateBack()}>Vissza</button>
                <button disabled={this.state.page_validation_in_progress || this.state.page === 1 || !this.state.mode} onClick={() => this.handleNavigateNext()}>Tovább</button>
                <LoadingButton loading={this.state.save_in_progress} disabled={this.state.page !== 1 || this.state.check_excel_in_progress || this.state.downloading_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton>
                {
                    this.state.page === 1 && this.state.mode === 'multi' ? <LoadingButton onClick={() => this.handleDownload()} loading={this.state.downloading_in_progress} disabled={this.state.save_in_progress || this.state.check_excel_in_progress}>Sablon letöltése</LoadingButton> : null
                }
                {
                    this.state.page === 1 && this.state.mode === 'multi' && this.state.data.hash ? <LoadingButton onClick={() => this.loadMultipleStudentFromFileCheckExcel()} loading={this.state.check_excel_in_progress} disabled={this.state.save_in_progress || this.state.downloading_in_progress}>Felmerülő hibák letöltése</LoadingButton> : null
                }
            </div>
            {
                Object.keys(this.state.last_saved_data).length ? <React.Fragment>
                    <div className="page-subtitle">A legutoljára mentett tanuló adatai</div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Mezőnév</th>
                                    <th>Érték</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>Tanév</td><td>{this.state.last_saved_data.year_name}</td></tr>
                                <tr><td>Osztály</td><td>{this.state.last_saved_data.class_name}</td></tr>
                                <tr><td>Név</td><td>{this.state.last_saved_data.name}</td></tr>
                                <tr><td>Oktatási azonosító</td><td>{this.state.last_saved_data.om}</td></tr>
                                <tr><td>Születési dátum</td><td><DateField value={this.state.last_saved_data.birthday} readOnly={true}/></td></tr>
                                <tr><td>Felvétel dátuma</td><td><DateField value={this.state.last_saved_data.date_from} readOnly={true}/></td></tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        <button onClick={this.handleOpenLastStudent}>Szerkesztés</button>
                    </div>
                </React.Fragment> : null
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloFelvetel))
