import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage} from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import { addAction, removeAction } from "../../actions"
import SimpleField from "@sapkk/app/components/SimpleField"
import DateField from "@sapkk/app/components/DateField"

class SAPAdat extends Component {
    constructor(props) {
        super(props)

        this.state = {
            student: this.props.student,
            rights: this.props.rights_for_student,
            fetch_in_progress: true,
            sap_data: [],
            new_sap_customer_code: '',
            new_date: '',
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(CanteenClient.getSAPDataForStudent(this.state.student).then((sap_data) => {
            this.setState({
                sap_data: sap_data,
                fetch_in_progress: false
            })
        }))
    }

    handleSave = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.editSAPDataForStudent(this.state.student, this.state.new_sap_customer_code || null, this.state.new_date).then(sap_data => {
                this.props.addMessage('Az SAP adat elmentése megtörtént.', 'success')
                this.setState({
                    edit: false,
                    sap_data: sap_data,
                    new_sap_customer_code: '',
                    new_date: '',
                    save_in_progress: false
                })
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handleChange = (event) =>{
        let name = event.target.name
        let value = event.target.type=="checkbox" ? event.target.checked : event.target.value
        this.setState((prevState)=>({
            [name]: value
        }))
    }

    render() {
        let empty_or_have_infinite_sap_data = this.state.sap_data.length === 0 || this.state.sap_data.some(sd => sd.date_to === null)
        return <React.Fragment>
            <div className="page-subtitle">SAP adatok</div>
            {!this.state.fetch_in_progress ? <React.Fragment>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Étvényesség kezdete</th>
                                <th>Érvényesség vége</th>
                                <th>Iskola</th>
                                <th>SAP vevőkód</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.sap_data.length !== 0 ? this.state.sap_data.map(sd => <tr>
                                    <td><DateField readOnly={true} value={sd.date_from}/></td>
                                    <td><DateField readOnly={true} value={sd.date_to}/></td>
                                    <td>{sd.school_abbr}</td>
                                    <td>{sd.sap_customer_code}</td>
                                </tr>) : <tr className="table-empty-indicator">
                                    <td colSpan={4}>Nem tartozik SAP adat a tanulóhoz</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    empty_or_have_infinite_sap_data ? <React.Fragment>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Új SAP vevőkód</label>
                            <SimpleField className="field field-simple" name="new_sap_customer_code" onChange={this.handleChange} value={this.state.new_sap_customer_code}/>
                        </div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Dátum</label>
                            <DateField className="field field-simple" name="new_date" onChange={this.handleChange} value={this.state.new_date}/>
                        </div>
                        <div className="actions">
                            <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave} disabled={this.state.save_in_progress}>Mentés</LoadingButton>
                        </div>
                    </React.Fragment> : null
                }
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        promise_error_handler: state.app.promise_error_handler,
        text_styles: state.text_styles,
        rights: state.rights,
        meal_request_states: state.meal_request_states
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAPAdat))