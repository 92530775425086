import React, { Component } from 'react'
import { setGuide, setTitle } from "../../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SAPServicesClient from "../../SAPServicesClient"
import SimpleField from '@sapkk/app/components/SimpleField';
import LoadingButton from '../../components/LoadingButton';
import { formatDate } from '../../functions'
import { withRouter } from 'react-router'
import Loading from '@sapkk/app/components/Loading'

class UjTanev extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school_id: this.props.school || '',
            open: false,
            page: -1,
            schools: [],
            years: [],
            calendar_table: [],
            save_in_progress: false,
            guide: null
        }
    }

    componentDidMount() {
        this.setState({
            school_year_id: "",
            school_year_data: undefined
        },()=>{
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getYears()
            ]).then(([years]) => {
                this.setState({
                    years: years
                },()=>{
                    this.handleNew()
                })
            }))
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.page < 0) {
            this.setState({
                page: 0
            })
        } else if (this.state.page > 2) {
            this.setState({
                page: 2
            })
        } else if (this.state.page !== prevState.page) {
            if (this.state.page === 0) {
                this.setState({
                    guide: 'Kérem, adja meg a tanév adatait!'
                })
            } else if (this.state.page === 1) {
                this.setState({
                    guide: 'Kérem, adja meg az áthelyezett munkanapokat és tanításmentes napokat!'
                })
            } else if (this.state.page === 2) {
                this.setState({
                    guide: 'Kérem, ellenőrizze a tanév adatait!'
                })
            }
        }
    }

    handleNavigateBack() {
        this.props.clearMessages()

        this.setState((prevState, props) => ({
            page: prevState.page - 1
        }))
    }

    handleNavigateNext() {
        this.props.clearMessages()

        this.setState((prevState) => {
            if (prevState.page === 1) {
                this.props.promise_error_handler(CanteenClient.convertCalendarDays(prevState.school_year_data.date_from, prevState.school_year_data.date_to, prevState.calendar_table).then(calendar =>
                    this.setState((state) => ({
                        page: state.page + 1,
                        school_year_data: {
                            ...state.school_year_data,
                            calendar: calendar.map(c => ({
                                ...c,
                                date: formatDate(c.date)
                            }))
                        }
                    }))
                )).then(result =>
                    this.setState({
                        page_validation_in_progress: false
                    })
                )

                return {
                    page_validation_in_progress: true
                }
            } else if(prevState.page === 0){
                this.props.promise_error_handler(SAPServicesClient.getCalendarSpecialDaysByInterval(prevState.school_year_data.date_from, prevState.school_year_data.date_to).then(calendars => {
                    this.setState({
                        calendar_table: calendars.map(calendar => ({
                            name: calendar.name,
                            date_from: formatDate(calendar.date),
                            date_to: formatDate(calendar.date),
                            work_day: calendar.work_day ? 1 : 0
                        }))
                    })
                }))
            }

            return {
                page: prevState.page + 1
            }
        })
    }

    handleAddLine() {
        this.setState((prevState, props) => ({
            calendar_table: prevState.calendar_table.concat({name: "", date_from: "", date_to: "", work_day: ""})
        }))
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            calendar_table: prevState.calendar_table.slice(0, id).concat(prevState.calendar_table.slice(id-(-1)))
        }))
    }

    handleRowChange(id, name, value) {
        this.setState((prevState, props) => ({
            calendar_table: prevState.calendar_table.slice(0, id).concat([{...prevState.calendar_table[id], [name]: value}]).concat(prevState.calendar_table.slice(id-(-1)))
        }))
    }

    handleSchoolYearDataChange(name, value) {
        this.setState(prevState => ({
            school_year_data: {
                ...prevState.school_year_data,
                [name]: value
            }
        }))
    }

    handleSave = ()=> {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, ()=>{
            this.props.promise_error_handler(()=>CanteenClient.createSchoolYear(this.state.school_id, this.state.school_year_data.year_id, this.state.school_year_data.date_from, this.state.school_year_data.date_to, this.state.school_year_data.graduate_date_to, this.state.school_year_data.calendar)
            .then((result) => {
                this.props.loadData()
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.props.history.push('/iskola-kezelese/'+this.state.school_id+'/tanevek/'+result.id)
                this.setState({
                    school_year_id: result.id,
                    school_year_data: {
                        ...result
                    },
                    save_in_progress: false
                })
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })    
            })
        })
    }

    handleSchoolYearChange = (school_year_id) => {
        this.props.clearMessages()
        this.setState({
            school_year_id: school_year_id,
            school_year_data: undefined
        })
    }
    handleNew = () => {
        this.props.clearMessages()
        this.setState((prevState)=>({
            school_year_id: "",
            page: 0,
            school_year_data: {
                school_id: prevState.school_id,
                year_id: prevState.years.length==1 ? prevState.years[0].id : undefined,
                date_from: undefined,
                date_to: undefined,
                graduate_date_to: undefined, 
                calendar: []
            },
            calendar_table: []
        }))
    }

    render() {
        return (
            <React.Fragment>
                {this.state.school_year_data ? <React.Fragment>
                    { this.state.page === 0 && <React.Fragment>
                    <div className="page-subtitle">Tanév adatainak megadása</div>
                    <div className="block block-lg-1-1">
                        <label className="label label-long required">Tanév</label>
                        <select className="field field-simple" value={this.state.school_year_data.year_id} onChange={event => this.handleSchoolYearDataChange('year_id', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            { this.state.years.filter(year=>year.active).map(year => <option value={year.id}>{year.name}</option>) }
                        </select>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long required">Tanév kezdete</label>
                        <input placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.date_from} onChange={event => this.handleSchoolYearDataChange('date_from', event.target.value)}/>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long required">Tanév vége</label>
                        <input placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.date_to} onChange={event => this.handleSchoolYearDataChange('date_to', event.target.value)}/>
                    </div>
                    {this.props.school_data.have_graduating_class ? <div className="block block-lg-1-2">
                        <label className="label label-long required">Végzős tanév vége</label>
                        <input placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.graduate_date_to} onChange={event => this.handleSchoolYearDataChange('graduate_date_to', event.target.value)}/>
                    </div> : null}
                </React.Fragment> }
                { this.state.page === 1 && <React.Fragment>
                    <div className="page-subtitle">Áthelyezett munkanapok és tanításmentes napok megadása</div>
                    <table className="workday-overwrite striped modify-table">
                        <thead>
                            <tr>
                                <th style={{width:'50%'}}>Név</th>
                                <th style={{width:'20%'}}>Kezdete</th>
                                <th style={{width:'20%'}}>Vége</th>
                                <th style={{width:'10%'}}>Étkezés</th>
                                <th className="col-actions"/>
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.calendar_table.map((data, id) =>
                            <tr key={id}>
                                <td><input className="field field-simple" value={ data.name } onChange={event => this.handleRowChange(id, 'name', event.target.value)}/></td>
                                <td><input placeholder="éééé.hh.nn" className="field field-simple" value={ data.date_from } onChange={event => this.handleRowChange(id, 'date_from', event.target.value)}/></td>
                                <td><input placeholder="éééé.hh.nn" className="field field-simple" value={ data.date_to } onChange={event => this.handleRowChange(id, 'date_to', event.target.value)}/></td>
                                <td>
                                    <select className="field field-simple" value={ data.work_day } onChange={event => this.handleRowChange(id, 'work_day', event.target.value)}>
                                        <option value=""/>
                                        <option value="1">Igen</option>
                                        <option value="0">Nem</option>
                                    </select>
                                </td>
                                <td className="col-actions"><button onClick={() => this.handleRemoveLine(id)}/></td>
                            </tr>
                        ) }
                            <tr className="table-new-row">
                                <td colSpan={4}>{this.state.calendar_table.length === 0 && "Nincs megadva áthelyezett munkanap vagy tanításmentes nap."}</td>
                                <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td>
                            </tr>
                        </tbody>
                    </table>
                </React.Fragment> }
                { this.state.page === 2 && <React.Fragment>
                    <div className="page-subtitle">Tanév adatai</div>
                    <div className="block block-lg-1-1">
                        <label className="label label-long">Tanév</label>
                        <div className="field field-simple">
                            { (this.state.years.find(year => year.id === parseInt(this.state.school_year_data.year_id)) || {}).name }
                        </div>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Tanév kezdete</label>
                        <SimpleField readOnly={!this.state.school_year_id || !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') || !this.state.school_year_data.active} placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.date_from}  onChange={event => this.handleSchoolYearDataChange('date_from', event.target.value)}/>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Tanév vége</label>
                        <SimpleField readOnly={!this.state.school_year_id|| !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') || !this.state.school_year_data.active}  placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.date_to}  onChange={event => this.handleSchoolYearDataChange('date_to', event.target.value)}/>
                    </div>
                    {this.props.school_data.have_graduating_class ? <div className="block block-lg-1-2">
                        <label className="label label-long">Végzős tanév vége</label>
                        <SimpleField readOnly={!this.state.school_year_id|| !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') || !this.state.school_year_data.active}  placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.graduate_date_to}  onChange={event => this.handleSchoolYearDataChange('graduate_date_to', event.target.value)}/>
                    </div> : null}
                    <div className="page-subtitle">Áthelyezett munkanapok és tanításmentes napok</div>
                    <table className="striped modify-table">
                        <thead>
                        <tr>
                            <th style={{width:'20%'}}>Dátum</th>
                            <th style={{width:'60%'}}>Név</th>
                            <th style={{width:'20%'}}>Étkezés</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.state.school_year_data.calendar.map((data, id) =>
                            <tr key={id}>
                                <td><SimpleField value={data.date} placeholder="éééé.hh.nn" readOnly={true}/></td>
                                <td><SimpleField value={data.name} readOnly={true}/></td>
                                <td>{data.work_day ? "Igen" : "Nem"}</td>
                            </tr>
                        ) }
                        </tbody>
                    </table>
                </React.Fragment> }
                <div className="actions">
                    {
                        !this.state.school_year_id ? <React.Fragment>
                            <button disabled={this.state.page === 0} onClick={() => this.handleNavigateBack()}>Vissza</button>
                            <button disabled={this.state.page === 0 && (!this.state.school_year_data.year_id || !this.state.school_year_data.date_from || !this.state.school_year_data.date_to)|| this.state.page_validation_in_progress || this.state.page === 2} onClick={() => this.handleNavigateNext()}>Tovább</button>
                        </React.Fragment> : null
                    }
                    {!this.state.school_year_id || this.state.school_year_data.active ? <LoadingButton disabled={this.state.page !== 2} loading={this.state.save_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton> : null}
                </div>
                </React.Fragment> : <Loading loading_text = ''/>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UjTanev))
