import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import { withRouter } from "react-router"
import LoadingButton from "../../components/LoadingButton"
import Uzenet from "../../components/Uzenet"
import SimpleField from "@sapkk/app/components/SimpleField"

class Uzenetek extends Component {
    constructor(props) {
        super(props)

        this.container_div = React.createRef()

        this.state = {
            meal_request: this.props.meal_request,
            new_message: '',
            fetch_in_progress: !!this.props.handleFetch,
            send_in_progress: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.meal_request !== state.meal_request || props.unseen_message_count !== state.unseen_message_count) {
            return {
                meal_request: props.meal_request,
                unseen_message_count: props.unseen_message_count
            }
        } else {
            return null
        }
    }
    componentDidMount() {
        this.handleFetch()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.unseen_message_count !== this.state.unseen_message_count) {
            this.loadMessages(this.toTheLastMessage)
        }
    }

    loadMessages = (callback) =>{
        this.props.handleFetch(() => {
            this.setState({
                fetch_in_progress: false
            }, () => {
                if (this.props.setSeenMealRequestMessages) {
                    this.props.setSeenMealRequestMessages()
                }
                if(callback){
                    callback()
                }
            })
        })
    }

    handleFetch = () => {
        if (this.props.handleFetch) {
            this.setState({
                fetch_in_progress: true
            }, () => {
                this.loadMessages(this.toTheLastMessage)
            })
        }
    }

    toTheLastMessage = () => {
        this.container_div.current.scrollTop = this.container_div.current.scrollHeight
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSend = () => {
        this.setState({
            send_in_progress: true
        }, () => {
            this.props.addMessageToMealRequest(this.state.new_message, ()=>{
                this.setState({
                    send_in_progress: false,
                    new_message: '',
                }, () => {
                    if (this.props.setSeenMealRequestMessages) {
                        this.props.setSeenMealRequestMessages()
                    }
                    this.container_div.current.scrollTop = this.container_div.current.scrollHeight
                })
            },()=>{
                this.setState({
                    send_in_progress: false
                })
            })
        })
    }

    render() {
        return !this.state.fetch_in_progress && this.state.meal_request.messages !== undefined && this.state.meal_request.messages !== null ? <React.Fragment>
            <div ref={this.container_div} className="speech-bubble-container">
                {
                    this.state.meal_request.messages.length!==0 ? this.state.meal_request.messages.map((message, i) => <React.Fragment>
                        <Uzenet message={message}/>
                    </React.Fragment>) : <div className="empty-messages">
                        Az igényléshez még nem tartozik üzenet
                    </div>
                }
            </div>
            {!this.props.readOnly ? <div className="student-select-block">
                <div className="block">
                    <label className="label label-long">Új üzenet</label>
                    {(this.state.meal_request.state === 'CREATED' || this.state.meal_request.state === 'UPDATED') ? <textarea className="field field-text" onChange={this.handleChange} name="new_message" value={this.state.new_message}>
                        {this.state.new_message}
                    </textarea> : <SimpleField className="field field-text" readOnly="true" value="Az üzenetküldés nem lehetséges, a kommunikáció lezárásra került."></SimpleField>}
                </div>
                <div>
                    <LoadingButton loading={this.state.send_in_progress} onClick={this.handleSend} disabled={this.state.meal_request.state !== 'CREATED' && this.state.meal_request.state !== 'UPDATED'}>Küldés</LoadingButton>
                </div>
            </div> : null}
        </React.Fragment> : null
    }
}

function mapStateToProps(state) {
    return {
        meal_request_states: state.meal_request_states,
        rights: state.rights,
        user: state.user
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Uzenetek))