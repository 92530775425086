import React, { Component } from 'react'
import { setGuide, setTitle, setMasquaradedStudent, setSystemMessagesCount } from "../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { Route } from "react-router-dom"
import MenzanaptarBase from './Menzanaptar';
import TanuloEgyenlegeBase from './TanuloEgyenlegeBase';
import Loading from '@sapkk/app/components/Loading';
import MegrendeltEtkezes from './TanuloKezelese/MegrendeltEtkezes';
import JelszoModositasa from './JelszoModositasa';
import Szulok from './TanuloKezelese/Szulok'
import TanuloiIgenyles from './TanuloKezelese/TanuloiIgenyles'
import OsztalyTortenet from './TanuloKezelese/OsztalyTortenet'
import AdatModositasok from './TanuloKezelese/AdatModositasok'
import AdatAllapot from './TanuloKezelese/AdatAllapot'
import Adat from './TanuloKezelese/Adat'
import ContentWithSideMenu from './ContentWithSideMenu'
import JelszoVisszaallitas from './TanuloKezelese/JelszoVisszaallitas'
import Szamlatortenet from './TanuloKezelese/Szamlatortenet'
import TanuloiMenu from "./TanuloKezelese/TanuloiMenu";
import TanuloiIgenylesekKorabbi from "./TanuloKezelese/TanuloiIgenylesekKorabbi";
import DokumentumBurkolo from "./TanuloKezelese/DokumentumBurkolo";

class TanuloKezelese extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            key: 0,
            loaded: false,
            error: false,
            edit_name: false,
            search_students: null
        }
    }

    componentDidMount() {
        this.loadData()
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.id !== state.id) {
            return {
                key: state.key + 1,
                id: props.match.params.id,
                loaded: false,
                error: false
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.id !== this.state.id) {
            this.loadData()
        }
        if(prevState.key !== this.state.key){
            this.props.setSystemMessagesCount(null)
        }
        if(prevProps.system_messages_count !== this.props.system_messages_count && this.props.system_messages_count!==null){
            this.countUnseenStudentData()
            this.countUnSeenMealRequests()
            this.countUnseenEInvoices()
            this.countSystemMessageForStudents()
        }
    }

    countUnseenStudentData = ()=>{
        return this.state.rights && this.state.rights.includes('READ_HISTORY') ? this.props.promise_error_handler(CanteenClient.countUnseenStudentData(-1*this.props.match.params.id).then(count => {
            this.setState({
                count_unseen_student_data: count
            })
        })) : new Promise((resolve, reject) => resolve(null))
    }

    countUnSeenMealRequests = ()=>{
        return this.state.rights && this.state.rights.includes('READ_MEAL_REQUEST') ? this.props.promise_error_handler(CanteenClient.countUnSeenMealRequests(-1*this.props.match.params.id).then(count => {
            this.setState({
                count_unseen_meal_requests: count
            })
        })) : new Promise((resolve, reject) => resolve(null))
    }

    countUnseenEInvoices = ()=>{
        return this.state.rights && this.state.rights.includes('MARK_E_INVOICE') && this.state.rights.includes('READ_BALANCES') ? this.props.promise_error_handler(CanteenClient.countUnseenEInvoices(-1*this.props.match.params.id).then(count => {
            this.setState({
                count_unseen_e_invoices: count
            })
        }).catch(e => {})) : new Promise((resolve, reject) => resolve(null))
    }

    countSystemMessageForStudents = ()=>{
        return this.state.rights && this.state.rights.includes('READ_DATA') && this.props.rights.can_have_children? this.props.promise_error_handler(Promise.all(
            this.props.rights.students.map(student => CanteenClient.getSystemMessagesCount(student.id).then(count => {
                this.setState((prevState) =>({
                    count_unseen_student: {
                        ...prevState.count_unseen_student,
                        [student.id] : count
                    }
                }))
            }))
        )) : new Promise((resolve, reject) => resolve(null))
    }

    loadData = () => {
        if (!this.props.match.params.id && Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length > 0) {
            let student =  this.props.rights.students[0]
            if(this.props.page === 'menu'){
                let student_with_menu = this.props.rights.students.find(s => s.have_menu)
                if(student_with_menu){
                    student = student_with_menu
                }
            }
            this.props.history.replace('/tanulo-kezelese/T' + (-1 * (this.props.user.masquaraded_student ||student.id)) + (this.props.page ? '/' + this.props.page : ''))
        } else {
            if (Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length > 0) {
                this.props.setMasquaradedStudent(-1 * this.props.match.params.id)
            }
            this.setState({
                id: this.props.match.params.id,
            }, () => {
                this.loadRights()
            })
        }
    }

    loadRights = () => {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getRightsForStudent(-1 * this.props.match.params.id),
            CanteenClient.getLastStudentRegisterData(-1 * this.props.match.params.id)
        ]).then(([rights, student_register]) => {
            this.props.setTitle(student_register.student.name + " adatai")
            this.setState({
                rights: rights,
                student_register: student_register,
            },()=>{
                Promise.all([
                    this.countUnseenStudentData(),
                    this.countUnSeenMealRequests(),
                    this.countUnseenEInvoices(),
                    this.countSystemMessageForStudents()
                ]).then(()=>{
                    this.setState({
                        loaded: true,
                        error: false
                    })
                })
            })
        }).catch(error => {
            this.props.setTitle(" Tanulói törzsadatok")
            this.setState({
                error: true,
                loaded: true
            })
            throw error
        }))
    }

    convertURLToStudentURL = (row = {}) => {
        let id = (-1 * row.student_id || this.state.id)
        let school_year_id = row.school_year_id || this.state.student_register.student_register.school_year_id
        switch (this.props.history.location.pathname) {
            case '/tanulo-kezelese/T' + this.state.id + '/adatok':
                return '/tanulo-kezelese/T' + id + '/adatok'
            case '/tanulo-kezelese/T' + this.state.id + '/megrendelt-etkezes':
                return '/tanulo-kezelese/T' + id + '/megrendelt-etkezes'
            case '/tanulo-kezelese/T' + this.state.id + '/etkezes-felvetele':
                return '/tanulo-kezelese/T' + id + '/etkezes-felvetele'
            case '/tanulo-kezelese/T' + this.state.id + '/jelszo':
                return '/tanulo-kezelese/T' + id + '/jelszo'
            case '/tanulo-kezelese/T' + this.state.id + '/szulok':
                return '/tanulo-kezelese/T' + id + '/szulok'
            case '/tanulo-kezelese/T' + this.state.id + '/dokumentumok':
                return '/tanulo-kezelese/T' + id + '/dokumentumok'
            case '/tanulo-kezelese/T' + this.state.id + '/osztaly-tortenet':
                return '/tanulo-kezelese/T' + id + '/osztaly-tortenet'
            default:
                if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/tanuloi-igenylesek')===0){
                    return '/tanulo-kezelese/T' + id + '/tanuloi-igenylesek'
                }
                if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/menzanaptar')===0){
                    return '/tanulo-kezelese/T' + id + '/menzanaptar/'+school_year_id
                }
                if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/adatok/modositastortenet')===0){
                    return '/tanulo-kezelese/T' + id + '/adatok/modositastortenet'
                }
                if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/szamlak')===0){
                    return '/tanulo-kezelese/T' + id + '/szamlak'
                }
                if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/gy2-egyenleg')===0){
                    return '/tanulo-kezelese/T' + id + '/gy2-egyenleg'
                }
                return '/tanulo-kezelese/T' + id
        }
    }

    convertURLToClassURL = (row = {}) => {
        let id = row.class_id || this.state.student_register.class.id
        let year_id = row.year_id || this.state.student_register.year.id
        let school_year_id = row.school_year_id || this.state.student_register.student_register.school_year_id
        if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/menzanaptar')===0){
            return '/osztaly-kezelese/' + id + '/menzanaptar/'+school_year_id
        }
        if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/gy2-egyenleg')===0){
            return '/osztaly-kezelese/' + id + '/gy2-egyenleg/'+school_year_id
        }
        return '/osztaly-kezelese/' + id + '/tanulok/' + year_id
    }

    convertURLToSchoolURL = (row = {}) => {
        let id = row.school_id || this.state.student_register.school.id
        let year_id = row.year_id || this.state.student_register.year.id
        let school_year_id = row.school_year_id || this.state.student_register.student_register.school_year_id
        if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/gy2-egyenleg')===0){
            return '/iskola-kezelese/' + id + '/gy2-egyenleg/'+school_year_id
        }
        if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/tanuloi-igenylesek')===0){
            return '/iskola-kezelese/' + id + '/feldolgozatlan-igenylesek'
        }
        if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/adatok/modositastortenet')===0){
            return '/iskola-kezelese/' + id + '/feldolgozatlan-torzsadatmodositasok'
        }
        if(this.props.history.location.pathname.indexOf('/tanulo-kezelese/T' + this.state.id + '/menzanaptar')===0){
            return '/iskola-kezelese/' + id + '/menzanaptar/'+school_year_id
        }
        return '/iskola-kezelese/' + id + '/tanulok/' + year_id
    }

    getLinkForStudent = (row) => {
        return this.convertURLToStudentURL(row)
    }

    getLinkForClass = (row) => {
        return this.convertURLToClassURL(row)
    }

    getLinkForSchool = (row) => {
        return this.convertURLToSchoolURL(row)
    }

    getLinkForRequest = (row={}) =>{
        return this.convertURLToStudentURL(row)+'/'+row.id+'/'+row.school_id
    }

    activeMethod = (menu_data) =>{
        return menu_data.url == this.props.history.location.pathname || this.props.history.location.pathname.indexOf(menu_data.url+'/')===0 || (menu_data.submenu||[]).some(sub_menu_data => this.activeMethod(sub_menu_data))
    }

    render() {

        let sub_student_data_menu = this.state.loaded && !this.state.error ? [
            this.state.rights.includes('READ_HISTORY') ? {id:1, order: 2, message_count:this.state.count_unseen_student_data, url: '/tanulo-kezelese/T' + (this.state.id) + '/adatok/modositastortenet', name: 'Módosítástörténet', render: <AdatModositasok getLinkForRequest={this.getLinkForRequest} student={this.state.student_register.student.id} rights_for_student={this.state.rights} pathname={'/tanulo-kezelese/T' + (-1 * this.state.student_register.student.id) + '/adatok/modositastortenet'} rights_for_student={this.state.rights} unseen_student_data_ids={this.state.unseen_student_data_ids}/> } : null,
            this.state.rights.includes('READ_DATA') ? {id:2, order: 1, exact_menu: true, url: '/tanulo-kezelese/T' + (this.state.id) + '/adatok/torzsadat', name: 'Törzsadat', render: <Adat id={this.state.id} student_register={this.state.student_register} rights_for_student={this.state.rights}/> } : null,
        ].filter(menu_data => menu_data) : []

        let sub_meal_request_data_menu = this.state.loaded && !this.state.error ? [
            this.state.rights.includes('WRITE_MEAL_REQUEST') ? {id:17,  url: '/tanulo-kezelese/T' + (this.state.id) + '/tanuloi-igenylesek/uj/new', name: 'Étkezés igénylése', render: <TanuloiIgenyles meal_request_id ="new" student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} school_data={this.state.student_register.school} student={this.state.student_register.student.id} year={this.state.student_register.year.id} rights_for_student={this.state.rights} loadRights={this.loadRights}/> } : null,
            this.state.rights.includes('WRITE_MEAL_REQUEST') ? {id:18, url: '/tanulo-kezelese/T' + (this.state.id) + '/tanuloi-igenylesek/lemondas', name: 'Étkezés végleges lemondása', render: <TanuloiIgenyles meal_request_id = "lemondas" student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} school_data={this.state.student_register.school} student={this.state.student_register.student.id}  year={this.state.student_register.year.id} rights_for_student={this.state.rights} loadRights={this.loadRights}/> } : null,
            this.state.rights.includes('WRITE_MEAL_REQUEST_WITH_PDF') ? {id:17,  url: '/tanulo-kezelese/T' + (this.state.id) + '/tanuloi-igenylesek/pdf', name: 'Papír alapú étkezési igény feltöltése', render: <TanuloiIgenyles meal_request_id={"pdf"} student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} school_data={this.state.student_register.school} student={this.state.student_register.student.id} year={this.state.student_register.year.id} rights_for_student={this.state.rights} loadRights={this.loadRights} /> } : null,
            this.state.rights.includes('READ_MEAL_REQUEST') ? {id:16, message_count:this.state.count_unseen_meal_requests, url: '/tanulo-kezelese/T' + (this.state.id) + '/tanuloi-igenylesek/korabbi', name: 'Korábbi igénylések', render: <TanuloiIgenylesekKorabbi student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} school_data={this.state.student_register.school} student={this.state.student_register.student.id} rights_for_student={this.state.rights} /> } : null,
        ].filter(menu_data => menu_data) : []

        let menus = this.state.loaded && !this.state.error ? [
            sub_student_data_menu.length ? {id: 3, submenu: sub_student_data_menu, name: 'Adatok' } : null,
            this.state.rights.includes('READ') ? {id:4,  url: '/tanulo-kezelese/T' + (this.state.id) + '/menzanaptar', name: 'Menzanaptár', render: <MenzanaptarBase school_data={this.state.student_register.school} school_year={this.props.match.params.school_year_id} student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} class={this.state.student_register.class.id} /> } : null,
            this.state.rights.includes('READ_MEAL_REQUEST') ? {id:5,  url: '/tanulo-kezelese/T' + (this.state.id) + '/tanuloi-igenylesek/korabbi/:meal_request_id', name: null, render: <TanuloiIgenyles student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} rights_for_student={this.state.rights} loadRights={this.loadRights}/> } : null,
            this.state.rights.includes('READ_MEAL_REQUEST') ? {id:6,  submenu: sub_meal_request_data_menu, name: 'Étkezés igénylése / étkezés végleges lemondása'} : null,
            this.state.rights.includes('READ_DATA') ? {id:7, url: '/tanulo-kezelese/T' + (this.state.id) + '/megrendelt-etkezes', name: 'Megrendelt étkezés', render: <MegrendeltEtkezes student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} year={this.state.student_register.year.id} class={this.state.student_register.class.id} read_meal_price={this.state.rights.includes('READ_MEAL_PRICE')} write_dinings={this.state.rights.includes('WRITE_DINING')} rights_for_student={this.state.rights} /> } : null,
            this.state.rights.includes('READ_MENU') ? {id:13, url: '/tanulo-kezelese/T' + (this.state.id) + '/menu', name: 'Menü - Étlap', render: <TanuloiMenu school={this.state.student_register.school.id} student_register={this.state.student_register.student_register} student={this.state.student_register.student.id} rights_for_student={this.state.rights} year={this.state.student_register.year.id}/> } : null,
            this.state.rights.includes('READ_DOCUMENT') ? {id:8, url: '/tanulo-kezelese/T' + (this.state.id) + '/dokumentumok', name: 'Dokumentumok', render: <DokumentumBurkolo with_help={true} student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} rights_for_student={this.state.rights} /> } : null,
            this.state.rights.includes('READ_BALANCES') ? {id:9, message_count: this.state.count_unseen_e_invoices, url: '/tanulo-kezelese/T' + (this.state.id) + '/szamlak', name: 'Számlák', render: <Szamlatortenet count_unseen_e_invoices={this.state.count_unseen_e_invoices} student={this.state.student_register.student.id} rights_for_student={this.state.rights}/> } : null,
            this.state.rights.includes('READ_GY2_BALANCES') ? {id:9, url: '/tanulo-kezelese/T' + (this.state.id) + '/gy2-egyenleg', name: 'Gyermekétkeztetésbeli egyenleg', render: <TanuloEgyenlegeBase school_year={this.props.match.params.school_year_id} student_register={this.state.student_register.student_register} student={this.state.student_register.student.id} /> } : null,
            this.state.rights.includes('PASSWORD_CHANGE') ? {id:10, url: '/tanulo-kezelese/T' + (this.state.id) + '/jelszo', name: 'Jelszó módosítása', render: <JelszoModositasa student={this.state.student_register.student.id} /> } : null,
            this.state.rights.includes('READ_PARENTS') ? {id:11, url: '/tanulo-kezelese/T' + (this.state.id) + '/adatkezelo', name: 'Adatkezelő', render: <Szulok school={this.state.student_register.school.id} student={this.state.student_register.student.id} year={this.state.student_register.year.id} class={this.state.student_register.class.id} write_dinings={this.state.rights.includes('WRITE_DINING')} /> } : null,
            this.state.rights.includes('READ_STUDENT_REGISTER_INFO') ? {id:12, url: '/tanulo-kezelese/T' + (this.state.id) + '/osztaly-tortenet', name: 'Osztálytörténet', render: <OsztalyTortenet student={this.state.student_register.student.id} convertURLToClassURL={this.convertURLToClassURL} convertURLToSchoolURL={this.convertURLToSchoolURL} rights_for_student={this.state.rights}/> } : null,
            this.state.rights.includes('PASSWORD_RESET') ? {id:12, url: '/tanulo-kezelese/T' + (this.state.id) + '/jelszo-visszaallitas', name: 'Jelszóvisszaállítás', render: <JelszoVisszaallitas student={this.state.student_register.student.id}/> } : null,
        ].filter(menu_data => menu_data) : []
        let convertPage = [
            this.props.page === 'adatok' ? <Route path={'/tanulo-kezelese'} render={() => <Adat id={this.state.id} student_register={this.state.student_register} rights_for_student={this.state.rights}/>} /> : null,
            this.props.page === 'menzanaptar' ? <Route path={'/tanulo-kezelese'} render={() => <MenzanaptarBase school_data={this.state.student_register.school} school_year={this.props.match.params.school_year_id} student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} class={this.state.student_register.class.id} />} /> : null,
            this.props.page === 'megrendelt-etkezes' ? <Route path={'/tanulo-kezelese'} render={() => <MegrendeltEtkezes student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} year={this.state.student_register.year.id} class={this.state.student_register.class.id} read_meal_price={this.state.rights.includes('READ_MEAL_PRICE')} write_dinings={this.state.rights.includes('WRITE_DINING')} rights_for_student={this.state.rights}/>} /> : null,
            this.props.page === 'szamlak' ? <Route path={'/tanulo-kezelese'} render={() => <Szamlatortenet student={this.state.student_register.student.id} rights_for_student={this.state.rights} count_unseen_e_invoices={this.state.count_unseen_e_invoices}/>} /> : null,
            this.props.page === 'jelszo' ? <Route path={'/tanulo-kezelese'} render={() => <JelszoModositasa student={this.state.student_register.student.id} />} /> : null,
            this.props.page === 'adatkezelo' ? <Route path={'/tanulo-kezelese'} render={() => <Szulok student={this.state.student_register.student.id} />} /> : null,
            this.props.page === 'tanuloi-igenylesek/korabbi' ? <Route path={'/tanulo-kezelese'} render={() => <TanuloiIgenylesekKorabbi student_register={this.state.student_register.student_register} student={this.state.student_register.student.id} />} /> : null,
            this.props.page === 'tanuloi-igenylesek/uj/new' ? <Route path={'/tanulo-kezelese'} render={() =>  <TanuloiIgenyles meal_request_id ="new" student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} school_data={this.state.student_register.school} student={this.state.student_register.student.id} year={this.state.student_register.year.id} rights_for_student={this.state.rights} loadRights={this.loadRights}/>} /> : null,
            this.props.page === 'dokumentumok' ? <Route path={'/tanulo-kezelese'} render={() => <DokumentumBurkolo with_help={true} student_register={this.state.student_register.student_register} school={this.state.student_register.school.id} student={this.state.student_register.student.id} rights_for_student={this.state.rights}/>} /> : null,
            this.props.page === 'menu' ? <Route path={'/tanulo-kezelese'} render={() => <TanuloiMenu school={this.state.student_register.school.id} student_register={this.state.student_register.student_register} student={this.state.student_register.student.id} rights_for_student={this.state.rights} year={this.state.student_register.year.id}/>} /> : null,
        ].filter(menu_data => menu_data)

        let menu_objects = this.state.loaded && !this.state.error ? [
            this.props.rights.can_have_children ? {active_method: (menu_data) =>{
                let base_url = '/tanulo-kezelese/T' + (this.state.id)
                return base_url === menu_data.url || (menu_data.url+'/').indexOf(base_url)===0
            },title:'Szülői menü', menu_elements: this.props.rights.students.map(student =>({message_count:this.state.count_unseen_student[student.id],url: this.convertURLToStudentURL({ student_id: student.id }), name: student.name})), with_route: false} : null,
            {title:'Tanulói törzsadatok', menu_elements: menus, with_route: true, active_method: this.activeMethod}
        ].filter(m => m) : []

        return <React.Fragment>
            {
                this.state.loaded ? (this.state.error ? null : <ContentWithSideMenu key={this.state.keys}
                    extra_routes={[<Route path={'/tanulo-kezelese/T' + (this.state.id) + '/adatok/modositastortenet/:student_data_id/:school_id'} render={() => <AdatAllapot countUnseenStudentData={this.countUnseenStudentData} school={this.state.student_register.school.id} student={this.state.student_register.student.id} rights_for_student={this.state.rights} />} />]}
                    menus={menu_objects} convertPage={convertPage} default_path={'/tanulo-kezelese/T' + this.state.id} url_base={'tanulo-kezelese'}>
                        <div className="block">
                            <ul className="actual-selector student">
                                <li><span><span>Tanuló aktuális osztálya:</span></span></li>
                                <li><span>{Object.keys(this.props.rights.rights).length === 0 || !this.state.student_register.class.id ? <span>{this.state.student_register.school.abbreviated_name}</span> : <Link to={this.convertURLToSchoolURL()}>{this.state.student_register.school.abbreviated_name}</Link>}</span></li>
                                <li><span>{Object.keys(this.props.rights.rights).length === 0 || !this.state.student_register.school.id ? <span>{this.state.student_register.class.name + ' (' + this.state.student_register.year.name + ')'}</span> : <Link to={this.convertURLToClassURL()}>{this.state.student_register.class.name + ' (' + this.state.student_register.year.name + ')'}</Link>}</span></li>
                            </ul>
                        </div>
                </ContentWithSideMenu>) : <Loading loading_text='' />
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        user: state.user,
        promise_error_handler: state.app.promise_error_handler,
        system_messages_count: state.system_messages.count,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    setMasquaradedStudent: setMasquaradedStudent,
    setSystemMessagesCount: setSystemMessagesCount,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloKezelese))
