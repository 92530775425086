import React, { Component } from 'react'
import { setGuide, setTitle} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import SearchResult from './SearchResult';
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from '../components/LoadingButton';

class Tanulok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school || null,
            class: this.props.class || null,
            year: this.props.year || null,
            school_years: [],
            fetch_in_progress: false,
            id: this.props.id || null, 
            skip_update: false,
            parameters: {
                limit: 20,
                ...((this.props.history.location.state || {}).parameters || {})
            },
            order: (this.props.history.location.state || {}).order || [],
            result: (this.props.history.location.state || {}).result || undefined
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(CanteenClient.getSchoolYearsBySchoolID(this.state.school).then(school_years => {
            this.setState((prevState) =>({
                school_years: school_years,
                school_year: (school_years.find(school_year => school_year.year_id==prevState.year)||{id:''}).id,
                year: (school_years.find(school_year => school_year.year_id==prevState.year)||{year_id:''}).year_id
            }),()=>{
                if(this.state.school_year){
                    this.handleFetch()
                } else if(this.state.school_years.length===1){
                    this.props.history.replace('/'+this.props.match.params.mode+'/'+(this.state.class || this.state.school)+'/tanulok/'+this.state.school_years[0].year_id)
                } else if(this.state.school_years.find(school_year => school_year.default)){
                    this.props.history.replace('/'+this.props.match.params.mode+'/'+(this.state.class || this.state.school)+'/tanulok/'+school_years.find(school_year => school_year.default).year_id)
                } else if(this.props.history.location.state){
                    this.handleFetch()
                }
            })
        }))
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.year || null) !== (this.state.year||null)){
            this.setState({
                year: this.props.year,
                act_school_year: (this.state.school_years.find(school_year => school_year.year_id==this.props.year)||{id:''}).id
            }, ()=> {
                this.handleFetch()
            })
        }
    }
    
    handleChangeOrder(order) {
        this.setState({
            skip_update: true,
            order: order
        }, () => {
            this.setState({
                skip_update: false
            })
            this.handleSearch(0)
        })
    }


    handleSearch = (page) =>{
        this.props.clearMessages()

        this.setState({
            search_in_progress: true,
            skip_update: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.openReport('CanteenService/Report/openStudentData', {
                school_id: this.state.school,
                school_year_id: this.state.school_year,
                class_id: this.state.class}, this.state.parameters.limit, page* this.state.parameters.limit, this.state.order).then(result => {
                this.setState({
                    search_in_progress: false,
                    result: {
                        result: result.result || [],
                        result_summary: {
                            ...result.result_summary,
                            page: page
                        },
                        headers: result.headers
                    }
                }, () => {
                    this.props.clearMessages()
                })
            })).then(() =>
                this.setState({
                    search_in_progress: false,
                    skip_update: false,
                    show_student_list: true
                })
            )
        })
    }

    handleschoolYearChange(school_year_id) {
        this.props.clearMessages()
        school_year_id = this.state.school_years.length===1 ? this.state.school_years[0].id :(this.state.school_years.find(school_year=>school_year.id==school_year_id)||{id:''}).id
        this.setState({
            act_school_year: school_year_id,
            show_student_list: false
        })
    }

    handleFetch() {
        if(!this.state.act_school_year || this.state.school_year == this.state.act_school_year) {
            this.handleSearch(0)
        } else {
            this.props.history.push('/'+this.props.match.params.mode+'/'+(this.state.class || this.state.school)+'/tanulok/'+(this.state.school_years.find(sy => sy.id ==this.state.act_school_year).year_id))
            this.setState((prevState)=>({
                school_year: prevState.act_school_year
            }),()=>{
                this.handleSearch(0)
            })
        }
    }

    getLinkForStudent = (row)=>{
        return '/tanulo-kezelese/T'+(-1*row.student_id)+'/adatok'
    }

    getLinkForClass = (row)=>{
        return '/osztaly-kezelese/'+row.class_id+'/tanulok/'+(this.state.school_years.find(sy => sy.id==this.state.school_year)||{}).year_id
    }

    render() {
        return (
            <React.Fragment>
                    <React.Fragment>
                        {
                    this.state.disable_menu ? <Loading loading_text=''/> : <React.Fragment><div className="page-subtitle">Tanév kiválasztása</div>
                        <div className="student-select-block">
                            <div>
                                <div className="block block-sm-1-1">
                                    <select disabled={this.state.school_years.length < 2} onChange={event => this.handleschoolYearChange(event.target.value)} value={this.state.act_school_year || this.state.school_year}>
                                        <option value="">Kérem válasszon!</option>
                                        { this.state.school_years.map(school_year => <option key={school_year.id} value={school_year.id}>{school_year.name}</option>) }
                                    </select>
                                </div>
                            </div>
                            <div>
                                <LoadingButton loading={this.state.fetch_in_progress} onClick={() => this.handleFetch()}>Lekérdez</LoadingButton>
                            </div>
                        </div>
                    </React.Fragment> }
                    </React.Fragment>
                {
                    !this.state.search_in_progress ? (this.state.show_student_list ? <SearchResult
                            title="Tanulók"
                            result={this.state.result}
                            parameters={this.state.parameters}
                            conditions={{
                                school_id: this.state.school || undefined,
                                school_year_id: this.state.school_year || undefined,
                                class_id: this.state.class || undefined
                            }}
                            search={page => this.handleSearch(page)}
                            order={this.state.order}
                            changeOrder={order => this.handleChangeOrder(order)}
                            linkMethods= {{
                                name: this.getLinkForStudent,
                                class_name: this.getLinkForClass
                            }}
                            report_url = {'CanteenService/Report/studentDataReport'}
                            hideHeaders = {['school_abbr', 'year_name'].concat(this.state.class ? ['class_name', 'class_type'] : [])}
                            /> : null) : <Loading loading_text=''/>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tanulok))
