import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import CanteenClient from "../../CanteenClient"
import Loading from "@sapkk/app/components/Loading"
import PopUp from "../../components/PopUp"
import { addAction, removeAction } from "../../actions"

class Szulok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school || null,
            class: this.props.class || null,
            student: this.props.student || null,
            year: this.props.year || null,
            parents: [],
            fetching: true
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+(-1*this.state.student)+'/adatkezelo'){
            this.props.history.replace('/tanulo-kezelese/T'+(-1*this.state.student)+'/adatkezelo')
        }
        this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        this.props.promise_error_handler(CanteenClient.getParents(this.state.student).then(parents => {
            this.setState({
                parents: parents,
                fetching: false
            })
        }))
    }

    componentWillUnmount() {
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    render() {
        return this.state.fetching ? <Loading loading_text=''/> : <div className="block">
            { this.state.popup_help_open && (
                <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                    <p>
                        <div>Az LDAP azonosítóval rendelkező szülőnek lehetősége van a gyerek oktatási azonosítójával és születési dátumával,  vagy a szülő LDAP azonosítójával és a hozzá tartozó jelszóval belépni a rendszer szülői felületére.</div>
                        <div>Ha LDAP azonosítóval történik a bejelentkezés, akkor a szülőnek először a gyerekét/gyerekeit „magához kell rendelnie”. Így a továbbiakban egy felületen tudja minden gyerekének adatát, hiányzását, stb… kezelni.</div>
                        <div>Egy gyereket, több szülő is magához tud rendelni abban az esetben, ha a szülő rendelkezik LDAP azonosítóval.</div>
                        <div>Az <span style={{fontWeight: 'bold'}}>Adatkezelő</span> menüpontban az látszik, hogy mely felhasználók rendelték a gyereket magukhoz.</div>
                    </p>
                </PopUp>
            ) }
            <div className="page-subtitle">
                A gyerek az alábbi személy(ek)hez van hozzárendelve:
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Név</th>
                        <th>Hozzáférés kezdete</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.parents.length !== 0 ? this.state.parents.map(parent => {
                        return <tr>
                            <td>{parent.name}</td>
                            <td>{parent.date_from}</td>
                        </tr>
                    }) : <tr className="table-empty-indicator">
                        <td colSpan={2}>Nincs szülő hozzárendelve</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Szulok))