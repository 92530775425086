import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage} from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import { addAction, removeAction } from "../../actions"
import DateField from "@sapkk/app/components/DateField"
import PopUp from "../../components/PopUp";

class SzamlaFormatum extends Component {
    constructor(props) {
        super(props)

        this.state = {
            student: this.props.student,
            rights: this.props.rights_for_student,
            fetch_in_progress: true,
            disabled_einvoice_info: null,
            new_sap_customer_code: '',
            new_date: '',
            popup_info: null
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getDisabledEInvoiceInfo(this.state.student),
            CanteenClient.haveEInvoice(this.state.student)
        ]).then(([disabled_einvoice_info, have_e_invoice]) => {
            this.setState({
                disabled_einvoice_info: disabled_einvoice_info || {date_from: ''},
                have_e_invoice: have_e_invoice,
                fetch_in_progress: false
            })
        }))
        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
    }

    componentWillUnmount(){
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    handleSave = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=> CanteenClient.disableEInvoice(this.state.student, this.state.disabled_einvoice_info.date_from).then(disabled_einvoice_info => {
                this.props.addMessage('Az adatok mentése megtörtént.', 'success')
                this.setState({
                    disabled_einvoice_info: disabled_einvoice_info,
                    save_in_progress: false
                })
            }).catch(e => {
                if(e.message.indexOf('E-számláról papír alapú számlára áttérni') === 0){
                    this.setState({
                        popup_info: {
                            message: e.message
                        }
                    })
                } else{
                    throw e
                }
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handleChange = (event) =>{
        let name = event.target.name
        let value = event.target.type==="checkbox" ? event.target.checked : event.target.value
        this.setState((prevState)=>({
            disabled_einvoice_info: {
                ...prevState.disabled_einvoice_info,
                [name]: value
            }
        }))
    }

    handleAccept = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.acceptDisableEInvoice(this.state.disabled_einvoice_info.id).then(disabled_einvoice_info => {
                this.props.addMessage('Az elfogadás megtörtént.', 'success')
                this.setState({
                    disabled_einvoice_info: disabled_einvoice_info,
                    save_in_progress: false
                })
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handleUnDone = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.undoneDisableEInvoice(this.state.disabled_einvoice_info.id).then(result => {
                this.props.addMessage('A visszavonás megtörtént.', 'success')
                this.setState({
                    disabled_einvoice_info: {date_from: ''},
                    save_in_progress: false
                })
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handlePopUpClose = () => {
        this.setState({
            popup_info: null
        })
    }

    render() {
        if(this.state.fetch_in_progress){
            return <Loading/>
        }
        let before_date = null
        if(this.state.disabled_einvoice_info.id){
            let date = new Date(this.state.disabled_einvoice_info.date_from)
            date.setDate(date.getDate() - 1)
            before_date = date.toISOString().split(['T'])[0]
        }
        //todo a felület nem kezeli, hogyha vissza lehet vonni a tiltást

        let actions = [
            !this.state.disabled_einvoice_info.id && this.props.rights_for_student.includes('DISABLE_E_INVOICE_INTERVAL') ? <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton> : null,
            this.state.disabled_einvoice_info.id && !this.state.disabled_einvoice_info.is_accepted && this.props.rights_for_student.includes('ACCEPT_E_INVOICE_INTERVAL') ? <LoadingButton loading={this.state.save_in_progress} disabled={this.state.undone_in_progress} onClick={this.handleAccept}>Elfogadás</LoadingButton> : null,
            this.state.disabled_einvoice_info.id && !this.state.disabled_einvoice_info.is_accepted && this.props.rights_for_student.includes('UNDONE_DISABLED_E_INVOICE_INTERVAL') ? <LoadingButton loading={this.state.undone_in_progress} disabled={this.state.save_in_progress} onClick={this.handleUnDone}>Visszavonás</LoadingButton> : null
        ].filter(x => x)

        return <React.Fragment>
            { this.state.popup_help_open && (
                <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                    <p>
                        <div style={{fontStyle: "italic"}}>
                            2022. szeptemberétől bevezetésre került az elektronikus számlázási rendszer. Az elkészült elektronikus számlák megtekintésére, letöltésére az UD Menza rendszerben, a Számlák menüpont alatt van lehetőség.
                        </div>
                    </p>
                    <p>
                        <div>Az <span style={{fontWeight: 'bold'}}>„Adatok”</span> -> <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>„Törzsadat”</span> menüpontban a <span style={{fontStyle: 'italic'}}>„Számlaformátum”</span> fülön a szülőnek lehetősége van az alapértelmezett számlázási módtól eltérően papír alapú számlát kérni.</div>
                        <div>A papíralapú számla igénylésére a képernyőn lévő dátum mezőbe a rögzítés napját követő munkanap megadásával van lehetőség, melyet még az iskolai ügyintézőnek – a szülővel történt egyeztetés után - jóvá kell hagynia.</div>
                    </p>
                </PopUp>
            ) }
            <div className={"szamlaformatum block"}>
                {
                    this.state.disabled_einvoice_info.id && !this.state.disabled_einvoice_info.is_accepted ? <div className="not-authenticated">Jóváhagyásra vár!</div> : null
                }
                <div className={"block"}>
                    {this.state.disabled_einvoice_info.id && this.state.disabled_einvoice_info.is_accepted ?
                        <React.Fragment>
                            <div className={"szamlaforma"}>A számla – az alapértelmezett számlázási módtól eltérően – papír alapon kerül kiállításra.</div>
                            {this.state.have_e_invoice ? <div className={""}>A számla elektronikus formában került kiállításra <DateField className={'text-field'} readOnly={true} value={before_date}/>-ig.</div> : null}
                        </React.Fragment>
                        : <React.Fragment>
                            <div className={"szamlaforma"}>A számla elektronikus formában kerül kiállításra.</div>
                            <div>
                                Az alapértelmezett számlázási módtól eltérően papíralapú számlát kérek a következő dátumtól: <DateField className="text-field"readOnly={this.state.disabled_einvoice_info.id || !this.props.rights_for_student.includes('DISABLE_E_INVOICE_INTERVAL')} placeholder={"éééé.hh.nn"} value={this.state.disabled_einvoice_info.date_from} name={"date_from"} onChange={this.handleChange}/>
                            </div>
                        </React.Fragment>}

                </div>
                {actions.length ? <div className={"actions block"}>
                    {actions}
                </div> : null}
                <hr className={"block"}/>
                <div className={"block tajekoztato"}>
                    <div>2022. szeptemberétől bevezetésre került az elektronikus számlázási rendszer.</div>
                    <div>Az elkészült elektronikus számlák megtekintésére, letöltésére az UD Menza rendszerben, a Számlák menüpont alatt van lehetőség.</div>
                    <div>Amennyiben a Törzsadat -> Kapcsolattartási adatok menüpont alatt megadásra kerül a kapcsolattartási e-mail cím, az elkészült számláról a rendszer automatikus tájékoztató üzenetet küld a megadott e-mail címre.</div>
                </div>
                {
                    this.state.popup_info ? <PopUp title={"Figyelmeztetés"} close={this.handlePopUpClose} buttons={[{action: this.handlePopUpClose, text: 'Ok'}]}>
                        <div className={"block"}>
                            {this.state.popup_info.message}
                        </div>
                    </PopUp> : null
                }
            </div>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        promise_error_handler: state.app.promise_error_handler,
        text_styles: state.text_styles,
        rights: state.rights,
        meal_request_states: state.meal_request_states
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SzamlaFormatum))