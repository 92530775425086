import React, { Component } from 'react'
import { setGuide, setTitle} from "../../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from '../../components/LoadingButton';

class TanuloEtkezesenekTomegesLezarasa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            years: [],
            classes: [],
            year: "",
            school_id: this.props.school || '',
            act_classes: [],
            date: "",
            save_in_progress: false,
            disable_menu: true
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getYearsForSchool(this.state.school_id,'ADD_STUDENT'),
        ]).then(([years]) => {
            this.setState({
                years: years,
                loaded: true,
                disable_menu: false
            }, ()=> {
                if(years.length === 1){
                    this.handleYearChange(years[0].id)
                }
            })
        }))
    }

    handleYearChange(year_id) {
        this.props.clearMessages()
        this.setState({
            year: year_id,
            classes: [],
            act_classes: []
        }, ()=> {
            if (year_id) {
                this.props.promise_error_handler(Promise.all([
                    CanteenClient.getSchoolClasses(this.state.school_id, year_id),
                ]).then(([classes]) => {
                    this.setState({
                        classes: classes,
                    })
                }))
            }
        })
    }
    handleDataChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        })

        this.props.promise_error_handler(()=>CanteenClient.closeDiningByClassIDs(this.state.school_id, this.state.year, this.state.date, this.state.act_classes).then(result => {
            this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
            this.setState({
                save_in_progress: false
            })
        })).then(() => {
            this.setState({
                save_in_progress: false
            })
        })
    }

    toggleSelectAll = ()=>{
        this.setState((prevState) => ({
            act_classes: prevState.act_classes.length ? [] : prevState.classes.map(s => s.id)
        }))
    }

    render() {
        return (
            this.state.disable_menu ? <Loading  loading_text=''/>: <React.Fragment>
                <div className="block">
                    <label className="label label-long required">Tanév</label>
                    <select disabled={this.state.disable_menu|| this.state.years.length < 2} className="field" value={this.state.year} onChange={event => this.handleYearChange(event.target.value)}>
                        <option value="" disabled={true}>Kérem válasszon!</option>
                        { this.state.years.map(year => <option value={year.id}>{year.name}</option>) }
                    </select>
                </div>
                <div className="block">
                    <label className="label label-long required">Lezárási dátum (nincs étkezés)</label>
                    <input className="field field-simple" placeholder="éééé.hh.nn" value={this.state.date} onChange={event => this.handleDataChange('date', event.target.value)}/>
                </div>
                <div className="block">
                    <label className="label label-long required">Osztály</label>
                    <div className="table-container">
                        <table className="close-class-dining striped">
                            <thead>
                                <tr>
                                    <th onClick={this.toggleSelectAll}>Lezárás</th>
                                    <th>Név</th>
                                </tr>
                            </thead>
                            <tbody>
                            { this.state.classes.map(c =>
                                <tr key={c.id}>
                                    <td><input type="checkbox" checked={this.state.act_classes.includes(c.id)} onClick={event => this.handleDataChange('act_classes', this.state.act_classes.filter(s => s !== c.id).concat(event.target.checked ? c.id : null).filter(s => s))}/></td>
                                    <td>{ c.name }</td>
                                </tr>
                            ) }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="actions">
                    <LoadingButton loading={this.state.save_in_progress} disabled={this.state.disable_menu} onClick={() => this.handleSave()}>Mentés</LoadingButton>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}


const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(TanuloEtkezesenekTomegesLezarasa)
