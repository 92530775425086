import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import { withRouter } from "react-router"
import FilestoreClient from "../../FileStoreClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import SelectField from "@sapkk/app/components/SelectField"
import SeparatedNumberField from "@sapkk/app/components/SeparatedNumberFields"
import LoadingButton from "../../components/LoadingButton"

class Field extends Component {
    constructor(props) {
        super(props)
        this.file_input = this.props.field.type=='FILE' ? React.createRef() : null
        this.state= {
            upload_in_progress: false,
            value: this.props.value.value,
            field: this.props.field
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value || props.field !== state.field) {
            return {
                value: props.value.value,
                field: props.field
            }
        } else {
            return null
        }
    }

    componentDidMount = ()=>{
        if(this.props.handleChange && this.state.field.possible_values && !this.state.field.multiple && !(this.props.readOnly || !this.props.handleChange || !this.state.field.editable)
            && this.state.field.required && this.state.field.possible_values.length === 1 && !this.state.value){
                this.props.handleChange(this.state.field,{target:{value:this.state.field.possible_values[0].value}})
        }
    }
    
    createInputElement = (prop, value) => {
        switch(this.state.field.type){
            case 'STRING':
                return <SimpleField {...prop} value={value}/>
            case 'REAL':
            case 'INTEGER':
                return <SeparatedNumberField  {...prop} value={value}/>
            case 'BOOL':
                return <input  {...prop} disabled={prop.readOnly} checked={value}/>
            case 'DATE':
                if(this.props.field.name === 'date_from' && this.props.acceptable_date){
                    prop.title='Javasolt dátum: ' + this.props.acceptable_date
                }
                return <SimpleField {...prop} value={value}/>
            case 'FILE':
                return <div className="text-container">
                    {value ? <a {...prop} onClick={()=>this.props.promise_error_handler(FilestoreClient.download(value))}>{(this.props.documents[value]||{}).file_name||'Feltöltött dokumentum letöltése'}</a> : <span className='text-container'>Nincs megadva</span>}
                    {!prop.readOnly ? <React.Fragment>
                        <LoadingButton loading={this.state.upload_in_progress} onClick={this.clickFileInput}>Feltöltés</LoadingButton>
                    </React.Fragment> : null}
                </div>
        }
    }

    uploadFile = () =>{
        if (this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            },()=>{
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0], true).then(result => {
                    if(this.props.handleFileUpload){
                        this.props.handleFileUpload(result)
                    }
                    if(this.state.field.multiple){
                        if(this.props.handleAddLine){
                            this.props.handleAddLine(this.state.field, result.hash)
                        }
                    } else{
                        if(this.props.handleChange){
                            this.props.handleChange(this.state.field,{target:{value:result.hash}})
                        }
                    }
                })).then(()=>{
                    this.setState({
                        upload_in_progress: false
                    })
                })
            })
        }
    }

    clickFileInput = () => {
        this.file_input.current.click()
    }

    getFieldInput = () => {
        let props = {
            className: 'field field-simple',
            readOnly: this.props.readOnly || !this.props.handleChange || !this.state.field.editable,
            name: this.state.field.name,
            id: this.state.field.name
        }
        props.onChange=(event)=>this.props.handleChange(this.state.field, event)
        props.onBlur=(event)=>this.props.handleBlur(this.state.field, event)
        if(this.state.field.possible_values !== null && this.state.field.possible_values !== undefined){
            if(this.state.field.multiple){
                return <table>
                    <tbody>
                        {this.state.field.possible_values.map((pv,i) => {
                            let checked = !!(this.state.value||[]).find(v=>v==pv.value)
                            props.value = pv.value
                            props.disabled = props.readOnly || !!pv.disabled
                            props.id = this.state.field.name+'_'+i
                            return <tr key={this.state.field.name+'_'+i+'_row'}>
                                <td><input type="checkbox" {...props} checked={checked}/></td>
                                <td>{this.state.field.name === 'reimbursement_reason' ?
                                    <label htmlFor={this.state.field.name+'_'+i} style={{userSelect: 'none'}} dangerouslySetInnerHTML={{__html: pv.alias}}/>:
                                    <label htmlFor={this.state.field.name+'_'+i} style={{userSelect: 'none'}}>{pv.alias}</label>}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
            return <SelectField
                {...props}
                value={ this.state.value }
                displayValues={this.state.field.possible_values.reduce((prev, curr) => ({
                ...prev,
                [curr.value]: curr.alias
            }),{})}>
                {[this.state.field.multiple ? null : <option value="">Kérem válasszon!</option>].concat(this.state.field.possible_values.map(pv => <option value={pv.value} disabled={!!pv.disabled}/>)).filter(e => e)}
            </SelectField>
        }
        switch(this.state.field.type){
            case 'STRING':
                props.type="text"
                break;
            case 'INTEGER':
                props.type="text"
                props.pattern='-?\\d*'
                break;
            case 'REAL':
                props.type="number"
                props.step="any"
                break;
            case 'BOOL':
                props.type="checkbox"
                break;
            case 'DATE':
                props.type="text"
                props.placeholder="éééé.hh.nn"
                break;
            case 'FILE':
                break;
        }
        if(this.state.field.multiple){
            return <div>
                <table className="modify-table">
                    <tbody>
                        {(this.state.value||[]).map(
                            (v, i) => {
                                props.onChange = (event)=>this.props.handleChange(this.state.field,event, i)
                                props.onBlur = (event)=>this.props.handleBlur(this.state.field,event, i)
                                props.className = props.className + " " + this.getClasses(this.state.field, i).join(' ')
                                return <tr key={i+','+v}>
                                    <td>{this.createInputElement(props,v)}</td>
                                    <td className="col-actions">{!props.readOnly && this.props.handleRemoveRow ? <button className="remove" onClick={()=>this.props.handleRemoveRow(this.state.field, i)}/> : null}</td>                             
                                </tr>
                            }
                        )}
                          {!props.readOnly && this.props.handleAddLine || (this.state.value||[]).length===0 ? <tr className="table-new-row">
                                <td>{(this.state.value||[]).length===0 ? (this.state.field.type==='FILE' ? 'Dokumentum nem került feltöltésre' : "Nincs érték megadva.") : null}</td>
                                <td className="col-actions">{!props.readOnly && this.props.handleAddLine? <button onClick={() => {this.state.field.type==='FILE' ? this.clickFileInput() : this.props.handleAddLine(this.state.field)}}/> : null}</td>
                            </tr> : null }
                    </tbody>
                </table>
            </div>
        } else{
            props.className = props.className + " " + this.getClasses(this.state.field).join(' ')
            return this.createInputElement(props,this.state.value)
        }
    }

    getClasses = (field, index = null) => {
        if(field.name === 'date_from'){
            if(this.props.border_date === false){
                return ['invalid']
            }
        }
        return []
    }

    render() {
        if(this.state.field.type=='BOOL' && this.state.field.reversed){
            return <div className={["block"].concat(this.props.classArray || []).join(' ')}>
            <span className={["input-container"].concat(this.state.field.required ? ['required'] : []).join(' ')}>{this.getFieldInput()}</span>
            <label className={'checkbox-label field'} title={this.state.field.alias} for={this.state.field.name}>{this.state.field.description}</label>
        </div>
        }
        return <div className={["block meal-request-field"].concat(this.props.classArray || []).join(' ')}>
            {
                this.file_input ? <input ref={this.file_input} type="file" onChange={this.uploadFile} style={{display: 'none'}}></input> : null
            }
            <label className={["label", 'label-long'].concat(this.state.field.required ? ['required'] : []).join(' ')} title={this.state.field.description} for={this.state.field.name}>{(this.props.is_cancelling ? this.state.field.cancelling_alias : null) || this.state.field.alias}</label>
            {
                this.getFieldInput()
            }
        </div>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Field))