class FilestoreClient {
    static download(id, service = null, temp = false) {
        let post_data = new FormData();
        post_data.append('hash', id)
        return (temp ? new Promise((resolve)=>resolve(null)) : fetch((service || process.env.REACT_APP_FILESTORE_URL) + '/FileStoreService/File/generateTempHash/', {
            credentials: 'include',
            method: "post",
            body: post_data
        })).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response =>{
            if(!response){
                return {hash: id}
            } else if(response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        }).then(result => {
            let iframe = document.getElementById("filestore-download-frame")
            if (!iframe) {
                iframe = document.createElement("iframe")
                iframe.id = "filestore-download-frame"
                iframe.style.display = "none"
                document.body.appendChild(iframe)
            }
            iframe.src = (service || process.env.REACT_APP_FILESTORE_URL) + '/FileStoreService/File/downloadTemp/' + result.hash + '?format=BIN'
        })
    }

    static downloadWithoutTempCheck(id, service = null, temp = false) {
        let iframe = document.getElementById("filestore-download-frame")
        if (!iframe) {
            iframe = document.createElement("iframe")
            iframe.id = "filestore-download-frame"
            iframe.style.display = "none"
            document.body.appendChild(iframe)
        }
        iframe.src = (service || process.env.REACT_APP_FILESTORE_URL) + '/FileStoreService/File/' + (temp ? 'downloadTemp' : 'download') +'/' + id + '?format=BIN'
    }

    static downloadBase64(id){
        return fetch(process.env.REACT_APP_FILESTORE_URL + '/FileStoreService/File/download/' + id + '?format=BASE64', {
            credentials: 'include',
            method: "get"
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response =>{
            if(response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static checkUniqueness(hash) {
        let post_data = new FormData();
        post_data.append('hash', hash)

        return fetch(process.env.REACT_APP_FILESTORE_URL + '/FileStoreService/File/checkUniqueness', {
            credentials: 'include',
            method: "post",
            body: post_data
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response =>{
            if(response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    throw new Error(json.message || 'Nem várt hiba történt.')
                })
            }
        })
    }

    static upload(file, all_information = false) {
        let upload_part = (resolve, reject, file, hash, start, fragment_size) => {
            let blob = file.slice(start, start + fragment_size)
            let reader = new FileReader()

            reader.onloadend = (event) => {
                let req = new XMLHttpRequest()
                let data = new FormData()

                if (hash) {
                    data.append('hash', hash)
                } else {
                    data.append('name', file.name)
                }
                if (file.size === start + event.loaded) {
                    data.append('storage_mode', 'TEMPORARY')
                } else {
                    data.append('storage_mode', 'FRAGMENT')
                }
                data.append('content', event.target.result.substring(event.target.result.indexOf(';base64,')+8))
                data.append('format', 'BASE64')

                req.addEventListener("load", (event) => {
                    let response = JSON.parse(event.target.response)

                    if (event.target.status < 200 || event.target.status >= 300) {
                        reject(new Error(response.message || 'Nem várt hiba történt.'))
                    } else if (file.size > start + fragment_size) {
                        setTimeout(() => upload_part(resolve, reject, file, response.hash, start + fragment_size, fragment_size), 0)
                    } else {
                        resolve(all_information ? response : response.hash)
                    }
                })

                req.open('POST', process.env.REACT_APP_FILESTORE_URL + '/FileStoreService/File/upload')
                req.send(data)
            }

            reader.readAsDataURL(blob)
        }

        return new Promise((resolve, reject) => {
            upload_part(resolve, reject, file, undefined, 0, 1048576)
        })
    }
}

export default FilestoreClient