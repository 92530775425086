import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import SelectField from "@sapkk/app/components/SelectField"
import DateField from "@sapkk/app/components/DateField"


class Iskola extends Component {

    constructor(props) {
        super(props)
    }

    handleDataChange(name,value){
        if(this.props.handleDataChange){
            this.props.handleDataChange(name,value)
        }
    }

    render() {
        let alkalmazasgazda = Object.keys(this.props.rights.rights).includes('alkalmazasgazda')
        return <React.Fragment>
            <div className="block block-lg-1-2">
                    <label className="label label-long">Név</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || !alkalmazasgazda || this.props.save_in_progress} value={this.props.data.name} onChange={event => this.handleDataChange('name', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Rövidítés</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || !alkalmazasgazda || this.props.save_in_progress} value={this.props.data.abbreviated_name} onChange={event => this.handleDataChange('abbreviated_name', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Cím</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || !alkalmazasgazda || this.props.save_in_progress} value={this.props.data.address} onChange={event => this.handleDataChange('address', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Bank név</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || !alkalmazasgazda || this.props.save_in_progress} value={this.props.data.bank_name} onChange={event => this.handleDataChange('bank_name', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Bankszámla</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || !alkalmazasgazda || this.props.save_in_progress} value={this.props.data.bank_account} onChange={event => this.handleDataChange('bank_account', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">E-mail-cím</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || this.props.save_in_progress} value={this.props.data.email} onChange={event => this.handleDataChange('email', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Menzanaptárt használ</label>
                    <SimpleField className="field field-simple" type="checkbox" readOnly={this.props.readOnly || !alkalmazasgazda || this.props.save_in_progress} checked={this.props.data.use_calendar} value={this.props.data.use_calendar ? 'Igen' : 'Nem'} onChange={event => this.handleDataChange('use_calendar', event.target.checked)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Ügyintéző</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || this.props.save_in_progress} value={this.props.data.administrator_name} onChange={event => this.handleDataChange('administrator_name', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Telefonszám</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || this.props.save_in_progress} value={this.props.data.phone_number} onChange={event => this.handleDataChange('phone_number', event.target.value)} />
                </div>
                <div className="page-subtitle">Egyéb paraméterek</div>
                {false && <div className="block block-lg-1-1">
                    <label className="label label-long">Igénylésszöveg</label>
                    <textarea className="field field-text" readOnly={this.props.readOnly || this.props.save_in_progress} onChange={event => this.handleDataChange('meal_request_text', event.target.value)} value={this.props.data.meal_request_text}></textarea>
                </div>}
                <div className="block block-lg-1-1">
                    <label className="label label-long">Menzanaptár szöveg</label>
                    <textarea className="field field-text" readOnly={this.props.readOnly || !alkalmazasgazda || this.props.save_in_progress} onChange={event => this.handleDataChange('dining_calendar_text', event.target.value)} value={this.props.data.dining_calendar_text}></textarea>
                </div>
                <div className={"block block-1-2"}>
                    <label className={"label label-long"}>Docuhub címke</label>
                    <SimpleField className="field field-simple" readOnly={this.props.readOnly || this.props.save_in_progress} value={this.props.data.docuhub_label_id} onChange={event => this.handleDataChange('docuhub_label_id', event.target.value)} />
                </div>
                {this.props.data.id ? <React.Fragment>
                    <div className="page-subtitle">Pénzügyi központ</div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Étvényesség kezdete</th>
                                    <th>Érvényesség vége</th>
                                    <th>Pénzügyi központ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.data.financial_centers.length !== 0 ? this.props.data.financial_centers.map(sd => <tr>
                                        <td><DateField readOnly={true} value={sd.date_from}/></td>
                                        <td><DateField readOnly={true} value={sd.date_to}/></td>
                                        <td>{sd.financial_center}</td>
                                    </tr>) : <tr className="table-empty-indicator">
                                        <td colSpan={3}>Nem tartozik pénzügyi központ</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                    !this.props.readOnly && alkalmazasgazda ? <React.Fragment>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Új pénzügyi központ</label>
                            <SimpleField className="field field-simple" name="new_financial_center" onChange={event => this.handleDataChange('new_financial_center', event.target.value)} value={this.props.data.new_financial_center}/>
                        </div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Dátum</label>
                            <DateField className="field field-simple" name="new_date" onChange={event => this.handleDataChange('new_date', event.target.value)} value={this.props.data.new_date}/>
                        </div>
                    </React.Fragment> : null
                }
                </React.Fragment> : null}
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        rights: state.rights
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Iskola))