import 'core-js'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from "./containers/App"
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import appReducer from './reducers'
import MyBrowserRouter from "./containers/MyBrowserRouter";
require('es6-promise').polyfill()
require('isomorphic-fetch')

const store = createStore(
    appReducer
)

ReactDOM.render(
    <Provider store={store}>
        <MyBrowserRouter basename={process.env.PUBLIC_URL}>
            <App/>
        </MyBrowserRouter>
    </Provider>,
    document.getElementById('root')
)