import React, { Component } from "react"
import { connect } from "react-redux"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Sablon from "./Sablon"

class BankAdatlapSablon extends Component {

    getDownloadMethod = (state, in_system,data) => {
        if (!data.all_student_from_school && (!data.class_id || data.student_ids.filter(st => st).length == 1)) {
            return CanteenClient.getFilledTemplateHash(state.template.document_hash, [], state.school, data.year_id, data.student_ids.length == 1 ? data.student_ids[0] : null, 'bank_helper',in_system, data.date, data.zero_disable)
        } else {
            return CanteenClient.getMultiFilledTemplateHash(state.template.document_hash, [], state.school, data.year_id, data.class_id, data.student_ids, 'bank_helper', in_system, data.date, data.zero_disable)
        }
    }

    render() {
        return <Sablon
            title="Banki adatlap sablon kezelése"
            template_type={'bank_helper'}
            school={this.props.school}
            rights_for_school={this.props.rights_for_school}
            can_download_online={true}
            with_date={true}
            date_title={'Adott napon érvényes átalány'}
            with_zero_disable_option={true}
            getDownloadMethod={this.getDownloadMethod}/>
    }
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BankAdatlapSablon))