import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading";
import LoadingButton from "../components/LoadingButton";
import SelectField from "@sapkk/app/components/SelectField"

class OsztalyAdataiBase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school || null,
            class: this.props.class || null
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/osztaly-kezelese/'+this.state.class+'/adatok'){
            this.props.history.replace('/osztaly-kezelese/'+this.state.class+'/adatok')
        }
        this.handleFetch()
    }

    handleDataChange(name, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            }
        }))
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler((() => {
                if (this.state.class) {
                    return CanteenClient.editSchoolClass(this.state.class, this.state.data.name, this.state.data.type, this.state.data.xmenza_code, this.state.data.is_graduating, this.state.can_generate_transfer_order)
                } else {
                    return CanteenClient.createSchoolClass(this.state.school, this.state.data.name, this.state.data.type, this.state.data.xmenza_code, this.state.data.is_graduating, this.state.can_generate_transfer_order)
                }
            })().then(result => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                this.setState({
                    class: result.id,
                    data: {
                        ...result
                    }
                })
            }))
        })
    }

    handleDelete = ()=>{
        this.props.clearMessages()

        this.setState({
            deleting_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.deleteSchoolClass(this.state.class).then(result => {
                this.props.clearMessages()
                this.props.addMessage('A törlés megtörtént.', 'success')

                this.setState({
                    class: "",
                    data: undefined
                })
            }))
        })
    }

    handleNew() {
        this.setState({
            class: "",
            data: {
                school_id: "",
                name: "",
                type: "",
                xmenza_code: "",
                is_graduating: "",
                can_generate_transfer_order: ""
            }
        })
    }

    handleFetch() {
        if(this.state.class){
            this.props.clearMessages()

            this.setState({
                fetch_in_progress: true
            },()=>{
                this.props.promise_error_handler(CanteenClient.getSchoolClass(this.state.class).then(schoolclass => {
                    this.setState({
                        data: schoolclass,
                        fetch_in_progress: false
                    })
                }))
            })
        } else{
            this.handleNew()
        }
    }

    render() {
        return <React.Fragment>
            { this.state.data ? <React.Fragment>
                <div className="page-subtitle">Osztály adatai</div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Név</label>
                    <SimpleField readOnly={!!this.state.class} className="field field-simple" value={this.state.data.name} onChange={event => this.handleDataChange('name', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Típus</label>
                    <SelectField readOnly={!!this.state.class} className="field field-simple" value={this.state.data.type} displayValues={Object.keys(this.props.class_types).reduce((o, k) => ({...o, [k]: this.props.class_types[k].name}),{})} onChange={event => this.handleDataChange('type', event.target.value)}>
                        <option value="">Kérem válasszon!</option>
                        {Object.keys(this.props.class_types).map(type => <option value={type}>{this.props.class_types[type].name}</option>)}
                    </SelectField>
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">xMenza kód</label>
                    <SimpleField readOnly={!(!this.state.class || 'alkalmazasgazda' in this.props.rights.rights)} className="field field-simple" value={this.state.data.xmenza_code} onChange={event => this.handleDataChange('xmenza_code', event.target.value)} />
                </div>
                <div className="block block-lg-1-2">
                    <label className="label label-long">Végzős</label>
                    <input disabled={!(!this.state.class || 'alkalmazasgazda' in this.props.rights.rights)} type="checkbox" checked={this.state.data.is_graduating} onChange={event => this.handleDataChange('is_graduating', event.target.checked)} />
                </div>
                
                <div className="block block-lg-1-2">
                    <label className="label label-long">Átalánygenerálás</label>
                    <input disabled={!(!this.state.class || 'alkalmazasgazda' in this.props.rights.rights)} type="checkbox" checked={this.state.data.can_generate_transfer_order} onChange={event => this.handleDataChange('can_generate_transfer_order', event.target.checked)} />
                </div>
                {
                    ('alkalmazasgazda' in this.props.rights.rights || !this.state.class) && <div className="actions">
                    <LoadingButton disabled={this.state.deleting_in_progress} loading={this.state.save_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton>
                    {this.state.class && !this.state.data.have_student ? <LoadingButton disabled={this.state.save_in_progress} loading={this.state.deleting_in_progress} onClick={() => this.handleDelete()}>Törlés</LoadingButton>: null}
                </div>
                }
            </React.Fragment> : <Loading loading_text=''/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        class_types: state.class_types,
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OsztalyAdataiBase))