import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import FilestoreClient from "../FileStoreClient"
import LoadingButton from "../components/LoadingButton";

class SAPVevoBetolto extends Component {
    file_input = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            data: {
                school_id: this.props.school || null,
                hash: '',
            },
            file_input_key: 0,
            upload_in_progress: false,
            save_in_progress: false
        }
    }

    componentDidMount() {
        if (this.state.data.school_id !== null && this.props.history.location.pathname!='/iskola-kezelese/' + this.state.data.school_id + '/vevokodok-betoltese') {
            this.props.history.replace('/iskola-kezelese/' + this.state.data.school_id + '/vevokodok-betoltese')
        } else if (this.state.data.school_id === null && this.props.history.location.pathname!='/szamlazas/vevokodok-betoltese') {
            this.props.history.replace('/szamlazas/vevokodok-betoltese')
        }
    }

    uploadFile = () => {
        if (this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            },()=>{
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0]).then(hash => {
                    this.setState(prevState => ({
                        upload_in_progress: false,
                        data: {
                            ...prevState.data,
                            hash: hash
                        }
                    }))
                }).catch(error => {
                    this.setState({
                        upload_in_progress: false
                    })
                    this.props.clearMessages()
                    throw error
                }))
            })
        }
    }

    loadMultipleSAPCustomerCodeFromFileCheckExcel = () => {
        this.props.clearMessages()
        this.setState({
            check_excel_in_progress: true
        }, ()=>{
            this.props.promise_error_handler(CanteenClient.getCheckExcelForSAPCodeFile(this.state.data.school_id,this.state.data.hash).then(result =>{
                return FilestoreClient.download(result.hash)
            })).then(()=>{
                this.setState({
                    check_excel_in_progress: false
                })
            })
        })
    }
    handleDownload = () => {
        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.getTemplateForMultipleSAPCustomerCodeBatch().then((result) => {
                return FilestoreClient.download(result.hash)
            })).then(()=>{
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }
    handleSave = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        },() => {
            this.props.promise_error_handler(()=>CanteenClient.loadSAPCodesFromFile(this.state.data.school_id,this.state.data.hash).then((result) => {
                this.props.addMessage('Az kodok elmentése megtörtént. A felvett rekordok száma a következő: '+result.count+'.', 'success')

                this.setState(prevState => ({
                    data: {
                        ...prevState.data,
                        hash: "",
                    },
                    file_input_key: prevState.file_input_key + 1,
                    save_in_progress: false
                }))
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })        
    }
    render() {
        return <React.Fragment>
            <div class="page-subtitle">Vevőkód betöltése</div>
            <div className="page-guide">Kérem, töltse fel a betöltőállományt!</div>
            <div className="block block-lg-1-2">
                <label className="label label-long required">Betöltőállomány</label>
                <input key={'file_input_'+this.state.file_input_key} ref={this.file_input} disabled={this.state.upload_in_progress} className="field" type="file" onChange={this.uploadFile}/>
            </div>
            <div className="actions">
                <LoadingButton loading={this.state.save_in_progress} disabled={this.state.check_excel_in_progress || this.state.downloading_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                <LoadingButton onClick={this.handleDownload} loading={this.state.downloading_in_progress} disabled={this.state.save_in_progress || this.state.check_excel_in_progress}>Sablon letöltése</LoadingButton>
                <LoadingButton onClick={this.loadMultipleSAPCustomerCodeFromFileCheckExcel} loading={this.state.check_excel_in_progress} disabled={this.state.save_in_progress || this.state.downloading_in_progress}>Felmerülő hibák letöltése</LoadingButton>
            </div>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SAPVevoBetolto))
