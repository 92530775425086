import React, { Component } from 'react'
import { setGuide, setTitle, setSystemMessagesCount,} from "../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import { Route } from "react-router-dom"
import MenzanaptarBase from './Menzanaptar';
import Tanulok from './Tanulok';
import IskolaKezelesBase from './IskolaKezelesBase';
import TanuloAthelyezese from './IskolaKezelese/TanuloAthelyezese';
import TanuloFelvetel from './IskolaKezelese/TanuloFelvetel';
import EtkezesiCsoportokKezelese from './IskolaKezelese/EtkezesiCsoportokKezelese';
import EtkezesAranakKezelese from './IskolaKezelese/EtkezesAranakKezelese';
import XMenzaStatusz from './IskolaKezelese/XMenzaStatusz';
import TanevekKezelese from './IskolaKezelese/TanevekKezelese';
import UjTanev from './IskolaKezelese/UjTanev';
import Osztalyok from './IskolaKezelese/Osztalyok';
import TanuloEgyenlegeBase from './TanuloEgyenlegeBase';
import Gyermeketkeztetes2Statusz from './IskolaKezelese/Gyermeketkeztetes2Statusz';
import IgenylesSablon from './IskolaKezelese/IgenylesSablon'
import KezelendoIgenylesek from './IskolaKezelese/KezelendoIgenylesek'
import IgenylesKezeles from './IskolaKezelese/IgenylesKezeles'
import KezelendoAdatmodositasiKerelmek from './IskolaKezelese/KezelendoAdatmodositasiKerelmek'
import AdatmodositasiKerelemKezeles from './IskolaKezelese/AdatmodositasiKerelemKezeles'
import IgenylesKereses from './IskolaKezelese/IgenylesKereses'
import AdatModositasKereses from './IskolaKezelese/AdatModositasKereses'
import Loading from '@sapkk/app/components/Loading'
import ContentWithSideMenu from './ContentWithSideMenu'
import BankAdatlapSablon from './IskolaKezelese/BankAdatlapSablon'
import AtalanySzabalyok from './IskolaKezelese/AtalanySzabalyok'
import Periodusok from './IskolaKezelese/Periodusok'
import SAPVevoBetolto from './SAPVevoBetolto'
import Allomanyok from './IskolaKezelese/Allomanyok'
import EtkezesiCsoportMenuk from "./IskolaKezelese/EtkezesiCsoportMenuk";
import TanuloEtkezesenekTomegesLezarasa from "./IskolaKezelese/TanuloEtkezesenekTomegesLezarasa";

class IskolaKezelese extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            year_id: this.props.match.params.year_id,
            key: 0,
            loaded: false
        }
    }

    componentDidMount() {
        this.loadData()
        this.props.setTitle("Iskolai törzsadatok")
    }

    incrementKey = () => {
        this.setState(prevState => ({
            key: prevState.key + 1
        }))
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.id !== state.id) {
            return {
                key: state.key + 1,
                id: props.match.params.id,
                year_id: props.match.params.year_id,
                loaded: false
            }
        } else if (props.match.params.year_id !== state.year_id) {
            return {
                key: state.key + 1,
                year_id: props.match.params.year_id
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.id !== this.state.id || prevState.key !== this.state.key) {
            this.loadData()
        }
        if(prevState.key !== this.state.key){
            this.props.setSystemMessagesCount(null)
        }
        if(prevProps.system_messages_count !== this.props.system_messages_count && this.props.system_messages_count!==null){
            this.countMealRequests()
            this.countStudentData()
        }
    }

    componentWillUnmount(){
        clearInterval(this.state.interval)
    }

    countMealRequests = ()=>{
        return this.state.rights && this.state.rights.includes('HANDLE_MEAL_REQUEST') ?  this.props.promise_error_handler(CanteenClient.listSchoolMealRequests(this.state.id, ['CREATED', 'UPDATED'], {
            only_count: true
        }).then(count => {
            this.setState({
                count_meal_requests: count
            })
        })) : new Promise((resolve, reject) => resolve(null))
    }

    countStudentData = ()=>{
        return this.state.rights && this.state.rights.includes('HANDLE_STUDENT_DATA_CHANGE_REQUEST') ? this.props.promise_error_handler(CanteenClient.listStudentDataInformation({
            authentic: [{operator: 3}],
            school_id_at_creation: [{operator: 9, value: this.state.id}],
            school_id: [{operator: 9, value: this.state.id}]
        }, {
            only_count: true
        },[],['original_school_id','school_id','student_id','authentic']).then(count => {
            this.setState({
                count_student_data: count
            })
        })) : new Promise((resolve, reject) => resolve(null))
    }

    loadData = () => {
        this.setState({
            id: this.props.match.params.id,
        }, () => {
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getRightsForSchool(this.props.match.params.id),
                CanteenClient.getSchool(this.props.match.params.id)
            ]).then(([rights, school]) => {
                
                this.setState({
                    rights: rights,
                    school: school,
                },()=>{
                    Promise.all([
                        this.countMealRequests(),
                        this.countStudentData()
                    ]).then(()=>{
                        clearInterval(this.state.interval)
                        this.setState({
                            loaded: true,
                            interval: setInterval(()=>Promise.all([
                                this.countMealRequests(),
                                this.countStudentData()
                            ]), 100000)
                        })
                    })
                })
            }))
        })
    }

    convertURLToClassURL = (row={}) => {
        let id = row.class_id
        switch (this.props.history.location.pathname) {
            case '/iskola-kezelese/' + this.state.id + '/tanulok':
                return '/osztaly-kezelese/' + id + '/tanulok/' + this.state.year_id
            case '/iskola-kezelese/' + this.state.id + '/menzanaptar':
            default:
                if(this.props.history.location.pathname.indexOf('/iskola-kezelese/' + this.state.id + '/menzanaptar')===0){
                    return  '/osztaly-kezelese/'+id+'/menzanaptar'
                }
                if(this.props.history.location.pathname.indexOf('/iskola-kezelese/' + this.state.id + '/gy2-egyenleg')===0){
                    return  '/osztaly-kezelese/'+id+'/gy2-egyenleg'+(row.school_year_id ? '/'+row.school_year_id : '')
                }
                return '/osztaly-kezelese/' + id
        }
    }

    convertURLToStudentURL = (row={}) => {
        let id = 'T'+(-1* row.student_id)
        switch (this.props.history.location.pathname) {
            case '/iskola-kezelese/' + this.state.id + '/tanulok/' + this.state.year_id:
                return '/tanulo-kezelese/' + id + '/adatok'
            case '/iskola-kezelese/' + this.state.id + '/feldolgozatlan-igenylesek':
                return '/tanulo-kezelese/' + id + '/tanuloi-igenylesek'
            case '/iskola-kezelese/' + this.state.id + '/feldolgozatlan-torzsadatmodositasok':
                return '/tanulo-kezelese/' + id + '/adatok/modositastortenet'
            default:
                if(this.props.history.location.pathname.indexOf('/iskola-kezelese/' + this.state.id + '/menzanaptar')===0){
                    return '/tanulo-kezelese/T'+(-1*row.student_id)+'/menzanaptar'
                }
                if(this.props.history.location.pathname.indexOf('/iskola-kezelese/' + this.state.id + '/gy2-egyenleg')===0){
                    return '/tanulo-kezelese/T'+(-1*row.student_id)+'/gy2-egyenleg'+(row.school_year_id ? '/'+row.school_year_id : '')
                }
                return '/tanulo-kezelese/' + id
        }
    }

    activeMethod = (menu_data) =>{
        return menu_data.url == this.props.history.location.pathname || this.props.history.location.pathname.indexOf(menu_data.url+'/')===0 || (menu_data.submenu||[]).some(sub_menu_data => this.activeMethod(sub_menu_data))
    }

    render() {

        let sub_tasks_menu = this.state.loaded ? [
            (this.state.rights.includes('HANDLE_STUDENT_DATA_CHANGE_REQUEST') ? {message_count:this.state.count_student_data, id: 2, url:  '/iskola-kezelese/'+this.state.id+'/feldolgozatlan-torzsadatmodositasok',name:'Törzsadat módosítás', render: <KezelendoAdatmodositasiKerelmek school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
            (this.state.rights.includes('HANDLE_MEAL_REQUEST') ? {message_count:this.state.count_meal_requests, id: 1, url:  '/iskola-kezelese/'+this.state.id+'/feldolgozatlan-igenylesek',name:'Étkezés igénylés', render: <KezelendoIgenylesek school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
        ].filter(menu_data => menu_data) : []

        let sablon_sub_sub_menu = this.state.loaded ? [
            (this.state.rights.includes('READ_MEAL_REQUEST_TEMPLATE') ? {id:23,url:  '/iskola-kezelese/'+this.state.id+'/igenylessablon',name:'Igényléssablon', render: <IgenylesSablon school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
            (this.state.rights.includes('READ_TEMPLATE') ? {id:24,url:  '/iskola-kezelese/'+this.state.id+'/bankiadatlapsablon',name:'Banki adatlap sablon', render: <BankAdatlapSablon school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
        ].filter(menu_data => menu_data) : []

        let lekerdezesek_sub_menu = this.state.loaded ? [
            (this.state.rights.includes('READ_MULTIPLE_MEAL_REQUEST') ? {id: 3,url:  '/iskola-kezelese/'+this.state.id+'/igenylesek',name:'Étkezés igénylés', render: <IgenylesKereses school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
            (this.state.rights.includes('READ_STUDENT_DATA_CHANGE_REQUEST') ? {id: 4,url:  '/iskola-kezelese/'+this.state.id+'/adatmodositasok',name:'Törzsadat módosítás', render: <AdatModositasKereses school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
            (this.state.rights.includes('READ_DATA') ? {id: 5,url: '/iskola-kezelese/'+this.state.id+'/tanulok',name:'Tanulói törzsadatok', render: <Tanulok school={this.state.school.id} year={this.state.year_id} /> }: null),
            (this.state.rights.includes('READ_GY2_BALANCES') ? {id: 6,url:  '/iskola-kezelese/'+this.state.id+'/gy2-egyenleg',name:'Gyermekétkeztetésbeli egyenleg', render: <TanuloEgyenlegeBase school_year={this.props.match.params.school_year_id} convertURLToClassURL={this.convertURLToClassURL} school={this.state.school.id} /> }: null),
            (this.state.rights.includes('READ_TRANSFER') ? {id: 6,url:  '/iskola-kezelese/'+this.state.id+'/allomanyok',name:'Állományok', render: <Allomanyok school={this.state.school.id} /> }: null),
            sablon_sub_sub_menu.length ? {id:7,submenu: sablon_sub_sub_menu, name: 'Sablonok'}: null,
        ].filter(menu_data => menu_data) : []

        let tanulo_kezelese_sub_menu = this.state.loaded ? [
            (this.state.rights.includes('ADD_STUDENT') ? {id:8, url:  '/iskola-kezelese/'+this.state.id+'/uj-tanulo',name:'Új tanuló felvétele', render: <TanuloFelvetel school={this.state.school.id} /> }: null),
            (this.state.rights.includes('ADD_STUDENT') ? {id:9, url:  '/iskola-kezelese/'+this.state.id+'/athelyezes',name:'Tanuló áthelyezése', render: <TanuloAthelyezese school={this.state.school.id} /> }: null),
            (this.state.rights.includes('MULTIPLE_STUDENT_DINING_CLOSE') ? {id:9, url:  '/iskola-kezelese/'+this.state.id+'/tomeges-etkezes-lezaras',name:'Tömeges étkezéslezárása', render: <TanuloEtkezesenekTomegesLezarasa school={this.state.school.id} /> }: null),
            (this.state.rights.includes('WRITE_SAP_CODE') ? {id:25, url:  '/iskola-kezelese/'+this.state.id+'/vevokodok-betoltese',name:'Vevőkódok betöltése', render: <SAPVevoBetolto school={this.state.school.id} /> }: null),
        ].filter(menu_data => menu_data) : []

        let parameterek_sub_menu = this.state.loaded ? [
            (this.state.rights.includes('READ_DATA') ? {id: 10, url: '/iskola-kezelese/'+this.state.id+'/adatok',name:'Iskola adatai', render: <IskolaKezelesBase school={this.state.school.id} /> }: null),
            (this.state.rights.includes('YEAR_CREATE') ? {id: 11, url:  '/iskola-kezelese/'+this.state.id+'/uj-tanev',name:'Új tanév', render: <UjTanev school_data={this.state.school} school={this.state.school.id}  loadData={this.loadData}/> }: null),
            (this.state.rights.includes('READ_DATA') ? {id: 12, url:  '/iskola-kezelese/'+this.state.id+'/tanevek',name:'Tanév adatai', render: <TanevekKezelese school_data={this.state.school} school={this.state.school.id} school_year_id={this.props.match.params.school_year_id} rights_for_school = {this.state.rights}/> }: null),
            (this.state.rights.includes('READ_CLASS') ? {id: 13, url:  '/iskola-kezelese/'+this.state.id+'/osztalyok',name:'Osztály', render: <Osztalyok school={this.state.school.id} /> }: null),
            (this.state.rights.includes('READ_DATA') ? {id: 14, url:  '/iskola-kezelese/'+this.state.id+'/etkezei-csoportok',name:'Étkezési csoportok', render: <EtkezesiCsoportokKezelese school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
            (this.state.rights.includes('READ_PRICE') ? {id: 15, url:  '/iskola-kezelese/'+this.state.id+'/etkezei-arak',name:'Étkezések ára', render: <EtkezesAranakKezelese school={this.state.school.id} rights_for_school = {this.state.rights}/> }: null),
            (this.state.rights.includes('READ_TRANSFER_RULE') ? {id: 15, url:  '/iskola-kezelese/'+this.state.id+'/atalany-osszegek',name:'Átalányösszegek', render: <AtalanySzabalyok rights_for_school = {this.state.rights} school={this.state.school.id} /> }: null),
            (this.state.rights.includes('READ_PERIOD') ? {id: 15, url:  '/iskola-kezelese/'+this.state.id+'/periodusok',name:'Periódusok', render: <Periodusok rights_for_school = {this.state.rights} school={this.state.school.id} /> }: null),
            (this.state.rights.includes('WRITE_MEAL_GROUP_MENU_PERIOD') ? {id: 15, url:  '/iskola-kezelese/'+this.state.id+'/menuk',name:'Menü - Étlap', render: <EtkezesiCsoportMenuk rights_for_school = {this.state.rights} school={this.state.school.id} /> }: null),
        ].filter(menu_data => menu_data) : []
        
        let menus = this.state.loaded ? [
            sub_tasks_menu.length ? {id:16, submenu: sub_tasks_menu, name: 'Feladatok'} : null,
            (this.state.rights.includes('MEAL_CANCELLATION_READ') ? {id:17, url:  '/iskola-kezelese/'+this.state.id+'/menzanaptar',name:'Menzanaptár', render: <MenzanaptarBase school_year={this.props.match.params.school_year_id} school={this.state.school.id} /> }: null),
            lekerdezesek_sub_menu.length ? {id:18, submenu: lekerdezesek_sub_menu, name: 'Lekérdezések'} : null,
            tanulo_kezelese_sub_menu.length ? {id:19, submenu: tanulo_kezelese_sub_menu, name: 'Tanuló kezelés'} : null,
            parameterek_sub_menu.length ? {id:20, submenu: parameterek_sub_menu, name: 'Paraméterek'} : null,
            (this.state.rights.includes('XMENZA_STATUS') ? {id:21, url:  '/iskola-kezelese/'+this.state.id+'/xmenza-statusz',name:'xMenza státusz', render: <XMenzaStatusz school={this.state.school.id} /> }: null),
            (this.state.rights.includes('READ_GYERMEKETKEZTETES2') ? {id:22, url:  '/iskola-kezelese/'+this.state.id+'/gyermeketkeztetes2-statusz',name:'Gyermekétkeztetés2 státusz', render: <Gyermeketkeztetes2Statusz school={this.state.school.id} /> }: null),
        ].filter(menu_data => menu_data) : []

        let menu_objects = [
            {title:'Törzsadatok karbantartása', menu_elements: menus, with_route: true, active_method: this.activeMethod, onClick: this.incrementKey}
        ]
        return <React.Fragment>
            {
                this.state.loaded ? <ContentWithSideMenu
                    extra_routes={[
                        this.state.rights.includes('HANDLE_MEAL_REQUEST') ? <Route path={'/iskola-kezelese/'+this.state.id+'/feldolgozatlan-igenylesek/:meal_request_id'} render={()=><IgenylesKezeles school={this.state.school.id}/>}/> : null,
                        this.state.rights.includes('HANDLE_STUDENT_DATA_CHANGE_REQUEST') ? <Route path={'/iskola-kezelese/'+this.state.id+'/feldolgozatlan-torzsadatmodositasok/T:student/:student_data_id'} render={()=><AdatmodositasiKerelemKezeles school={this.state.school.id}/>}/> : null,
                        this.state.rights.includes('HANDLE_STUDENT_DATA_CHANGE_REQUEST') ? <Route path={'/iskola-kezelese/'+this.state.id+'/feldolgozatlan-torzsadatmodositasok/T:student'} render={()=><AdatmodositasiKerelemKezeles school={this.state.school.id}/>}/> : null
                    ]}
                    key={this.state.keys} menus={menu_objects} default_path={'/iskola-kezelese/'+this.state.id} url_base={'iskola-kezelese'}>
                        <div className="block">
                            <div className="page-subtitle">Aktuális iskola</div>
                            <ul className="actual-selector">
                                <li><span>{this.state.school.abbreviated_name}</span></li>
                            </ul>
                        </div>
                </ContentWithSideMenu> : <Loading loading_text='' />
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
        system_messages_count: state.system_messages.count,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    setSystemMessagesCount: setSystemMessagesCount,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IskolaKezelese))
