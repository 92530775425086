import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import SelectField from "@sapkk/app/components/SelectField";
import { Link } from "react-router-dom"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";

class Osztalyok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            class: "",
            classes: [],
            ignored_classes: [],
            data: undefined,
            save_in_progress: false,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getSchoolClasses(this.state.school,null,{fields: ['id','school_id','name','type','xmenza_code','is_graduating','have_student', 'can_generate_transfer_order']}),
            Object.keys(this.props.rights.rights).includes('alkalmazasgazda') ? CanteenClient.getIgnoredClasses(this.state.school) : null
        ]).then(([classes, ignored_classes]) => {
            this.setState({
                classes: classes,
                ignored_classes: ignored_classes || [],
                fetch_in_progress: false
            })
        }))
    }

    handleAddLine() {
        this.setState((prevState, props) => ({
            classes: prevState.classes.concat({name: "", type: "", xmenza_code: "", have_student: false, is_graduating: false, can_generate_transfer_order: true})
        }))
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            classes: prevState.classes.slice(0, id).concat(prevState.classes.slice(id-(-1)))
        }))
    }

    handleRowChange(id, name, value) {
        this.setState((prevState, props) => ({
            classes: prevState.classes.slice(0, id).concat([{...prevState.classes[id], [name]: value}]).concat(prevState.classes.slice(id-(-1)))
        }))
    }

    handleSave = ()=> {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.editClasses(this.state.school, this.state.classes).then(result => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                this.setState({
                    classes: result,
                    save_in_progress: false
                })
            })).then(()=>{
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }
    render() {
        let alkalmazasgazda = Object.keys(this.props.rights.rights).includes('alkalmazasgazda')
        let readOnly = !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') && !(this.props.rights.rights['ugyintezo']||[]).includes(''+this.state.school) 
        return <React.Fragment>
            {!this.state.fetch_in_progress ?<React.Fragment>
                <div className="page-subtitle">Osztályok</div>
                <div className="table-container">
                    <table className="striped modify-table school-class-table">
                        <thead>
                            <tr>
                                <th>Név</th>
                                <th>Típus</th>
                                <th>Végzős</th>
                                <th>Átalánygenerálás</th>
                                <th className="col-actions"/>
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.classes.map((data, id) =>{
                            return <tr key={id}>
                                <td><SimpleField value={readOnly || !!data.id ? <Link to={'/osztaly-kezelese/'+data.id}>{data.name}</Link> : data.name } readOnly={readOnly|| !!data.id} onChange={event => this.handleRowChange(id, 'name', event.target.value)}/></td>
                                <td>
                                    <SelectField readOnly={readOnly|| !!data.id}  value={ data.type } onChange={event => this.handleRowChange(id, 'type', event.target.value)} displayValues={Object.keys(this.props.class_types).reduce((prev, curr) => ({
                                        ...prev,
                                        [curr]: this.props.class_types[curr].name
                                    }),{})}>
                                        <option value="">Kérem válasszon!</option>
                                        {Object.keys(this.props.class_types).map(type => <option value={type}/>)}
                                    </SelectField>
                                </td>
                                <td><input type="checkbox" checked={ data.is_graduating } disabled={readOnly} onChange={event => this.handleRowChange(id, 'is_graduating', event.target.checked)}/></td>
                                <td><input type="checkbox" checked={ data.can_generate_transfer_order } disabled={!alkalmazasgazda} onChange={event => this.handleRowChange(id, 'can_generate_transfer_order', event.target.checked)}/></td>
                                <td className="col-actions">{(!readOnly && (Object.keys(this.props.rights.rights).includes('alkalmazasgazda') && !data.have_student || !data.id)) ? <button className="remove" onClick={() => this.handleRemoveLine(id)}/> : null}</td>
                            </tr>}
                        ) }
                            {!readOnly ? <tr className="table-new-row">
                                <td colSpan={5}>{this.state.classes.length === 0 && "Nincs megadva osztály."}</td>
                                <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td>
                            </tr> : null }
                        </tbody>
                    </table>
                </div>
                {
                    false && this.state.ignored_classes.length!==0 && Object.keys(this.props.rights.rights).includes('alkalmazasgazda') ? <React.Fragment>
                    <div className="page-subtitle">XMenzában ignorált osztályok</div>
                    <div className="table-container">
                        <table className="striped modify-table">
                            <thead>
                                <tr>
                                    <th>Név</th>
                                    <th>xMenza kód</th>
                                </tr>
                            </thead>
                            <tbody>
                            { this.state.ignored_classes.map((data, id) =>{
                                return <tr key={id}>
                                    <td><SimpleField value={data.csoport} readOnly={true}/></td>
                                    <td><SimpleField value={data.cskod} readOnly={true}/></td>
                                </tr>}
                            ) }
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>: null
                }
                {!readOnly ? <div className="actions">
                    <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                </div> : null}
            </React.Fragment> : <Loading loading_text=''/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        class_types: state.class_types,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Osztalyok))