import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import TanuloiIgenyles from "../TanuloKezelese/TanuloiIgenyles"

class IgenylesKezeles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            meal_request_id: this.props.match.params.meal_request_id,
            next_and_prev: null,
            fetch_in_progress: true,
            original_meal_request: null
        }
    }

    componentDidMount() {
        this.handleFetch()
    }
    
    componentWillUnmount(){
        clearTimeout(this.state.timer)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match.params.meal_request_id !== state.meal_request_id) {
            return {
                meal_request_id: props.match.params.meal_request_id,
                fetch_in_progress: true
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.meal_request_id !== this.state.meal_request_id) {
            this.handleFetch()
        }
    }

    handleFetch = ()=> {
        this.setState({
            fetch_in_progress: true
        },()=>{
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getNextAndPrevMealRequestIDForSchool(this.state.meal_request_id, this.state.school,{state: [{operator:13, value:['CREATED', 'UPDATED']}]})
            ]).then(([next_and_prev]) => {
                this.setState({
                    next_and_prev: next_and_prev,
                    fetch_in_progress: false
                })
            }))
        })
    }

    handleGoPrev = () =>{
        this.props.history.push('/iskola-kezelese/'+this.state.school+'/feldolgozatlan-igenylesek/'+this.state.next_and_prev.prev_id)
    }

    handleGoNext = () =>{
        this.props.history.push('/iskola-kezelese/'+this.state.school+'/feldolgozatlan-igenylesek/'+this.state.next_and_prev.next_id)
    }

    handleDetermined = () => {
        this.setState({
            timer: setTimeout(this.props.history.push('/iskola-kezelese/'+this.state.school+'/feldolgozatlan-igenylesek'))
        })
    }

    setOriginalMealRequest = (meal_request) => {
        this.setState({
            original_meal_request: meal_request
        })
    }

    render() {
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
                {this.state.original_meal_request ? <div class="calendar-pager">
                    <button disabled={this.state.next_and_prev.prev_id==null} onClick={this.handleGoPrev}>&lt;&lt;</button>
                    <div>{this.state.original_meal_request.student_name+' ('+this.state.original_meal_request.creation_time+')'}</div>
                    <button disabled={this.state.next_and_prev.next_id==null} onClick={this.handleGoNext}>&gt;&gt;</button>
                </div>: null }
                <TanuloiIgenyles school_level={true} setOriginalMealRequest={this.setOriginalMealRequest} meal_request_id={this.state.meal_request_id} school={this.state.school} handleReject={this.handleDetermined} handleAccept={this.handleDetermined}/>
            </React.Fragment> : null}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IgenylesKezeles))