import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import CanteenClient from "../../CanteenClient"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton"
import { clearMessages, addMessage } from "@sapkk/app/actions"

class JelszoVisszaallitas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            student: this.props.student || null,
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+(-1*this.state.student)+'/jelszo-visszaallitas'){
            this.props.history.replace('/tanulo-kezelese/T'+(-1*this.state.student)+'/jelszo-visszaallitas')
        }
    }

    handleReset = ()=> {
        this.props.clearMessages()

        this.setState({
            reset_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.resetPasswordForStudent(this.state.student).then(result => {
                this.props.addMessage('A helyreállítás megtörtént.', 'success')
            })).then(()=>{
                this.setState({
                    reset_in_progress: false
                })
            })
        })
    }

    render() {
        return this.state.fetching ? <Loading loading_text=''/> : <div className="block">
            <div className="page-subtitle">Jelszóvisszaállítás</div>
            <div className="page-guide">A „Jelszóvisszaállítás” gombra kattintva iniciális jelszó kerül beállításra (a tanuló születési dátuma).</div>
            <div className="actions">
                <LoadingButton loading={this.state.reset_in_progress} onClick={this.handleReset}>Visszaállítás</LoadingButton>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler
    }
}

const mapDispatchToProps = {
    addMessage: addMessage,
    clearMessages: clearMessages,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JelszoVisszaallitas))