import React, { Component } from 'react'
import {setGuide, setPromptCallback, setTitle} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { Route, Switch } from "react-router-dom"

class ContentWithSideMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: 0,
            menu_show: {}
        }
    }

    incrementKey = () => {
        if(this.props.prompt_block){
            this.props.setPromptCallback((ok)=>{this.setState((prevState)=>({
                key: ok ? prevState.key + 1 : prevState.key
            }))})
        } else {
            this.setState((prevState) => ({
                key: prevState.key+ 1
            }))
        }
    }

    toggleMenu = (menu_data,active_method, event)=>{
        event.preventDefault()
        let active = active_method(menu_data)
        if(menu_data.submenu){
            menu_data.submenu.filter(sub_menu_data =>
                    (this.state.menu_show[sub_menu_data.id] === undefined && active || this.state.menu_show[sub_menu_data.id]) && (this.state.menu_show[menu_data.id] === undefined && active || this.state.menu_show[menu_data.id])
                ).forEach(sub_menu_data => {
                this.toggleMenu(sub_menu_data, active_method, {preventDefault: ()=>{}})
            })
        }
        this.setState((prevState)=>({
            menu_show: {
                ...prevState.menu_show,
                [menu_data.id]: (prevState.menu_show[menu_data.id] === undefined ? active : prevState.menu_show[menu_data.id]) ^ true
            }
        }))
    }

    getMessageCount = (menu_data) => {
        let message_count = menu_data.message_count || 0
        if(menu_data.submenu){
            menu_data.submenu.forEach(sub_menu_data => {
                message_count += this.getMessageCount(sub_menu_data)
            })
        }
        return message_count
    }

    menuCreator = (menu_data, active_method, onClick, level = 0) =>{
        let active = active_method(menu_data)
        if(this.state.menu_show[menu_data.id] === undefined && active && menu_data.submenu){
            this.setState((prevState)=>({
                menu_show: {
                    ...prevState.menu_show,
                    [menu_data.id]: true
                }
            }))
        }
        let show_submenu = menu_data.submenu && (this.state.menu_show[menu_data.id] === undefined && active || this.state.menu_show[menu_data.id])
        return <React.Fragment>
            <li className={[menu_data.submenu ? 'with-submenu' : null, active && (!menu_data.submenu || this.state.menu_show[menu_data.id]===0) ? 'active' : null, show_submenu ? 'show-submenu' : null, 'level-'+level].filter(class_name => class_name).join(' ')}>
                <Link onClick={(event)=>{
                    if(onClick){
                        onClick()
                    }
                    menu_data.url ? this.incrementKey() : this.toggleMenu(menu_data,active_method,event)
                }} to={menu_data.url || null} data-message-count={this.getMessageCount(menu_data) || null}>{menu_data.name}</Link>
            </li>
            {
                menu_data.submenu ? <ul className={show_submenu ? null: 'hidden'}>
                    {menu_data.submenu.sort((a,b) => (a.order||0)-(b.order||0)).map(sub_menu_data =>this.menuCreator(sub_menu_data,active_method, onClick, level+1))}
                </ul> : null
            }
            </React.Fragment>
    }

    getClosestRender = (menu_data) => {
        if(menu_data.render){
            return menu_data.render
        }
        if(!menu_data.submenu || menu_data.submenu.length === 0){
            return null
        }
        let closestRender = null
        menu_data.submenu.find(sub_menu_data => {
            closestRender = this.getClosestRender(sub_menu_data)
            return !!closestRender
        })
        return closestRender
    }

    routeCreator = (menu_data) => {
        let render = this.getClosestRender(menu_data)
        return (menu_data.submenu ? menu_data.submenu.map(sub_menu_data => this.routeCreator(sub_menu_data)) : []).concat([
            menu_data.url ? <Route path={menu_data.url.replace(this.props.url_base, ':mode')} render={() => render} /> : null
        ])
    }

    render() {
        let have_side_menu = this.props.menus.find(menu => menu.menu_elements.length > 1)
        let with_route = this.props.menus.filter(menu => menu.with_route)
        return <React.Fragment>
            {
                 have_side_menu ? <div className="block block-md-1-5 block-md">
                    {
                        this.props.menus.map(menu => {
                            return menu.menu_elements.length > 1 ? <div className="side-menu">
                                <div>{menu.title}</div>
                                <ul>
                                    {menu.menu_elements.filter(menu_data => menu_data.name).map(menu_data => this.menuCreator(menu_data,menu.active_method || (()=>false), menu.onClick))}
                                </ul>
                            </div> : null
                        })
                    }
                </div> : null
            }
            {
                <div className={"block" + (have_side_menu ? ' block-md-4-5' : '')}>
                    {this.props.children}
                    <Switch key={'content.' + this.state.key}>
                        {this.props.extra_routes}
                        {with_route.map(menu =>menu.menu_elements.map(menu_data =>this.routeCreator(menu_data)))}
                        {(this.props.convertPage||[]).map(route => route)}
                        {with_route.length > 0 ? <Route path={this.props.default_path} render={() => this.getClosestRender(with_route[0].menu_elements[0])} /> : null}
                    </Switch>
                </div>
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
        prompt_block: state.prompt_info.block
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    setPromptCallback: setPromptCallback
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentWithSideMenu))
