import React, { Component } from 'react'
import { setGuide, setTitle } from "../../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from '@sapkk/app/components/SimpleField';
import SelectField from '@sapkk/app/components/SelectField';
import LoadingButton from '../../components/LoadingButton';
import { formatDate } from '../../functions'
import { withRouter } from 'react-router'

class TanevekKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school_id: this.props.school || '',
            school_years: [],
            open: false,
            page: -1,
            years: [],
            calendar_table: [],
            save_in_progress: false,
            school_year_id: this.props.school_year_id || '',
        }
    }

    componentDidMount() {
        this.setState({
            school_years: [],
            school_year_data: undefined
        },()=>{
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getSchoolYearsBySchoolID(this.state.school_id),
                CanteenClient.getYears()
            ]).then(([school_years,years]) => {
                this.setState({
                    school_years: school_years,
                    years: years
                },()=>{
                    if(this.state.school_year_id && (this.state.school_years.find(sy=>sy.id==this.state.school_year_id))){
                        this.handleFetch()
                    } else if(this.state.school_years.length===1){
                        this.props.history.replace('/iskola-kezelese/'+this.state.school_id+'/tanevek/'+this.state.school_years[0].id)
                    } else if(this.state.school_years.find(sy=>sy.default)) {
                        this.props.history.replace('/iskola-kezelese/'+this.state.school_id+'/tanevek/'+(this.state.school_years.find(sy=>sy.default).id))
                    } else {
                        this.props.history.replace('/iskola-kezelese/'+this.state.school_id+'/tanevek')
                    }
                })
            }))
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.school_year_id !== state.act_school_year) {
            return {
                act_school_year: props.school_year_id,
                school_year_id: props.school_year_id
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.school_year_id !== this.props.school_year_id) {
            this.handleFetch()
        }
    }

    handleAddLine() {
        this.setState((prevState, props) => ({
            calendar_table: prevState.calendar_table.concat({name: "", date_from: "", date_to: "", work_day: "", new: true})
        }))
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            calendar_table: prevState.calendar_table.slice(0, id).concat(prevState.calendar_table.slice(id-(-1)))
        }))
    }

    handleRowChange(id, name, value) {
        this.setState((prevState, props) => ({
            calendar_table: prevState.calendar_table.slice(0, id).concat([{...prevState.calendar_table[id], [name]: value}]).concat(prevState.calendar_table.slice(id-(-1)))
        }))
    }

    handleSchoolYearDataChange(name, value) {
        this.setState(prevState => ({
            school_year_data: {
                ...prevState.school_year_data,
                [name]: value
            }
        }))
    }

    handleSave = ()=> {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, ()=>{
            this.props.promise_error_handler(CanteenClient.convertCalendarDays(this.state.school_year_data.date_from, this.state.school_year_data.date_to, this.state.calendar_table).then(calendar =>
                this.setState((state, props) => ({
                    school_year_data: {
                        ...state.school_year_data,
                        calendar: calendar
                    }
                }),()=>{
                    this.props.promise_error_handler(()=>CanteenClient.editSchoolYear(this.state.school_year_id, this.state.school_id, this.state.school_year_data.date_from, this.state.school_year_data.date_to, this.state.school_year_data.graduate_date_to, this.state.school_year_data.billing_type, this.state.school_year_data.calendar).then((result) => {
                        this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                        this.setState({
                            school_year_data: {
                                ...result,
                                date_from: formatDate(result.date_from),
                                date_to: formatDate(result.date_to),
                                graduate_date_to: formatDate(result.graduate_date_to)
                            },
                            calendar_table: result.calendar.map(calendar => ({
                                name: calendar.name,
                                date_from: formatDate(calendar.date),
                                date_to: formatDate(calendar.date),
                                work_day: calendar.work_day ? '1' : '0',
                                new: false,
                            })),
                            save_in_progress: false
                        })
                    }))
                })
            )).then(()=>{
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handleSchoolYearChange = (school_year_id) => {
        this.props.clearMessages()
        this.setState({
            school_year_id: school_year_id,
            school_year_data: undefined
        })
    }

    handleFetch = () =>{
        if(!this.state.act_school_year || this.state.school_year_id == this.state.act_school_year) {
            this.props.clearMessages()
            this.setState({
                fetch_in_progress: true
            },()=>{
                this.props.promise_error_handler(CanteenClient.getSchoolYear(this.state.school_year_id).then(school_year => {
                    this.setState({
                        act_school_year: this.state.school_year_id,
                        school_year_data: {
                            ...school_year,
                            date_from: formatDate(school_year.date_from),
                            date_to: formatDate(school_year.date_to),
                            graduate_date_to: formatDate(school_year.graduate_date_to)
                        },
                        fetch_in_progress: false,
                        calendar_table: school_year.calendar.map(calendar => ({
                            name: calendar.name,
                            date_from:  formatDate(calendar.date),
                            date_to:  formatDate(calendar.date),
                            work_day: calendar.work_day ? '1' : '0',
                            new: false
                        }))
                    })
                }))
            })
        } else{
            this.props.history.push('/iskola-kezelese/'+this.state.school_id+'/tanevek/'+this.state.school_year_id)
        }
    }

    render() {
        let readOnly = !this.props.rights_for_school.includes('YEAR_EDIT')
        return (
            <React.Fragment>
                <div className="page-subtitle">Tanév kiválasztása</div>
                <div className="student-select-block">
                    <div>
                        <div className="block block-lg-1-1">
                            <select className="field field-simple" value={this.state.school_year_id} onChange={event => this.handleSchoolYearChange( event.target.value)}>
                                <option value="">Kérem válasszon!</option>
                                { this.state.school_years.map(school_year => <option value={school_year.id}>{school_year.name}</option>) }
                            </select>
                        </div>
                    </div>
                    <div>
                        <LoadingButton disabled={!this.state.school_year_id} loading={this.state.fetch_in_progress} onClick={() => this.handleFetch()}>Lekérdez</LoadingButton>
                    </div>
                </div>
                {this.state.school_year_data && <React.Fragment>
                    <div className="page-subtitle">Tanév adatai</div>
                    <div className="block block-lg-1-1">
                        <label className="label label-long">Tanév</label>
                        <div className="field field-simple">
                            { (this.state.years.find(year => year.id === parseInt(this.state.school_year_data.year_id)) || {}).name }
                        </div>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Tanév kezdete</label>
                        {
                            this.state.school_year_id 
                                ? <SimpleField readOnly={readOnly || !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') || !this.state.school_year_data.active} placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.date_from}  onChange={event => this.handleSchoolYearDataChange('date_from', event.target.value)}/>
                                : <SimpleField readOnly={true} value={this.state.school_year_data.date_from} className="field field-simple"/>
                        }
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Tanév vége</label>
                        {
                            this.state.school_year_id 
                                ? <SimpleField readOnly={readOnly || !this.state.school_year_id|| !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') || !this.state.school_year_data.active}  placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.date_to}  onChange={event => this.handleSchoolYearDataChange('date_to', event.target.value)}/>
                                : <SimpleField readOnly={true} value={this.state.school_year_data.date_to} className="field field-simple"/>
                        }
                    </div>
                    {this.props.school_data.have_graduating_class ? <div className="block block-lg-1-2">
                        <label className="label label-long">Végzős tanév vége</label>
                        {
                            this.state.school_year_id 
                                ? <SimpleField readOnly={readOnly || !this.state.school_year_id|| !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') || !this.state.school_year_data.active}  placeholder="éééé.hh.nn" className="field field-simple" value={this.state.school_year_data.graduate_date_to}  onChange={event => this.handleSchoolYearDataChange('graduate_date_to', event.target.value)}/>
                                : <SimpleField readOnly={true} value={this.state.school_year_data.graduate_date_to} className="field field-simple"/>
                        }
                    </div> : null}
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Számlázás típusa</label>
                        <SelectField className="field field-simple" readOnly={this.props.readOnly || !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') || !this.state.school_year_data.active} value={this.state.school_year_data.billing_type} onChange={event => this.handleSchoolYearDataChange('billing_type', event.target.value)} displayValues={Object.keys(this.props.billing_types).reduce((prev, curr) => ({
                            ...prev,
                            [curr]: this.props.billing_types[curr].name
                        }), {})}>
                            <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                            {Object.keys(this.props.billing_types).map(v => <option value={v} />)}
                        </SelectField>
                    </div>
                    <div className="page-subtitle">Áthelyezett munkanapok és tanításmentes napok megadása</div>
                    <table className="workday-overwrite striped modify-table">
                        <thead>
                            <tr>
                                <th style={{width:'50%'}}>Név</th>
                                <th style={{width:'20%'}}>Kezdete</th>
                                <th style={{width:'20%'}}>Vége</th>
                                <th style={{width:'10%'}}>Étkezés</th>
                                <th className="col-actions"/>
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.calendar_table.map((data, id) =>
                            <tr key={id}>
                                <td><SimpleField value={ data.name } readOnly={readOnly ||!this.state.school_year_data.active} onChange={event => this.handleRowChange(id, 'name', event.target.value)}/></td>
                                <td><SimpleField placeholder="éééé.hh.nn" readOnly={readOnly ||!data.new} value={ data.date_from } onChange={event => this.handleRowChange(id, 'date_from', event.target.value)}/></td>
                                <td><SimpleField placeholder="éééé.hh.nn" readOnly={readOnly ||!data.new}  value={ data.date_to } onChange={event => this.handleRowChange(id, 'date_to', event.target.value)}/></td>
                                <td>
                                    <SelectField readOnly={readOnly || !data.new}  value={ data.work_day } onChange={event => this.handleRowChange(id, 'work_day', event.target.value)} displayValues={{0:'Nem', 1: 'Igen'}}>
                                        <option value=""/>
                                        <option value="1"/>
                                        <option value="0"/>
                                    </SelectField>
                                </td>
                                <td className="col-actions">{(Object.keys(this.props.rights.rights).includes('alkalmazasgazda')) && this.state.school_year_data.active ? <button onClick={() => this.handleRemoveLine(id)}/> : null}</td>
                            </tr>
                        ) }
                            {Object.keys(this.props.rights.rights).includes('alkalmazasgazda') && this.state.school_year_data.active ? <tr className="table-new-row">
                                <td colSpan={4}>{this.state.calendar_table.length === 0 && "Nincs megadva áthelyezett munkanap vagy tanításmentes nap."}</td>
                                <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td>
                            </tr> : null }
                        </tbody>
                    </table>
                <div className="actions">
                    {
                        !readOnly && !this.state.school_year_id ? <React.Fragment>
                            <button disabled={this.state.page === 0} onClick={() => this.handleNavigateBack()}>Vissza</button>
                            <button disabled={this.state.page_validation_in_progress || this.state.page === 2} onClick={() => this.handleNavigateNext()}>Tovább</button>
                        </React.Fragment> : null
                    }
                    {!readOnly && (!this.state.school_year_id || this.state.school_year_data.active) ? <LoadingButton loading={this.state.save_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton> : null}
                </div>
                </React.Fragment>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights,
        billing_types: state.billing_types
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanevekKezelese))