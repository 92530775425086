import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import SelectField from "@sapkk/app/components/SelectField";
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import DateField from "@sapkk/app/components/DateField"
import DateTimeField from "@sapkk/app/components/DateTimeField"
import FilestoreClient from "../../FileStoreClient"

class Periodusok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            school_year: "",
            school_years: [],
            periods: undefined,
            save_in_progress: false,
            fetch_in_progress: true,
            generating_in_progress: null
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getSchoolYearsBySchoolID(this.state.school),
        ]).then(([school_years]) => {
            this.setState({
                school_years: school_years
            },()=>{
                let actual_school_year = school_years.find(sy => sy.default)
                this.handleSchoolYearChange((actual_school_year||{}).id || '')    
            })
        }))
    }

    handleRowChange(id, name, value) {
        this.setState((prevState) => ({
            periods: prevState.periods.slice(0, id).concat([{...prevState.periods[id], [name]: value}]).concat(prevState.periods.slice(id-(-1)))
        }))
    }

    handleSchoolYearChange = (school_year_id) => {
        this.setState({
            period_fetch_in_progress: true
        },()=>{
            this.props.promise_error_handler((school_year_id ? CanteenClient.listPeriods({school_year_id: [{operator: 9, value: school_year_id}]}) : new Promise((resolve, reject) => resolve([]))).then(periods => {
                this.setState({
                    period_fetch_in_progress: false,
                    school_year: school_year_id,
                    periods: periods,
                    original_periods: periods,
                    fetch_in_progress: false
                })
            }))
        })
    }

    handleSave = ()=> {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.editPeriods(this.state.school_year, this.state.periods).then(result => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                this.setState({
                    periods: result,
                    original_periods: result,
                    save_in_progress: false
                })
            })).then(()=>{
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    generatePeriod = (year, month, id) => {
        this.setState({
            generating_in_progress: id
        },()=>{
            this.props.clearMessages()
            this.props.promise_error_handler(CanteenClient.generateTransferOrdersForPeriod(this.state.school_year,year, month).then((result)=>{
                this.props.addMessage('Generált átalányok száma: '+result.last_updated_count,'success')
                this.setState((prevState) =>({
                    generating_in_progress: null,
                    last_generated_result: result,
                    periods: prevState.periods.slice(0, id).concat([{...prevState.periods[id], transfer_order_updated: result.updated, transfer_order_generation_last_was_successfull: result.last_successfull}]).concat(prevState.periods.slice(id-(-1)))
                }))
            }).catch(e =>{
                this.setState({
                    generating_in_progress: null,
                })
                throw e
            }))
        })
    }

    downloadGeneratedTransferOrderReport = () =>{
        this.setState({
            download_in_progress: true
        },()=>{
            this.props.clearMessages()
            this.props.promise_error_handler(FilestoreClient.download(this.state.last_generated_result.last_hash)).then(()=>{
                this.setState({
                    download_in_progress: false
                })
            })
        })
    }

    render() {
        let transfer_order_read_right = this.props.rights_for_school.includes('READ_TRANSFER_RULE')
        let can_edit = this.props.rights_for_school.includes('WRITE_PERIOD')
        let haveGenerateRight = can_edit && 'alkalmazasgazda' in this.props.rights.rights
        return <React.Fragment>
            {!this.state.fetch_in_progress ?<React.Fragment>
                {!this.state.generating_in_progress && this.state.last_generated_result && this.state.last_generated_result.last_updated_count ? <React.Fragment>
                    <div className="actions">
                        <LoadingButton loading={this.state.download_in_progress} onClick={this.downloadGeneratedTransferOrderReport}>Generált átalányok letöltése</LoadingButton>
                    </div>
                </React.Fragment> : null}
                <div className="page-subtitle">Periódusok</div>
                <div className="block">
                    <label className="label label-short">Tanév</label>
                    <SelectField readOnly={this.state.period_fetch_in_progress} onChange={(event) =>this.handleSchoolYearChange(event.target.value)} className={"field field-simple"} value={this.state.school_year} displayValues={this.state.school_years.reduce((prev, curr) => ({
                        ...prev,
                        [curr.id]: (curr.name || curr.year_name)+ (curr.school_name ? " ("+curr.school_name+")" : '')
                    }), {})}>
                        <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                        {this.state.school_years.map(sy => <option value={sy.id} />)}
                    </SelectField>
                </div>
                {this.state.period_fetch_in_progress ? <Loading loading_text=''/> : <div className="table-container">
                    <table className="striped period-table">
                        <thead>
                            <tr>
                                <th>Év</th>
                                <th>Hónap</th>
                                <th>Kezdődátum</th>
                                <th>Záródátum</th>
                                <th>Számlázási kezdődátum</th>
                                <th>Számlázási végdátum</th>
                                {transfer_order_read_right ? <React.Fragment>
                                    <th>Átalánygeneráló dátum</th>
                                    <th>Legutolsó átalánygenerálás időpontja</th>
                                    <th>Legutolsó átalánygenerálás sikeressége</th>
                                </React.Fragment> : null}
                                <th>Nyitott</th>
                                {haveGenerateRight ? <th>Generálás</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.periods.map((data, id) =>{
                            return <tr key={id}>
                                <td><SimpleField value={data.year } readOnly={true}/></td>
                                <td><SimpleField value={data.month } readOnly={true}/></td>
                                <td><DateField value={data.period_start_date } readOnly={true}/></td>
                                <td><DateField value={data.period_end_date } readOnly={true}/></td>
                                <td><DateField onChange={event => this.handleRowChange(id, 'invoice_date_from', event.target.value)} value={data.invoice_date_from } readOnly={!data.open || !can_edit}/></td>
                                <td><DateField onChange={event => this.handleRowChange(id, 'invoice_date_to', event.target.value)} value={data.invoice_date_to } readOnly={!data.open || !can_edit}/></td>
                                {transfer_order_read_right ? <React.Fragment>
                                    <td><DateField readOnly={!data.open || !can_edit} value={data.transfer_order_generate_date }  onChange={event => this.handleRowChange(id, 'transfer_order_generate_date', event.target.value)}/></td>
                                    <td><DateTimeField readOnly={true} value={data.transfer_order_updated }/></td>
                                    <td><SimpleField value={data.transfer_order_generation_last_was_successfull!== null ?(data.transfer_order_generation_last_was_successfull? "✓" : "✗") : ''} readOnly={true}/></td>
                                </React.Fragment> : null}
                                <td><input disabled={!can_edit} type="checkbox" checked={ data.open } onChange={event => this.handleRowChange(id, 'open', event.target.checked)}/></td>
                                {haveGenerateRight ? <td><LoadingButton disabled={!this.state.original_periods[id].open || this.state.generating_in_progress !== null} loading={this.state.generating_in_progress === id} onClick={()=>{this.generatePeriod(data.year, data.month, id)}}>Generálás</LoadingButton></td> : null}
                            </tr>}
                        ) }
                        </tbody>
                    </table>
                </div>}
                <div className="actions">
                    {can_edit ? <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton> : null}
                </div>
            </React.Fragment> : <Loading loading_text=''/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Periodusok))