import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import SelectField from "@sapkk/app/components/SelectField";
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import Menunaptar from "../Menunaptar";
import {getDefaultPeriod} from "../../functions";
import {month_names} from "../../constants";
import {addAction, removeAction} from "../../actions";
import PopUp from "../../components/PopUp";
import NavigationLock from "../../components/NavigationLock";

class TanuloiMenu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            student_register: this.props.student_register,
            student: this.props.student,
            year: this.props.year || null,
            meal_group: null,
            period: null,
            meal_groups: [],
            periods: [],
            in_progress: 'fetch',
            student_meal_group_menu: null,
            hash: null,
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+(-1*this.state.student)+'/menu'){
            this.props.history.replace('/tanulo-kezelese/T'+(-1*this.state.student)+'/menu')
        } else {
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getMealGroupsForSchool(this.state.school, undefined, this.props?.history?.location?.state?.student_id || this.state.student_register.student_id, this.props?.history?.location?.state?.school_year_id || this.state.student_register.school_year_id, true),
                CanteenClient.listStudentPeriods({
                    school_year_id: [{
                        operator: 9,
                        value: this.props?.history?.location?.state?.school_year_id || this.state.student_register.school_year_id
                    }],
                    student_id: [{
                        operator: 9,
                        value: this.props?.history?.location?.state?.student_id || this.state.student_register.student_id
                    }]
                }),
                CanteenClient.getNextChoosableMenuItemDate(this.props?.history?.location?.state?.student_id || this.state.student_register.student_id),
                CanteenClient.listMealGroupStudent(this.state.student, this.state.school, this.state.year)
            ]).then(([full_meal_groups, periods, min_date,meal_group_students]) => {
                let meal_groups = full_meal_groups.filter(mg => mg.have_menu)
                let actual_meal_group = meal_groups.length === 1 ? meal_groups[0].id : (this.props?.history?.location?.state?.meal_group_id !== undefined ? this.props?.history?.location?.state.meal_group_id : null)
                this.setState({
                    min_date: min_date,
                    full_meal_groups: full_meal_groups,
                    meal_groups: meal_groups,
                    periods: periods,
                    period: this.props?.history?.location?.state?.period !== undefined ? periods.findIndex(p => p.year + '-' + ('' + p.month).padStart(2, '0') === this.props?.history?.location?.state.period) : getDefaultPeriod(periods),
                    meal_group_students: meal_group_students,
                    in_progress: null
                }, () => {
                    if (actual_meal_group) {
                        this.handleChange('meal_group', actual_meal_group)
                    }
                })
            }))
            if (Object.keys(this.props.rights.rights).length === 0) {
                this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
            }
        }
    }

    handleChange = (name, value) => {
        if(!this.state.popup_page && Object.keys(this.state.student_meal_group_menu?.menu_item_for_period_days || []).find(date => this.state.student_meal_group_menu.menu_item_for_period_days[date].changed)){
            this.setState({
                popup_show: true,
                popup_page: {
                    key: name,
                    value: value
                }
            })
        } else {
            this.setState((prevState)=>({
                [name]: value,
                popup_show: false,
                popup_page: null,
                student_meal_group_menu: !!value || value === 0 ? prevState.student_meal_group_menu : null,
                in_progress: ((name === 'period'|| this.state.period === 0) ? value : prevState.period) && (name === 'meal_group' ? value : prevState.meal_group) ? 'student_meal_group_menu' : null
            }), () => {
                if((this.state.period || this.state.period === 0) && this.state.meal_group){
                    let period = this.state.periods[this.state.period]
                    CanteenClient.findStudentMenuForPeriod(this.state.student, this.state.meal_group, period.year, period.month).then(student_meal_group_menu => {
                        this.setState({
                            student_meal_group_menu: student_meal_group_menu,
                            in_progress: null
                        })
                    })
                }
            })

        }
    }

    handlePaging = (direction) => {
        this.handleChange('period', this.state.period + direction)
    }

    handlePagingCancel = () => {
        this.setState({
            popup_show: false,
            popup_page: null
        })
    }

    handleClose = () => {
        this.setState({
            in_progress: 'close'
        }, ()=> {
            this.props.promise_error_handler( ()=> CanteenClient.closeStudentMenuForPeriod(this.state.student_meal_group_menu.id).then(student_meal_group_menu => {
                this.setState({
                    student_meal_group_menu: student_meal_group_menu,
                    in_progress: null,
                    popup_close_open: false
                })
            })).then(()=>this.setState({
                in_progress: null,
                popup_close_open: false
            }))
        })
    }

    handleReOpen = () => {
        this.setState({
            in_progress: 'reopen'
        }, ()=> {
            this.props.promise_error_handler(()=> CanteenClient.reopenStudentMenuForPeriod(this.state.student_meal_group_menu.id).then(student_meal_group_menu => {
                this.setState({
                    student_meal_group_menu: student_meal_group_menu,
                    in_progress: null
                })
            })).then(()=>this.setState({
                in_progress: null
            }))
        })
    }

    handleSave = () => {
        this.setState({
            in_progress: 'save'
        }, ()=> {
            this.props.promise_error_handler(()=> CanteenClient.saveStudentMenuItemChanges(this.state.student_meal_group_menu.id, this.state.student_meal_group_menu.menu_item_for_period_days).then(student_meal_group_menu => {
                this.props.addMessage('Sikeres mentés', 'success')
                this.setState({
                    student_meal_group_menu: student_meal_group_menu,
                    in_progress: null
                })
            })).then(()=>this.setState({
                in_progress: null
            }))
        })
    }

    handleDownload = () => {
        if(!this.state.popup_download && Object.keys(this.state.student_meal_group_menu?.menu_item_for_period_days || []).find(date => this.state.student_meal_group_menu.menu_item_for_period_days[date].changed)){
            this.setState({
                popup_download: true
            })
        } else {
            this.props.clearMessages()

            this.setState({
                in_progress: 'downloading',
                popup_download: false
            }, () => {
                this.props.promise_error_handler(CanteenClient.getStudentMenuForPeriodPDF(this.state.student_meal_group_menu.id).then((hash) => {
                    return FilestoreClient.download(hash)
                })).then(()=>{
                    this.setState({
                        in_progress: null
                    })
                })
            })
        }
    }

    changeChosenMenuItem = (date, item_id) => {
        this.setState((prevState) =>({
            student_meal_group_menu: {
                ...prevState.student_meal_group_menu,
                menu_item_for_period_days: {
                    ...prevState.student_meal_group_menu.menu_item_for_period_days,
                    [date]: {
                        ...prevState.student_meal_group_menu.menu_item_for_period_days[date],
                        menu_item_id: item_id,
                        original_menu_item_id: prevState.student_meal_group_menu.menu_item_for_period_days[date].original_menu_item_id || prevState.student_meal_group_menu.menu_item_for_period_days[date].menu_item_id,
                        changed: true
                    }
                }
            }
        }))
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    handleClosePopupOpen = () => {
        this.setState({
            popup_close_open: true
        })
    }

    handleClosePopupClose = () => {
        this.setState({
            popup_close_open: false
        })
    }
    handleClosePopupDownload = () => {
        this.setState({
            popup_download: false
        })
    }

    isDisabled = (date, menu_item_id, can_change_student_menu_item,can_use_without_date_check) => {
        if(!can_change_student_menu_item || this.state.in_progress){
            return true
        }
        if(!(date in this.state.student_meal_group_menu.menu_for_period.menu_item_for_period_days)){
            return true
        } else if(!(menu_item_id in this.state.student_meal_group_menu.menu_for_period.menu_item_for_period_days[date])){
            return true
        } else if(!(date in this.state.student_meal_group_menu.menu_item_for_period_days)){
            return true
        } else if(this.state.student_meal_group_menu.closed){
            return true
        }
        if(!can_use_without_date_check){
            if(this.state.min_date.date+ " " + (new Date().toISOString().split("T")[1]) > date + ' ' + this.state.min_date.time){
                return true
            }
        }
        return false
    }

    render() {
        let date = new Date().toISOString().split('T')[0]
        let can_close_student_menu_period = this.props.rights_for_student.includes('CLOSE_STUDENT_MENU_PERIOD') && !this.state.student_meal_group_menu?.closed
        let can_reopen_student_menu = this.props.rights_for_student.includes('REOPEN_STUDENT_MENU_PERIOD') && this.state.student_meal_group_menu?.closed
        let can_change_student_menu_item = this.props.rights_for_student.includes('CHANGE_STUDENT_MENU_ITEM') && !this.state.student_meal_group_menu?.closed
        let can_use_without_date_check = Object.keys(this.props.rights.rights).includes('alkalmazasgazda')
        return <React.Fragment>
            {this.state.in_progress !== 'fetch' ?<React.Fragment>
                { this.state.popup_help_open && (
                    <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                        <p>
                            <div>Menüválasztás:</div>
                        </p>
                        <p>
                            <div>Az étlapon lévő <span style={{fontWeight: 'bold'}}>„A”</span> menü az alapértelmezett menü. Ha nem történik módosítás - <span style={{fontWeight: 'bold'}}>„B”</span> menü választás -, az <span style={{fontWeight: 'bold'}}>„A”</span> menü kerül megrendelésre a következő hónapra.</div>
                        </p>
                        <p>
                            <div>Az <span style={{fontWeight: 'bold'}}>„A”</span> vagy <span style={{fontWeight: 'bold'}}>„B”</span> menü közül az adott nap cellái közötti kattintással tud választani.</div>
                        </p>
                        <p>
                            <div>A kiválasztott menü jobb felső sarkában megjelenik a  <span style={{color: "#004735"}}>&#x2713;</span> jel.</div>
                        </p>
                        <p>
                            <div>A menüt el kell menteni.</div>
                        </p>
                        <p>
                            <div>Módosításra mindaddig lehetősége van, míg az étlap alján a "Lezárás időpontja" szöveg meg nem jelenik.</div>
                        </p>
                    </PopUp>
                ) }
                {this.state.popup_close_open ? <PopUp title={'Megerősítés'} close={this.handleClosePopupClose} buttons={[{action: this.handleClose, text: 'Ok', loading: this.state.in_progress === 'close', disabled: !!this.state.in_progress},{action: this.handleClosePopupClose, text: 'Mégse', disabled: !!this.state.in_progress}]}>
                    <div className="block">
                        Valóban lezárja az étlapot? {Object.keys(this.state.student_meal_group_menu?.menu_item_for_period_days || []).find(date => this.state.student_meal_group_menu.menu_item_for_period_days[date].changed) ? ' A módosítások nem kerültek még elmentésre.' : null}
                    </div>
                </PopUp> : null}
                {this.state.popup_show ? <PopUp title={'Megerősítés'} close={this.handlePagingCancel} buttons={[{action: ()=>this.handleChange(this.state.popup_page.key, this.state.popup_page.value), text: 'Ok'},{action: this.handlePagingCancel, text: 'Mégse'}]}>
                    <div className="block">
                        A módosítás nem került elmentésre. Valóban kilép?
                    </div>
                </PopUp> : null}
                {this.state.popup_download ? <PopUp title={'Megerősítés'} close={this.handleClosePopupDownload} buttons={[{action: ()=>this.handleDownload(), text: 'Ok'},{action: this.handleClosePopupDownload, text: 'Mégse'}]}>
                    <div className="block">
                        A módosítás nem került elmentésre. Valóban le akarja tölteni a dokumentumot a korábbi állapottal?
                    </div>
                </PopUp> : null}
                <NavigationLock
                    when={!!Object.keys(this.state.student_meal_group_menu?.menu_item_for_period_days || []).find(date => this.state.student_meal_group_menu.menu_item_for_period_days[date].changed)}
                    message='A módosítás nem került elmentésre. Valóban kilép?'
                />
                <div className="page-subtitle">Étlap</div>
                {
                    date[0] > (this.props.student_register||{}).date_to ? <div className="block">
                        <h4>A menü nem elérhető.</h4>
                    </div> : (this.state.meal_groups.length === 0 ? <div className={"block"}>
                        <h4>{
                            this.state.full_meal_groups.length > 0 ? 'Az alábbi étkezéseket rendelte: ' + this.state.full_meal_groups.map(fmg => fmg.name).join(', '): 'Nem rendelt étkezést'
                        }, menüválasztásra nincs lehetősége.</h4>
                    </div> : <React.Fragment>
                        {this.state.meal_groups.length > 1 ? <div className="block">
                            <label className="label label-long">Étkezési csoport</label>
                            <SelectField onChange={(event) =>this.handleChange('meal_group',event.target.value)} className={"field field-simple"} value={this.state.meal_group} displayValues={this.state.meal_groups.reduce((prev, curr) => ({
                                ...prev,
                                [curr.id]: curr.name
                            }), {})}>
                                <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                                {this.state.meal_groups.map(mg => <option value={mg.id} />)}
                            </SelectField>
                        </div> : null}
                        {
                            this.state.in_progress === 'student_meal_group_menu' ? <Loading/> : <React.Fragment>
                                <div className="calendar-pager">
                                    <button disabled={this.state.period === 0} onClick={() => this.handlePaging(-1)}>&lt;&lt;</button>
                                    <div>{this.state.periods[this.state.period].year + '. ' + month_names[this.state.periods[this.state.period].month - 1]}</div>
                                    <button disabled={this.state.period === this.state.periods.length - 1} onClick={() => this.handlePaging(+1)}>&gt;&gt;</button>
                                </div>
                                {
                                    this.state.student_meal_group_menu ? <React.Fragment>
                                        <Menunaptar
                                            meal_group = {this.state.meal_groups.find(mg => mg.id === parseInt(this.state.meal_group))}
                                            menu_item_for_period = {this.state.student_meal_group_menu.menu_for_period}
                                            student_menu_item = {this.state.student_meal_group_menu.menu_item_for_period_days}
                                            created = {this.state.student_meal_group_menu.created}
                                            closed = {this.state.student_meal_group_menu.closed}
                                            changeChosenMenuItem = {can_change_student_menu_item ? this.changeChosenMenuItem : undefined}
                                            isDisabled = {(date, menu_item_id) => this.isDisabled(date, menu_item_id, can_change_student_menu_item, can_use_without_date_check)}
                                        />
                                        <div className={"actions"}>
                                            {can_close_student_menu_period ? <LoadingButton loading={this.state.in_progress === 'close'} disabled={this.state.in_progress} onClick={this.handleClosePopupOpen}>Lezárás</LoadingButton> : null}
                                            {can_reopen_student_menu ? <LoadingButton loading={this.state.in_progress === 'reopen'} disabled={this.state.in_progress} onClick={this.handleReOpen}>Újranyitás</LoadingButton> : null}
                                            {can_change_student_menu_item ? <LoadingButton loading={this.state.in_progress === 'save'} disabled={this.state.in_progress} onClick={this.handleSave}>Mentés</LoadingButton> : null}
                                            <LoadingButton loading = {this.state.in_progress === 'downloading'} disabled={this.state.in_progress} onClick={this.handleDownload}>Letöltés</LoadingButton>
                                        </div>
                                    </React.Fragment>: null
                                }
                                {
                                    !this.state.student_meal_group_menu  ? <h4>
                                        {this.state.meal_group_students.find(ds => {
                                            let date = new Date(this.state.periods[this.state.period].year,this.state.periods[this.state.period].month - 1, 1)
                                            let last_date = new Date(this.state.periods[this.state.period].year,this.state.periods[this.state.period].month, 0)
                                            let date_string = date.getFullYear() + '-'+ (date.getMonth() + 1 + '').padStart(2, '0') + '-' + (date.getDate() + '').padStart(2, '0')
                                            let last_date_string = last_date.getFullYear() + '-'+ (last_date.getMonth() + 1 + '').padStart(2, '0') + '-' + (last_date.getDate() + '').padStart(2, '0')
                                            return ds.date_from <= last_date_string && (ds.date_to === null || ds.date_to >= date_string) && this.state.meal_group === ds.meal_group_id
                                        }) ? 'A lekérdezett periódusra nem került étlap feltöltésre.' : 'Nem rendelt étkezést, menüválasztásra nincs lehetősége.'
                                        }
                                    </h4>: null
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>)
                }
            </React.Fragment> : <Loading loading_text=''/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloiMenu))