import React, { Component } from 'react'
import { setGuide, setTitle } from "../../actions"
import { connect } from "react-redux"
import CanteenClient from "../../CanteenClient"
import { clearMessages, addMessage } from '@sapkk/app/actions';
import Loading from "@sapkk/app/components/Loading"
import { Link } from "react-router-dom"
import FilestoreClient from '../../FileStoreClient';
import LoadingButton from '../../components/LoadingButton';
import DateTimeField from '@sapkk/app/components/DateTimeField';

class Gyermeketkeztetes2Statusz extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            messages: undefined,
            school_year_id: '',
            school_years: undefined,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(CanteenClient.getSchoolYearsBySchoolID(this.state.school).then(school_years =>{
            this.setState((prevState) => ({
                school_years: school_years,
            }),()=>{
                if(this.state.school_years.length===1){
                    this.handleChangeSchoolYear(this.state.school_years[0].id, this.handleFetch)
                } else if(this.state.school_years.find(sy=>sy.default)) {
                    this.handleChangeSchoolYear(this.state.school_years.find(sy=>sy.default).id, this.handleFetch)
                } else {
                    this.handleChangeSchoolYear(null, this.handleFetch)
                }
            })
        }))
    }

    handleChangeSchoolYear = (school_year, callback = ()=>{})=>{
        this.setState({
            messages: undefined,
            school_year_id: this.state.school_years.findIndex(sy=>sy.id==school_year)!==-1 ? school_year : ''
        }, callback)
    }

    handleFetch = ()=>{
        this.props.clearMessages()
        this.setState({
            fetch_in_progress: true
        },()=>{
            this.props.promise_error_handler(CanteenClient.getGyermeketkeztetes2Status(this.state.school_year_id).then(status =>
                this.setState({
                    ...status,
                    fetch_in_progress: false
                })
            ))
        })
    }

    static typeConversion =  {
        "delete": "Törlendő",
        "creation": "Létrehozandó",
        "operator" : "Üzemeletetői feladat",
        "modification" : "Módosítás",
        "manual" : "Kézi átvitel"
    }

    convertMessage = (message) =>{
        if(!message.student_id){
            return message.message
        }
        return <Link to={'/tanulo-kezelese/T'+(-1*message.student_id)+'/adatok'} target="_blank">{message.message}</Link>
    }

    handleDownload = () => {
        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.reportDownload('/CanteenService/Report/gyermeketkeztetes2Status', {
                school_year_id: this.state.school_year_id
            }).then((result) => {
                return FilestoreClient.download(result.hash)
            }).catch(error => {
                this.props.clearMessages()
                throw error
            })).then(() => {
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    render() {
        return (
            this.state.school_years !== undefined ? <React.Fragment>
                <div className="page-subtitle">Tanév kiválasztása</div>
                <div className="student-select-block">
                    <div>
                        <div className="block block-lg-1-1">
                            <select className="field field-simple" value={this.state.school_year_id} onChange={event => this.handleChangeSchoolYear( event.target.value)}>
                                <option value="">Kérem válasszon!</option>
                                { this.state.school_years.map(school_year => <option value={school_year.id}>{school_year.name}</option>) }
                            </select>
                        </div>
                    </div>
                    <div>
                        <LoadingButton disabled={!this.state.school_year_id} loading={this.state.fetch_in_progress} onClick={this.handleFetch}>Lekérdez</LoadingButton>
                    </div>
                </div>
                <div className="page-subtitle">Gyermekétkeztetés2 státusz</div>
                {
                    this.state.messages!==undefined ? <React.Fragment>
                        <div className="block">
                            <label className="label label-long">Szinkronizáció időpontja</label>
                            <div className={'text-container '}><DateTimeField readOnly={true} value={ this.state.timestamp }/></div>
                        </div>
                        <div className="block table-container">
                            <table className="xmenza-status striped">
                                <thead>
                                    <th>Üzenet</th>
                                    <th>Állapot</th>
                                </thead>
                                <tbody>
                                    { this.state.messages.map(message =>
                                        <tr>
                                            <td>{this.convertMessage(message)}</td>
                                            <td className="error">{Gyermeketkeztetes2Statusz.typeConversion[message.type] || "Ismeretlen állapot"}</td>
                                        </tr>
                                    ) }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions">
                            <LoadingButton loading={this.state.downloading_in_progress} onClick={this.handleDownload}>Letöltés</LoadingButton>
                        </div>
                    </React.Fragment> : (this.state.fetch_in_progress ? <Loading loading_text=''/> : null)
                }
            </React.Fragment> : <Loading loading_text=''/>
        )
    }
}
function mapStateToProps(state) {
    return {
        class_types: state.class_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(Gyermeketkeztetes2Statusz)
