import React, { Component } from 'react'
import { setGuide, setTitle, addAction, removeAction, setSystemMessagesCount} from "../../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages, setActions} from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import LoadingButton from '../../components/LoadingButton';
import TanuloAdat from './TanuloAdat'
import PopUp from "../../components/PopUp"
import Loading from '@sapkk/app/components/Loading'
import NavigationLock from "../../components/NavigationLock";

class TanuloiAdatok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school || null,
            class: this.props.class || null,
            student: this.props.student || null,
            year: this.props.year || null,
            rights:  this.props.rights_for_student,
            countries: undefined,
            street_types: undefined,
            student_data: undefined,
            payment_methods: undefined,
            original_student_data: undefined,
            read_only: !(this.props.history.location.state || {edit: false}).edit,
            fetch_in_progress: false,
            save_in_progress: false,
            date_from: (this.props.history.location.state || {date_from: null}).date_from,
            changed: false
        }
    }

    componentDidMount() {
        
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getCountries(),
            CanteenClient.getStreetTypes(),
            CanteenClient.getPaymentMethods()
        ]).then(([countries,street_types,payment_methods])=>{
            this.setState({
                countries: countries,
                street_types: street_types,
                payment_methods: payment_methods
            },()=>{
                this.handleFetch(this.state.date_from)
            })
        }).catch(error => {
            this.setState({
                fetch_in_progress: false
            })
            throw error
        }))

        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
    }

    componentWillUnmount(){
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    getAddressCity = ()=>{
        if ((this.state.countries.find(x => x.id == this.state.student_data.student_data.address_data.country_id || "") || {}).country_code === 'HU') {
            if ((this.state.student_data.student_data.address_data.zip_code || {}).length === 4) {
                return CanteenClient.getCityByCountryAndZipCode(this.state.student_data.student_data.address_data.country_id, this.state.student_data.student_data.address_data.zip_code).then(cities => {
                    this.setState(prevState => ({
                        student_data: {
                            ...prevState.student_data,
                            student_data: {
                                ...prevState.student_data.student_data,
                                address_data: {
                                    ...prevState.student_data.student_data.address_data,
                                    city_name: cities.length === 1 ? cities[0].name : (cities.find(c => c.name == prevState.student_data.student_data.address_data.city_name) ? prevState.student_data.student_data.address_data.city_name :"")
                                }
                            }
                        },
                        cities: cities
                    }))
                }).catch(error => {
                    this.setState(prevState => ({
                        student_data: {
                            ...prevState.student_data,
                            student_data: {
                                ...prevState.student_data.student_data,
                                address_data: {
                                    ...prevState.student_data.student_data.address_data,
                                    city_name: ""
                                }
                            }
                        },
                        cities: undefined
                    }))
                })
            } else {
                this.setState(prevState => ({
                    student_data: {
                        ...prevState.student_data,
                        student_data: {
                            ...prevState.student_data.student_data,
                            address_data: {
                                ...prevState.student_data.student_data.address_data,
                                city_name: ""
                            }
                        }
                    },
                    cities: undefined
                }))
            }
        }
        return new Promise((resolve, reject) => resolve(null))
    }

    getInvoiceCity = ()=>{
        if (this.state.student_data.student_data.invoice_recipient_data && (this.state.countries.find(x => x.id == this.state.student_data.student_data.invoice_recipient_data.country_id || "") || {}).country_code === 'HU') {
            if ((this.state.student_data.student_data.invoice_recipient_data.zip_code || {}).length === 4) {
                return CanteenClient.getCityByCountryAndZipCode(this.state.student_data.student_data.invoice_recipient_data.country_id, this.state.student_data.student_data.invoice_recipient_data.zip_code).then(cities => {
                    this.setState(prevState => ({
                        student_data: {
                            ...prevState.student_data,
                            student_data: {
                                ...prevState.student_data.student_data,
                                invoice_recipient_data: {
                                    ...prevState.student_data.student_data.invoice_recipient_data,
                                    city_name: cities.length === 1 ? cities[0].name : (cities.find(c => c.name == prevState.student_data.student_data.invoice_recipient_data.city_name) ? prevState.student_data.student_data.invoice_recipient_data.city_name :"")
                                }
                            }
                        },
                        invoice_cities: cities
                    }))
                }).catch(error => {
                    this.setState(prevState => ({
                        student_data: {
                            ...prevState.student_data,
                            student_data: {
                                ...prevState.student_data.student_data,
                                invoice_recipient_data: {
                                    ...prevState.student_data.student_data.invoice_recipient_data,
                                    city_name: ""
                                }
                            }
                        },
                        invoice_cities: undefined
                    }))
                })
            } else {
                this.setState(prevState => ({
                    student_data: {
                        ...prevState.student_data,
                        student_data: {
                            ...prevState.student_data.student_data,
                            invoice_recipient_data: {
                                ...prevState.student_data.student_data.invoice_recipient_data,
                                city_name: ""
                            }
                        }
                    },
                    invoice_cities: undefined
                }))
            }
        }
        return new Promise((resolve, reject) => resolve(null))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.student_data && this.state.student_data) {
            if (prevState.student_data.student_data.address_data.country_id !== this.state.student_data.student_data.address_data.country_id || prevState.student_data.student_data.address_data.zip_code !== this.state.student_data.student_data.address_data.zip_code) {
                this.getAddressCity()
            }

            if (prevState.student_data.student_data.invoice_recipient_data && this.state.student_data.student_data.invoice_recipient_data) {
                if (prevState.student_data.student_data.invoice_recipient_data.country_id !== this.state.student_data.student_data.invoice_recipient_data.country_id || prevState.student_data.student_data.invoice_recipient_data.zip_code !== this.state.student_data.student_data.invoice_recipient_data.zip_code) {
                    this.getInvoiceCity()
                }
            }
        }
    }

    handleFetch(date_from=null) {
        this.props.clearMessages()
        this.setState({
            show_student_list: false,
            fetch_in_progress: true,
            date_from: date_from,
            student_data: null
        }, ()=>{
            this.props.promise_error_handler(CanteenClient.getStudent(this.state.student, this.state.school).then(data => {
                this.setState({
                    write_normal_data: this.state.rights.includes('WRITE'),
                    write_financial_data: this.state.rights.includes('WRITE_FINANCIAL'),
                    write_invoice_recipient_data: this.state.rights.includes('WRITE_INVOICE_RECIPIENT'),
                    write_om_and_birthday_data: this.state.rights.includes('WRITE_OM_AND_BIRTHDAY'),
                    write_closed: this.state.rights.includes('WRITE_CLOSED') || this.state.rights.includes('WRITE_CLOSED_AS_PARENT') || this.state.rights.includes('WRITE_CLOSED_AS_CLERK'),
                    read_actual_financial_data: this.state.rights.includes('READ_ACTUAL_FINANCIAL_DATA'),
                    student_data: {
                        ...data,
                        student_data: {
                            ...data.student_data,
                            address_data: {
                                ...(data.student_data.address_data||{})
                            },
                            invoice_recipient_data: data.student_data.invoice_recipient_data||null,
                            account_number: [data.student_data.bank_key, data.student_data.bank_account_number].filter(v => v).join('-')
                        }
                    },
                    original_student_data: {
                        ...data,
                        student_data: {
                            ...data.student_data,
                            address_data: {
                                ...(data.student_data.address_data||{})
                            },
                            invoice_recipient_data: data.student_data.invoice_recipient_data||null,
                            account_number: [data.student_data.bank_key, data.student_data.bank_account_number].filter(v => v).join('-')
                        }
                    },
                    cities: undefined,
                    invoice_cities: undefined,
                    read_only: true,
                    changed: false
                },()=>{
                    Promise.all([
                        this.getAddressCity(),
                        this.getInvoiceCity()
                    ],()=>{
                        this.setState({
                            fetch_in_progress: false
                        })
                    })
                })
            })).then(() => {
                if((this.props.history.location.state || {edit: false}).edit){
                    this.handleEditStart()
                }
                this.setState({
                    fetch_in_progress: false
                })
            })
        })
    }

    handleDataChange = (category, name, value)=>{
        if (category) {
            this.setState(prevState => ({
                student_data: {
                    ...prevState.student_data,
                    student_data: {
                        ...prevState.student_data.student_data,
                        [category]: {
                            ...prevState.student_data.student_data[category],
                            [name]: value
                        }
                    }
                },
                changed: true
            }))
        } else {
            this.setState(prevState => ({
                student_data: {
                    ...prevState.student_data,
                    student_data: {
                        ...prevState.student_data.student_data,
                        [name]: value
                    }
                },
                changed: true
            }))
        }
    }

    handleChange = (name, value)=>{
        this.setState(prevState => ({
            student_data: {
                ...prevState.student_data,
                [name]: value
            },
            om_or_birthday_changed: prevState.om_or_birthday_changed || name == 'om' || name == 'birthday',
            changed: true
        }))
    }

    handleSameAddressCheckboxChange = (checked)=>{
        this.setState(prevState => ({
            student_data: {
                ...prevState.student_data,
                student_data: {
                    ...prevState.student_data.student_data,
                    invoice_recipient_data: checked ? null : {
                        country_id: (this.state.countries.find(c=>c.country_code=='HU')||{}).id,
                        street_type_name: 'utca'
                    }
                }
            },
            changed: true
        }))
    }

    handleEditStart() {
        this.props.clearMessages()
        if(this.state.rights.includes('WRITE') || this.state.rights.includes('WRITE_FINANCIAL')){
            this.setState(prevState => ({
                student_data: {
                    ...prevState.student_data,
                    student_data: {
                        ...prevState.student_data.student_data,
                        address_data: {
                            country_id: (this.state.countries.find(c=>c.country_code=='HU')||{}).id,
                            street_type_name: 'utca',
                            ...prevState.student_data.student_data.address_data
                        }
                    }
                },
                om_or_birthday_changed: false,
                read_only: false,
                changed: false
            }))
        }
    }

    handleEditCancel() {
        this.props.clearMessages()
        this.setState(prevState => ({
            student_data: {
                ...prevState.original_student_data,
                student_data: {
                    ...prevState.original_student_data.student_data,
                    address_data: {
                        ...(prevState.original_student_data.student_data.address_data||{})
                    },
                    invoice_recipient_data: prevState.original_student_data.student_data.invoice_recipient_data ? {...prevState.original_student_data.student_data.invoice_recipient_data} : null
                }
            },
            om_or_birthday_changed: false,
            read_only: true,
            changed: false
        }))
    }

    handleEditSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        })

        this.props.promise_error_handler(()=>CanteenClient.editStudent(this.state.student, this.state.student_data.student_data, this.state.date_from, this.state.student_data.birthday, this.state.student_data.om, this.state.student_data.sap_customer_code).then(data => {
            this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
            this.setState({
                save_in_progress: false,
                student_data: {
                    ...data,
                    student_data: {
                        ...data.student_data,
                        address_data: {
                            ...(data.student_data.address_data||{})
                        },
                        invoice_recipient_data: data.student_data.invoice_recipient_data||null,
                        account_number: [data.student_data.bank_key, data.student_data.bank_account_number].filter(v => v).join('-')
                    }
                },
                original_student_data: {
                    ...data,
                    student_data: {
                        ...data.student_data,
                        address_data: {
                            ...(data.student_data.address_data||{})
                        },
                        invoice_recipient_data: data.student_data.invoice_recipient_data||null,
                        account_number: [data.student_data.bank_key, data.student_data.bank_account_number].filter(v => v).join('-')
                    }
                },
                om_or_birthday_changed: false,
                read_only: true,
                changed: false
            })
        })).then(() => {
            this.setState({
                save_in_progress: false
            })
        })
    }

    capsLockDetector = (event) =>{
        if(this.state.capslock != event.getModifierState("CapsLock")){
            this.setState({
                capslock: event.getModifierState("CapsLock")
            })
        }
    }

    render() {
        let today = (new Date()).toISOString().split('T')[0]
        return (
            <React.Fragment>
                <NavigationLock
                    when={this.state.changed}
                    message='A módosítás nem került elmentésre. Valóban kilép?'
                />
                { this.state.popup_help_open && (
                    <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                        <p>
                            <div>Az <span style={{fontWeight: 'bold'}}>„Adatok”</span> -&gt; <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>„Törzsadat”</span> menüpontban a „Törzsadat” fülön a tanuló személyes adatait, a térítési díjat fizető nevét és bankszámla adatait, valamint a számlázási adatokat tudja megtekinteni és módosítani a szülő.</div>
                            <div>A térítési díjról a számla havonta a tanuló nevére és címére kerül kiállításra. Amennyiben ettől eltérő számlázási címet szeretne a szülő megadni, azt az ügyintézővel történő egyeztetés után, indokolt esetben az ügyintéző tudja módosítani.</div>
                        </p>
                        <p>
                            <div>Az <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>„Adatmódosítás”</span> ikonra kattintva az adatok megadására, módosítására van lehetőség.</div>
                            <div>Az adatok megadása után a Mentés ikonra kattintva az adatok elmentésre kerülnek, és a képernyő jobb felső sarkánál megjelenik egy „Jóváhagyásra vár!” megjegyzés.</div>
                            <div>Az adatmódosítás jóváhagyását az iskolai ügyintéző végzi. Amennyiben elutasítja, arról a szülő rendszerüzenetet kap.</div>
                        </p>
                        <p>
                            <div>A címadatok kitöltésének alapelvei:</div>
                            <ul>
                                <li>Minden címadatot a megfelelő rubrikában kell rögzíteni. Például a címadatban szereplő „Földszint” adatnak (vagy a megfelelő rövidítésnek) a Szint mezőbe kell kerülnie.</li>
                                <li>Ha egy címadat mezőbe nem kell értéket megadni, akkor hagyjuk üresen, nem szabad kötőjellel feltölteni.</li>
                                <li>A Kerület, Házszám, Épület, Lépcsőház, Szint és Ajtó mezők esetében a törzsadat mezőben a konkrét érték mellett nem szabad feltüntetni a címadat típusát vagy arra utaló rövidítést. Vagyis, ha például a tanuló címében „6. lépcsőház” szerepel, akkor a törzsadatban csak a „6.” értéket szabad megadni.</li>
                                <li>Ha a Kerület, Házszám, Épület, Lépcsőház, Szint, Ajtó mezőben sorszám szerepel, akkor azt sorszámként, tehát „12.” formában kell megadni, vagyis pont (.) karakterrel kell zárni.</li>
                                <li>A kerület mező kitöltése szinte csak Budapest város esetén indokolt a magyarországi címek esetében. Eltérő város esetén csak akkor töltsük ki a kerületet, ha az a valóságban is része a tanuló bejelentett címének.</li>
                                <li>Amennyiben egy cím helyrajzi számot tartalmaz, akkor abban az esetben, ha a közterület „nevesítve” van, akkor a házszám mezőbe írjuk be a helyrajzi számot (pl. közterület neve: Diósvár, közterület jellege: dűlő, házszám: 0551/12.), ha a cím nem tartalmaz közterületet, akkor a közterület nevébe írjuk be a helyrajzi számot (ilyenkor a házszám üresen marad).</li>
                                <li>A közterületek neve esetén kerüljük a rövidítéseket (pl. közterület neve: Károli G. =&gt; Károli Gáspár).</li>
                                <li>A számot igénylő mezők (a Kerület mezőt leszámítva) esetén arab számok kerüljenek rögzítésre (pl. szint: I. =&gt; 1.).</li>
                            </ul>
                        </p>
                    </PopUp>
                ) }
                {
                    this.state.capslock ? <div class="messages warning">A Caps Lock be van kapcsolva</div> : null
                }
                {
                    this.state.om_or_birthday_changed ? <div class="messages warning">Az oktatási azonosító és/vagy a születési dátum módosult, kérem, hogy figyeljen!</div> : null
                }
                { this.state.student_data && this.state.countries && this.state.street_types ? <React.Fragment>
                    {
                        today <= this.props.student_register.date_to ? null : <div className="student-data-closed">Zárolt</div>
                    }
                    <TanuloAdat
                        cities={this.state.cities}
                        capsLockDetector={this.capsLockDetector}
                        invoice_cities={this.state.invoice_cities}
                        school={this.state.school}
                        class={this.state.class || null}
                        student={this.state.student || null}
                        year={this.state.year || null}
                        rights={this.state.rights_for_student}
                        countries={this.state.countries}
                        street_types={this.state.street_types}
                        student_data={this.state.student_data}
                        payment_methods={this.state.payment_methods}
                        read_only={this.state.read_only}
                        write_normal_data={this.state.write_normal_data}
                        write_om_and_birthday_data={this.state.write_om_and_birthday_data}
                        write_financial_data={this.state.write_financial_data}
                        write_invoice_recipient_data={this.state.write_invoice_recipient_data}
                        handleDataChange={this.handleDataChange}
                        handleChange={this.handleChange}
                        handleSameAddressCheckboxChange={this.handleSameAddressCheckboxChange}
                        read_actual_financial_data={this.state.read_actual_financial_data}
                    />
                    <div className="actions">
                        {  (this.state.write_closed || today <= this.props.student_register.date_to) && this.state.student_data.student_data.date_to ==null && (
                            this.state.student_data.student_data.authentic!==null ? this.state.rights.includes('WRITE') : this.state.rights.includes('WRITE') && this.props.rights.students.findIndex(s => s.id == this.state.student) >= 0
                        ) && this.state.read_only && <button onClick={() => this.handleEditStart()}>{this.props.rights.students.findIndex(s => s.id == this.state.student) >= 0 ? 'Adatmódosítás' : 'Szerkesztés'}</button> }
                        { !this.state.read_only && <LoadingButton loading={this.state.save_in_progress} onClick={() => this.handleEditSave()}>Mentés</LoadingButton> }
                        { !this.state.read_only && <button disabled={this.state.save_in_progress} onClick={() => this.handleEditCancel()}>Mégse</button> }
                    </div>
                </React.Fragment> : <Loading loading_text=''/>}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    addAction: addAction,
    removeAction: removeAction
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloiAdatok))
