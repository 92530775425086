import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import PopUp from "../components/PopUp";
import SimpleField from "@sapkk/app/components/SimpleField";
import Loading from "@sapkk/app/components/Loading";
import {addAction, removeAction, setRights} from "../actions";

class UjGyerekHozzarendelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            childrens: [],
            new_children_om: '',
            new_children_password: '',
            save_in_progress: false,
            show_new_children_popup: false,
            delete_id: null,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        this.props.setTitle('Gyerek hozzárendelés')
        this.props.setGuide(null)
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getChildrens()
        ]).then(([childrens]) => {
            this.setState({
                childrens: childrens,
                fetch_in_progress: false
            })
        }))

        this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
    }

    componentWillUnmount(){
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    handleRightChange = ()=>{
        Promise.all([
            CanteenClient.getRights().catch(() => {
                return {
                    rights: {},
                    schools: [],
                    students: []
                }
            })
        ]).then(([rights]) => {
            this.props.setRights(rights.rights, rights.schools, rights.students, rights.can_have_children)
            this.setState({
                freeze: false
            })
        })
    }


    handleAddLine = ()=>{
        this.setState({
            show_new_children_popup: true,
            new_children_om: '',
            new_children_password: ''
        })
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            delete_id: prevState.childrens[id].id
        }))
    }

    handleClose = ()=>{
        this.props.clearMessages()
        this.setState({
            delete_id: null,
            show_new_children_popup: false
        })
    }

    handleAdd = ()=>{
        this.props.clearMessages()
        this.props.promise_error_handler(CanteenClient.addChildren(this.state.new_children_om, this.state.new_children_password).then(result => {
            this.setState({
                childrens: result
            },this.handleRightChange)
        })).then(()=>{
            this.setState({
                show_new_children_popup: false 
            })
        })
    }

    handleChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleDelete = ()=>{
        this.props.clearMessages()
        this.props.promise_error_handler(CanteenClient.deleteChildren(this.state.delete_id).then(result => {
            this.setState({
                childrens: result
            },this.handleRightChange)
        })).then(()=>{
            this.setState({
                delete_id: null 
            })
        })
    }
   
    render() {
        return !this.state.fetch_in_progress ? <React.Fragment>
            { this.state.popup_help_open && (
                <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                    <p>
                        <div>Ha LDAP azonosítóval történik a bejelentkezés, akkor a szülőnek először a gyerekét/gyerekeit „magához kell rendelnie”. Így a továbbiakban egy felületen tudja minden gyerekének adatát, hiányzását, stb… kezelni.</div>
                    </p>
                    <p>
                        <div>Bejelentkezés után a <span className="plus-icon"/> ikonra kattintva tud a szülő gyereket rendelni magához.</div>
                        <div>A felugró ablakban kell megadni a gyerek oktatási azonosítóját és jelszóként a gyerek születési dátumát ÉÉÉÉ-HH-NN formátumban, majd az OK gombra kattintva elmentésre kerül a gyerek a szülőhöz.</div>
                    </p>
                    <p>
                        <div>Lehetőség van további gyerek hozzárendeléshez is a <span className="plus-icon"/> ikonra kattintva.</div>
                        <div>Ha a gyerek(ek) szülői hozzárendelése megtörtént, akkor a felső ikonsorban a „Kezdőoldal” feliratra kattitva megjelenik a szülői felület kezdőoldala, a „Tanulói törzsadatok” feliratra kattitva a tanuló törzsadata.</div>
                        <div>Az LDAP azonosítóval bejelentkezett szülő kezdőoldala az „Új gyerek hozzárendelése” menüponttal tér el az OM azonosítóval történő szülői bejelentkezés felületétől.</div>
                    </p>
                </PopUp>
            ) }
            {
                this.state.show_new_children_popup ? <PopUp
                        buttons={[
                            {action: this.handleAdd, text: 'Ok'},
                            {action: this.handleClose, text: 'Mégse'}
                        ]}
                        title={'Gyerek hozzárendelés'}
                        close={this.handleClose}>
                        <div className="block">
                            <label className="label label-long">Oktatási azonosító:</label>
                            <SimpleField className="field field-simple" value = {this.state.new_children_om} onChange ={(event)=>this.handleChange('new_children_om',event.target.value)}/>
                        </div>
                        <div className="block">
                            <label className="label label-long">Jelszó:</label>
                            <SimpleField type="password" className="field field-simple" value = {this.state.new_children_password} onChange ={(event)=>this.handleChange('new_children_password',event.target.value)}/>
                        </div>
                    </PopUp> : null
            }
            {
                this.state.delete_id ? <PopUp buttons={[
                    {action: this.handleDelete, text: 'Ok'},
                    {action: this.handleClose, text: 'Mégse'}
                ]}
                title={'Gyerek törlése'}
                close={this.handleClose}>
                <div className="block">Biztosan törölni szeretné a hozzárendelést?</div>
                </PopUp> : null
            }
            <div className="page-subtitle">Hozzárendelt gyerekek</div>
            <div className="table-container">
                <table className="striped modify-table">
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Oktatási azonosító</th>
                            <th>Jelenlegi iskola</th>
                            <th>Jelenlegi osztály</th>
                            <th className="col-actions"/>
                        </tr>
                    </thead>
                    <tbody>
                    { this.state.childrens.map((data, id) =>{
                        return <tr key={id}>
                            <td><Link to={'/tanulo-kezelese/T'+(-1*data.id)}>{data.name}</Link></td>
                            <td>{data.om}</td>
                            <td>{data.school_name}</td>
                            <td>{data.class_name}</td>
                            <td className="col-actions"><button className="remove" onClick={() => this.handleRemoveLine(id)}/></td>
                        </tr>}
                    ) }
                        <tr className="table-new-row">
                            <td colSpan={4}>{this.state.childrens.length === 0 && "Nincs gyerek hozzárendelve."}</td>
                            <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>  : <Loading loading_text=''/>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    setRights: setRights,
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UjGyerekHozzarendelese))