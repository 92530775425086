import React, { Component } from 'react'
import { setGuide, setTitle, removeAction, addAction} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import SeparatedNumberFields from "@sapkk/app/components/SeparatedNumberFields"
import DateField from '@sapkk/app/components/DateField';
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import Loading from '@sapkk/app/components/Loading'
import PopUp from '../components/PopUp'

class TanuloEgyenlege extends Component {
    constructor(props) {
        super(props)

        this.state = {
            year: this.props.year || null,
            school: this.props.school || null,
            class: this.props.class || null,
            student: this.props.student || null,
            years: [],
            schools: [],
            classes: [],
            students: [],
            school_years: [],
            data: undefined,
            fetch_in_progress: false,
            disable_menu: true,
            guide: null,
            school_year: this.props.school_year || ''
        }
    }

    changeHistory = (school_year, replace = true)=>{
        let method = (pathname) => {
            if(this.props.history.location.pathname === pathname){
                this.setState({
                    fetch_in_progress: false
                })
            } else if(replace){
                this.props.history.replace(pathname)
            } else {
                this.props.history.push(pathname)
            }
        }
        let school_year_url = school_year ? '/'+school_year : ''
        if(this.state.student){
            method('/tanulo-kezelese/T'+(-1*this.state.student)+'/gy2-egyenleg'+school_year_url)
        } else if(this.state.class){
            method('/osztaly-kezelese/'+this.state.class+'/gy2-egyenleg'+school_year_url)
        } else {
            method('/iskola-kezelese/'+this.state.school+'/gy2-egyenleg'+school_year_url)
        }
    }

    componentDidMount() {
        this.setState({
            fetch_in_progress: true,
            guide: Object.keys(this.props.rights.rights).length === 0 ? "A kiállított számla és a befizetett étkezési díj feltöltésre kerül a rendszerbe, melyet folyamatosan nyomon követhet. Az étkezések elszámolása a tanév végén történik, mely alapján a túlfizetések visszautalásra kerülnek, míg az esetleges hátralékot Önnek szükséges rendezni." : null
        },()=>{
            this.props.promise_error_handler((() => {
                if(this.state.student){
                    return CanteenClient.getSchoolYearsForStudent(this.state.student)
                } else if(this.state.school){
                    return CanteenClient.getSchoolYearsBySchoolID(this.state.school)
                }
            })().then(school_years => {
                this.setState({
                    school_years: school_years,
                },()=>{
                    if(this.state.student){
                        if (Object.keys(this.props.rights.rights).length === 0) {
                            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
                        }
                    }
                    if(this.state.school_year && (this.state.school_years.find(sy=>sy.id==this.state.school_year))){
                        this.handleFetch()
                    } else if(this.state.school_years.length===1){
                        this.changeHistory(this.state.school_years[0].id)
                    } else if(this.state.school_years.find(sy=>sy.default)) {
                        this.changeHistory(this.state.school_years.find(sy=>sy.default).id)
                    } else {
                        this.changeHistory(null)
                    }
                })
            }))
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.school_year !== state.school_year) {
            return {
                school_year: props.school_year || ''
            }
        }
        return null
    }

    componentWillUnmount() {
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.school_year !== this.props.school_year) {
            this.handleFetch()
        }
    }

    handleSchoolYearChange(school_year_id) {
        this.props.clearMessages()

        this.setState({
            data: undefined
        },()=>{
            this.changeHistory(school_year_id, false)
        })
    }

    handleFetch() {
        this.props.clearMessages()

        this.setState({
            fetch_in_progress: true,
        });

        this.props.promise_error_handler((() => {
            if (!this.state.student || (this.state.student && !this.state.school_year)) {
                return CanteenClient.getBalances(this.state.year, this.state.school, this.state.class, this.state.student, this.state.school_year)
            } else {
                return CanteenClient.getInvoices(this.state.year, this.state.school, this.state.class, this.state.student, this.state.school_year)
            }
        })().then(data => {
            this.setState({
                fetch_in_progress: false,
                data: data
            })
        })).then(() => {
            this.setState({
                save_in_progress: false
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.guide ? <div className="page-guide">{this.state.guide}</div> : null}
                <div className="page-subtitle">Egyenleg</div>
                <div className="block">
                    <label className="label label-long">Tanév</label>
                    <select onChange={event => this.handleSchoolYearChange(event.target.value)} value={this.state.school_year}>
                        <option value="" disabled={!this.state.student}>{!this.state.student ? 'Kérem válasszon!' : 'Minden tanév'}</option>
                        { this.state.school_years.map(school_year => <option key={school_year.id} value={school_year.id}>{
                            this.state.student ? school_year.year_name + ' - ' + school_year.school_name : school_year.name}</option>)
                        }
                    </select>
                </div>
                {this.state.popup_help_open ? <PopUp buttons={[
                            {action: this.handleHelpClose, text: 'Ok'}
                        ]}
                        title={'Segítség'}
                        close={this.handleHelpClose}>
                        <div className="page-guide">Segítségnyújtásos szöveg</div>
                    </PopUp> : null}
                { this.state.data ? <React.Fragment>
                    { !this.state.year && !this.state.school && !this.state.class && !this.state.student && !this.state.school_year &&
                        <div className="table-container">
                            <table className="balance modify-table striped">
                                <thead>
                                <tr>
                                    <th>Tanév</th>
                                    <th>Egyenleg (Ft)</th>
                                </tr>
                                </thead>
                                <tbody>
                                { this.state.data.length !== 0 ? this.state.data.map(d =>
                                    <tr>
                                        <td>{ d.name }</td>
                                        <td className={d.sum < 0 ? 'overpayment' : d.sum > 0 ? 'debt' : null}><SeparatedNumberFields readOnly={true} value={d.sum}/></td>
                                    </tr>
                                ) : <tr className="table-empty-indicator">
                                    <td colSpan="2">Nincs adat</td>
                                </tr>}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>Mindösszesen</td>
                                    <td><SeparatedNumberFields readOnly={true} value={ this.state.data.reduce((v, d) => v - -d.sum, 0) }/></td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    }
                    { this.state.year && !this.state.school && !this.state.class && !this.state.student && !this.state.school_year &&
                    <div className="table-container">
                        <table className="balance modify-table striped">
                            <thead>
                            <tr>
                                <th>Iskola</th>
                                <th>Egyenleg (Ft)</th>
                            </tr>
                            </thead>
                            {
                                 this.state.data.length !== 0 ? <React.Fragment>
                                     <tbody>
                                    { this.state.data.map(d =>
                                        <tr>
                                            <td>{ d.name }</td>
                                            <td className={d.sum < 0 ? 'overpayment' : d.sum > 0 ? 'debt' : null}><SeparatedNumberFields readOnly={true} value={d.sum}/></td>
                                        </tr>
                                    ) }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Mindösszesen</td>
                                            <td><SeparatedNumberFields readOnly={true} value={ this.state.data.reduce((v, d) => v - -d.sum, 0) }/></td>
                                        </tr>
                                    </tfoot>
                                 </React.Fragment> : <tbody>
                                    <tr className="table-empty-indicator">
                                        <td colSpan="2">Nincs adat</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    }
                    {this.state.school && !this.state.class && !this.state.student  &&
                    <div className="table-container">
                        <table className="balance modify-table striped">
                            <thead>
                            <tr>
                                <th>Osztály</th>
                                <th>Egyenleg (Ft)</th>
                            </tr>
                            </thead>
                            {
                                 this.state.data.length !== 0 ? <React.Fragment>
                                     <tbody>
                                    { this.state.data.map(d =>
                                        <tr>
                                            <td>{d.class_id ? <Link to={this.props.convertURLToClassURL({...d,school_year_id:this.state.school_year})}>{ d.name }</Link> : d.name}</td>
                                            <td className={d.sum < 0 ? 'overpayment' : d.sum > 0 ? 'debt' : null}><SeparatedNumberFields readOnly={true} value={d.sum}/></td>
                                        </tr>
                                    ) }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Mindösszesen</td>
                                            <td><SeparatedNumberFields readOnly={true} value={ this.state.data.reduce((v, d) => v - -d.sum, 0) }/></td>
                                        </tr>
                                    </tfoot>
                                 </React.Fragment> : <tbody>
                                    <tr className="table-empty-indicator">
                                        <td colSpan="2">Nincs adat</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    }
                    {this.state.school && this.state.class && !this.state.student &&
                    <div className="table-container">
                        <table className="balance modify-table striped">
                            <thead>
                            <tr>
                                <th>Név</th>
                                <th>Egyenleg (Ft)</th>
                            </tr>
                            </thead>
                            {
                                 this.state.data.length !== 0 ? <React.Fragment>
                                     <tbody>
                                    { this.state.data.map(d =>
                                        <tr>
                                            <td>{d.student_id ? <Link to={this.props.convertURLToStudentURL({...d,school_year_id:this.state.school_year})}>{ d.name }</Link> : d.name}</td>
                                            <td className={d.sum < 0 ? 'overpayment' : d.sum > 0 ? 'debt' : null}><SeparatedNumberFields readOnly={true} value={d.sum}/></td>
                                        </tr>
                                    ) }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Mindösszesen</td>
                                            <td><SeparatedNumberFields readOnly={true} value={ this.state.data.reduce((v, d) => v - -d.sum, 0) }/></td>
                                        </tr>
                                    </tfoot>
                                 </React.Fragment> : <tbody>
                                    <tr className="table-empty-indicator">
                                        <td colSpan="2">Nincs adat</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    }
                    { !this.state.year && !this.state.school && !this.state.class && this.state.student && !this.state.school_year &&
                    <div className="table-container">
                        <table className="balance modify-table striped">
                            <thead>
                            <tr>
                                <th>Tanév</th>
                                <th>Egyenleg (Ft)</th>
                            </tr>
                            </thead>
                            {
                                 this.state.data.length !== 0 ? <React.Fragment>
                                     <tbody>
                                    { this.state.data.map(d =>
                                        <tr>
                                            <td>{ d.name }</td>
                                            <td className={d.sum < 0 ? 'overpayment' : d.sum > 0 ? 'debt' : null}><SeparatedNumberFields readOnly={true} value={d.sum}/></td>
                                        </tr>
                                    ) }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>Mindösszesen</td>
                                            <td><SeparatedNumberFields readOnly={true} value={ this.state.data.reduce((v, d) => v - -d.sum, 0) }/></td>
                                        </tr>
                                    </tfoot>
                                 </React.Fragment> : <tbody>
                                    <tr className="table-empty-indicator">
                                        <td colSpan="2">Nincs adat</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    }
                    { this.state.student && ((this.state.school && this.state.class) || (this.state.school_year)) &&
                    <div className="table-container">
                        <table className="balance modify-table striped">
                            <thead>
                            <tr>
                                <th>Típus</th>
                                <th>Időszak</th>
                                <th>Biz.dátum</th>
                                <th>Biz.szám/Szla.szám</th>
                                <th>Tételsz.</th>
                                <th>Referencia</th>
                                <th>Összeg (Ft)</th>
                            </tr>
                            </thead>
                            {
                                this.state.data.length !== 0 ? <React.Fragment>
                                    <tbody>
                                        { this.state.data.map(d =>
                                            <tr>
                                                <td>{ d.type }</td>
                                                <td>{ d.period }</td>
                                                <td><DateField readOnly={true} value={d.invoice_date }/></td>
                                                <td>{ d.invoice_number }</td>
                                                <td>{ d.item_number }</td>
                                                <td>{ d.reference }</td>
                                                <td className={d.sum < 0 ? 'overpayment' : d.sum > 0 ? 'debt' : null}><SeparatedNumberFields readOnly={true} value={d.sum}/></td>
                                            </tr>
                                        ) }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={6}>Mindösszesen</td>
                                            <td><SeparatedNumberFields readOnly={true} value={ this.state.data.reduce((v, d) => v - -d.sum, 0) }/></td>
                                        </tr>
                                    </tfoot>
                                </React.Fragment> : <tbody>
                                    <tr className="table-empty-indicator">
                                        <td colSpan="7">Nincs adat</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    }
                </React.Fragment> : (this.state.fetch_in_progress ? <Loading loading_text=""/> : null)}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    addAction: addAction,
    removeAction: removeAction
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloEgyenlege))
