import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import Iskola from "../components/Iskola"
import LoadingButton from "../components/LoadingButton";
import Loading from "@sapkk/app/components/Loading"


class IskolaKezelesBase extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school_id: this.props.school || '',
            data: undefined,
            save_in_progress: false,
        }
    }

    componentDidMount() {
        if(this.state.school_id){
            if(this.props.history.location.pathname!=='/iskola-kezelese/'+this.state.school_id+'/adatok'){
                this.props.history.replace('/iskola-kezelese/'+this.state.school_id+'/adatok')
            }
        } else{
            if(this.props.history.location.pathname!=='/iskola-kezelese/uj'){
                this.props.history.replace('/iskola-kezelese/uj')
            }
            this.props.setTitle('Új iskola létrehozása')
            this.props.setGuide(null)
        }
        this.handleFetch()
    }

    handleDataChange = (name, value) => {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            },
        }))
    }

    handleSave = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler((() => {
                if (this.state.school_id) {
                    return CanteenClient.editSchool(this.state.school_id,this.state.data.name, this.state.data.abbreviated_name, this.state.data.address, this.state.data.bank_name, this.state.data.bank_account, this.state.data.all_day_canceled, this.state.data.xmenza_code, this.state.data.email,this.state.data.use_calendar, this.state.data.administrator_name, this.state.data.phone_number, this.state.data.meal_request_text, this.state.data.dining_calendar_text, this.state.data.new_financial_center, this.state.data.new_date, this.state.data.docuhub_label_id)
                } else {
                    return CanteenClient.createSchool(this.state.data.name, this.state.data.abbreviated_name, this.state.data.address, this.state.data.bank_name, this.state.data.bank_account, this.state.data.all_day_canceled, this.state.data.xmenza_code, this.state.data.email,this.state.data.use_calendar, this.state.data.administrator_name, this.state.data.phone_number, this.state.data.meal_request_text, this.state.data.dining_calendar_text, this.state.data.docuhub_label_id)
                }
            })()
            .then((result) => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.setState({
                    school_id: result.id,
                    data: {
                        ...result,
                        new_financial_center: '',
                        new_date: ''
                    }
                })
            })).then(()=>{
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handleSchoolChange(school_id) {
        this.setState({
            school_id: school_id,
            data: undefined,
            showed: false
        })
    }

    handleNew() {
        this.setState({
            school_id: "",
            data: {
                name: "",
                abbreviated_name: "",
                address: "",
                bank_name: "",
                bank_account: "",
                all_day_canceled: true,
                xmenza_code: "",
                email: "",
                use_calendar: true,
                administrator_name: '',
                phone_number: '',
                meal_request_text: '',
                dining_calendar_text: ''
            },
            showed: true
        })
    }

    handleFetch() {
        if(this.state.school_id){
            this.setState({
                fetch_in_progress: true,
            },() => {
                this.props.promise_error_handler(CanteenClient.getSchool(this.state.school_id).then(school_data => {
                    this.setState(prevState => ({
                        data: {
                            ...school_data,
                            new_financial_center: '',
                            new_date: ''
                        },
                        fetch_in_progress: false,
                        showed: true
                    }))
                }))
            })
        } else{
            this.handleNew()
        }
    }

    render() {
        let readOnly = !Object.keys(this.props.rights.rights).includes('alkalmazasgazda') && !(this.props.rights.rights['ugyintezo']||[]).includes(''+this.state.school_id) 
        return <React.Fragment>
            {
                this.state.showed ? <React.Fragment>
                    <div className="page-subtitle">Iskola adatai</div>
                    <Iskola
                        handleDataChange={this.handleDataChange}
                        data={this.state.data}
                        readOnly={readOnly}
                        save_in_progress={this.state.save_in_progress}
                    />
                    {!readOnly ? <div className="actions">
                        <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                    </div> : null}
                </React.Fragment> : <Loading loading_text = ''/>
            }
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IskolaKezelesBase))