import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import EtkezesFelveteleTanulohoz from "./EtkezesFelveteleTanulohoz";
import CanteenClient from "../../CanteenClient";
import Loading from "@sapkk/app/components/Loading";
import SelectField from "@sapkk/app/components/SelectField";
import PopUp from "../../components/PopUp";
import { addAction, removeAction } from "../../actions";

class MegrendeltEtkezes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school || null,
            class: this.props.class || null,
            student: this.props.student || null,
            year: this.props.year || null,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+(-1*this.state.student)+'/megrendelt-etkezes'){
            this.props.history.replace('/tanulo-kezelese/T'+(-1*this.state.student)+'/megrendelt-etkezes')
        }
        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
        this.props.promise_error_handler(CanteenClient.getSchoolYearsForStudent(this.state.student).then(school_years => {
            let school_year = (school_years.find(sy => sy.school_id == this.state.school && sy.year_id == this.state.year)||{id: null}).id
            CanteenClient.getRightsForStudent(this.state.student, school_year).then(rights => {
                this.setState((prevState) => ({
                    school_years: school_years,
                    school_year: school_year,
                    rights_for_student: rights,
                    fetch_in_progress: false
                }))
            })
        }))
    }

    componentWillUnmount() {
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    handleSchoolYearChange = (event) => {
        let school_year = this.state.school_years.find(sy => sy.id == event.target.value)||{year_id: null, school_id: null}
        CanteenClient.getRightsForStudent(this.state.student, school_year.id).then(rights => {
            this.setState((prevState) => ({
                school_year: school_year.id,
                year: school_year.year_id,
                school: school_year.school_id,
                rights_for_student: rights,
            }))
        })
    }

    render() {
        if(this.state.fetch_in_progress){
            return <Loading loading_text=""/>
        }
        return <React.Fragment>
            { this.state.popup_help_open && (
                <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                    <p>A <span style={{fontWeight: 'bold'}}>„Megrendelt étkezés”</span> menüpontban a tanulóhoz rendelt havi fizetendő átalány összegét, az étkezési csoportot, és az étkezés térítési díjára vonatkozó támogatás típusát, érvényességét látja a szülő.</p>
                </PopUp>
            ) }
            <div className="block">
                <label className="label label-short">Tanév</label>
                <SelectField onChange={this.handleSchoolYearChange} className={"field field-simple"} value={this.state.school_year} displayValues={this.state.school_years.reduce((prev, curr) => ({
                    ...prev,
                    [curr.id]: curr.year_name + " ("+curr.school_name+")"
                }), {})}>
                    <option value="" disabled={true}>Kérem válasszon!</option>
                    {this.state.school_years.map(sy => <option value={sy.id} />)}
                </SelectField>
            </div>
            <EtkezesFelveteleTanulohoz school_year_data={this.state.school_years.find(sy => sy.id == this.state.school_year) || {}} hide_buttons={!this.props.write_dinings} {...{...this.props,school: this.state.school, year: this.state.year, rights_for_student: this.state.rights_for_student}} handleEditCancel={()=>this.handleEditCancel()} readOnly={true}/>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MegrendeltEtkezes))