import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom"
import Welcome from "./Welcome"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import {
    setPromiseErrorHandler,
    addMessage,
    clearMessages,
    setRights,
    setUserData,
    setContactURL,
    setUserManualURL,
    setPrivacyPolicyURL,
    setSystemMessagesCount,
    addAction,
    removeAction, setPromptCallback
} from "../actions"
import Login from "./Login"
import AuthClient from "@sapkk/auth-client"
import SapkkApp from "@sapkk/app/containers/App"
import CanteenClient from "../CanteenClient"
import Home from "./Home"
import Contact from "./Contact"

import "../static/css/style.css"
import Riportok from './Riportok';
import TanuloKezelese from './TanuloKezelese';
import OsztalyKezelese from './OsztalyKezelese';
import IskolaKezelese from './IskolaKezelese';
import TanuloKereses from './TanuloKezelese/TanuloKereses';
import Iskolak from './Iskolak';
import IskolaKezelesBase from './IskolaKezelesBase';
import TamogatasKezelese from './TamogatasKezelese';
import EtkezesiTipusKezelese from './EtkezesiTipusKezelese';
import TanevAktivalasa from './TanevAktivalasa';
import UjGyerekHozzarendelese from './UjGyerekHozzarendelese';
import IgenylesmezoKezelese from './IgenylesmezoKezelese'
import RendszerUzenetek from './RendszerUzenetek'
import { setActions } from '@sapkk/app/actions'
import PopUp from '../components/PopUp'
import NemregAktivFelhasznalok from './NemregAktivFelhasznalok'
import Szamlazas from './Szamlazas'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: 0,
            freeze: true,
            interval: null,
            interval_fresh: null,
            confirm_promise_data: {
                error: null,
                fn: null
            },
            is_fresh: true
        }
    }

    componentDidMount() {
        this.props.setPromiseErrorHandler(this.promiseErrorHandler)
        this.checkAuth()
        this.isMyVersionFresh()
    }

    checkAuth = ()=>{
        Promise.all([
            CanteenClient.checkAuth().catch(() =>
                AuthClient.checkAuth()
            ),
        ])
        .then(([user_data]) => {
            this.props.setUserData(user_data)
        }).catch(() => {
            this.props.setUserData({ id: 0 })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.clearMessages()
        }
        if (this.props.messages_updated !== prevProps.messages_updated) {
            window.scrollTo(0, 0)
        }
        if(this.props.system_messages_count !== prevProps.system_messages_count){
            if(this.props.system_messages_count === null){
                this.getSystemMessagesCount()
            } else if(this.props.system_messages_count === 0){
                this.props.removeAction('system_message')
            } else {
                this.props.addAction({id: 'system_message', name: '',data:{count: this.props.system_messages_count > 10 ? '9+' : this.props.system_messages_count}, order: -1, onClick: ()=> this.props.history.push('/rendszeruzenetek')})
            }
        }
    }

    componentWillUnmount(){
        clearInterval(this.state.maintenance_interval)
        clearInterval(this.state.interval)
        clearInterval(this.state.interval_fresh)
    }

    getSystemMessagesCount = (callback = ()=>{}) => {
        this.props.promise_error_handler(CanteenClient.getSystemMessagesCount().then(system_messages_count => {
            this.props.setSystemMessagesCount(system_messages_count)
            callback()
        }))
    }

    isMyVersionFresh = ()=>{
        CanteenClient.isMyVersionFresh().then(fresh => {
            if(sessionStorage.getItem('fresh_frontend')){
                sessionStorage.removeItem('fresh_frontend')
                if(!fresh.is_fresh){
                    CanteenClient.registerError('cache-problem').catch(()=>{})
                }
            }
            this.setState({
                is_fresh: fresh.is_fresh
            })
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.user.id !== nextProps.user.id) {
            this.setState({
                freeze: true,
                maintenance_popup_show: false
            })
            clearInterval(this.state.interval)
            clearInterval(this.state.maintenance_interval)
            clearInterval(this.state.interval_fresh)
            if (nextProps.user.id !== this.props.user.id) {
                Promise.all([
                    CanteenClient.getRights().catch(() => {
                        return {
                            rights: {},
                            schools: [],
                            students: [],
                            can_have_children: false
                        }
                    }),
                    CanteenClient.getSupportInfo().catch(() => {
                        return {}
                    })
                ]).then(([rights, support_info]) => {
                    this.getSystemMessagesCount()
                    this.props.setRights(rights.rights, rights.schools, rights.students, rights.can_have_children)
                    this.props.setContactURL(support_info.contact)
                    this.props.setUserManualURL(support_info.user_manual)
                    this.props.setPrivacyPolicyURL(support_info.privacy_policy)
                    this.setState({
                        freeze: false,
                        interval: setInterval(this.getSystemMessagesCount,60000),
                        interval_fresh: setInterval(this.isMyVersionFresh,600000),
                    })
                })
            }
            return false
        }
        return !nextState.freeze
    }

    handleLogout =() => {
        let fn = () => CanteenClient.logout().then(() => {
            this.props.setUserData({ id: 0 })
            this.props.history.push('/welcome')
        })
        if(this.props.prompt_block){
            this.setState({freeze: true}, ()=>{
                this.props.history.push('/welcome')
                this.props.setPromptCallback((ok)=>{
                    if(ok){
                        fn()
                    } else {
                        this.setState({
                            freeze: false
                        })
                    }
                })
            })
        } else{
            fn()
        }
    }

    incrementKey = () => {
        if(this.props.prompt_block){
            this.props.setPromptCallback((ok)=>{this.setState((prevState)=>({
                key: ok ? prevState.key + 1 : prevState.key
            }))})
        } else {
            this.setState((prevState) => ({
                key: prevState.key+ 1
            }))
        }
    }

    promiseErrorHandler = (promise) =>{
        return (typeof promise === 'function' ? promise() : promise).catch(error => {
            if(error.status==503){
                this.setState((prevState)=>({
                    maintenance_popup_show: true,
                    maintenacne_message: error.message,
                    maintenance_interval: setInterval(this.getMaintenanceMessage,200000),
                }))
            } else{
                if(error.code === 999555999){
                    if(typeof promise === 'function'){
                        this.setState({
                            confirm_promise_data: {
                                error: error,
                                fn: promise
                            }
                        })
                    } else {
                        throw error
                    }
                } else {
                    let type = error.type === 'W' ? 'warning' : 'error'
                    if ((error.additional_info || []).length > 0) {
                        error.additional_info.forEach(message =>
                            this.props.addMessage(message, type)
                        )
                    } else {
                        this.props.addMessage(error.message, type)
                    }
                }
            }
        })
    }

    getMaintenanceMessage = ()=>{
        this.props.promise_error_handler(CanteenClient.getMaintenanceMessage().then(result =>{
            this.setState({
                maintenacne_message: result.message,
            },()=>{
                if(!this.state.maintenacne_message){
                    clearInterval(this.state.maintenance_interval)
                    this.setState({
                        maintenance_popup_show: false,
                        maintenance_interval: null,
                    },()=>{
                        window.location.reload()
                    })
                }
            })
        }))
    }

    handlePromiseCancel = () => {
        this.setState({
            confirm_promise_data: {
                error: null,
                fn: null
            }
        })
    }

    handlePromiseRetry = () => {
        CanteenClient.is_confirmed = true
        this.promiseErrorHandler(this.state.confirm_promise_data.fn).then(()=>{
            CanteenClient.is_confirmed = false
            this.handlePromiseCancel()
        })
    }
    freshFrontend = () => {
        sessionStorage.setItem('fresh_frontend', true)
        window.location.reload()
    }

    render() {
        if (this.state.freeze) {
            return null
        }
        let rights = Object.keys(this.props.rights.rights)
        return (
            <React.Fragment>
                <SapkkApp title="UD Menza"
                          onUserChange={this.checkAuth}
                          menuItems={[
                              { to: "/", name: "Kezdőoldal" },
                              this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) ? { to: '/tanulo-kezelese', name: ' Tanulói törzsadatok' } : null,
                              this.props.user.id && rights.length !== 0 ? { to: '/iskola-kezelese', name: 'Iskola kezelése' } : null,
                              this.props.user.id && rights.length > 0 ? { to: '/riportok', name: 'Riportok' } : null
                          ].filter(menu => menu)}
                          onMenuClick={() => this.incrementKey()} logout={() => this.handleLogout()}>
                    {this.state.maintenance_popup_show ? <PopUp buttons={[
                        { action: this.handleLogout, text: 'Kijelentkezés' }
                    ]}
                                                                title={'Karbantartás'}>
                        <div className="page-guide maintenance">{this.state.maintenacne_message}</div>
                    </PopUp> : null}
                    {this.state.confirm_promise_data.error ? <PopUp buttons={[
                        { action: this.handlePromiseRetry, text: 'Megerősítés' },
                        { action: this.handlePromiseCancel, text: 'Mégse' },
                    ]} title={'Megerősítés szükséges'}>
                        <div className="page-guide messages warning">A művelet végrehajtása során az alábbiak megerősítése szükséges: <ul>
                            {this.state.confirm_promise_data.error.additional_info.map(m => <li>{m}</li>)}
                        </ul></div>
                    </PopUp> : null}

                    <Switch key={'content.' + this.state.key}>
                        <Route path='/welcome' exact={true} component={Welcome} />
                        <Route path='/contact' exact={true} component={Contact} />
                        {this.props.user.id && Object.keys(this.props.rights.rights).length === 0 && <Route path='/uj-gyerek-hozzarendelese' component={UjGyerekHozzarendelese} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/tanuloi-adatok' render={()=><TanuloKezelese page="adatok"/>} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/menzanaptar' render={()=><TanuloKezelese page="menzanaptar"/>} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/megrendelt-etkezes' render={()=><TanuloKezelese page="megrendelt-etkezes"/>} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/szamlak' render={()=><TanuloKezelese page="szamlak"/>} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/jelszo-modositasa' render={()=><TanuloKezelese page="jelszo"/>} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/tanuloi-igenylesek' render={()=><TanuloKezelese page="tanuloi-igenylesek/uj/new"/>} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/dokumentumok' render={()=><TanuloKezelese page="dokumentumok"/>} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/menu' render={()=><TanuloKezelese page="menu"/>} />}
                        {this.props.user.id && rights.length > 0 && <Route path='/riportok' component={Riportok} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/tanulo-kezelese/T:id/adatok/modositastortenet/:student_data_id/:school_id' component={TanuloKezelese} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/tanulo-kezelese/T:id/adatok/:tab' component={TanuloKezelese} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/tanulo-kezelese/T:id/:mode/:school_year_id' component={TanuloKezelese} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/tanulo-kezelese/T:id' component={TanuloKezelese} />}
                        {this.props.user.id && !(Object.keys(this.props.rights.rights).length === 0 && this.props.rights.students.length === 0) && <Route path='/tanulo-kezelese' component={TanuloKereses} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/osztaly-kezelese/:id/menzanaptar/:school_year_id' component={OsztalyKezelese} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/osztaly-kezelese/:id/:mode/:year_id' component={OsztalyKezelese} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/osztaly-kezelese/:id' component={OsztalyKezelese} />}
                        {this.props.user.id && rights.includes('alkalmazasgazda') && rights.length !== 0 && <Route path='/iskola-kezelese/uj' render={() => <IskolaKezelesBase />} />}
                        {this.props.user.id && rights.includes('alkalmazasgazda') && rights.length !== 0 && <Route path='/tanev-aktivalasa' component={TanevAktivalasa} />}
                        {this.props.user.id && rights.includes('alkalmazasgazda') && rights.length !== 0 && <Route path='/etkezesi-tipus-kezelese' component={EtkezesiTipusKezelese} />}
                        {this.props.user.id && rights.includes('alkalmazasgazda') && rights.length !== 0 && <Route path='/igenylesmezok-kezelese' component={IgenylesmezoKezelese} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/iskola-kezelese/:id/tanevek/:school_year_id' component={IskolaKezelese} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/iskola-kezelese/:id/menzanaptar/:school_year_id' component={IskolaKezelese} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/iskola-kezelese/:id/:mode/:year_id' component={IskolaKezelese} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/iskola-kezelese/:id' component={IskolaKezelese} />}
                        {this.props.user.id && rights.length !== 0 && <Route path='/iskola-kezelese' component={Iskolak} />}
                        {this.props.user.id && rights.includes('alkalmazasgazda') && <Route path='/tamogatas-kezelese' component={TamogatasKezelese} />}
                        {this.props.user.id && (rights.includes('alkalmazasgazda') /*|| rights.includes('penzugyi_ugyintezo')*/) && <Route path='/szamlazas' component={Szamlazas} />}
                        {this.props.user.id && rights.includes('alkalmazasgazda') && <Route path='/nemregiben-aktiv-felhasznalok' component={NemregAktivFelhasznalok} />}
                        {this.props.user.id && <Route path='/rendszeruzenetek' render={()=><RendszerUzenetek key={this.state.system_messages_count} getSystemMessagesCount={this.getSystemMessagesCount}/>} />}
                        {this.props.user.id && <Route path='*' component={Home} />}
                        {!this.props.user.id && <Route path='/' exact={true} component={Welcome} />}
                        {!this.props.user.id && <Route path='*' component={Login} />}
                    </Switch>
                </SapkkApp>
                {!this.state.is_fresh ? <div className={"old-frontend-version-container"}>
                    <div className={"old-frontend-version-content"}>Az UD Menza rendszer egy korábbi verzióját nyitotta meg. A rendszer megfelelő használatához kérem, hogy <a href="" onClick={this.freshFrontend}>frissítse az oldalt.</a></div>
                </div> : null}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        user: state.user,
        rights: state.rights,
        page: state.page,
        messages_updated: state.messages.updated,
        messages: state.messages.messages,
        support_info: state.support_info,
        system_messages_count: state.system_messages.count,
        prompt_block: state.prompt_info.block
    }
}

const mapDispatchToProps = {
    setPromiseErrorHandler: setPromiseErrorHandler,
    setUserData: setUserData,
    setRights: setRights,
    addMessage: addMessage,
    clearMessages: clearMessages,
    setContactURL: setContactURL,
    setUserManualURL: setUserManualURL,
    setPrivacyPolicyURL: setPrivacyPolicyURL,
    setSystemMessagesCount: setSystemMessagesCount,
    setActions: setActions,
    addAction: addAction,
    removeAction: removeAction,
    setPromptCallback: setPromptCallback
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
