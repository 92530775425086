import React, { Component } from 'react'
import { setGuide, setTitle} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import MenzanaptarBase from './Menzanaptar';
import OsztalyAdataiBase from './OsztalyAdataiBase';
import Tanulok from './Tanulok';
import TanuloEgyenlegeBase from './TanuloEgyenlegeBase';
import Loading from '@sapkk/app/components/Loading';
import ContentWithSideMenu from './ContentWithSideMenu'

class OsztalyKezelese extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            year_id: this.props.match.params.year_id,
            key: 0,
            loaded: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    static getDerivedStateFromProps(props, state) {
        if(props.match.params.id !== state.id){
            return {
                key: state.key + 1,
                id: props.match.params.id,
                year_id: props.match.params.year_id,
                loaded: false
            }
        } else if(props.match.params.year_id !== state.year_id){
            return {
                key: state.key + 1,
                year_id: props.match.params.year_id
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.id !==this.state.id){
            this.loadData()
        }
    }

    loadData = ()=>{
        this.setState({
            id: this.props.match.params.id,
        }, ()=>{
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getRightsForClass(this.props.match.params.id),
                CanteenClient.getSchoolClass(this.props.match.params.id)
            ]).then(([rights,school_class]) => {
                this.props.promise_error_handler(CanteenClient.getSchool(school_class.school_id).then((school)=>{
                    this.props.setTitle(school_class.name+ " osztály adatai")
                    this.setState({
                        rights: rights,
                        school: school,
                        school_class: school_class,
                        loaded: true
                    })    
                }))
            })).then(() => {
                this.props.setTitle(this.state.school_class ? this.state.school_class.name+' osztály adatai' : "Osztály adatai")
            })    
        })
    }

    convertURLToSchoolURL = (row={}) => {
        switch (this.props.history.location.pathname) {
            case '/osztaly-kezelese/'+this.state.id+'/adatok':
                return '/iskola-kezelese/'+this.state.school.id+'/adatok'
            case '/osztaly-kezelese/'+this.state.id+'/tanulok/'+this.state.year_id:
                return '/iskola-kezelese/'+this.state.school.id+'/tanulok/'+this.state.year_id
            default:
                if(this.props.history.location.pathname.indexOf('/osztaly-kezelese/' + this.state.id + '/menzanaptar')===0){
                    return  '/iskola-kezelese/'+this.state.school.id+'/menzanaptar'
                }
                if(this.props.history.location.pathname.indexOf('/osztaly-kezelese/' + this.state.id + '/gy2-egyenleg')===0){
                    return  '/iskola-kezelese/'+this.state.school.id+'/gy2-egyenleg'+(row.school_year_id ? '/'+row.school_year_id : '')
                }
                return '/iskola-kezelese/'+this.state.school.id
        }
    }

    convertURLToStudentURL = (row) => {
        switch (this.props.history.location.pathname) {
            case '/osztaly-kezelese/'+this.state.id+'/adatok':
                return '/tanulo-kezelese/T'+(-1*row.student_id)
            case '/osztaly-kezelese/'+this.state.id+'/tanulok/'+this.state.year_id:
                return '/tanulo-kezelese/T'+(-1*row.student_id)+'/adatok'
            case '/osztaly-kezelese/'+this.state.id+'/gy2-egyenleg':
                return '/tanulo-kezelese/T'+(-1*row.student_id)+'/gy2-egyenleg'
            default:
                if(this.props.history.location.pathname.indexOf('/osztaly-kezelese/' + this.state.id + '/menzanaptar')===0){
                    return '/tanulo-kezelese/T'+(-1*row.student_id)+'/menzanaptar'
                }
                if(this.props.history.location.pathname.indexOf('/osztaly-kezelese/' + this.state.id + '/gy2-egyenleg')===0){
                    return '/tanulo-kezelese/T'+(-1*row.student_id)+'/gy2-egyenleg'+(row.school_year_id ? '/'+row.school_year_id : '')
                }
                return '/tanulo-kezelese/T'+(-1*row.student_id)
        }
    }

    render() {
        let menus = this.state.loaded ? [
            this.state.rights.includes('READ_DATA') ? {id:1, url:'/osztaly-kezelese/'+this.state.id+'/adatok', name:'Adatok', render: <OsztalyAdataiBase school={this.state.school.id} class={this.state.school_class.id}/>} : null,
            this.state.rights.includes('MEAL_CANCELLATION_READ') ? {id:2, url:'/osztaly-kezelese/'+this.state.id+'/menzanaptar', name:'Menzanaptár', render: <MenzanaptarBase school_year={this.props.match.params.school_year_id} school={this.state.school.id} class={this.state.school_class.id}/>} : null,
            this.state.rights.includes('READ_DATA') ? {id:3, url:'/osztaly-kezelese/'+this.state.id+'/tanulok', name:'Tanulók', render: <Tanulok school={this.state.school.id} class={this.state.school_class.id} year={this.state.year_id}/>} : null,
            this.state.rights.includes('READ_GY2_BALANCES') ? {id:4, url:'/osztaly-kezelese/'+this.state.id+'/gy2-egyenleg', name:'Gyermekétkeztetésbeli egyenleg', render: <TanuloEgyenlegeBase school_year={this.props.match.params.school_year_id} convertURLToStudentURL={this.convertURLToStudentURL} school={this.state.school.id} class={this.state.school_class.id} year={this.state.year_id}/>} : null,
        ].filter(menu_data => menu_data) : []

        let menu_objects = [
            {title:'Törzsadatok', menu_elements: menus, with_route: true, active_method: (menu_data)=>menu_data.url == this.props.history.location.pathname || this.props.history.location.pathname.indexOf(menu_data.url+'/')===0}
        ]
        return <React.Fragment>
            {
                this.state.loaded ? <ContentWithSideMenu key={this.state.keys} menus={menu_objects} default_path={'/osztaly-kezelese/'+this.state.id} url_base={'osztaly-kezelese'}>
                        <div className="block">
                            <div className="page-subtitle">Aktuális osztály</div>
                            <ul className="actual-selector">
                                <li><span><Link to={this.convertURLToSchoolURL()}>{this.state.school.abbreviated_name}</Link></span></li>
                                <li><span>{this.state.school_class.name}</span></li>
                            </ul>
                        </div>
                </ContentWithSideMenu> : <Loading loading_text=""/>
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OsztalyKezelese))
