import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import LoadingButton from "../../components/LoadingButton";
import Loading from "@sapkk/app/components/Loading"

class TamogatoKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            schools: [],
            providers: [],
            fetch_in_progress: true,
            save_in_progress: false
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tamogatas-kezelese/tamogatok'){
            this.props.history.replace('/tamogatas-kezelese/tamogatok')
        }
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getSchools('WRITE'),
            CanteenClient.getReimbursementProviders(['id','name','xmenza_code_all']),
        ]).then(([schools,providers]) => {
            this.setState({
                schools: schools,
                providers: providers,
                fetch_in_progress: false
            })
        }))
    }

    handleAddLine() {
        this.setState((prevState, props) => ({
            providers: prevState.providers.concat({name: "", xmenza_codes: this.state.schools.reduce((prev, curr) => ({
                ...prev,
                [curr.id] : ''
            }),{})})
        }))
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            providers: prevState.providers.slice(0, id).concat(prevState.providers.slice(id-(-1)))
        }))
    }

    handleRowChange(id, parent, name, value) {
        this.setState((prevState, props) => ({
            providers: prevState.providers.slice(0, id)
                .concat([{...prevState.providers[id], ...(parent===null ? {[name]: value} : {[parent] : {
                    ...prevState.providers[id][parent],
                    [name] : value
                }})}])
                .concat(prevState.providers.slice(id-(-1)))
        }))
    }

    handleSave =() => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.editReimbursementProviders(this.state.providers).then(providers => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.setState({
                    providers: providers
                })
            }))
        })
    }

    render() {
        return <React.Fragment>
                <div className="page-subtitle">Támogatók</div>
                { this.state.fetch_in_progress ? <Loading loading_text=''/> : <React.Fragment><div className="table-container">
                    <table className="workday-overwrite striped modify-table">
                        <thead>
                            <tr>
                                <th rowSpan='2'>Név</th>
                                <th colSpan={Math.max(this.state.schools.length,1)}>xMenza-kód</th>
                            </tr><tr>
                                {
                                    this.state.schools.length ? this.state.schools.map(school => <th title={school.name}>{school.abbreviated_name}</th>) : <th>Nincs iskola</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.schools.length ? <React.Fragment>
                                                            { this.state.providers.map((data, id) =>
                            <tr key={id}>
                                <td><SimpleField value={ data.name } onChange={event => this.handleRowChange(id, null, 'name', event.target.value)}/></td>
                                {
                                    this.state.schools.map(school => <td><SimpleField value={ data.xmenza_codes[school.id] || ''} onChange={event => this.handleRowChange(id, 'xmenza_codes', school.id, event.target.value)}/></td>)
                                }
                                <td className="col-actions">{!data.id ? <button onClick={() => this.handleRemoveLine(id)}/> : null}</td>
                            </tr>
                        ) }
                            <tr className="table-new-row">
                                <td colSpan={this.state.schools.length + 1}>{this.state.providers.length === 0 && "Nincs megadva típus."}</td>
                                <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td>
                            </tr>
                                </React.Fragment> : <tr className="table-empty-indicator"><td colSpan={2}>Nincs iskola megadva. Először vegyen fel egy iskolát.</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="actions">
                    <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                </div>
            </React.Fragment>}
            </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TamogatoKezelese))
