import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../components/LoadingButton";
import SeparatedNumberField from "@sapkk/app/components/SeparatedNumberFields"
import SelectField from "@sapkk/app/components/SelectField"

class IgenylesmezoKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: [],
            save_in_progress: false,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.listMealRequestFields(),
        ]).then(([fields]) => {
            this.setState({
                fields: fields,
                fetch_in_progress: false
            })
        }))
    }

    handleAddLine() {
        this.setState((prevState, props) => ({
            fields: prevState.fields.concat({name: "", alias: "", description: "", required: false, multiple: false, type: "", default_length: "", cancelling_alias: null, user_can_modify: true, reversed: false})
        }))
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            fields: prevState.fields.slice(0, id).concat(prevState.fields.slice(id-(-1)))
        }))
    }

    handleRowChange(id, name, value) {
        this.setState((prevState, props) => ({
            fields: prevState.fields.slice(0, id).concat([{...prevState.fields[id], [name]: value}]).concat(prevState.fields.slice(id-(-1)))
        }))
    }

    handleSave = ()=> {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.editMealRequestFields(this.state.fields).then(result => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.setState({
                    fields: result
                })
            })).then(()=>{
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    render() {
        return <React.Fragment>
            {!this.state.fetch_in_progress ?<React.Fragment>
                <div className="page-subtitle">Igénylőlapon használható mezők</div>
                <div className="table-container">
                    <table className="striped modify-table meal-request-fields">
                        <thead>
                            <tr>
                                <th>Technikai név</th>
                                <th>Megjelenítendő név / szöveg</th>
                                <th>Leírás</th>
                                <th>Kötelezőség</th>
                                <th>Többszörösség</th>
                                <th>Típus</th>
                                <th>Alapértelmezett méret</th>
                                <th>Fordított megjelenítés</th>
                                <th>Lemondás esetén megjelenítendő név / szöveg</th>
                                <th className="col-actions"/>
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.fields.map((data, id) =>{
                            return <tr key={id}>
                                <td><SimpleField value={data.name} readOnly={!!data.id} onChange={event => this.handleRowChange(id, 'name', event.target.value)}/></td>
                                <td><SimpleField value={data.alias} onChange={event => this.handleRowChange(id, 'alias', event.target.value)}/></td>
                                <td><SimpleField value={data.description} onChange={event => this.handleRowChange(id, 'description', event.target.value)}/></td>
                                <td><input checked={data.required} disabled={!!data.id} type="checkbox" onChange={event => this.handleRowChange(id, 'required', event.target.checked)}/></td>
                                <td><input checked={data.multiple} disabled={!!data.id} type="checkbox" onChange={event => this.handleRowChange(id, 'multiple', event.target.checked)}/></td>
                                <td>
                                    <SelectField readOnly={!!data.id}  value={ data.type } onChange={event => this.handleRowChange(id, 'type', event.target.value)} displayValues={Object.keys(this.props.field_types).reduce((prev, curr) => ({
                                        ...prev,
                                        [curr]: this.props.field_types[curr].name
                                    }),{})}>
                                        <option value="">Kérem válasszon!</option>
                                        {Object.keys(this.props.field_types).map(type => <option value={type}/>)}
                                    </SelectField>
                                </td>
                                <td>{(data.type=='STRING' || data.type=='DATE') ? <SeparatedNumberField value={data.default_length} type="number" onChange={event => this.handleRowChange(id, 'default_length', event.target.value)}/>: null}</td>
                                <td>{(data.type=='BOOL') ? <input checked={data.reversed} type="checkbox" onChange={event => this.handleRowChange(id, 'reversed', event.target.checked)}/>: null}</td>
                                <td><SimpleField value={data.cancelling_alias} onChange={event => this.handleRowChange(id, 'cancelling_alias', event.target.value)}/></td>
                                <td className="col-actions">{data.user_can_modify ? <button className="remove" onClick={() => this.handleRemoveLine(id)}/> : null}</td>
                            </tr>}
                        ) }
                            <tr className="table-new-row">
                                <td colSpan={9}>{this.state.fields.length === 0 && "Nincs megadva mező."}</td>
                                <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="actions">
                    <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                </div>
            </React.Fragment> : <Loading loading_text=''/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        field_types: state.field_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IgenylesmezoKezelese))