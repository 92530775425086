import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import LoadingButton from "../components/LoadingButton";

class EtkezesiTipusKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            meals: [],
            xmenza_codes: [],
            data: {
                name: "",
                xmenza_code:"",
                class_type: ""
            },
            save_in_progress: false,
            meal_id: ""
        }
    }

    componentDidMount() {
        this.props.setTitle("Étkezési típus")
        this.props.setGuide(null)

        this.props.promise_error_handler(Promise.all([
            CanteenClient.getMeals(),
            CanteenClient.getMealXMeanzaCodes()
        ]).then(([meals,xmenza_codes]) => {
            this.setState({
                xmenza_codes: xmenza_codes,
                meals: meals,
            })
        }))
    }

    handleNew() {
        this.setState({
            meal_id: "",
            data: {
                name: "",
                xmenza_code:"",
                class_type: ""
            },
            showed: true,
        })
    }

    handleFetch() {
        this.setState({
            fetching_meal: true,
            showed: false
        }, ()=>{
            this.props.promise_error_handler(CanteenClient.getMeal(this.state.meal_id).then((meal) => {
                this.setState({
                    data: meal,
                    fetching_meal: false,
                    showed: true
                })
            }))
        })
    }

    handleMealChange(meal_id) {
        this.setState({
            meal_id: meal_id,
            showed: false
        })
    }

    handleDataChange(name, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            },
        }))
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler((() => {
                if (!this.state.meal_id) {
                    return CanteenClient.createMeal(this.state.data.name,this.state.data.xmenza_code, this.state.data.class_type)
                } else {
                    return CanteenClient.editMeal(this.state.meal_id, this.state.data.name,this.state.data.xmenza_code, this.state.data.class_type)
                }
            })()
            .then((meal) => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.setState(prevState => ({
                    data: prevState.meal_id ? meal : {
                        name: "",
                        xmenza_code:"",
                        class_type: ""
                    }
                }))
            })).then(() => {
                this.props.promise_error_handler(CanteenClient.getMeals().then(meals => {
                    this.setState({
                        save_in_progress: false,
                        meals: meals
                    })
                }))
            })
        })
    }

    handleDelete() {
        this.props.clearMessages()

        this.setState({
            delete_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.deleteMeal(this.state.meal_id).then((meals) => {
                this.props.addMessage('A törlés megtörtént.', 'success')
                this.setState(prevState => ({
                    data:  {
                        name: "",
                        xmenza_code:"",
                        class_type: ""
                    },
                    meal_id: '',
                    meals: meals,
                    delete_in_progress: false
                }))
            }).catch(e => {
                this.setState({
                    delete_in_progress : false
                })
                throw e
            }))
        })
    }


    render() {
        return <React.Fragment>
            <div className="page-subtitle">Étkezés kiválasztása</div>
            <div className="entity-select-block">
                <div>
                    <div className="block block-lg-1-1">
                        <select className="field field-simple" value={this.state.meal_id} onChange={event => this.handleMealChange(event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            { this.state.meals.map(meal => <option value={meal.id}>{ meal.name + (meal.class_type ? ' ('+((this.props.class_types[meal.class_type] || {}).name || 'Ismeretlen osztálytípus') + ')' : '') }</option>) }
                        </select>
                    </div>
                </div>
                <div>
                    <LoadingButton loading={this.state.fetching_meal} disabled={!this.state.meal_id} onClick={() => this.handleFetch()}>Lekérdez</LoadingButton>
                    <button onClick={() => this.handleNew()}>Új</button>
                </div>
            </div>
            {
                this.state.showed && <React.Fragment>
                    <div className="page-subtitle">Étkezések kezelése</div>
                    <div className="block block-lg-1-1">
                        <label className="label label-short">Név</label>
                        <SimpleField className="field field-simple" value={this.state.data.name} onChange={event => this.handleDataChange('name', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-1">
                        <label className="label label-short" title='Korábbi xMenza kód'>Kód</label>
                        <select className="field field-simple" value={this.state.data.xmenza_code} onChange={event => this.handleDataChange('xmenza_code', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {Object.keys(this.state.xmenza_codes).map(xmenza_code => <option value={xmenza_code}>{xmenza_code}</option>)}
                        </select>
                    </div>
                    <div className="block block-lg-1-1">
                        <label className="label label-short">Osztálytípus</label>
                        <select className="field field-simple" value={this.state.data.class_type} onChange={event => this.handleDataChange('class_type', event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {Object.keys(this.props.class_types).map(type => <option value={type}>{this.props.class_types[type].name}</option>)}
                        </select>
                    </div>
                    <div className="actions">
                        <LoadingButton loading={this.state.save_in_progress} disabled={this.state.delete_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton>
                        {this.state.meal_id ? <LoadingButton disabled={this.state.save_in_progress} loading={this.state.delete_in_progress} onClick={() => this.handleDelete()}>Törlés</LoadingButton> : null}
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        class_types: state.class_types,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EtkezesiTipusKezelese))