import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import {withRouter} from "react-router"
import SelectField from "@sapkk/app/components/SelectField";
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import Menunaptar from "../Menunaptar";
import {getDefaultPeriod} from "../../functions";
import {month_names} from "../../constants";
import PopUp from "../../components/PopUp";
import NavigationLock from "../../components/NavigationLock";

class EtkezesiCsoportMenuk extends Component {

    file_input = React.createRef()
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            school_year: null,
            meal_group: null,
            period: null,
            school_years: [],
            meal_groups: [],
            periods: [],
            in_progress: 'fetch',
            meal_group_menu: null,
            file_input_key: 0,
            hash: null,
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getSchoolYearsBySchoolID(this.state.school),
            CanteenClient.getMealGroupsForSchool(this.state.school, true)
        ]).then(([school_years, meal_groups]) => {
            let actual_school_year = school_years.find(sy => this.props?.history?.location?.state?.school_year_id !== undefined ? sy.id === this.props?.history?.location?.state.school_year_id : sy.default)
            let actual_meal_group = meal_groups.length === 1 ? meal_groups[0].id : (this.props?.history?.location?.state?.meal_group_id !== undefined ? this.props?.history?.location?.state.meal_group_id : null)
            this.setState({
                school_years: school_years,
                meal_groups: meal_groups,
                meal_group: actual_meal_group,
                school_year: actual_school_year ? actual_school_year.id : null,
                in_progress: 'period_fetch'
            },()=>{
                this.handleSchoolYearChange((actual_school_year||{}).id || '', this.props?.history?.location?.state?.school_year_id !== undefined )
            })
        }))
    }


    handleSchoolYearChange = (school_year_id, from_state = false) => {
        this.setState({
            in_progress: 'period_fetch'
        },()=>{
            this.props.promise_error_handler((school_year_id ? CanteenClient.listPeriods({school_year_id: [{operator: 9, value: school_year_id}]}) : new Promise((resolve, reject) => resolve([]))).then(periods => {
                this.setState({
                    school_year: school_year_id,
                    periods: periods,
                    period:  null,
                    original_periods: periods,
                    meal_group_menu: null,
                }, () => {
                    this.handleChange('period', from_state ? periods.findIndex(p => p.year + '-' + (''+p.month).padStart(2, '0') === this.props?.history?.location?.state.period) : getDefaultPeriod(periods))
                })
            }))
        })
    }

    handleChange = (name, value) => {
        if(!this.state.popup_page &&  this.state.meal_group_menu && !this.state.meal_group_menu.finalized){
            this.setState({
                popup_show: true,
                popup_page: {
                    key: name,
                    value: value
                }
            })
        } else {
            this.setState((prevState) => ({
                [name]: value,
                popup_show: false,
                popup_page: null,
                in_progress: (name === 'period' ? value : prevState.period) && (name === 'meal_group' ? value : prevState.meal_group) ? 'meal_group_menu' : null,
                meal_group_menu: !!value || value === 0 ? prevState.meal_group_menu : null,
            }), () => {
                if ((this.state.period || this.state.period === 0) && this.state.meal_group) {
                    let period = this.state.periods[this.state.period]
                    Promise.all([
                        CanteenClient.findMenuForPeriod(this.state.meal_group, period.year, period.month),
                        CanteenClient.getAcceptableDates(this.state.school_year, period.year, period.month),
                    ]).then(([meal_group_menu, acceptable_dates]) => {
                        this.setState({
                            meal_group_menu: meal_group_menu,
                            acceptable_dates: acceptable_dates.map(ad => ad.date),
                            in_progress: null
                        })
                    })
                }
            })
        }
    }

    uploadFile() {
        if (this.file_input.current.files.length === 1) {
            this.setState({
                n_progress: 'upload'
            },()=>{
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0]).then(hash => {
                    this.setState(prevState => ({
                        hash: hash,
                        in_progress: null
                    }))
                }).catch(error => {
                    this.setState({
                        in_progress: null
                    })
                    this.props.clearMessages()
                    throw error
                }))
            })
        }
    }

    handleCreate = ()=> {
        this.props.clearMessages()
        this.setState({
            in_progress: 'create'
        }, ()=> {
            this.props.promise_error_handler(()=>CanteenClient.createMenuFromFile(this.state.school_year, this.state.meal_group, this.state.periods[this.state.period].year, this.state.periods[this.state.period].month, this.state.hash).then(meal_group_menu => {
                this.props.addMessage('Sikeres létrehozás.', 'success')
                this.setState((prevState) => ({
                    in_progress: null,
                    meal_group_menu: meal_group_menu,
                    file_input_key: prevState.file_input_key + 1
                }))
            }).catch(error => {
                this.setState({
                    in_progress: null
                })
                this.props.clearMessages()
                throw error
            }))
        })
    }

    handleClose = () => {
        this.props.clearMessages()
        this.setState({
            in_progress: 'close'
        }, ()=> {
            this.props.promise_error_handler(()=>CanteenClient.closeMenuForPeriod(this.state.meal_group_menu.id).then(result => {
                this.props.addMessage('Sikeres lezárás.', 'success');
                (result.messages || []).forEach(message => {
                    this.props.addMessage(message, 'warning')
                })
                this.setState({
                    meal_group_menu: result.menu_for_period,
                    in_progress: null,
                    popup_close_open: false
                })
            })).then(()=>this.setState({
                in_progress: null,
                popup_close_open: false
            }))
        })
    }
    handleDelete = () => {
        this.props.clearMessages()
        this.setState({
            in_progress: 'delete'
        }, ()=> {
            this.props.promise_error_handler(()=>CanteenClient.deleteMenuForPeriod(this.state.meal_group_menu.id).then(result => {
                this.props.addMessage('Sikeres törlés.', 'success');
                this.setState({
                    meal_group_menu: result,
                    in_progress: null
                })
            })).then(()=>this.setState({
                in_progress: null
            }))
        })
    }
    handleSave = () => {
        this.props.clearMessages()
        this.setState({
            in_progress: 'save'
        }, ()=> {
            this.props.promise_error_handler(()=>CanteenClient.editMenuItemForPeriodDays(this.state.meal_group_menu.id, this.state.meal_group_menu.menu_item_for_period_days).then(meal_group_menu => {
                this.props.addMessage('Sikeres mentés.', 'success')
                this.setState({
                    meal_group_menu: meal_group_menu,
                    in_progress: null
                })
            })).then(()=>this.setState({
                in_progress: null
            }))
        })
    }

    handlePaging = (direction) => {
        this.handleChange('period', this.state.period + direction)
    }

    handleDownload = () => {
        this.setState({
            in_progress: 'download'
        }, () => {
            this.props.promise_error_handler(CanteenClient.getMenuForPeriodPDF(this.state.meal_group_menu.id).then((hash) => {
                return FilestoreClient.download(hash)
            })).then(()=>{
                this.setState({
                    in_progress: null
                })
            })
        })
    }

    handleOnChange = (date, menu_item_id, name, value) => {
        this.setState((prevState) => ({
            meal_group_menu: {
                ...prevState.meal_group_menu,
                menu_item_for_period_days: {
                    ...prevState.meal_group_menu.menu_item_for_period_days,
                    [date]: {
                        ...prevState.meal_group_menu.menu_item_for_period_days?.[date]||{},
                        [menu_item_id]:{
                            ...prevState.meal_group_menu.menu_item_for_period_days?.[date]?.[menu_item_id]||{},
                            [name]: value
                        }
                    }
                }
            }
        }))
    }

    isDisabled = (date, menu_item_id, can_edit) => {
        if(!can_edit){
            return true
        }
        return !this.state.acceptable_dates.includes(date);

    }

    handleChangeCancel = () => {
        this.setState({
            popup_show: false
        })
    }

    handleClosePopupOpen = () => {
        this.setState({
            popup_close_open: true
        })
    }

    handleClosePopupClose = () => {
        this.setState({
            popup_close_open: false
        })
    }

    render() {
        let date_parts = (new Date().toISOString().split('T')[0]).split('-')
        let can_edit = this.state.meal_group_menu?.finalized ? this.props.rights_for_school.includes('WRITE_MEAL_GROUP_MENU') : this.props.rights_for_school.includes('CREATE_MENU_PERIOD')
        let can_delete = this.state.meal_group_menu?.finalized ? false : this.props.rights_for_school.includes('WRITE_MEAL_GROUP_MENU')
        return <React.Fragment>
            {this.state.in_progress !== 'fetch' ?<React.Fragment>
                {this.state.popup_close_open ? <PopUp title={'Megerősítés'} close={this.handleClosePopupClose}  buttons={[{action: this.handleClose, text: 'Ok', loading: this.state.in_progress === 'close', disabled: !!this.state.in_progress},{action: this.handleClosePopupClose, text: 'Mégse', disabled: !!this.state.in_progress}]}>
                    <div className="block">
                        Valóban lezárja az étlapot?
                    </div>
                </PopUp> : null}
                <div className="page-subtitle">Menük</div>
                <div className="block">
                    <label className="label label-long">Étkezési csoport</label>
                    <SelectField readOnly={this.state.in_progress === 'period_fetch'} onChange={(event) =>this.handleChange('meal_group',event.target.value)} className={"field field-simple"} value={this.state.meal_group} displayValues={this.state.meal_groups.reduce((prev, curr) => ({
                        ...prev,
                        [curr.id]: curr.name
                    }), {})}>
                        <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                        {this.state.meal_groups.map(mg => <option value={mg.id} />)}
                    </SelectField>
                </div>
                <div className="block">
                    <label className="label label-long">Tanév</label>
                    <SelectField readOnly={this.state.in_progress === 'period_fetch'} onChange={(event) =>this.handleSchoolYearChange(event.target.value)} className={"field field-simple"} value={this.state.school_year} displayValues={this.state.school_years.reduce((prev, curr) => ({
                        ...prev,
                        [curr.id]: (curr.name || curr.year_name)+ (curr.school_name ? " ("+curr.school_name+")" : '')
                    }), {})}>
                        <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                        {this.state.school_years.map(sy => <option value={sy.id} />)}
                    </SelectField>
                </div>
                {this.state.in_progress === 'period_fetch' ? <Loading loading_text=''/> :
                    <div className="calendar-pager">
                        <button disabled={this.state.period === 0} onClick={() => this.handlePaging(-1)}>&lt;&lt;</button>
                        <div>{this.state.periods[this.state.period].year + '. ' + month_names[this.state.periods[this.state.period].month - 1]}</div>
                        <button disabled={this.state.period === this.state.periods.length - 1} onClick={() => this.handlePaging(+1)}>&gt;&gt;</button>
                    </div>}
                {this.state.meal_group && (this.state.period || this.state.period === 0) ? <React.Fragment>
                    {
                        this.state.in_progress === 'meal_group_menu' ? <Loading/> : <React.Fragment>
                            {
                                this.state.meal_group_menu ? <React.Fragment>
                                    {this.state.popup_show ? <PopUp title={'Megerősítés'} close={this.handleChangeCancel} buttons={[{action: ()=>this.handleChange(this.state.popup_page.key, this.state.popup_page.value), text: 'Igen'},{action: this.handleChangeCancel, text: 'Mégse'}]}>
                                        <div className="block">
                                            Az étlap nem került véglegesítésre. Valóban kilép?
                                        </div>
                                    </PopUp> : null}
                                    <NavigationLock
                                        when={!this.state.meal_group_menu.finalized}
                                        message={'Az étlap nem került véglegesítésre. Valóban kilép?'}
                                    />
                                    {!this.state.meal_group_menu.finalized ? <div className={"not-authenticated"}>Nincs véglegesítve</div> : null}
                                    <Menunaptar
                                        meal_group = {this.state.meal_groups.find(mg => mg.id === parseInt(this.state.meal_group))}
                                        menu_item_for_period = {this.state.meal_group_menu}
                                        created = {this.state.meal_group_menu.finalized}
                                        closed = {this.state.meal_group_menu.closed}
                                        handlePaging = {this.state.handlePaging}
                                        period = {this.state.period}
                                        periods = {this.state.periods}
                                        handleOnChange = {can_edit ? this.handleOnChange : undefined}
                                        isDisabled = {(date, menu_item_id) => this.isDisabled(date, menu_item_id, can_edit)}
                                    />
                                    <div className={"actions"}>
                                        {can_edit ? <LoadingButton loading ={this.state.in_progress === 'save'}  disabled={this.state.in_progress} onClick={this.handleSave}>Mentés és generálás</LoadingButton> : null}
                                        {can_delete ? <LoadingButton loading ={this.state.in_progress === 'delete'}  disabled={this.state.in_progress} onClick={this.handleDelete}>Törlés</LoadingButton> : null}
                                        {!this.state.meal_group_menu.closed && this.state.meal_group_menu.finalized ? <LoadingButton loading={this.state.in_progress === 'close'} disabled={this.state.in_progress} onClick={this.handleClosePopupOpen}>Lezárás</LoadingButton> :null}
                                        <LoadingButton loading={this.state.in_progress === 'download'} disabled={this.state.in_progress} onClick={this.handleDownload}>Letöltés</LoadingButton>
                                    </div>
                                </React.Fragment>: null
                            }
                            {
                                !this.state.meal_group_menu ? (this.state.periods[this.state.period].year + '-' + (this.state.periods[this.state.period].month + '').padStart(2, '0')  > date_parts[0] + '-'+ date_parts[1] ? <React.Fragment>
                                    <div className={"page-subguide"}>
                                        Az étkezési csoporthoz a periódusban nem került menü-étlap feltöltésre. A menü létrehozásához töltse fel az állományt, majd kattintson a „Létrehozás” gombra.
                                    </div>
                                    <div className="block block-lg-1-2">
                                        <label className="label label-long required">Betöltőállomány</label>
                                        <input key={'file_input_'+this.state.file_input_key} ref={this.file_input} disabled={this.state.in_progress} className="field" type="file" onChange={() => this.uploadFile()}/>
                                    </div>
                                    <div className="actions">
                                        <LoadingButton loading={this.state.in_progress === 'create'} disabled={this.state.in_progress} onClick={this.handleCreate}>Létrehozás</LoadingButton>
                                    </div>
                                </React.Fragment> : <React.Fragment>
                                    <div className={"page-subguide"}>
                                        Az étkezési csoporthoz a periódusban nem került menü-étlap feltöltésre. A feltöltés nem lehetséges, mert a periódus zárolt.
                                    </div>
                                </React.Fragment>): null
                            }
                        </React.Fragment>
                    }
                </React.Fragment> : null}
            </React.Fragment> : <Loading loading_text=''/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EtkezesiCsoportMenuk))