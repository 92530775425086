import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import CanteenClient from "../../CanteenClient"
import Loading from "@sapkk/app/components/Loading"
import { Link } from "react-router-dom"
import DateField from "@sapkk/app/components/DateField"
import SelectField from "@sapkk/app/components/SelectField"
import LoadingButton from "../../components/LoadingButton"
import { clearMessages, addMessage } from "@sapkk/app/actions"
import PopUp from "../../components/PopUp"

class OsztalyTortenet extends Component {
    constructor(props) {
        super(props)

        this.state = {
            student: this.props.student || null,
            student_registers: [],
            fetching: true
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+(-1*this.state.student)+'/osztaly-tortenet'){
            this.props.history.replace('/tanulo-kezelese/T'+(-1*this.state.student)+'/osztaly-tortenet')
        } else{
            Promise.all([
                CanteenClient.getStudentStudentRegisters(this.state.student),
                CanteenClient.listSchoolYears([],{fields: ['name', 'id', 'school_id']}),
                CanteenClient.getSchools(),
                CanteenClient.listClasses()
            ]).then(([student_registers, school_years, schools, classes]) => {
                this.setState({
                    schools: schools,
                    school_years: school_years,
                    student_registers: student_registers,
                    classes: classes,
                    fetching: false
                })
            })
        }
    }

    handleAddLine() {
        this.setState((prevState, props) => ({
            student_registers: prevState.student_registers.concat({school_id: "", school_year_id: "", class_id: "", date_from: "", date_to: "", new: true})
        }))
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            student_registers: prevState.student_registers.slice(0, id).concat(prevState.student_registers.slice(id-(-1)))
        }))
    }

    handleRowChange(id, name, value) {
        this.setState((prevState, props) => ({
            student_registers: prevState.student_registers.slice(0, id).concat([{...prevState.student_registers[id], [name]: value,...(name=='school_id' ? {school_year_id: "", class_id: ""} : {})}]).concat(prevState.student_registers.slice(id-(-1)))
        }))
    }

    handleShowSavePopUp = () => {
        this.setState({
            save_popup: true
        })
    }

    handleCloseSavePopUp = () => {
        this.setState({
            save_popup: false
        })
    }

    handleSave = ()=> {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true,
            save_popup: false
        }, () => {
            this.props.promise_error_handler(()=> CanteenClient.editStudentsRegisters(this.state.student, this.state.student_registers).then(result => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                this.setState({
                    student_registers: result,
                    save_in_progress: false
                })
            })).then(()=>{
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    render() {
        let write_student_register_info = this.props.rights_for_student.includes('WRITE_STUDENT_REGISTER_INFO')
        return this.state.fetching ? <Loading loading_text=''/> : <div className="block">
            {
                this.state.save_popup ? <PopUp title={'Figyelmeztetés'} close={this.handleCloseSavePopUp} buttons={[{action: this.handleSave, text: 'Igen'},{action: this.handleCloseSavePopUp, text: 'Nem'}]}>
                <p>Az osztálytörténet módosításával a menzanaptár és megrendelt étkezés adatai módosításra/lezárásra kerülnek! Menti az adatokat?</p>
            </PopUp> : null}
            <div className="page-subtitle">
                A gyerek az alábbi osztályokban járt eddig:
            </div>
            <table className="modify-table">
                <thead>
                    <tr>
                        <th>Iskola</th>
                        <th>Tanév</th>
                        <th>Osztály</th>
                        <th>Osztályba járás kezdete</th>
                        <th>Osztályba járás vége</th>
                        {write_student_register_info ? <th className="col-actions"></th> : null}
                    </tr>
                </thead>
                <tbody>
                    {this.state.student_registers.map((student_register,id) => {
                        return <tr>
                            <td>
                                <SelectField readOnly={!write_student_register_info || !student_register.new} onChange={(event) =>this.handleRowChange(id, 'school_id' ,event.target.value)} value={student_register.school_id} displayValues={this.state.schools.concat(student_register.school_id && !this.state.schools.find(s => s.id === student_register.school_id) ? [{id: student_register.school_id, name: student_register.school_name}] : []).reduce((prev, curr) => ({
                                    ...prev,
                                    [curr.id]: curr.name
                                }), {})}>
                                    <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                                    {this.state.schools.map(s => <option value={s.id} />)}
                                </SelectField>
                            </td>
                            <td>{student_register.school_id ? <SelectField readOnly={!write_student_register_info || !student_register.new} onChange={(event) =>this.handleRowChange(id, 'school_year_id' ,event.target.value)} value={student_register.school_year_id} displayValues={this.state.school_years.filter(sy => sy.school_id == student_register.school_id).concat(student_register.school_year_id && !this.state.school_years.find(sy => sy.id === student_register.school_year_id) ? [{id: student_register.school_year_id, name: student_register.year_name}] : []).reduce((prev, curr) => ({
                                    ...prev,
                                    [curr.id]: curr.name
                                }), {})}>
                                    <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                                    {this.state.school_years.filter(sy => sy.school_id == student_register.school_id).map(sy => <option value={sy.id} />)}
                                </SelectField>: null }</td>
                            <td>{student_register.school_id ? <SelectField readOnly={!write_student_register_info || !student_register.new} onChange={(event) =>this.handleRowChange(id, 'class_id' ,event.target.value)} value={student_register.class_id} displayValues={this.state.classes.filter(c => c.school_id == student_register.school_id).concat(student_register.class_id && !this.state.classes.find(sc => sc.id === student_register.class_id) ? [{id: student_register.class_id, name: student_register.class_name}] : []).reduce((prev, curr) => ({
                                    ...prev,
                                    [curr.id]: curr.name
                                }), {})}>
                                    <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                                    {this.state.classes.filter(c => c.school_id == student_register.school_id).map(c => <option value={c.id} />)}
                                </SelectField>: null }</td>
                            <td><DateField value = {student_register.date_from} onChange={(event)=>{this.handleRowChange(id, 'date_from',event.target.value)}} readOnly={!write_student_register_info || !student_register.new}></DateField></td>
                            <td><DateField value = {student_register.date_to} onChange={(event)=>{this.handleRowChange(id, 'date_to',event.target.value)}} readOnly={!write_student_register_info}></DateField></td>
                            {write_student_register_info ? <td className="col-actions"><button className="remove" onClick={()=>this.handleRemoveLine(id)}></button></td> : null}
                        </tr>
                    })}
                    {write_student_register_info || this.state.student_registers.length === 0 ? <tr className="table-new-row">
                        <td colSpan={5}>{this.state.student_registers.length === 0 ? 'Nincs osztálytörténet' : null}</td>
                        {write_student_register_info ? <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td> : null}
                    </tr> : null}
                </tbody>
            </table>
            {write_student_register_info ? <div className="actions">
                <LoadingButton onClick={this.handleShowSavePopUp}>Mentés</LoadingButton>
            </div> : null}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler
    }
}

const mapDispatchToProps = {
    addMessage: addMessage,
    clearMessages: clearMessages,
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OsztalyTortenet))