import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import LoadingButton from "../../components/LoadingButton";

class TamogatasokKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            reimbursement_id: "",
            reimbursements: [],
            meals: [],
            providers: [],
            types: [],
            data: undefined,
            fetch_in_progress: false,
            save_in_progress: false
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tamogatas-kezelese/tamogatasok'){
            this.props.history.replace('/tamogatas-kezelese/tamogatasok')
        }
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getReimbursements(),
            CanteenClient.getMeals(),
            CanteenClient.getReimbursementProviders(),
            CanteenClient.getReimbursementTypes(),
        ]).then(([reimbursements, meals,providers,types]) => {
            this.setState({
                reimbursements: reimbursements,
                meals: meals,
                providers: providers,
                types: types
            })
        }))
    }

    handleReimbursementChange(reimbursement_id) {
        this.setState({
            reimbursement_id: reimbursement_id,
            data: undefined
        })
    }

    handleNew() {
        this.props.clearMessages()

        this.setState({
            reimbursement_id: "",
            data: {
                name: "",
                description: "",
                type_id: "",
                provider_id: "",
                active: true,
                aids: []
            }
        })
    }

    handleFetch() {
        this.setState({
            fetch_in_progress: true,
        },() => {
            CanteenClient.getReimbursementAids(this.state.reimbursement_id).then(aids => {
                this.setState(prevState => ({
                    data: {
                        ...prevState.reimbursements.find(r => r.id == prevState.reimbursement_id),
                        aids: aids
                    },
                    fetch_in_progress: false
                }))
            })
        })
    }

    handleDataChange(name, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: value
            },
        }))
    }

    handleAidDataChange(meal, type, amount) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                aids: prevState.data.aids.filter(aid => aid.meal_id !== meal || aid.aid_type !== type).concat({meal_id: meal, aid_type: type, aid_amount: amount}),
            }
        }))
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler((() => {
                if (this.state.reimbursement_id) {
                    return CanteenClient.editReimbursement(this.state.reimbursement_id, this.state.data.name, this.state.data.description, this.state.data.provider_id, this.state.data.type_id, this.state.data.active, this.state.data.aids.filter(aid => aid.aid_amount))
                } else {
                    return CanteenClient.createReimbursement(this.state.data.name, this.state.data.description, this.state.data.provider_id, this.state.data.type_id, this.state.data.active, this.state.data.aids.filter(aid => aid.aid_amount))
                }
            })().then(reimbursement => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                this.setState({
                    data: reimbursement
                })
            })).then(reimbursement => {
                this.props.promise_error_handler(CanteenClient.getReimbursements().then(reimbursements => {
                    this.setState({
                        reimbursements: reimbursements,
                        save_in_progress: false
                    })
                }))
            })
        })
    }

    render() {
        return <React.Fragment>
            <div className="page-subtitle">Támogatás kiválasztása</div>
            <div className="entity-select-block">
                <div>
                    <div className="block block-lg-1-1">
                        <select className="field field-simple" value={this.state.reimbursement_id} onChange={event => this.handleReimbursementChange(event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            { this.state.reimbursements.map(reimbursement => <option value={reimbursement.id}>{reimbursement.name}</option>) }
                        </select>
                    </div>
                </div>
                <div>
                    <LoadingButton loading={this.state.fetch_in_progress} disabled={!this.state.reimbursement_id} onClick={() => this.handleFetch()}>Lekérdez</LoadingButton>
                    <button onClick={() => this.handleNew()}>Új</button>
                </div>
            </div>
            { this.state.meals && this.state.data && <React.Fragment>
                <div className="page-subtitle">Támogatás adatai</div>
                <div className="block block-lg-1-1">
                    <label className="label label-long required">Név</label>
                    <SimpleField className="field field-simple" value={this.state.data.name} onChange={event => this.handleDataChange('name', event.target.value)} />
                </div>
                <div className="block block-lg-1-1">
                    <label className="label label-long">Leírás</label>
                    <SimpleField className="field field-simple" value={this.state.data.description} onChange={event => this.handleDataChange('description', event.target.value)} />
                </div>
                <div className="block block-lg-1-1">
                    <label className="label label-long required">Támogató</label>
                    <select className="field field-simple" value={this.state.data.provider_id} onChange={event => this.handleDataChange('provider_id', event.target.value)}>
                        <option value="">Kérem válasszon!</option>
                        {this.state.providers.map(provider => <option value={provider.id}>{provider.name}</option>)}
                    </select>
                </div>
                <div className="block block-lg-1-1">
                    <label className="label label-long required">Típus</label>
                    <select className="field field-simple" value={this.state.data.type_id} onChange={event => this.handleDataChange('type_id', event.target.value)}>
                        <option value="">Kérem válasszon!</option>
                        {this.state.types.map(type => <option value={type.id}>{type.name}</option>)}
                    </select>
                </div>
                <div className="block block-lg-1-1">
                    <label className="label label-long">Aktív</label>
                    <SimpleField className="field field-simple" checked={this.state.data.active} onChange={event => this.handleDataChange('active', event.target.checked)} type="checkbox" />
                </div>
                <div className="page-subtitle">Étkezések</div>
                <div className="table-container">
                    <table className="modify-table striped">
                        <thead>
                            <tr>
                                <th>Étkezésnév</th>
                                <th>Osztálytípus</th>
                                { Object.keys(this.props.aid_types).map(aid_type => <th key={aid_type}>{this.props.aid_types[aid_type].name + ' (' + this.props.aid_types[aid_type].unit + ')'}</th>) }
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.meals.map(meal =>
                                <tr key={meal.id}>
                                    <td>{meal.name}</td>
                                    <td>{(this.props.class_types[meal.class_type]||{}).name || meal.class_type}</td>
                                    { Object.keys(this.props.aid_types).map(aid_type => <td key={aid_type}><input value={(this.state.data.aids.find(aid => aid.meal_id === meal.id && aid.aid_type === aid_type) || {}).aid_amount||""} onChange={event => this.handleAidDataChange(meal.id, aid_type, event.target.value)}/></td>) }
                                </tr>
                            ) }
                        </tbody>
                    </table>
                </div>
                <div className="actions">
                    <LoadingButton onClick={() => this.handleSave()} loading={this.state.save_in_progress}>Mentés</LoadingButton>
                </div>
            </React.Fragment> }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        aid_types: state.aid_types,
        class_types: state.class_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TamogatasokKezelese))
