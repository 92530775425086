import React, { Component } from 'react'
import { setGuide, setTitle } from "../../actions"
import { connect } from "react-redux"
import CanteenClient from "../../CanteenClient"
import { clearMessages, addMessage } from '@sapkk/app/actions';
import DateTimeField from "@sapkk/app/components/DateTimeField"
import Loading from "@sapkk/app/components/Loading"
import { Link } from "react-router-dom"
import FilestoreClient from '../../FileStoreClient';
import LoadingButton from '../../components/LoadingButton';

class XMenzaStatusz extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            parts: undefined,
            status: undefined
        }
    }

    componentDidMount() {
        this.handleFetch()
    }

    handleFetch = ()=>{
        this.props.clearMessages()
        this.setState({
            fetch_in_progress: true
        },()=>{
            this.props.promise_error_handler(CanteenClient.getXMenzaStatus(this.state.school).then(status =>
                this.setState({
                    ...status,
                    fetch_in_progress: false
                })
            ))
        })
    }

    static typeConversion =  {
        "ERROR": "Ügyintézői beavatkozást igényel",
        "BATCH": "Betöltő",
        "WARNING" : "Felülvizsgálat szükséges"
    }

    convertMessage = (part_type, message) =>{
        if(!message.id){
            return message.message
        }
        switch (part_type) {
            case 'osztaly':
                return <Link to={'/osztaly-kezelese/'+message.id} target="_blank">{message.message}</Link>
            case 'torzsadat':
                return <Link to={'/tanulo-kezelese/T'+(-1*message.id)+'/adatok'} target="_blank">{message.message}</Link>
            case 'menzanaptar':
                return <Link to={'/tanulo-kezelese/T'+(-1*message.id)+'/menzanaptar'} target="_blank">{message.message}</Link>
            case 'tamogatas':
                return <Link to={'/tanulo-kezelese/T'+(-1*message.id)+'/etkezes-felvetele'} target="_blank">{message.message}</Link>
            default:
                return message.message
        }
    }

    handleDownload = () => {
        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.reportDownload('/CanteenService/Report/xMenzaStatus', {
                school_id: this.state.school
            }).then((result) => {
                return FilestoreClient.download(result.hash)
            })).then(() => {
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-subtitle">xMenza státusz</div>
                {
                    this.state.status!==undefined ? <React.Fragment>
                        <div className="block">
                            <label className="label label-long">Állapot</label>
                            <div className={'text-container '+ (this.state.status ? (this.state.status.batch_error + this.state.status.error + this.state.status.warning) === 0 ? " xmenza-status ok" : " xmenza-status error" : '')}>{ this.state.status ? (this.state.status.batch_error + this.state.status.error + this.state.status.warning) === 0 ? "OK" : "Hiba" : "" }</div>
                        </div>
                        <div className="block">
                            <label className="label label-long">Szinkronizáció időpontja</label>
                            <div className={'text-container '+ ((this.state.status && this.state.status.expired_information) ? "xmenza-status data-expired" : '')}><DateTimeField readOnly={true} value={ this.state.status ? this.state.status.last_checked : "" }/></div>
                        </div>
                        <div className="block">
                            <label className="label label-long">Aktuális időszak</label>
                            <div className='text-container'>{ this.state.status ? this.state.status.period : "" }</div>
                        </div>
                        <div className="page-subtitle">Részletek</div>
                        <div className="block table-container">
                            <table className="xmenza-status striped">
                                <thead>
                                    <th>Üzenet</th>
                                    <th>Állapot</th>
                                </thead>
                                <tbody>
                                    { this.state.parts.map(part =>
                                        <React.Fragment>
                                            <tr className="part-row">
                                                <td>{ part.name }</td>
                                                <td className={ (part.status.batch_error + part.status.error + part.status.warning) === 0 ? "ok" : "error" }>{ (part.status.batch_error + part.status.error + part.status.warning) === 0 ? "OK" : "Hiba" }</td>
                                            </tr>
                                            { part.messages.map(message =>
                                                <tr>
                                                    <td>{this.convertMessage(part.type,message)}</td>
                                                    <td className="error">{XMenzaStatusz.typeConversion[message.type] || "Ismeretlen állapot"}</td>
                                                </tr>
                                            ) }
                                        </React.Fragment>
                                    ) }
                                </tbody>
                            </table>
                        </div>
                        <div className="actions">
                            <LoadingButton loading={this.state.downloading_in_progress} onClick={this.handleDownload}>Letöltés</LoadingButton>
                        </div>
                    </React.Fragment> : <Loading loading_text=''/>
                }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(XMenzaStatusz)
