import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton"
import SimpleField from "@sapkk/app/components/SimpleField";
import DateField from "@sapkk/app/components/DateField";

class NemTanuloiVevokodok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fetch_in_progress: true,
            org_sap_customer_codes: []
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/szamlazas/nem-tanuloi-vevokodok'){
            this.props.history.replace('/szamlazas/nem-tanuloi-vevokodok')
        }
        this.handleFetch()
    }

    handleFetch = ()=>{
        this.props.clearMessages()
        this.setState({
            fetch_in_progress: true,
            save_in_progress: false
        },()=>{
            this.props.promise_error_handler(CanteenClient.listOrgSAPCustomerCodes().then(org_sap_customer_codes =>
                this.setState({
                    org_sap_customer_codes: org_sap_customer_codes,
                    fetch_in_progress: false
                })
            ))
        })
    }
    handleRemoveLine = (index) => {
        this.setState((prevState, props) => ({
            org_sap_customer_codes: prevState.org_sap_customer_codes.slice(0, index).concat(prevState.org_sap_customer_codes.slice(index + 1))
        }))
    }

    handleRowChange = (event, i) => {
        let name = event.target.name
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        this.setState((prevState, props) => ({
            org_sap_customer_codes: prevState.org_sap_customer_codes.slice(0, i).concat([{...prevState.org_sap_customer_codes[i], [name]: value}]).concat(prevState.org_sap_customer_codes.slice(i+1))
        }))
    }

    handleAddLine = () => {
        this.setState((prevState, props) => ({
            org_sap_customer_codes: prevState.org_sap_customer_codes.concat([{sap_customer_code: '', name: '', new_code: true, disabled_einvoice_date_from: null}])
        }))
    }

    handleSave = () => {
        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.editOrgSAPCustomerCodes(this.state.org_sap_customer_codes).then(org_sap_customer_codes =>
                this.setState({
                    org_sap_customer_codes: org_sap_customer_codes,
                    save_in_progress: false
                })
            ))
        })
    }

    render() {
        if(this.state.fetch_in_progress) {
            return <Loading loading_text=''/>
        }
        return <React.Fragment>
            <div className="page-subtitle">Nem tanulói vevőkódok</div>
            <table className={"modify-table org-sap-codes"}>
                <thead>
                    <tr>
                        <th>Vevőkód</th>
                        <th>Megnevezés</th>
                        <th>Papír alapú számlázás kezdete</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.org_sap_customer_codes.map((oscc,i) => <tr>
                        <td><SimpleField readOnly={!oscc.new_code} value={oscc.sap_customer_code} name={"sap_customer_code"} onChange={(e)=>this.handleRowChange(e, i)}/></td>
                        <td><SimpleField value={oscc.name} name={"name"} onChange={(e)=>this.handleRowChange(e, i)}/></td>
                        <td><DateField readOnly={!!oscc.disabled_einvoice_id} value={oscc.disabled_einvoice_date_from} name={"disabled_einvoice_date_from"} onChange={(e)=>this.handleRowChange(e, i)}/></td>
                        <td className={"col-actions"}><button className={"remove"} onClick={()=>this.handleRemoveLine(i)}/></td>
                    </tr>)}
                    <tr className="table-new-row">
                        <td colSpan={3}>{this.state.org_sap_customer_codes.length === 0 && "Nincs megadva nem tanulói SAP vevőkód."}</td>
                        <td className={"col-actions"}><button className={"add"} onClick={this.handleAddLine}/></td>
                    </tr>
                </tbody>
            </table>
            <div className={"actions"}>
                <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
            </div>
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NemTanuloiVevokodok))