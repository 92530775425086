import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage, setActions } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import PopUp from "../../components/PopUp"
import { addAction, removeAction, setSystemMessagesCount } from "../../actions"
import { Link } from "react-router-dom"
import SelectField from "@sapkk/app/components/SelectField"

class Dokumentumok extends Component {
    constructor(props) {
        super(props)

        this.file_input = React.createRef()

        this.state = {
            school: this.props.school,
            student: this.props.student,
            documents: [],
            school_years: [],
            edit: false,
            fetch_in_progress: true,
            upload_in_progress: false,
            document_types: []
        }
    }

    componentDidMount() {
        if(this.props.with_help){
            if (Object.keys(this.props.rights.rights).length === 0) {
                this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
            }
        }
        this.props.promise_error_handler(Promise.all([
            CanteenClient.listStudentDocument(this.state.student),
            CanteenClient.getSchoolYearsForStudent(this.state.student),
            CanteenClient.getStudentDocumentTypes()
        ]).then(([documents,school_years,  document_types]) => {
            this.setState({
                school_years: school_years,
                documents: documents,
                original_documents: documents,
                document_types
            },()=>{
                this.handleReadAllDocument()
            })
        })).then(() => {
            this.setState({
                fetch_in_progress: false
            })
        })
    }

    componentWillUnmount() {
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    handleRemoveLine = (index) => {
        this.setState((prevState, props) => ({
            documents: prevState.documents.slice(0, index).concat(prevState.documents.slice(index + 1))
        }))
    }

    handleRowChange = (event) => {
        let id = event.target.attributes.getNamedItem("data_index").value
        let name = event.target.name
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        this.setState((prevState, props) => ({
            documents: prevState.documents.slice(0, id).concat([{...prevState.documents[id], [name]: value}]).concat(prevState.documents.slice(id-(-1)))
        }))
    }

    handleEdit = () => {
        this.setState((prevState) => ({
            edit: true,
            documents: [
                ...prevState.documents
            ]
        }))
    }

    handleCancel = () => {
        this.setState((prevState) => {
            return {
                edit: false,
                documents: prevState.original_documents
            }
        })
    }

    handleReadAllDocument = () => {
        this.props.promise_error_handler(CanteenClient.setSeenDocuments(this.state.documents.map(document => document.id)).then(() => {
            this.props.setSystemMessagesCount(null)
        }))
    }

    handleSave = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=> CanteenClient.editStudentDocuments(this.state.student, this.state.documents).then(result => {
                this.setState({
                    documents: result,
                    original_documents: result,
                    edit: false,
                    save_in_progress: false
                },()=>{
                    this.handleReadAllDocument()
                })
                this.props.addMessage('A dokumentumok elmentése megtörtént.', 'success')
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    uploadFile = () => {
        if (this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            }, () => {
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0],true).then(result => {
                    this.setState((prevState, props) => ({
                        documents: (prevState.documents || []).concat([{generated: false, document_hash: result.hash, file_name: result.name, school_id: this.state.school, informations: []}])
                    }))
                }).catch(error => {
                    this.props.clearMessages()
                    throw error
                })).then(() => {
                    this.setState({
                        upload_in_progress: false
                    })
                })
            })
        }
    }

    getMealRequestLink = (row) => {
        return '/tanulo-kezelese/T'+(-1*this.state.student)+'/tanuloi-igenylesek/korabbi/'+row.meal_request_id
    }

    render() {
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
                { this.state.popup_help_open && (
                    <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                        <p>
                            <div>A <span style={{fontWeight: 'bold'}}>„Dokumentumok”</span> menüpontban az igénylésekhez korábban feltöltött dokumentumokat, a rendszer által generált PDF formátumú igényléseket, nyilatkozatokat tekintheti meg a szülő, valamint új dokumentumok feltöltésére van lehetőség.</div>
                        </p>
                        <p>
                            <div>A már a rendszerben lévő dokumentumokat a sor végén lévő <span className="download-icon"/> ikonra lépve lehet letölteni, megtekinteni.</div>
                        </p>
                        <p>
                            <div>Új dokumentum feltöltésére az „Új dokumentum feltöltése” ikonra lépve van lehetőség. Feltöltött dokumentum törlésére a <span className="minus-icon"/> ikonra kattintva van lehetőség a mentés előtt. Mentés után dokumentum a rendszerből nem törölhető.</div>
                        </p>
                    </PopUp>
                ) }
                <input style={{display: 'none'}} type="file" ref={this.file_input} onChange={this.uploadFile}/>
                <div className="table-container">
                    <table className="modify-table striped documents">
                        <thead>
                            <tr>
                                <th>Típus</th>
                                <th>Feltöltés időpontja</th>
                                <th>Fájlnév</th>
                                <th>Igénylés azonosítója</th>
                                <th>Iskola</th>
                                <th>Tanév</th>
                                <th>Feltöltő</th>
                                <th className="col-actions"></th>
                                <th className="col-actions"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.documents.map((document, i) => <tr>
                                    <td><SelectField data_index={i} onChange={this.handleRowChange} name="type" readOnly={!this.state.edit || document.generated} value={document.type||''} displayValues={this.state.document_types.reduce((prev, curr) => ({
                                        ...prev,
                                        [curr.id]: curr.name
                                    }), {})}>
                                        <option value="" disabled={true}>Kérem, válasszon!</option>
                                        {this.state.document_types.filter(type => type.writeable).map(type => <option value={type.id} />)}
                                    </SelectField></td>
                                    <td>{document.upload_time}</td>
                                    <td title={document.file_name}>{document.file_name}</td>
                                    <td><Link to={this.getMealRequestLink(document)}>{document.meal_request_id}</Link></td>
                                    <td>{document.school_abbr}</td>
                                    <td><SelectField data_index={i} onChange={this.handleRowChange} name="school_year_id" readOnly={!this.state.edit || document.generated} value={!this.state.edit || document.generated ? document.school_year_id||'' : ((this.state.school_years.filter(sy => sy.school_id == document.school_id).find(sy => sy.id==document.school_year_id)||{}).id || '')} displayValues={this.state.school_years.reduce((prev, curr) => ({
                                        ...prev,
                                        [curr.id]: (curr.name || curr.year_name)
                                    }), {})}>
                                        <option value="" disabled={true}>Kérem, válasszon!</option>
                                        {this.state.school_years.filter(sy => sy.school_id == document.school_id).map(sy => <option value={sy.id} />)}
                                    </SelectField></td>
                                    <td>{document.generated ? 'Rendszer' : <span title={document.creator_type=='ADMINISTRATOR' ? document.uploader_name : null}>{(this.props.creator_types[Object.keys(this.props.creator_types).find(dt => dt == document.creator_type)]||{}).name}</span>}</td>
                                    <td className="col-actions"><button className="download" title="Letöltés" onClick={(event) => {event.stopPropagation();this.props.promise_error_handler(FilestoreClient.download(document.document_hash))}} /></td>
                                    <td className="col-actions">{!document.id ? <button className="remove" title='Törlés' onClick={(event) => {event.stopPropagation();this.handleRemoveLine(i)}} /> : null}</td>
                                </tr>)
                            }
                            <tr className="table-new-row">
                                <td colSpan={7}>{this.state.documents.length === 0 && "Nincs megadva dokumentum."}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    (this.props.rights_for_student||[]).includes('WRITE_DOCUMENT') && (new Date()).toISOString().split('T')[0] < (this.props.student_register||{}).date_to ? <div className="actions">
                    <LoadingButton disabled={this.state.save_in_progress} loading={ this.state.upload_in_progress} onClick={() => this.file_input.current.click()}>Új dokumentum feltöltése</LoadingButton>
                    {!this.state.edit && (this.props.rights_for_student||[]).includes('WRITE_DOCUMENT_INFORMATION') ? <LoadingButton onClick={this.handleEdit}>Szerkesztés</LoadingButton> : null}
                    {this.state.documents.find(d => d.id == null) || this.state.edit ? <React.Fragment>
                        <LoadingButton loading={this.state.save_in_progress} disabled={ this.state.upload_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                        <button disabled={this.state.save_in_progress || this.state.upload_in_progress} onClick={this.handleCancel}>Mégse</button>
                    </React.Fragment> : null
                    }
                </div> : null
                }
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        rights: state.rights,
        meal_request_states: state.meal_request_states,
        creator_types: state.creator_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    addAction: addAction,
    removeAction: removeAction,
    setSystemMessagesCount: setSystemMessagesCount
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dokumentumok))