import React, { Component } from 'react'
import { setGuide, setTitle} from "../../actions"
import { connect } from "react-redux"
import { addMessage, clearMessages } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from '../../components/LoadingButton';

class TanuloAthelyezese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            schools: [],
            source_years: [],
            source_classes: [],
            target_years: [],
            target_classes: [],
            source_year: "",
            source_school: this.props.school || '',
            source_class: "",
            source_students: [],
            target_year: "",
            target_school: "-1",
            target_class: "",
            students: [],
            date: "",
            save_in_progress: false,
            disable_menu: true
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getSchools(),
        ]).then(([schools]) => {
            this.setState({
                schools: schools,
                loaded: true
            },()=>{
                let callback = ()=>{
                    let callcallback = () => {
                        this.setState({
                            disable_menu: false
                        })
                    }
                    if(!('alkalmazasgazda' in this.props.rights.rights) && this.state.schools.length===1){
                        this.handleSchoolChange('target',this.state.schools[0].id,()=>this.handleYearChange('target','',()=>this.handleClassChange('target',"",undefined,callcallback),callcallback),callcallback)
                    } else{
                        callcallback()
                    }
                }
                this.handleSchoolChange('source',this.state.school_id,()=>this.handleYearChange('source','',()=>this.handleClassChange('source',"",undefined,callback),callback),callback)
            })      
        })).catch(error => {
            this.setState({
                error: true,
                loaded: true
            })
            throw error
        })
    }

    handleYearChange(block, year_id, callback_after_classes_loaded=undefined, callback=()=>{}) {
        callback_after_classes_loaded = callback_after_classes_loaded || (()=>{if(block==='source'){this.handleClassChange('source','',undefined,callback)}})
        this.props.clearMessages()
        year_id = this.state[block+'_years'].length ===1 ? this.state[block+'_years'][0].id : (this.state[block+'_years'].find(year=>year.id==year_id)||{id:''}).id
        this.setState({
            ...('alkalmazasgazda' in this.props.rights.rights ? {} : {
                ['target_class']: "",
                ['target_year']: "",
                ['target_students']: []
            }),
            [block+'_year']: year_id,
            [block+'_class']: "",
            [block+'_students']: []
        }, ()=> {
            if (block === 'source') {
                this.setState({
                    students: []
                })
            }
            if (year_id) {
                this.props.promise_error_handler(Promise.all([
                    CanteenClient.getSchoolClasses(this.state[block+'_school'], block == 'target' ? null : year_id),
                ]).then(([classes]) => {
                    this.setState({
                        [block+'_classes']: classes,
                    }, callback_after_classes_loaded)
                }))
            } else{
                callback()
            }
        })
    }

    handleSchoolChange(block, school_id,callback_after_years_loaded=undefined, callback=()=>{}) {
        callback_after_years_loaded = callback_after_years_loaded || (()=>{if(block==='source'){this.handleYearChange('source','',undefined,callback)}})

        this.props.clearMessages()
        school_id = school_id == -1 ? school_id : block=='source' && this.state.schools.length===1 ? this.state.schools[0].id : (this.state.schools.find(school=>school.id==school_id)||{id:''}).id
        this.setState({
            ...('alkalmazasgazda' in this.props.rights.rights ? {} : {
                ['target_year']: '',
                ['target_class']: '',
                ['target_students']: []
            }),
            [block+'_school']: school_id,
            [block+'_year']: '',
            [block+'_class']: "",
            [block+'_students']: []
        }, ()=> {
            if (block === 'source') {
                this.setState({
                    students: []
                })
            }    
            if (school_id || school_id==-1) {
                this.props.promise_error_handler(Promise.all([
                    CanteenClient.getYearsForSchool(school_id,'ADD_STUDENT',true),
                ]).then(([years]) => {
                    this.setState({
                        [block+'_years']: years
                    },callback_after_years_loaded)
                }))
            } else{
                this.setState({
                    [block+'_years']: [],
                    [block+'_classes']: []
                },callback)
            }
        })
    }

    handleClassChange(block, class_id,callback_after_students_loaded=undefined, callback=()=>{}) {
        this.props.clearMessages()
        callback_after_students_loaded = callback_after_students_loaded || (()=>callback())

        class_id = block=='source' && this.state[block+'_classes'].length ===1 ? this.state[block+'_classes'][0].id : (this.state[block+'_classes'].find(schoolclass=>schoolclass.id==class_id)||{id:''}).id
        this.setState({
            [block+'_class']: class_id,
            [block+'_students']: []
        },()=>{
            if (block === 'source') {
                this.setState({
                    students: []
                })
            }
            if (block === 'source' && class_id) {
                this.props.promise_error_handler(CanteenClient.getActualStudentsInClass(this.state.source_year, this.state.source_school, class_id).then(students => {
                    this.setState({
                        students: students,
                        source_students: students.reduce((l, s) => l.concat(s.id), [])
                    },callback_after_students_loaded)
                }))
            } else{
                callback()
            }
        })

    }

    handleDataChange(name, value) {
        this.setState({
            [name]: value
        })
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        })

        this.props.promise_error_handler(()=>CanteenClient.changeClassForStudents(this.state.source_year, this.state.source_school, this.state.source_class, this.state.target_year || undefined, this.state.target_school || undefined, this.state.target_class || undefined, this.state.source_students, this.state.date || undefined).then(students => {
            this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

            this.setState({
                source_students: [],
                students: students,
                save_in_progress: false
            })
        })).then(() => {
            this.setState({
                save_in_progress: false
            })
        })
    }

    toggleSelectAll = ()=>{
        this.setState((prevState) => ({
            source_students: prevState.source_students.length ? [] : prevState.students.map(s => s.id)
        }))
    }

    render() {
        return (
            this.state.disable_menu ? <Loading  loading_text=''/>: <React.Fragment>
                <div className="page-subtitle">Forrásosztály kiválasztása</div>
                <div className="block">
                    <label className="label label-long">Iskola</label>
                    <select disabled={this.state.disable_menu || this.state.schools.length < 2} className="field" value={this.state.source_school} onChange={event => this.handleSchoolChange('source', event.target.value)}>
                        <option value="" disabled={true}>Kérem válasszon!</option>
                        { this.state.schools.map(school => <option value={school.id}>{school.name}</option>) }
                    </select>
                </div>
                <div className="block">
                    <label className="label label-long">Tanév</label>
                    <select disabled={this.state.disable_menu|| this.state.source_years.length < 2} className="field" value={this.state.source_year} onChange={event => this.handleYearChange('source', event.target.value)}>
                        <option value="" disabled={true}>Kérem válasszon!</option>
                        { this.state.source_years.map(year => <option value={year.id}>{year.name}</option>) }
                    </select>
                </div>
                <div className="block">
                    <label className="label label-long">Osztály</label>
                    <select disabled={this.state.disable_menu|| this.state.source_classes.length < 2}  className="field" value={this.state.source_class} onChange={event => this.handleClassChange('source', event.target.value)}>
                        <option value="" disabled={true}>Kérem válasszon!</option>
                        { this.state.source_classes.map(schoolclass => <option value={schoolclass.id}>{schoolclass.name}</option>) }
                    </select>
                </div>
                <div className="page-subtitle">Célosztály kiválasztása</div>
                <div className="block">
                    <label className="label label-long">Iskola</label>
                    <select disabled={this.state.disable_menu|| this.state.schools.length < 1}  className="field" value={this.state.target_school} onChange={event => this.handleSchoolChange('target', event.target.value)}>
                        {this.state.schools.length !== 1 || 'alkalmazasgazda' in this.props.rights.rights ? <option value="-1" disabled={true}>Kérem válasszon!</option> : null}
                        <option value="">Iskolából történő kivezetés</option>
                        { this.state.schools.filter(school=>'alkalmazasgazda' in this.props.rights.rights || school.id==this.state.source_school).map(school => <option value={school.id}>{school.name}</option>) }
                    </select>
                </div>
                <div className="block">
                    <label className="label label-long">Tanév</label>
                    <select disabled={this.state.disable_menu|| this.state.target_years.length < 1}  className="field" value={this.state.target_year} onChange={event => this.handleYearChange('target', event.target.value)}>
                        <option value="" disabled={true}>Kérem válasszon!</option>
                        { this.state.target_years.map(year => <option value={year.id}>{year.name}</option>) }
                    </select>
                </div>
                <div className="block">
                    <label className="label label-long">Osztály</label>
                    <select disabled={this.state.disable_menu|| this.state.target_classes.length < 1} className="field" value={this.state.target_class} onChange={event => this.handleClassChange('target', event.target.value)}>
                        <option value="" disabled={true}>Kérem válasszon!</option>
                        { this.state.target_classes.map(schoolclass => <option value={schoolclass.id}>{schoolclass.name}</option>) }
                    </select>
                </div>
                <div className="page-subtitle">Tanulók áthelyezése</div>
                <div className="block">
                    <label className="label label-long required">Dátum</label>
                    <input className="field field-simple" placeholder="éééé.hh.nn" value={this.state.date} onChange={event => this.handleDataChange('date', event.target.value)}/>
                </div>
                <div className="block">
                    <label className="label label-long">Tanulók</label>
                    <div className="table-container">
                        <table className="move-student striped">
                            <thead>
                                <th onClick={this.toggleSelectAll}>Áthelyezés</th>
                                <th>Név</th>
                                <th>Oktatási azonosító</th>
                            </thead>
                            <tbody>
                            { this.state.students.map(student =>
                                <tr key={student.id}>
                                    <td><input type="checkbox" checked={this.state.source_students.includes(student.id)} onClick={event => this.handleDataChange('source_students', this.state.source_students.filter(s => s !== student.id).concat(event.target.checked ? student.id : null).filter(s => s))}/></td>
                                    <td>{ student.name }</td>
                                    <td>{ student.om }</td>
                                </tr>
                            ) }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="actions">
                    <LoadingButton loading={this.state.save_in_progress} disabled={this.state.disable_menu} onClick={() => this.handleSave()}>Mentés</LoadingButton>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}


const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(TanuloAthelyezese)
