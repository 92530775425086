import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import SearchResult from "../SearchResult";
import Loading from "@sapkk/app/components/Loading"

class KezelendoIgenylesek extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            page: (this.props.history.location.state || {}).page || null,
            skip_update: false,
            parameters: {
                limit: 20
            },
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/iskola-kezelese/'+this.state.school+'/feldolgozatlan-igenylesek'){
            this.props.history.replace('/iskola-kezelese/'+this.state.school+'/feldolgozatlan-igenylesek')
        }
        this.handleSearch(this.state.page || 0)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.history.location.state && !state.skip_update) {
            return {
                page: props.history.location.state.page
            }
        } else {
            return null
        }
    }

    classMethodForCreation = (row)=>{
        return row.unseen_message_count ? ['search_result-with-unseen-message'] : []
    }

    handleSearch = (page) =>{
        this.props.clearMessages()

        this.setState({
            search_in_progress: true,
            skip_update: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.listSchoolMealRequests(this.state.school, ['CREATED', 'UPDATED'], {
                info: true,
                limit: this.state.parameters.limit,
                offset: page* this.state.parameters.limit
            }).then(result => {
                this.setState({
                    search_in_progress: false,
                    result: {
                        result: result.result.map(data => ({
                            ...data,
                            creation_time: data.creation_time
                        })) || [],
                        result_summary: {
                            ...result.result_summary,
                            page: page
                        },
                        headers: [
                            {name: 'year_name', alias: 'Tanév', childrens: [], depth: 1, level: 1, order: 1, path: ['year_name'],parent_header_id: null, mergeable: false},
                            {name: 'id', alias: 'Azonosító', childrens: [], depth: 1, level: 1, order: 1, path: ['id'],parent_header_id: null, mergeable: false},
                            {name: 'student_name', alias: 'Tanuló neve', childrens: [], depth: 1, level: 1, order: 1, path: ['student_name'],parent_header_id: null, mergeable: false},
                            {name: 'creation_time', data_path:['unseen_message_count'], class_method: this.classMethodForCreation, alias: 'Létrehozva', childrens: [], depth: 1, level: 1, order: 1, path: ['creation_time'],parent_header_id: null, mergeable: false},
                            {name: 'update_time', alias: 'Utoljára módosítva', childrens: [], depth: 1, level: 1, order: 1, path: ['update_time'],parent_header_id: null, mergeable: false},
                            {name: 'in_system', alias: 'Online igénylés', type: 'BOOL', childrens: [], depth: 1, level: 1, order: 1, path: ['in_system'],parent_header_id: null, mergeable: false},
                        ]
                    }
                }, () => {
                    this.props.history.replace(this.props.history.location.pathname, {
                        ...this.props.history.location.state,
                        page: this.state.result.result_summary.page
                    })
                })
            })).then(() =>
                this.setState({
                    search_in_progress: false,
                    skip_update: false
                })
            )
        })
    }

    getLinkForStudent = (row={}) => {
        let id = -1*row.student_id
        return '/tanulo-kezelese/T' + id + '/tanuloi-igenylesek'
    }

    getLinkForMealRequest = (row={}) =>{
        return '/iskola-kezelese/' + this.state.school + '/feldolgozatlan-igenylesek/'+row.id
    }

    render() {
        if (this.state.result && this.props.history.location.state) {
            return <SearchResult
                title={'Feldolgozásra váró igénylések'}
                not_found_text={'Nincs feldolgozásra váró igénylés'}
                result={this.state.result}
                parameters={this.state.parameters}
                search={page => this.handleSearch(page)}
                tableClassName={'meal-requests-table'}
                linkMethods= {{
                    student_name: this.getLinkForStudent,
                    id: this.getLinkForMealRequest
                }}
            />
        }
        return <Loading loading_text=""/>
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KezelendoIgenylesek))