import {makeid} from "./functions";

class CanteenClient {
    static version = '1.0.13#202408261543'

    static client_id = makeid(20)

    static is_confirmed = false

    static fetchCanteen(url, body){
        body.__client_id = CanteenClient.client_id
        body.__version = CanteenClient.version
        return fetch(url, {
            credentials: 'include',
            method: "post",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(
                {
                    ...body,
                    ...(CanteenClient.is_confirmed ? {__is_confirmed : true} : {}),
                }
            )
        }).catch(() => {
            throw new Error('Nem várt hiba történt.')
        }).then(response => {
            if (response.ok) {
                return response.json()
            } else {
                return response.json().then(json => {
                    let error = new Error(json.message || 'Nem várt hiba történt.')
                    error.additional_info = json.additional_info || []
                    error.code = json.code
                    error.type = json.type
                    error.status = response.status
                    throw error
                })
            }
        })
    }

    static isMyVersionFresh(){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/isMyVersionFresh', {})
    }
    static getSupportInfo() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Support/getInfo', {})
    }
    
    static getMaintenanceMessage() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Support/getMaintenanceMessage', {})
    }

    static getSupportContactInfo() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Support/getContactInfo', {})
    }

    static registerError(type){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/registerError', {type: type})
    }

    static sendSupportContactMessage(name, email, phone_number, school, topic, message) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Support/sendMessage', {
            name: name,
            email: email,
            phone_number: phone_number,
            school: school,
            topic: topic,
            message: message
        })
    }

    static canHaveChild() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/canHaveChild', {})
    }

    static getChildrens() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/getChildrens', {})
    }

    static addChildren(om, password) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/addChildren',{
            om: om,
            password: password
        })
    }

    static deleteChildren(student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/deleteChildren', {
            student_id: student_id
        })
    }

    static changePasswordForStudent(student_id, old_password, new_password) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/changePasswordForStudent', {
            student_id: student_id,
            original_password: old_password,
            new_password: new_password
        })
    }

    static getRights() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/getRights', {
        })
    }

    static login(username, password) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/login', {
            username: username,
            password: password
        })
    }

    static logout() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/logout', {
        })
    }

    static checkAuth() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/checkAuth', {
        })
    }

    static createYear(name, active) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/create', {
            name: name,
            active: active
        })
    }

    static updateYears(years) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/multiUpdate',{
            year_data: years
        })
    }

    static createSchoolYear(school_id, year_id, date_from, date_to, graduate_date_to, calendar) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/create', {
            school_id: school_id,
            year_id: year_id,
            date_from: date_from,
            date_to: date_to,
            graduate_date_to: graduate_date_to,
            calendar: calendar
        })
    }

    static editSchoolYear(id, school_id, date_from, date_to, graduate_date_to, billing_type, calendar) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/edit', {
            school_id: school_id,
            id: id,
            date_from: date_from,
            date_to: date_to,
            graduate_date_to: graduate_date_to,
            billing_type: billing_type,
            calendar: calendar
        })
    }

    static convertCalendarDays(date_from, date_to, calendar) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/createCalendarDaysByIntervals', {
            date_from: date_from,
            date_to: date_to,
            intervals: calendar
        })
    }

    static getYears(parameters) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/list', {
            parameters: parameters
        })
    }

    static getYear(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/get', {
            id: id
        })
    }

    static getActiveYears(school_id = null) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/list', {
            conditions: {
                active: [
                    { operator: 11 }
                ],
                school_id: school_id ? [
                    {operator: 9, value: school_id}
                ] : []
            },
            parameters: {
                fields: ['id', 'name']
            }
        })
    }

    static getSchools(usage = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/list', {
            parameters: {
                fields: ['id', 'name', 'abbreviated_name'],
                usage: usage
            }
        })
    }

    static getYearSchools(year_id, usage = undefined, active = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/list', {
            conditions: {
                year_id: [
                    { operator: 9, value: year_id }
                ], active: active !== undefined ? [
                    { operator: active ? 11 : 12 }
                ] : undefined
            },
            parameters: {
                fields: ['id', 'name', 'abbreviated_name'],
                usage: usage
            }
        })
    }

    static getYearsForSchool(school_id, usage = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/list', {
            conditions: {
                school_id: [
                    { operator: 9, value: school_id }
                ]
            },
            parameters: {
                usage: usage
            }
        })
    }

    static getSchoolClasses(school_id, year_id, parameters = undefined) {
        return (() => {
            if (year_id) {
                return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/getClassesByYearAndSchool', {
                    school_id: school_id,
                    year_id: year_id
                })
            } else {
                return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/list', {
                    conditions: {
                        school_id: [
                            { operator: 9, value: school_id }
                        ]
                    },
                    parameters: {
                        ...(parameters === undefined ? { fields: ['id', 'name'] } : parameters)
                    }
                })
            }
        })()
    }

    static getLastStudents(class_id, year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/getLastStudents', {
            class_id: class_id,
            year_id: year_id
        })
    }

    static getActualStudentsInClass(year_id, school_id, class_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/getActualStudentsInClass', {
            year_id: year_id,
            school_id: school_id,
            class_id: class_id
        })
    }

    static getStudent(student_id, school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/get', {
            id: student_id,
            school_id: school_id
        })
    }

    static searchStudentByName(student_name) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/list', {
            conditions: {
                name: [
                    { operator: 0, value: student_name }
                ]
            },
            parameters: {
                info: true
            }
        })
    }

    static getIgnoredClasses(school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/XMenza/getIgnoredClasses', {
            school_id: school_id
        })
    }

    static editStudent(student_id, student_data, date, birthday, om, sap_customer_code) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/edit', {
            id: student_id,
            name: student_data.name,
            account_number: student_data.account_number || null,
            address_data: Object.keys(student_data.address_data).some(x => student_data.address_data[x]) ? student_data.address_data : null,
            invoice_recipient_data: student_data.invoice_recipient_data,
            payment_method_id: student_data.payment_method_id || undefined,
            sap_customer_code: sap_customer_code,
            payers_name: student_data.payers_name,
            bank_account_type: student_data.bank_account_type,
            birthday: birthday,
            om: om,
            date: date
        })
    }

    static getReports() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Report/list', {
            conditions: {
                active: [
                    {operator: 11}
                ]
            }
        })
    }

    static getLastDiningForStudentForYear(student_id, year_id, school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/getLastDiningForStudentForYear', {
            student_id: student_id,
            year_id: year_id,
            school_id: school_id
        })
    }

    static getDiningsForStudentInSchoolAndYear(student_id, school_id, year_id, cancelling_dining_student = false) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/listDinings', {
            conditions: {
                student_id: [
                    { operator: 9, value: student_id }
                ],
                year_id: [
                    { operator: 9, value: year_id }
                ],
                school_id: [
                    { operator: 9, value: school_id }
                ]
            },
            orders: [{ field: 'date_from', order: 'DESC' }],
            cancelling_dining_student: cancelling_dining_student
        })
    }

    static listMealGroupStudent(student_id, school_id, year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/listMealGroupStudent', {
            conditions: {
                student_id: [
                    { operator: 9, value: student_id }
                ],
                year_id: [
                    { operator: 9, value: year_id }
                ],
                school_id: [
                    { operator: 9, value: school_id }
                ]
            },
            orders: [{ field: 'date_from', order: 'DESC' }]
        })
    }


    static getReportParams(report_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Report/getParams', {
            report_id: report_id
        })
    }

    static getSchoolClass(class_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/get', {
            id: class_id
        })
    }

    static getCountries() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Address/listCountries', {
        })
    }

    static getStreetTypes() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Address/listStreetTypes', {
        })
    }

    static getCityByCountryAndZipCode(country_id, zip_code) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Address/getCityByCountryAndZipCode', {
            country_id: country_id,
            zip_code: zip_code
        })
    }

    static getActiveDateRange(school_year_id,student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/getActiveDateRange', {
            school_year_id: school_year_id,
            student_id: student_id
        })
    }

    static getStudentDiningCalendar(school_year_id,year, month, student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/getStudentDiningCalendar', {
            year: year,
            month: month,
            student_id: student_id,
            school_year_id: school_year_id
        })
    }

    static getClassDiningCalendar(school_year_id,year, month, class_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/getClassDiningCalendar', {
            year: year,
            month: month,
            class_id: class_id,
            school_year_id: school_year_id
        })
    }

    static getSchoolDiningCalendar(school_year_id,year, month) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/getSchoolDiningCalendar', {
            year: year,
            month: month,
            school_year_id: school_year_id
        })
    }

    static updateStudentMealCancellations(school_year_id,student_id, cancellations) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/updateStudentMealCancellations', {
            student_id: student_id,
            cancellations: cancellations,
            school_year_id: school_year_id
        })
    }

    static updateStudentsMealCancellations(school_year_id,class_id, cancellations, group_data) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/updateStudentsMealCancellations', {
            cancellations: cancellations,
            group_data: group_data,
            school_year_id: school_year_id,
            class_id: class_id
        })
    }

    static updateClassesMealCancellations(school_year_id,cancellations, group_data) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/updateClassesMealCancellations', {
            cancellations: cancellations,
            school_year_id: school_year_id,
            group_data: group_data
        })
    }

    static getClassTypes() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/getClassTypes', {
        })
    }

    static getClassTypesForSchool(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/getClassTypes', {
            id: id
        })
    }

    static getClassesByYearAndSchool(year_id, school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/getClassesByYearAndSchool', {
            year_id: year_id,
            school_id: school_id
        })
    }

    static getBySchoolClassAndStudent(school_id, class_id, student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/getBySchoolClassAndStudent', {
            class_id: class_id || undefined,
            school_id: school_id,
            student_id: student_id || undefined
        })
    }

    static loadMultipleStudentFromFileCheckExcel(year_id, school_id, hash){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/loadMultipleStudentFromFileCheckExcel',{
            year_id: year_id,
            school_id: school_id,
            hash: hash
        })
    }

    static loadMultipleStudentFromFile(year_id, school_id, hash) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/loadMultipleStudentFromFile',{
            year_id: year_id,
            school_id: school_id,
            hash: hash
        })
    }

    static getSAPDataForStudent(student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getSAPDataForStudent',{
            student_id: student_id
        })
    }

    static getDisabledEInvoiceInfo(student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getDisabledEInvoiceInfo',{
            student_id: student_id
        })
    }

    static disableEInvoice(student_id, date_from) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/disableEInvoice',{
            student_id: student_id,
            date_from: date_from
        })
    }

    static acceptDisableEInvoice(id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/acceptDisableEInvoice',{
            id: id
        })
    }

    static undoneDisableEInvoice(id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/undoneDisableEInvoice',{
            id: id
        })
    }

    static haveEInvoice(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/haveEInvoice',{
            student_id: student_id
        })
    }

    static editSAPDataForStudent(student_id, new_sap_customer_code, new_date) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/editSAPDataForStudent',{
            student_id: student_id,
            new_sap_customer_code: new_sap_customer_code,
            new_date: new_date
        })
    }

    static getContactInfo(student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getContactInfo',{
            student_id: student_id
        })
    }
    static deleteContactInfo(student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/deleteContactInfo',{
            student_id: student_id
        })
    }

    static editContactInfo(student_id,concat_info) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/editContactInfo',{
            student_id: student_id,
            email: concat_info.email,
            use_for_notification: concat_info.use_for_notification,
            use_for_contact: concat_info.use_for_contact
        })
    }

    static addStudentForSchoolYearClass(year_id, class_id, om, birthday, name, school_id, date_from) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/addStudentForSchoolYearClass', {
            year_id: year_id,
            school_id: school_id,
            class_id: class_id,
            om: om,
            birthday: birthday,
            name: name,
            date_from: date_from || null
        })
    }

    static createSchoolClass(school_id, name, type, xmenza_code, is_graduating, can_generate_transfer_order) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/create', {
            school_id: school_id,
            type: type,
            name: name,
            xmenza_code: xmenza_code,
            is_graduating: is_graduating,
            can_generate_transfer_order: can_generate_transfer_order
        })
    }

    static editSchoolClass(id, name, type, xmenza_code, is_graduating, can_generate_transfer_order) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/edit', {
            id: id,
            type: type,
            name: name,
            xmenza_code: xmenza_code,
            is_graduating: is_graduating,
            can_generate_transfer_order: can_generate_transfer_order
        })
    }

    static editClasses(school_id, classes) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/editClasses', {
            school_id: school_id,
            classes: classes
        })
    }

    static deleteSchoolClass(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/delete', {
            id: id
        })
    }

    static createSchool(name, abbreviated_name, address, bank_name, bank_account, all_day_canceled, xmenza_code, email, use_calendar, administrator_name, phone_number, meal_request_text, dining_calendar_text, docuhub_label_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/create', {
            abbreviated_name: abbreviated_name,
            address: address,
            name: name,
            bank_name: bank_name,
            bank_account: bank_account,
            all_day_canceled: all_day_canceled,
            xmenza_code: xmenza_code,
            email: email,
            use_calendar: use_calendar,
            administrator_name: administrator_name,
            phone_number: phone_number,
            meal_request_text: meal_request_text,
            dining_calendar_text: dining_calendar_text,
            docuhub_label_id: docuhub_label_id
        })
    }
    static editSchool(id, name, abbreviated_name, address, bank_name, bank_account, all_day_canceled, xmenza_code, email, use_calendar, administrator_name, phone_number, meal_request_text, dining_calendar_text, new_financial_center, new_date, docuhub_label_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/edit', {
            abbreviated_name: abbreviated_name,
            address: address,
            name: name,
            id: id,
            bank_name: bank_name,
            bank_account: bank_account,
            all_day_canceled: all_day_canceled,
            xmenza_code: xmenza_code,
            email: email,
            use_calendar: use_calendar,
            administrator_name: administrator_name,
            phone_number: phone_number,
            meal_request_text: meal_request_text,
            dining_calendar_text: dining_calendar_text,
            new_financial_center: new_financial_center,
            new_date: new_date,
            docuhub_label_id: docuhub_label_id
        })
    }

    static getSchool(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/get', {
            id: id
        })
    }

    static getSchoolYear(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/get', {
            id: id
        })
    }
    static getRightsForStudent(id, school_year_id = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getRightsForStudent', {
            id: id,
            school_year_id: school_year_id ? school_year_id : undefined
        })
    }
    
    static getParents(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getParents', {
            id: id
        })
    }

    static getRightsForClass(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/getRightsForClass', {
            id: id
        })
    }

    static getRightsForSchool(id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/getRightsForSchool', {
            id: id
        })
    }

    static getLastStudentRegisterData(id,school_year_id = null) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getLastStudentRegisterData', {
            id: id,
            school_year_id: school_year_id
        })
    }

    static getSchoolYearsForSchool(school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/list', {
            conditions: {
                school_id: [
                    { operator: 9, value: school_id }
                ]
            },
            parameters: {
                fields: ['id', 'name', 'year_id', 'school_id']
            }
        })
    }

    static getSchoolYearsForStudent(student_id, active) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getSchoolYears', {
            id: student_id,
            active: active
        })
    }

    static getSchoolYearsBySchoolID(school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/getSchoolYears', {
            id: school_id
        })
    }

    static getSchoolYearByYearAndSchool(year_id, school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/getByYearAndSchool', {
            year_id: year_id,
            school_id: school_id
        })
    }

    static getMeal(meal_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/get', {
            id: meal_id
        })
    }

    static deleteMeal(meal_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/delete', {
            id: meal_id
        })
    }

    static getMealGroup(meal_group_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/get', {
            id: meal_group_id
        })
    }

    static getMealGroupsForSchool(school_id, have_menu = undefined, student_id = undefined, student_school_year_id = undefined, student_in_future = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/list', {
            conditions: {
                school_id: [
                    { operator: 9, value: school_id }
                ],
                ...(have_menu !== undefined ? {
                    have_menu: [
                        { operator: have_menu ? 11 : 12}
                    ]
                } : {}),
                ...(student_id !== undefined ? {
                    student_id: [
                        { operator: 9, value:student_id}
                    ]
                } : {}),
                ...(student_school_year_id !== undefined ? {
                    student_school_year_id: [
                        { operator: 9, value:student_school_year_id}
                    ]
                } : {}),
                ...(student_in_future !== undefined ? {
                    student_in_future: [
                        { operator: student_in_future ? 11 : 12}
                    ]
                } : {})
            },
        })
    }

    static findMenuForPeriod(meal_group_id, year, month) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/findMenuForPeriod', {
            meal_group_id: meal_group_id,
            year: year,
            month: month
        })
    }

    static getAcceptableDates(school_year_id, year, month) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/getAcceptableDates', {
            conditions: {
                school_year_id: [
                    { operator: 9, value:school_year_id}
                ],
                year: [
                    { operator: 9, value:year}
                ],
                month: [
                    { operator: 9, value:month}
                ]
            }
        })
    }

    static findStudentMenuForPeriod(student_id, meal_group_id, year, month) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/findStudentMenuForPeriod', {
            student_id: student_id,
            meal_group_id: meal_group_id,
            year: year,
            month: month
        })
    }

    static editMenuItemForPeriodDays(menu_for_period_id, menu_items_for_period_day_data) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/editMenuItemForPeriodDays', {
            menu_for_period_id: menu_for_period_id,
            menu_items_for_period_day_data: menu_items_for_period_day_data
        })
    }

    static closeMenuForPeriod(menu_for_period_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/closeMenuForPeriod', {
            menu_for_period_id: menu_for_period_id
        })
    }
    static deleteMenuForPeriod(menu_for_period_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/deleteMenuForPeriod', {
            menu_for_period_id: menu_for_period_id
        })
    }
    static closeStudentMenuForPeriod(student_menu_for_period_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/closeStudentMenuForPeriod', {
            student_menu_for_period_id: student_menu_for_period_id
        })
    }

    static reopenStudentMenuForPeriod(student_menu_for_period_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/reopenStudentMenuForPeriod', {
            student_menu_for_period_id: student_menu_for_period_id
        })
    }

    static getStudentMenuForPeriodPDF(student_menu_for_period_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/getStudentMenuForPeriodPDF', {
            student_menu_for_period_id: student_menu_for_period_id
        })
    }

    static getMenuForPeriodPDF(menu_for_period_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/getMenuForPeriodPDF', {
            menu_for_period_id: menu_for_period_id
        })
    }

    static getNextChoosableMenuItemDate(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/getNextChoosableMenuItemDate', {
            student_id: student_id
        })
    }

    static saveStudentMenuItemChanges(student_menu_for_period_id, student_menu_item_data){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/saveStudentMenuItemChanges', {
            student_menu_for_period_id: student_menu_for_period_id,
            student_menu_item_data: student_menu_item_data
        })
    }

    static createMenuFromFile(school_year_id, meal_group_id, year, month, hash) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/createMenuFromFile', {
            school_year_id: school_year_id,
            meal_group_id: meal_group_id,
            year: year,
            month: month,
            hash: hash
        })
    }

    static getMeals() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/list', {
        })
    }

    static getMealsForSchool(school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/getMealsForSchool', {
            school_id: school_id
        })
    }

    static getStudentReimbursementsForStudentForSchoolAndYear(student_id, school_id, year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/getStudentReimbursementsForStudentForSchoolAndYear', {
            student_id: student_id,
            school_id: school_id,
            year_id: year_id
        })
    }

    static getTransferForStudent(student_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/TransferOrder/getTransferForStudentByDate', {
            student_id: student_id,
        })
    }

    static getTransferOrderRulesForDate(school_id, date) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/TransferOrder/getRulesForDate', {
            school_id: school_id,
            date: date
        })
    }

    static setTransferOrderRulesForDate(school_id, date, rule_groups) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/TransferOrder/setRulesForDate', {
            school_id: school_id,
            date: date,
            rule_groups: rule_groups
        })
    }

    static deleteRulesByDate(school_id, date) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/TransferOrder/deleteRulesByDate', {
            school_id: school_id,
            date: date,
        })
    }

    static loadDiningsFromFile(school_year_id, hash, excel_report = false) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/loadFromFile', {
            hash: hash,
            school_year_id: school_year_id,
            excel_report: excel_report
        })
    }

    static listPeriods(conditions, parameters, orders) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Period/list', {
            conditions: conditions,
            parameters: parameters,
            orders: orders
        })
    }

    static listStudentPeriods(conditions, parameters, orders) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Period/listStudentPeriod', {
            conditions: conditions,
            parameters: parameters,
            orders: orders
        })
    }

    static editPeriods(school_year_id, periods) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Period/edit', {
            school_year_id: school_year_id,
            periods: periods
        })
    }

    static listTransferOrderRuleIntervalsForSchool(school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/TransferOrder/listRuleIntervalsForSchool', {
            school_id: school_id
        })
    }

    static generateTransferOrdersForPeriod(school_year_id, year, month) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/TransferOrder/generateTransferOrdersForPeriod', {
            school_year_id: school_year_id,
            year: year,
            month: month
        })
    }

    static getTransfersForStudentForSchoolAndYear(student_id, school_id, year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/TransferOrder/getTransfersForStudentForSchoolAndYear', {
            student_id: student_id,
            school_id: school_id,
            year_id: year_id
        })
    }

    static getReimbursementProviders(fields = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/listReimbursementProviders', {
            parameters: {
                fields: fields
            }
        })
    }

    static getReimbursementTypes(fields = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/listReimbursementTypes', {
            parameters: {
                fields: fields
            }
        })
    }

    static editReimbursementTypes(types) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/editReimbursementTypes', {
            types: types
        })
    }

    static editReimbursementProviders(providers) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/editReimbursementProviders', {
            credentials: 'include',
            providers: providers
        })
    }


    static getMealPrices(school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/listPrices', {
            conditions: {
                date: [
                    { operator: 0, value: (new Date()).toISOString().split("T")[0] }
                ],
                school_id: [
                    { operator: 9, value: school_id }
                ]
            }
        })
    }

    static reportCall(url, params = {}) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/' + url, {
            ...params
        })
    }

    static getMealPricesForSchool(school_id, date) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/listPrices', {
            conditions: {
                school_id: [
                    { operator: 9, value: school_id }
                ],
                date: date ? [
                    { operator: 0, value: date }
                ] : undefined,
                date_to: !date ? [
                    { operator: 3 }
                ] : undefined
            }
        })
    }


    static getReimbursements(only_active = false) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/list', {
            conditions: (only_active ? {
                active: [
                    { operator: 11 }
                ]
            } : {}),
        })
    }

    static getMealPricesForMealGroup(meal_group_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/getMealPrices', {
            meal_group_id: meal_group_id
        })
    }
    static getReimbursementAids(reimbursement_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/getAids', {
            id: reimbursement_id
        })
    }

    static createReimbursementAndDiningsWithoutRequest(student_id, school_year_id, meal_group_id, date_from, transfer_orders, reimbursement_student_data, deleteable_dining_student_ids) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/createReimbursementAndDiningsWithoutRequest', {
            student_id: student_id,
            school_year_id: school_year_id,
            meal_group_id: meal_group_id !== "" ? meal_group_id : null,
            date_from: meal_group_id !== "" ? date_from : null,
            transfer_orders: transfer_orders,
            reimbursement_student_data: reimbursement_student_data,
            deleteable_dining_student_ids: deleteable_dining_student_ids
        })
    }

    static getFirstDayOfMonthForStudentByYearAndSchool(year_id, school_id, student_id, year, month) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/getFirstDayOfMonthForStudentByYearAndSchool', {
            student_id: student_id,
            year_id: year_id,
            school_id: school_id,
            year: year,
            month: month
        })
    }
    static getMealRequestBorderForDate(student_id, school_year_id, date_from, cancellation) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/getBorderForDate', {
            student_id: student_id,
            school_year_id: school_year_id,
            date_from: date_from,
            cancellation: cancellation
        })
    }

    static createMeal(name, xmenza_code, class_type) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/create', {
            name: name,
            xmenza_code: xmenza_code,
            class_type: class_type
        })
    }

    static editMeal(id, name, xmenza_code, class_type) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/edit', {
            id: id,
            name: name,
            xmenza_code: xmenza_code,
            class_type: class_type
        })
    }

    static createReimbursement(name, description, provider_id, type_id, active, aids) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/create', {
            name: name,
            description: description,
            provider_id: provider_id,
            type_id: type_id,
            active: active,
            aids: aids
        })
    }

    static editReimbursement(id, name, description, provider_id, type_id, active, aids) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Reimbursement/edit', {
            id: id,
            name: name,
            description: description,
            provider_id: provider_id,
            type_id: type_id,
            active: active,
            aids: aids
        })
    }

    static createMealGroup(name, description, school_id, active, is_dietary, have_menu, class_type, xmenza_code, meals, menu_items) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/create', {
            name: name,
            description: description,
            active: active,
            is_dietary: is_dietary,
            have_menu: have_menu,
            school_id: school_id,
            class_type: class_type || null,
            xmenza_code: xmenza_code,
            meal_ids: meals.map(meal => meal.id),
            menu_items: menu_items
        })
    }

    static editMealGroup(id, name, description, active,is_dietary, have_menu, class_type, xmenza_code, meals, menu_items) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/edit', {
            id: id,
            name: name,
            description: description,
            active: active,
            is_dietary: is_dietary,
            have_menu: have_menu,
            class_type: class_type || null,
            xmenza_code: xmenza_code,
            meal_ids: meals.map(meal => meal.id),
            menu_items: menu_items
        })
    }

    static setPricesForSchool(school_id, meal_prices, date) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/setPricesForSchool', {
            meal_prices: meal_prices,
            school_id: school_id,
            date: date
        })
    }

    static getPaymentMethods() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/PaymentMethod/list', {
            conditions: {
                active: [
                    { operator: 11 }
                ]
            }
        })
    }

    static getMealXMeanzaCodes() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/getXMenzaCodes', {
        })
    }

    static reportDownload(riport_url, params) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + (riport_url[0] === '/' ? '' : '/') + riport_url, {
            ...Object.keys(params).filter(key => params[key] !== '').reduce((prev, curr) => ({
                ...prev,
                [curr]: params[curr]
            }), {})
        })
    }

    static openReport(riport_url, params, limit = undefined, offset = undefined, orders = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/' + riport_url, {
            ...Object.keys(params).filter(key => params[key]).reduce((prev, curr) => ({
                ...prev,
                [curr]: params[curr]
            }), {}),
            limit: limit,
            offset: offset,
            orders: orders
        })
    }

    static listMealRequests(conditions, limit = undefined, offset = undefined, orders = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/list',{
            conditions: conditions,
                parameters: {
                    limit: limit,
                    offset: offset,
                    info: true    
                },
                orders: orders
        })
    }

    static listStudentDataByStudentInformationCondition(conditions, limit = undefined, offset = undefined, orders = undefined) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/listStudentDataByStudentInformationCondition',{
            conditions: conditions,
                parameters: {
                    limit: limit,
                    offset: offset,
                    info: true    
                },
                orders: orders
        })
    }

    static getMultipleStudentBatchTemplateHash() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/getTemplateForMultipleStudentBatch', {
        })
    }

    static listBillingState() {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/listBillingState', {})
    }

    static closeDiningByClassIDs(school_id, year_id, date, class_ids) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealGroup/closeDiningByClassIDs', {
            school_id: school_id,
            year_id: year_id,
            date: date,
            class_ids: class_ids
        })
    }


    static changeClassForStudents(source_year_id, source_school_id, source_class_id, target_year_id, target_school_id, target_class_id, students, date) {

        return (target_school_id == -1 || students.length === 0 ? new Promise((resolve, reject) => {
            throw new Error('A céliskolát és az áthelyezendő tanulókat ki kell választani')
        }) : this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/StudentRegister/changeClassForStudents', {
            original_year_id: source_year_id,
            original_school_id: source_school_id,
            original_class_id: source_class_id,
            new_year_id: target_year_id,
            new_school_id: target_school_id,
            new_class_id: target_class_id,
            student_ids: students,
            date: date
        }))
    }

    static getBalances(year_id, school_id, class_id, student_id, school_year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/listBalances', {
            conditions: {
                year_id: year_id ? [
                    { operator: 9, value: year_id }
                ] : undefined,
                school_id: school_id ? [
                    { operator: 9, value: school_id }
                ] : undefined,
                class_id: class_id ? [
                    { operator: 9, value: class_id }
                ] : undefined,
                student_id: student_id ? [
                    { operator: 9, value: student_id }
                ] : undefined,
                school_year_id: school_year_id ? [
                    { operator: 9, value: school_year_id }
                ] : undefined
            },
            group_fields: [school_id ? class_id ? 'student_id' : 'class_id' : student_id ? 'school_year_id' : 'school_id']
        })
    }

    static listSAPBalances(student_id, year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/listSAPBalances', {
            conditions: {
                student_id: student_id ? [
                    { operator: 9, value: student_id }
                ] : undefined,
                year_id: year_id ? [
                    { operator: 9, value: year_id}
                ] : year_id === null ? [{operator: 3}] : undefined
            }
        })
    }

    static getInvoices(year_id, school_id, class_id, student_id, school_year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/listInvoices', {
            conditions: {
                year_id: year_id ? [
                    { operator: 9, value: year_id }
                ] : undefined,
                school_id: school_id ? [
                    { operator: 9, value: school_id }
                ] : undefined,
                class_id: class_id ? [
                    { operator: 9, value: class_id }
                ] : undefined,
                student_id: student_id ? [
                    { operator: 9, value: student_id }
                ] : undefined,
                school_year_id: school_year_id ? [
                    { operator: 9, value: school_year_id }
                ] : undefined
            }
        })
    }

    static getXMenzaStatus(school_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/XMenza/getXMenzaStatus', {
            school_id: school_id
        })
    }
    
    static getGyermeketkeztetes2Status(school_year_id) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Gyermeketkeztetes2/getStatus', {
            school_year_id: school_year_id
        })
    }

    static listMealRequestFields(conditions= []) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestField/list',{
            conditions: conditions
        })
    }

    static editMealRequestFields(fields){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestField/editFields',{fields: fields})
    }

    static createMealRequestTemplate(school_id, document_hash, template_object_data){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestTemplate/create',{
            school_id: school_id,
            document_hash: document_hash,
            template_object_data : template_object_data
        })
    }

    static editMealRequestTemplate(id, template_object_data){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestTemplate/edit',{
            id: id,
            template_object_data : template_object_data
        })
    }

    static getActiveMealRequestTemplateForSchool(school_id, cancelling = null){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestTemplate/getActiveForSchool',{
            school_id: school_id,
            cancelling: cancelling
        })
    }

    static getActiveTemplateForSchool(school_id, type){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Template/getActiveForSchool',{
            school_id: school_id,
            type: type
        })
    }

    static createTemplate(school_id, document_hash, type){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Template/create',{
            school_id: school_id,
            document_hash: document_hash,
            type : type
        })
    }

    static getFilledTemplateHash(document_hash, field_ids, school_id, year_id, student_id,template_type,in_system = false, date = null, zero_disable = null){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Template/getDocumentHashForSchool',{
            school_id: school_id,
            template_document: document_hash,
            year_id: year_id,
            template_field_ids: field_ids,
            student_id: student_id,
            template_type: template_type,
            in_system: in_system,
            date: date,
            zero_disable: zero_disable
        })
    }
    static getMultiFilledTemplateHash(document_hash, field_ids, school_id, year_id,class_id, student_ids,template_type,in_system = false, date = null, zero_disable=null){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Template/getDocumentHashForMultipleStudents',{
            school_id: school_id,
            template_document: document_hash,
            year_id: year_id,
            template_field_ids: field_ids,
            template_type: template_type,
            conditions: {
                class_id: class_id ? [
                    { operator: 9, value: class_id }
                ] : undefined,
                student_id: student_ids.length > 0 ? [
                    { operator: 13, value: student_ids }
                ] : undefined
            },
            in_system: in_system,
            date: date,
            zero_disable: zero_disable
        })
    }

    static getDefaultMealRequestForStudent(id, student_id, school_id, year_id, in_system = true, cancelling_dining_student = null){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestTemplate/getDefaultMealRequestForStudent',{
            school_id: school_id,
            id: id,
            year_id: year_id,
            student_id: student_id,
            in_system: in_system,
            cancelling_dining_student: cancelling_dining_student
        })
    }

    static getDefaultValuesForMealRequestFields(field_ids, student_id, school_id,year_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestTemplate/getDefaultValuesForFields',{
            school_id: school_id,
            field_ids: field_ids,
            year_id: year_id,
            student_id: student_id
        })
    }

    static listFieldsWithPossibleValues(fields, student_id, school_id, year_id, cancelling){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestTemplate/listFieldsWithPossibleValues',{
            school_id: school_id,
            fields: fields,
            year_id: year_id,
            student_id: student_id,
            cancelling: cancelling
        })
    }

    static createMealRequest(school_year_id, student_id, template_id, data, document_hash, in_system, cancelling_dining_student){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/create',{
            school_year_id: school_year_id,
            template_id: template_id,
            data: data,
            student_id: student_id,
            document_hash: document_hash,
            in_system: in_system,
            cancelling_dining_student: cancelling_dining_student
        })
    }

    static getMealRequest(id, required_school_id = null){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/get',{
            id: id,
            required_school_id: required_school_id
        })
    }

    static editMealRequest(id, document_hash, data){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/edit',{
            id: id,
            data: data,
            document_hash: document_hash
        })
    }

    static getUnseenStudentData(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getUnseenStudentData',{
            student_id: student_id
        })
    }

    static countUnseenStudentData(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/countUnseenStudentData',{
            student_id: student_id
        })
    }

    static countUnSeenMealRequests(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/countUnSeenMealRequests',{
            student_id: student_id
        })
    }

    static rollbackMealRequest(id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/rollback',{
            id: id
        })
    }

    static rejectMealRequest(id, message){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/reject',{
            id: id,
            message: message
        })
    }

    static acceptMealRequest(id, message, student_id, school_year_id, meal_group_id, transfer_orders, date_from, reimbursement_student_data, deleteable_dining_student_ids){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/accept',{
            id: id,
            message: message,
            student_id: student_id,
            school_year_id: school_year_id,
            meal_group_id: meal_group_id || null,
            transfer_orders: transfer_orders,
            date_from: date_from,
            reimbursement_student_data: reimbursement_student_data,
            deleteable_dining_student_ids: deleteable_dining_student_ids
        })
    }

    static listStudentMealRequests(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/list',{
            conditions: {
                student_id: [
                    {operator: 9, value: student_id}
                ]
            }
        })
    }

    static listSchoolMealRequests(school_id, state = null, parameters = {}){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/list',{
            conditions: {
                school_id: [
                    {operator: 9, value: school_id}
                ],
                state: (state ? [
                    {operator: 13, value: state}
                ]: [])
            },
            parameters: parameters
        })
    }

    static getTemplateForMealRequest(meal_request_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequestTemplate/getTemplateForMealRequest',{
            meal_request_id: meal_request_id
        })
    }

    static listStudentDocument(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/StudentDocument/list',{
            conditions: {
                student_id: [
                    {operator: 9, value: student_id}
                ]
            }
        })
    }

    static editStudentDocuments(student_id, documents){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/StudentDocument/editStudentDocuments',{
            student_id: student_id,
            documents: documents
        })
    }

    static getNextAndPrevMealRequestIDForSchool(id, school_id, conditions){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/getNextAndPrevIDForSchool',{
            id: id,
            school_id: school_id,
            conditions: conditions
        })
    }

    static listMealRequestMessages(meal_request_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/listMealRequestMessages',{
            meal_request_id: meal_request_id
        })
    }
    static setSeenMealRequestMessages(meal_request_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/setSeenMealRequestMessages',{
            meal_request_id: meal_request_id
        })
    }

    static setSeenDocuments(document_ids){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/StudentDocument/setSeenStudentDocuments',{
            document_ids: document_ids
        })
    }

    static setSeenMealRequestMessage(message_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/setSeenMealRequestMessage',{
            message_id: message_id
        })
    }
    static addMessageToMealRequest(id,message){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/addMessage',{
            id: id,
            message: message
        })
    }

    static listStudentDataInformation(conditions, parameters, orders, group_fields){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/listStudentDataInformation',{
            conditions: conditions,
            parameters: parameters,
            orders: orders,
            group_fields: group_fields
        })
    }

    static canWriteSchoolYearDiningCalendar(school_year_id, class_id, student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DiningCalendar/canWriteSchoolYear',{
            school_year_id: school_year_id,
            class_id: class_id,
            student_id: student_id
        })
    }

    static getNextAndPrevStudentDataIDForSchool(student_id, school_id, conditions, parameters, group_fields) {
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getNextAndPrevIDForSchool',{
            school_id: school_id,
            conditions: conditions,
            parameters: parameters,
            group_fields: group_fields,
            student_id: student_id
        })
    }

    static getStudentByStudentData(id, student_data_id, school_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getStudentByStudentData',{
            school_id: school_id,
            student_data_id: student_data_id,
            id: id
        })
    }

    static acceptStudentData(id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/accept',{
            id: id
        })
    }
    static rejectStudentData(id, message, school_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/reject',{
            message: message,
            id: id,
            school_id:school_id
        })
    }
    static getStudentSchools(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/School/list',{
            conditions: {
                student_id: [
                    {operator: 9, value: student_id}
                ]
            }
        })
    }

    static getSchoolIDForStudentDaa(student_data_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getSchoolIDForStudentDaa',{
            student_data_id: student_data_id
        })
    }

    static getStudentStudentRegisters(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/StudentRegister/list',{
            conditions: {
                student_id: [
                    {operator: 9, value: student_id}
                ]
            },
            orders: [
                {field: 'date_from', order: 'DESC'},
            ]
        })
    }

    static getSystemMessages(){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/getSystemMessages',{})
    }

    static getMealRequestUnSeenMessageCount(meal_request_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/MealRequest/getUnSeenMessageCount',{
            id: meal_request_id
        })
    }

    static getSystemMessagesCount(student_id = null){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/getSystemMessagesCount',{
            student_id: student_id
        })
    }

    static setStudentDataSeen(student_data_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/setStudentDataSeen',{
            student_data_id: student_data_id
        })
    }
    static editStudentsRegisters(student_id, student_register_data){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/StudentRegister/editStudentsRegisters',{
            student_id: student_id,
            student_register_data: student_register_data
        })
    }
    static listSchoolYears(conditions, parameters, orders){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/list',{
            conditions: conditions,
            parameters: parameters,
            orders: orders
        })
    }
    static listClasses(conditions, parameters, orders){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolClass/list',{
            conditions: conditions,
            parameters: parameters,
            orders: orders
        })
    }

    static listIntervalsForSchool(school_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Meal/listIntervalsForSchool',{
            school_id: school_id
        })
    }

    static addMessageToStudentData(id, message, school_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/addMessageToStudentData',{
            id: id,
            message: message,
            school_id: school_id
        })
    }

    static getCheckExcelForSAPCodeFile(school_id, hash){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getCheckExcelForSAPCodeFile',{
            school_id: school_id,
            hash: hash
        })
    }

    static loadSAPCodesFromFile(school_id, hash){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/loadSAPCodesFromFile',{
            school_id: school_id,
            hash: hash
        })
    }

    static getTemplateForMultipleSAPCustomerCodeBatch(){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Student/getTemplateForMultipleSAPCustomerCodeBatch',{})
    }

    static resetPasswordForStudent(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/resetPasswordForStudent',{
            student_id: student_id
        })
    }

    static getPeriodsForSchoolYear(id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SchoolYear/getPeriods',{
            id: id
        })
    }

    static recentlyActiveUsers(){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/User/recentlyActiveUsers',{})
    }

    static listSavedReports(conditions, parameters, orders){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Report/listSavedReports',{
            conditions: conditions,
            parameters: parameters,
            orders: orders
        })
    }

    static getSapDiffs(){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SAPDiff/getDiff',{})
    }

    static listOrgSAPCustomerCodes(conditions = []){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SAPCustomerCode/listOrgSAPCustomerCodes',{
            conditions: conditions
        })
    }

    static editOrgSAPCustomerCodes(data){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/SAPCustomerCode/editOrgSAPCustomerCodes',{
            data: data
        })
    }

    static downloadEInvoice(invoice_number){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/downloadEInvoice/',{
            invoice_number: invoice_number
        })
    }

    static countUnseenEInvoices(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/countUnseenEInvoices/',{
            student_id: student_id
        })
    }

    static getActiveYearIDsForStudent(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Year/getActiveYearIDsForStudent',{
            student_id: student_id
        })
    }

    static getSummarizedBalances(student_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/Balance/getSummarizedBalances',{
            student_id: student_id
        })
    }

    static getStudentDocumentTypes(){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/StudentDocument/getTypes',{})
    }

    static getDocuhubDocumentsForSchool(school_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DocuHub/getDocumentsForSchool',{
            school_id: school_id
        })
    }

    static downloadDocuhubDocumentsForSchool(school_id, docuhub_id){
        return this.fetchCanteen(process.env.REACT_APP_SERVICE_URL + '/CanteenService/DocuHub/download',{
            school_id: school_id,
            docuhub_id: docuhub_id
        })
    }
}

export default CanteenClient
