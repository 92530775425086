import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"

import Loading from "@sapkk/app/components/Loading"

class Iskolak extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            schools: []
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getSchools()
        ]).then(([schools]) => {
            if(schools.length===1){
                this.props.history.replace('/iskola-kezelese/'+schools[0].id)
            } else{
                this.props.setTitle('Iskolák')
                this.props.setGuide(null)
                this.setState({
                    schools: schools,
                    loading: false
                })
            }
        }))
    }
    render() {
        return <React.Fragment>
            {this.state.loading ? <Loading loading_text=''/> : <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Név</th>
                            <th>Rövidítés</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.schools.map(school =>{
                                return <tr>
                                    <td><Link to={'/iskola-kezelese/'+school.id}>{school.name}</Link></td>
                                    <td>{school.abbreviated_name}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div> 
            }
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Iskolak))