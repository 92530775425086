import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import { withRouter } from "react-router"

class Field extends Component {
    constructor(props) {
        super(props)

    }

    convertText(){
        let text = this.props.text
        Object.keys(this.props.data).forEach(field_name => {
            let value = this.props.data[field_name].value
            let field = this.props.fields.find(f => f.name==field_name) || {}
            if(field.multiple){
                value = (value||[]).join(', ')
            } else{
                value = value || ''
            }
            if(!value && field.default_length){
                value = '_'.repeat(field.default_length)
            }
            text = text.replace(new RegExp('\{\{ '+field_name+' \}\}', 'g'), value)
        })
        text = text.replace(new RegExp('\{\_\{', 'g'), '{{')
        text = text.replace(new RegExp('\{\_%', 'g'), '{%')
        text = text.replace(new RegExp('\}\_\}', 'g'), '}}')
        text = text.replace(new RegExp('%\_\{', 'g'), '%}')
        return text
    }

    render() {
        return <div className={["block"].concat(this.props.style || []).join(' ')}>
            {this.convertText()}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Field))