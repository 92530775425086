import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import DateField from "@sapkk/app/components/DateField"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";

class EtkezesAranakKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            meals: [],
            intervals: [],
            school_id: this.props.school,
            data: undefined,
            new: undefined,
            new_interval_from: undefined,
            new_meal_prices: {},
            fetch_in_progress: true,
            save_in_progress: false
        }
    }

    componentDidMount() {

        this.setState({
            fetching_meals: true,
            meals: [],
            data: undefined
        },()=>{
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getMealsForSchool(this.state.school_id),
                CanteenClient.listIntervalsForSchool(this.state.school_id)
            ]).then(([meals, intervals])=>{
                this.setState({
                    meals: meals,
                    intervals: intervals,
                    fetching_meals: false,
                    date: intervals.length > 0 ? intervals[intervals.length - 1].date_from : 0,
                    fetch_in_progress:  intervals.length > 0
                }, ()=>{
                    this.handleFetch()
                })
            }))
        })
    }

    handleDateChange(date) {
        this.setState({
            date: date,
            data: undefined
        })
    }

    handleNew() {
        this.setState({
            date: "",
            new_in_progress: true,
        }, () => {
            this.props.promise_error_handler(CanteenClient.getMealPricesForSchool(this.state.school_id, null).then(meal_prices => {
                this.setState({
                    data: meal_prices,
                    new_interval_from: "",
                    new_meal_prices: {},
                    new: true,
                    new_in_progress: false
                })
            }))
        })
    }

    handleFetch() {
        if(this.state.date){
            this.setState({
                fetch_in_progress: true,
            }, () => {
                this.props.promise_error_handler(CanteenClient.getMealPricesForSchool(this.state.school_id, this.state.date).then(meal_prices => {
                    this.setState({
                        data: meal_prices,
                        new_interval_from: meal_prices.interval.from,
                        new_meal_prices: meal_prices.prices.reduce((prev, curr) =>({...prev,[curr.meal.id]: curr.price.amount}),{}),
                        new: false,
                        fetch_in_progress: false
                    })
                }))
            })
        }
    }

    handleIntervalFromChange(value) {
        this.setState({
            new_interval_from: value
        })
    }

    handlePriceChange(meal_id, amount) {
        this.setState(prevState => ({
            new_meal_prices: {
                ...prevState.new_meal_prices,
                [meal_id]: amount
            }
        }))
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.setPricesForSchool(this.state.school_id, this.state.new_meal_prices, this.state.new_interval_from).then((meal_prices) => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                this.setState({
                    date: meal_prices.interval.from,
                    data: meal_prices,
                    new_interval_from: meal_prices.interval.from,
                    new_meal_prices: meal_prices.prices.reduce((prev, curr) =>({...prev,[curr.meal.id]: curr.price.amount}),{}),
                    new: false,
                    save_in_progress: false
                })
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    render() {
        let readOnly = !this.props.rights_for_school.includes('WRITE_PRICE')
        return <React.Fragment>
            {
                <React.Fragment><div className="page-subtitle">Árdátum kiválasztása</div>
                    <div className={readOnly ? 'student-select-block': "entity-select-block"}>
                        <div>
                            <div className="block block-lg-1-1">
                                <select className="field field-simple" value={this.state.date} onChange={event => this.handleDateChange(event.target.value)}>
                                    <option>Kérem, válasszon!</option>
                                    {this.state.intervals.map(interval_data => <option value={interval_data.id}>{interval_data.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div>
                            <LoadingButton disabled={this.state.fetching_meals || !this.state.school_id || !this.state.date || this.state.new_in_progress} loading={this.state.fetch_in_progress} onClick={() => this.handleFetch()}>Lekérdez</LoadingButton>
                            {!readOnly ? <LoadingButton disabled={this.state.fetching_meals || !this.state.school_id || this.state.fetch_in_progress} onClick={() => this.handleNew()} loading={this.state.new_in_progress}>Új</LoadingButton> : null}
                        </div>
                    </div>
            </React.Fragment> }
            { this.state.data ?
                <React.Fragment>
                    <div className="page-subtitle">Árak érvényessége</div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Érvényesség kezdete</label>
                        <DateField placeholder="éééé.hh.nn." className="field field-simple" readOnly={this.state.new !== true} value={this.state.new_interval_from} onChange={event => this.handleIntervalFromChange(event.target.value)}/>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Érvényesség vége</label>
                        <DateField placeholder="éééé.hh.nn." className="field field-simple" readOnly={true} value={this.state.data.interval.to}/>
                    </div>
                    <div className="page-subtitle">Étkezések</div>
                    <div className="table-container">
                        <table className="modify-table striped">
                            <thead>
                                <tr>
                                    <th>Étkezés neve</th>
                                    <th>Osztálytípus</th>
                                    <th>Étkezés bruttó ára (Ft)</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.meals.map(meal =>
                                    <tr>
                                        <td>{meal.name}</td>
                                        <td>{meal.class_type ? (this.props.class_types[meal.class_type] || {}).name || 'Ismeretlen osztálytípus' : null}</td>
                                        <td><SimpleField readOnly={readOnly || this.state.data.interval.to || !this.state.new && !this.state.data.interval.from} className={(!readOnly && (this.state.new || !this.state.data.interval.to && this.state.data.interval.from)) ? "field field-simple": null} value={this.state.new_meal_prices[meal.id]} onChange={(event) => {this.handlePriceChange(meal.id, event.target.value)}}/></td>
                                    </tr>
                                ) }
                            </tbody>
                        </table>
                    </div>
                    <div className="actions">
                        { !readOnly && (this.state.new || !this.state.data.interval.to) && <LoadingButton loading={this.state.save_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton> }
                    </div>
                </React.Fragment> : null
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        class_types: state.class_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EtkezesAranakKezelese))
