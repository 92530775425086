
import React, { Component } from "react"
import { connect } from "react-redux"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import { setTitle, setGuide } from "@sapkk/app/actions"
import Loading from "@sapkk/app/components/Loading"
import DateTimeField from "@sapkk/app/components/DateTimeField"

class NemregAktivFelhasznalok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            recently_active_users: [],
            interval: undefined,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        this.props.setTitle("Nemrég aktív felhasználók")
        this.props.setGuide(null)
        this.getRecentlyActiveUsers()
        this.setState({
            interval: setInterval(this.getRecentlyActiveUsers, 60000)
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.interval)
    }

    getRecentlyActiveUsers = () => {
        CanteenClient.recentlyActiveUsers().then((recently_active_users) => {
            this.setState({
                recently_active_users: recently_active_users,
                fetch_in_progress: false
            })
        })
    }

    render() {
        return !this.state.fetch_in_progress ? <React.Fragment>
            <div className="page-subtitle">Nemrég aktív felhasználók listája</div>
            <table>
                <thead>
                    <tr>
                        <th>Felhasználónév</th>
                        <th>Teljes név</th>
                        <th>Utolsó aktivitás</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.recently_active_users.length !== 0 ? this.state.recently_active_users.map(rau => <tr>
                            <td>{rau.username}</td>
                            <td>{rau.full_name}</td>
                            <td><DateTimeField readOnly={true} value={rau.last_timestamp}/></td>
                        </tr>
                    ) : <tr className="table-empty-indicator">
                            <td colSpan={3}>Nincs aktív felhasználó</td>
                        </tr>}
                </tbody>
            </table>
        </React.Fragment> : <Loading loading_text = ''/>
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NemregAktivFelhasznalok))