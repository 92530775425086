import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import CanteenClient from "../../CanteenClient"
import Loading from "@sapkk/app/components/Loading"
import { clearMessages, addMessage } from "@sapkk/app/actions"
import SeparatedNumberField from "@sapkk/app/components/SeparatedNumberFields"
import SzamlatortenetEv from "./SzamlatortenetEv"
import PopUp from "../../components/PopUp";
import {addAction, removeAction} from "../../actions";

class Szamlatortenet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            student: this.props.student || null,
            years: [],
            summarized_balances: [],
            fetching: true,
            show_details_sum : false,
            show_details_years: []
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/tanulo-kezelese/T'+(-1*this.state.student)+'/szamlak'){
            this.props.history.replace('/tanulo-kezelese/T'+(-1*this.state.student)+'/szamlak')
        } else{
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getActiveYearIDsForStudent(this.state.student),
                CanteenClient.getSummarizedBalances(this.state.student)
            ])).then(([years, summarized_balances]) => {
                this.setState({
                    years: years,
                    summarized_balances: summarized_balances,
                    fetching: false
                })
            })
        }
        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
    }

    componentWillUnmount(){
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }
    
    toggleShowDetailsSum = () => {
        this.setState((prevState) => ({
            show_details_sum: !prevState.show_details_sum,
            show_details_years: []
        }))
    }
    
    toggleShowDetailsYears = (year_id) => {
        this.setState((prevState) => {
            let index = prevState.show_details_years.indexOf(year_id)
            return  {
                show_details_years: index !== -1 ? prevState.show_details_years.slice(0,index).concat(prevState.show_details_years.slice(index + 1)) : prevState.show_details_years.concat([year_id])
            }
        })
    }

    render() {
        let non_active_year_summarized_balances = this.state.summarized_balances.filter(sb => !this.state.years.map(y => y.id).includes(sb.year_id))
        let non_active_year_sum = non_active_year_summarized_balances.reduce((prev, curr) => prev + curr.sum, 0)
        return this.state.fetching ? <Loading loading_text=''/> : <div className="block">
            { this.state.popup_help_open && (
                <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                    <p>
                        <div>2022. szeptemberétől bevezetésre került az elektronikus számlázási rendszer.</div>
                        <div>Az elkészült elektronikus számlák megtekintésére, letöltésére, a papíralapú számlák számlasorszámának, összegének megtekintésére az UD Menza rendszerben, a Számlák menüpont alatt van lehetőség.</div>
                    </p>
                    <p>
                        <div>A számláról az alábbi információk érhetők el</div>
                    </p>
                    <p>
                        <div><span style={{fontWeight: "bold"}}>Iskolakód:</span> a számla az adott iskolában történő étkezéshez került kiállításra</div>
                    </p>
                    <p>
                        <div><span style={{fontWeight: "bold"}}>Időszak:</span> számlázási (étkezési) időszak</div>
                    </p>
                    <p>
                        <div><span style={{fontWeight: "bold"}}>Számla sorszáma:</span> számla sorszáma</div>
                    </p>
                    <p>
                        <div><span style={{fontWeight: "bold"}}>Számla összege (HUF):</span> a számla bruttó összege</div>
                    </p>
                    <p>
                        <div><span style={{fontWeight: "bold"}}>Kiegyenlített összeg (HUF):</span> a kiegyenlített összeg az átutalt összegtől eltérhet, amennyiben a befizetés összege nem egyezik meg az átalánydíjjal</div>
                    </p>
                    <p>
                        <div><span style={{fontWeight: "bold"}}>e-számla:</span> amennyiben elektronikus számla került kiállításra, megjelenik az <span className="download-epdf-icon"/> ikon. Rákattintva a számla megtekinthető, letölthető.</div>
                    </p>
                    <p>
                        <div>Amennyiben a Törzsadat -> Kapcsolattartási adatok menüpont alatt megadásra kerül a kapcsolattartási e-mail cím, az elkészült számláról a rendszer automatikus tájékoztató üzenetet küld a megadott e-mail címre.</div>
                    </p>
                </PopUp>
            ) }
            {
                this.state.years.map(year => <React.Fragment>
                    <div className="page-subtitle">{year.name} tanév számlái</div>
                    <SzamlatortenetEv count_unseen_e_invoices={this.props.count_unseen_e_invoices} rights_for_student={this.props.rights_for_student} active={true} student={this.state.student} year={year.id}/>
                </React.Fragment>)
            }
            {non_active_year_summarized_balances.length !== 0 ? <React.Fragment>
                <div className='page-subtitle'>Korábbi tanévek egyenlege</div>
                <div className="block table-container">
                    <table className="recent-years-balance without-border">
                        <tbody>
                        <tr>
                            <td>Korábbi tanévek egyenlege (HUF)</td>
                            <td  className='non-active-years-balance-sum'><SeparatedNumberField className={'field field-simple'} readOnly={true} value={non_active_year_sum}/></td>
                            <td><button onClick={this.toggleShowDetailsSum}>{this.state.show_details_sum ? 'Elrejtés' : 'Megjelenítés'}</button></td>
                        </tr>
                        </tbody>
                    </table>
                    {
                        this.state.show_details_sum ? <table className="recent-years-balance">
                            <thead>
                            <tr>
                                <th>Tanév</th>
                                <th>Egyenleg (HUF)</th>
                                <th>Részletek</th>
                            </tr>
                            </thead>
                            <tbody>
                            {non_active_year_summarized_balances.map(naysb => <React.Fragment>
                                <tr>
                                    <td>{naysb.name}</td>
                                    <td><SeparatedNumberField readOnly={true} value={naysb.sum}/></td>
                                    <td>{
                                        naysb.in_system ? <button onClick={()=>this.toggleShowDetailsYears(naysb.year_id)}>{this.state.show_details_years.includes(naysb.year_id) ? 'Elrejtés' : 'Tanév számlái'}</button> : null }</td>
                                </tr>
                                {
                                    naysb.in_system && this.state.show_details_years.includes(naysb.year_id) ? <tr className="recent-year-details">
                                        <td colSpan={3}>
                                            <SzamlatortenetEv count_unseen_e_invoices={this.props.count_unseen_e_invoices} rights_for_student={this.props.rights_for_student} active={false} student={this.state.student} year={naysb.year_id}/>
                                        </td>
                                    </tr> : null
                                }
                            </React.Fragment>)}
                            </tbody>
                        </table> : null
                    }
                </div>
            </React.Fragment> : null}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    addMessage: addMessage,
    clearMessages: clearMessages,
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Szamlatortenet))