import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import { Link } from "react-router-dom"

class RendszerUzenetek extends Component {
    constructor(props) {
        super(props)

        this.state = {
            system_messages: [],
            class: "",
            classes: [],
            ignored_classes: [],
            data: undefined,
            save_in_progress: false,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(CanteenClient.getSystemMessages().then(system_messages => {
            this.props.getSystemMessagesCount(()=>{
                this.setState({
                    system_messages: system_messages,
                    fetch_in_progress: false
                })
            })
        }))
        this.props.setTitle('Rendszerüzenetek')
        this.props.setGuide(null)
    }

    getLinkForMessage(message){
        switch (message.type) {
            case 'student_data_rejected':
                return '/tanulo-kezelese/T'+(-1*message.student_id)+'/adatok/modositastortenet/'+message.id+'/'+message.school_id
            case 'meal_request_new_message':
                return '/tanulo-kezelese/T'+(-1*message.student_id)+'/tanuloi-igenylesek/korabbi/'+message.id
            case 'new_document':
                return '/tanulo-kezelese/T'+(-1*message.student_id)+'/dokumentumok'
            case 'non_received_sap_balance':
                return '/tanulo-kezelese/T'+(-1*message.student_id)+'/szamlak'
            case 'unaccepted_disabled_einvoice_interval':
                return {pathname: '/tanulo-kezelese/T'+(-1*message.student_id)+'/adatok/torzsadat', state: {page: 'szamlaformatum'}}
            case 'not_closed_school_menu':
                return {pathname: '/iskola-kezelese/'+(message.school_id)+'/menuk', state: {school_year_id: message.school_year_id, meal_group_id: message.meal_group_id, period: message.period}}
            case 'missing_menu_item_with_date':
                return {pathname: '/iskola-kezelese/'+(message.school_id)+'/menuk', state: {school_year_id: message.school_year_id, meal_group_id: message.meal_group_id, period: message.period}}
            case 'not_closed_student_menu':
                return {pathname: '/tanulo-kezelese/T'+(-1*message.student_id)+'/menu', state: {school_year_id: message.school_year_id, student_id: message.student_id, meal_group_id: message.meal_group_id, period: message.period}}
            default:
                return null
        }
    }

    render() {
        return <React.Fragment>
            {!this.state.fetch_in_progress ?<React.Fragment>
                <div className="table-container">
                    <table className="striped modify-table">
                        <thead>
                            <tr>
                                <th>Üzenet</th>
                                <th>Időpont</th>
                                <th>Érintett tanuló</th>
                            </tr>
                        </thead>
                        <tbody>
                        { this.state.system_messages.length !== 0 ? this.state.system_messages.map((message, id) =>{
                            let link = this.getLinkForMessage(message)
                            return <tr key={id}>
                                <td>{link ? <Link to={link}>{message.message}</Link> : message.message}</td>
                                <td>{message.timestamp}</td>
                                <td><Link to={'/tanulo-kezelese/T'+(-1*message.student_id)}>{message.student_name}</Link></td>
                            </tr>}
                        ): <tr className="table-empty-indicator">
                            <td colSpan={3}>Nincs új rendszerüzenet</td>
                        </tr> }
                        </tbody>
                    </table>
                </div>
            </React.Fragment> : <Loading loading_text=''/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
        class_types: state.class_types,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RendszerUzenetek))