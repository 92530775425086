import React, { Component } from 'react'
import { setGuide, setTitle, addAction, removeAction} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import { Link } from "react-router-dom"
import LoadingButton from '../components/LoadingButton';
import SelectField from '@sapkk/app/components/SelectField'
import PopUp from '../components/PopUp'
import FilestoreClient from '../FileStoreClient'
import {month_names} from "../constants";


class Menzanaptar extends Component {
    constructor(props) {
        super(props)
        this.file_input = React.createRef()
        this.state = {
            school: this.props.school || null,
            class: this.props.class || null,
            student: this.props.student || null,
            open: false,
            date_from: { year: 0, month: 1 },
            date_to: { year: 9999, month: 12 },
            date_selected: { year: 0, month: 1 },
            data_valid: false,
            month_current: undefined,
            month_prev: undefined,
            month_next: undefined,
            save_in_progress: false,
            disable_menu: true,
            guide: null,
            fetch_in_progress: true,
            school_year: this.props.school_year || '',
            file_input_key: 0
        }
    }

    componentDidMount() {
        this.updateSeconds()
        this.setState({
            fetch_in_progress: true,
            data_valid: false,
            guide: Object.keys(this.props.rights.rights).length === 0 && this.props.school_data.dining_calendar_text ? this.props.school_data.dining_calendar_text : null
        },()=>{
            if (Object.keys(this.props.rights.rights).length === 0) {
                this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
            }
            this.props.promise_error_handler((()=>{
                if(this.state.student){
                    return CanteenClient.getSchoolYearsForStudent(this.state.student)
                } else {
                    return CanteenClient.getSchoolYearsBySchoolID(this.state.school)
                }
            })().then(school_years => {
                this.setState((prevState) => ({
                    school_years: school_years,
                }),()=>{
                    if(this.state.school_year && (this.state.school_years.find(sy=>sy.id==this.state.school_year))){
                        this.handleFetch()
                    } else if(this.state.school_years.length===1){
                        this.changeHistory(this.state.school_years[0].id)
                    } else if(this.state.school_years.find(sy=>sy.default)) {
                        this.changeHistory(this.state.school_years.find(sy=>sy.default).id)
                    } else {
                        this.changeHistory(null)
                    }
                })
            }))
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.school_year !== state.school_year) {
            return {
                school_year: props.school_year
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.school_year !== this.props.school_year) {
            this.handleFetch()
        }
    }

    componentWillUnmount() {
        this.props.removeAction('help')
        clearTimeout(this.state.update_seconds_timeout)
    }
    updateSeconds = ()=> {
        let date = new Date(Date.now() + 600000)
        let timeout = 1000 - date.getMilliseconds()
        this.setState((prevState)=> ({
            warning_datetime: date,
            update_seconds_timeout: setTimeout(this.updateSeconds, timeout > 500 ? timeout - 500 : timeout + 500)
        }))
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    changeHistory = (school_year,replace=true)=>{
        let method = (pathname) =>{
            if(this.props.history.location.pathname === pathname){
                this.setState({
                    fetch_in_progress: false
                })
            } else if(replace){
                this.props.history.replace(pathname)
            } else{
                this.props.history.push(pathname)
            }
        }
        let school_year_url = school_year ? '/'+school_year : ''
        if(this.state.student){
            method('/tanulo-kezelese/T'+(-1*this.state.student)+'/menzanaptar'+school_year_url)
        } else if(this.state.class){
            method('/osztaly-kezelese/'+this.state.class+'/menzanaptar'+school_year_url)
        } else {
            method('/iskola-kezelese/'+this.state.school+'/menzanaptar'+school_year_url)
        }
    }

    handleChangeSchoolYear = (event) => {
        this.changeHistory(event.target.value, false)
    }

    handleFetch = () =>{
        this.setState({
            fetch_in_progress: !!this.state.school_year,
            data_valid: false,
            school_data: null
        },()=>{
            if(this.state.school_year){
                this.props.promise_error_handler(Promise.all([
                    !this.state.student ? new Promise((resolve, reject) => resolve(null)) : CanteenClient.getLastStudentRegisterData(this.state.student, this.state.school_year),
                    CanteenClient.canWriteSchoolYearDiningCalendar(this.state.school_year, this.state.class, this.state.student),
                    CanteenClient.getSchool(this.state.school_years.find(sy => sy.id==this.state.school_year).school_id),
                    CanteenClient.getActiveDateRange(this.state.school_year,this.state.student).then(range => {
                        this.setState({
                            date_from: {year: range.from.year, month: range.from.month},
                            date_to: {year: range.to.year, month: range.to.month},
                        })
                        return {
                            date_from: {year: range.from.year, month: range.from.month},
                            date_to: {year: range.to.year, month: range.to.month},
                        }
                    }).then(range => {
                        let date_selected = this.state.date_selected.year == 0 ? { year: new Date().getFullYear(), month: new Date().getMonth() + 1 } : this.state.date_selected
                        if (date_selected.year + date_selected.month / 12 < range.date_from.year + range.date_from.month / 12) {
                            date_selected = { year: range.date_from.year, month: range.date_from.month }
                        } else if (date_selected.year + date_selected.month / 12 > range.date_to.year + range.date_to.month / 12) {
                            date_selected = { year: range.date_to.year, month: range.date_to.month }
                        }
            
                        if (this.state.student) {
                            return Promise.all([
                                CanteenClient.getStudentDiningCalendar(this.state.school_year,date_selected.year, date_selected.month, this.state.student),
                                CanteenClient.getStudentDiningCalendar(this.state.school_year,date_selected.year + Math.floor((date_selected.month - 2) / 12), (date_selected.month + 10) % 12 + 1, this.state.student),
                                CanteenClient.getStudentDiningCalendar(this.state.school_year,date_selected.year + Math.floor(date_selected.month / 12), date_selected.month % 12 + 1, this.state.student)
                            ])
                        } else if (this.state.class) {
                            return Promise.all([
                                CanteenClient.getClassDiningCalendar(this.state.school_year,date_selected.year, date_selected.month, this.state.class)
                            ])
                        } else if (this.state.school) {
                            return Promise.all([
                                CanteenClient.getSchoolDiningCalendar(this.state.school_year,date_selected.year, date_selected.month)
                            ])
                        }
                    })
                ]).then(([student_register_data, write_access, school_data,[month_current, month_prev, month_next]]) => {
                    this.setState({
                        fetch_in_progress: false,
                        write_access: write_access,
                        student_register_data : student_register_data,
                        school_data: school_data,
                        open: true,
                        date_selected: { year: month_current.year, month: month_current.month },
                        data_valid: true,
                        month_prev: month_prev,
                        month_current: month_current,
                        month_next: month_next
                    })
                })).then(() => {
                    this.setState({
                        fetch_in_progress: false
                    })
                })
            }  
        })
    }

    handlePaging(paging) {
        if (!this.state.popup_show && Object.keys(this.state.month_current.data).filter(entity => Object.keys(this.state.month_current.data[entity]).some(day => this.state.month_current.data[entity][day].changed)).length > 0) {
            this.setState({
                popup_show: true,
                popup_page: paging
            })
        } else {
            this.setState(prevState => ({
                date_selected: {
                    year: prevState.date_selected.year + Math.floor((prevState.date_selected.month - 1 + paging) / 12),
                    month: (prevState.date_selected.month - 1 + (paging % 12 + 12)) % 12 + 1,
                },
                data_valid: false,
                paging: true,
                popup_show: false,
                popup_page: null
            }), () => {
                this.props.promise_error_handler((() => {
                    if (this.state.student) {
                        return Promise.all([
                            CanteenClient.getStudentDiningCalendar(this.state.school_year,this.state.date_selected.year, this.state.date_selected.month, this.state.student),
                            CanteenClient.getStudentDiningCalendar(this.state.school_year,this.state.date_selected.year + Math.floor((this.state.date_selected.month - 2) / 12), (this.state.date_selected.month + 10) % 12 + 1, this.state.student),
                            CanteenClient.getStudentDiningCalendar(this.state.school_year,this.state.date_selected.year + Math.floor(this.state.date_selected.month / 12), this.state.date_selected.month % 12 + 1, this.state.student)
                        ])
                    } else if (this.state.class) {
                        return Promise.all([
                            CanteenClient.getClassDiningCalendar(this.state.school_year,this.state.date_selected.year, this.state.date_selected.month, this.state.class)
                        ])
                    } else if (this.state.school) {
                        return Promise.all([
                            CanteenClient.getSchoolDiningCalendar(this.state.school_year,this.state.date_selected.year, this.state.date_selected.month, this.state.school)
                        ])
                    }
                })().then(([month_current, month_prev, month_next]) => {
                    this.setState(prevState => {
                        if (prevState.date_selected.year === month_current.year && prevState.date_selected.month === month_current.month) {
                            return {
                                data_valid: true,
                                month_prev: month_prev,
                                month_current: month_current,
                                month_next: month_next,
                                paging: false
                            }
                        } else {
                            return {}
                        }
                    })
                }))
            })
        }
    }

    handlePagingCancel = () => {
        this.setState({
            popup_show: false
        })
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        });
        let current_date = new Date(Date.now())
        let current_timestamp = current_date.toISOString()?.replace('T', ' ')?.split('.')?.[0]
        this.props.promise_error_handler((() => {
            if (this.state.student) {
                return CanteenClient.updateStudentMealCancellations(this.state.school_year,this.state.student, Object.keys(this.state.month_current.data[this.state.student]).filter(v => this.state.month_current.data[this.state.student][v].changed && (!this.state.month_current.data[this.state.student][v].border_work_day || current_timestamp < this.state.month_current.data[this.state.student][v].border_work_day)).reduce((o, v) => ({...o, [this.state.date_selected.year + "-" + this.state.date_selected.month.toString().padStart(2, "0") + "-" + v.padStart(2, "0")]: this.state.month_current.data[this.state.student][v]}), {}))
            } else if (this.state.class) {
                return CanteenClient.updateStudentsMealCancellations(this.state.school_year,this.state.class, Object.keys(this.state.month_current.data).filter(student => Object.keys(this.state.month_current.data[student])
                    .some(day => this.state.month_current.data[student][day].changed && (!this.state.month_current.data[student][day].border_work_day || current_timestamp < this.state.month_current.data[student][day].border_work_day)))
                    .reduce((o, student) => ({...o, [student]: Object.keys(this.state.month_current.data[student])
                            .filter(v => this.state.month_current.data[student][v].changed && (!this.state.month_current.data[student][v].border_work_day || current_timestamp < this.state.month_current.data[student][v].border_work_day))
                            .reduce((o, v) => ({...o, [this.state.date_selected.year + "-" + this.state.date_selected.month.toString().padStart(2, "0") + "-" + v.padStart(2, "0")]: this.state.month_current.data[student][v]}),{}),}),{}), Object.keys(this.state.month_current.group_data).filter(v => this.state.month_current.group_data[v].changed).reduce((prev, curr) => {
                    prev[this.state.date_selected.year + "-" + this.state.date_selected.month.toString().padStart(2, "0") + "-" + curr.padStart(2, "0")] = this.state.month_current.group_data[curr]
                    return prev
                }, {}))
            } else if (this.state.school) {
                return CanteenClient.updateClassesMealCancellations(this.state.school_year,Object.keys(this.state.month_current.data)
                    .filter(school_class => Object.keys(this.state.month_current.data[school_class])
                        .some(day => this.state.month_current.data[school_class][day].changed && (!this.state.month_current.data[school_class][day].border_work_day || current_timestamp < this.state.month_current.data[school_class][day].border_work_day)))
                    .reduce((o, school_class) => ({...o, [school_class]: Object.keys(this.state.month_current.data[school_class])
                            .filter(v => this.state.month_current.data[school_class][v].changed && (!this.state.month_current.data[school_class][v].border_work_day || current_timestamp < this.state.month_current.data[school_class][v].border_work_day))
                            .reduce((o, v) => ({...o, [this.state.date_selected.year + "-" + this.state.date_selected.month.toString().padStart(2, "0") + "-" + v.padStart(2, "0")]: this.state.month_current.data[school_class][v]}),{}),}),{}), Object.keys(this.state.month_current.group_data).filter(v => this.state.month_current.group_data[v].changed).reduce((prev, curr) => {
                    prev[this.state.date_selected.year + "-" + this.state.date_selected.month.toString().padStart(2, "0") + "-" + curr.padStart(2, "0")] = this.state.month_current.group_data[curr]
                    return prev
                }, {}))
            }
        })().then(() => {
            /*this.setState(prevState => ({
                month_current: {
                    ...prevState.month_current,
                    data: Object.keys(prevState.month_current.data).reduce((data, entity_id) => ({...data, [entity_id]:
                        Object.keys(prevState.month_current.data[entity_id]).reduce((days, day_id) => ({...days, [day_id]: {
                            ...prevState.month_current.data[entity_id][day_id],
                            changed: false
                        }}), {})
                    }), {})
                }
            }))*/
            this.handleFetch()

            this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
        })).then(() => {
            this.setState({
                save_in_progress: false
            })
        })
    }

    static levels = ['SCHOOL', 'CLASS', 'STUDEN']

    changeDayCanceled(day_id, level) {
        let levelrank = Menzanaptar.levels.indexOf(level)
        this.setState(prevState => ({
            month_current: {
                ...prevState.month_current,
                group_data: {
                    ...prevState.month_current.group_data,
                    [day_id]: {
                        ...prevState.month_current.group_data[day_id] || {},
                        canceled: !prevState.month_current.group_data[day_id].canceled,
                        changed: true,
                        id: !prevState.month_current.group_data[day_id].canceled ? undefined : prevState.month_current.group_data[day_id].id
                    }
                },
                data: Object.keys(prevState.month_current.data).reduce((o, v) => {
                    let level_ranks = (prevState.month_current.data[v][day_id].levels||[]).map(l => Menzanaptar.levels.indexOf(l))
                    let canceled = prevState.month_current.data[v][day_id].disabled || prevState.month_current.data[v][day_id].another_class ? prevState.month_current.data[v][day_id].canceled : !prevState.month_current.group_data[day_id].canceled
                    let forcing_level_rank = prevState.month_current.data[v][day_id].forcing_level_rank
                    let disabled_to_change_upper_level = prevState.month_current.data[v][day_id].disabled_to_change_upper_level
                    let mixed = level_ranks.length !== 0 && level_ranks.some(lv => lv > (forcing_level_rank !== undefined ? forcing_level_rank : levelrank))
                    if(false && (disabled_to_change_upper_level !== undefined && disabled_to_change_upper_level > levelrank  || !mixed && level_ranks.length > 0 &&  !level_ranks.some(lv => lv <= (forcing_level_rank !== undefined ? forcing_level_rank : levelrank)))){
                        return {...o, [v]: {
                            ...prevState.month_current.data[v]
                        }}
                    } else {
                        return {...o, [v]: {
                            ...prevState.month_current.data[v],
                            [day_id]: {
                                ...prevState.month_current.data[v][day_id],
                                original_canceled: prevState.month_current.data[v][day_id].original_canceled === undefined ? prevState.month_current.data[v][day_id].canceled :prevState.month_current.data[v][day_id].original_canceled,
                                canceled: canceled,
                                mixed: prevState.month_current.data[v][day_id].disabled || prevState.month_current.data[v][day_id].another_class ? prevState.month_current.data[v][day_id].mixed : mixed,
                                changed: !prevState.month_current.data[v][day_id].disabled && !prevState.month_current.data[v][day_id].another_class,
                                level: level
                            }
                        }}
                    }
                }, {})
            }
        }))
    }

    changeEntityDayCanceled(entity_id, day_id, canceled, level) {
        this.setState(prevState => {
            let level_ranks = (prevState.month_current.data[entity_id][day_id].levels||[]).map(l => Menzanaptar.levels.indexOf(l))
            let levelrank = Menzanaptar.levels.indexOf(level)
            let mixed = level_ranks.length !== 0 && level_ranks.some(lv => lv > levelrank)
            if(!mixed && level_ranks.length > 0 &&  !level_ranks.some(lv => lv <= levelrank)){
                return prevState.month_current.data[entity_id]
            } else {
                return {
                    month_current: {
                        ...prevState.month_current,
                        data: {
                            ...prevState.month_current.data,
                            [entity_id]: {
                                ...prevState.month_current.data[entity_id],
                                [day_id]: {
                                    ...prevState.month_current.data[entity_id][day_id],
                                    canceled: canceled,
                                    original_canceled: prevState.month_current.data[entity_id][day_id].original_canceled === undefined ? prevState.month_current.data[entity_id][day_id].canceled :prevState.month_current.data[entity_id][day_id].original_canceled,
                                    mixed: mixed,
                                    changed: true,
                                    level: level,
                                    forcing_level_rank: !canceled ? levelrank : undefined,
                                    disabled_to_change_upper_level: canceled ? levelrank : undefined
                                }
                            }
                        }
                    }
                }
            }
        })
    }

    uploadFile = () =>{
        if (this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            },()=>{
                this.props.clearMessages()
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0], true).then(result => {
                    this.props.promise_error_handler(CanteenClient.loadDiningsFromFile(this.state.school_year,result.hash).then(()=>{
                        this.props.addMessage('Sikeres betöltés.','success')
                        this.setState((prevState) =>({
                            file_input_key: prevState.file_input_key + 1,
                            upload_in_progress: false
                        }),this.handleFetch)
                    }).catch(error => {
                        this.setState({
                            upload_in_progress: false
                        })
                        throw error
                    }))
                }))
            })
        }
    }

    downloadErrorReport = () =>{
        if (this.file_input.current.files.length === 1) {
            this.setState({
                download_in_progress: true
            },()=>{
                this.props.clearMessages()
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0], true).then(result => {
                    this.props.promise_error_handler(CanteenClient.loadDiningsFromFile(this.state.school_year,result.hash, true).then((result)=>{
                        this.props.promise_error_handler(FilestoreClient.download(result.hash))
                        this.setState({
                            download_in_progress: false
                        })
                    }).catch(error => {
                        this.setState({
                            download_in_progress: false
                        })
                        throw error
                    }))
                }))
            })
        }
    }

    render() {
        let warning_timestamp = this.state.warning_datetime?.toISOString()?.replace('T', ' ')?.split('.')?.[0]
        let school_id = ((this.state.school_years||[]).find(sy=>sy.id==this.state.school_year)||{}).school_id
        let haveEditRightWithoutUseCalendar = this.state.write_access && this.state.school_data && ('alkalmazasgazda' in this.props.rights.rights || ((this.props.rights.rights.ugyintezo||[]).concat((this.props.rights.rights.hianyzaskezelo || [])).findIndex(s => s == school_id) >= 0 || this.props.rights.students.findIndex(s => s.id == this.state.student) >= 0))
        let haveEditRight = haveEditRightWithoutUseCalendar && (this.state.school_data.use_calendar || 'alkalmazasgazda' in this.props.rights.rights)
        let closest_to_warning_timestamp = undefined
        let current_date = new Date(Date.now())
        let current_timestamp = current_date.toISOString()?.replace('T', ' ')?.split('.')?.[0]
        if(this.state.student && !this.state.fetch_in_progress){
            closest_to_warning_timestamp = Object.keys(this.state.month_current.data[this.state.student]).filter(day => this.state.month_current.data[this.state.student][day].border_work_day && this.state.month_current.data[this.state.student][day].border_work_day < warning_timestamp && this.state.month_current.data[this.state.student][day].border_work_day > current_timestamp).map(day => this.state.month_current.data[this.state.student][day].border_work_day).sort()?.[0] || undefined
        }
        return (
            <React.Fragment>
                <div className="page-subtitle">Menzanaptár</div>
                {closest_to_warning_timestamp ? (()=> {
                    let border_datetime = new Date(closest_to_warning_timestamp.replace(' ', 'GMT'))
                    let diff = border_datetime - current_date
                    let hours = Math.floor(diff/(1000*3600))
                    let mins = Math.floor((diff - hours * 1000*3600)/(1000*60))
                    let secs = Math.floor((diff - hours * 1000*3600 - mins * 1000*60)/1000)
                    return  <div className={"countdown-container meal-cancellation-countdown-container"}>
                        <h4>Az étkezés lemondására {(''+border_datetime.getHours()).padStart(2, '0')}:{(''+border_datetime.getMinutes()).padStart(2, '0')} óráig van lehetőség.</h4>
                        <h4>Lemondásig hátralévő idő:</h4>
                        <div className="countdown">
                            <div className={"digit"} data-value={(hours).toString().padStart(2, '0').charAt(0)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                            <div className={"digit"} data-value={(hours).toString().padStart(2, '0').charAt(1)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                            <div>:</div>
                            <div className={"digit"} data-value={(mins).toString().padStart(2, '0').charAt(0)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                            <div className={"digit"} data-value={(mins).toString().padStart(2, '0').charAt(1)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                            <div>:</div>
                            <div className={"digit"} data-value={(secs).toString().padStart(2, '0').charAt(0)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                            <div className={"digit"} data-value={(secs).toString().padStart(2, '0').charAt(1)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                        </div>
                    </div>
                })(): null}
                { this.state.popup_help_open && (
                    <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                        <p>
                            <div>A <span style={{fontWeight: 'bold'}}>„Menzanaptár”</span> menüpontban a szülő a naptári napra történő kattintással jelezheti, hogy gyerekének mely nap(ok)ra nem kéri az étkezés biztosítását az iskolában. Korábban lemondott étkezési napra az étkezést a naptári napra történő kattintással ismét megrendelheti. A napi étkezés lemondására/megrendelésére előző nap 9:00 óráig van lehetőség.</div>
                        </p>
                        <p style={{fontStyle: 'italic'}}>
                            <div>A Debreceni Egyetem Kossuth Lajos Gyakorló Gimnáziuma és Általános Iskola Csengő utcai feladatellátási helyén a menük kiválasztását és a hóközbeni lemondásokat a Szolgáltató honlapján (<a target="_blank" rel="noopener noreferrer" href="https://www.etelhordo.hu/kossuth/hun/">https://www.etelhordo.hu/kossuth/hun/</a>)  teheti meg a továbbiakban is.</div>
                            <div>A lenti táblázatba az ügyintéző rögzíti be időközönként a Szolgáltató jelzése alapján a lemondott étkezéseket.</div>
                        </p>
                        <p>
                            <div>Az étkezési díjak év végi elszámolásának alapja a menzanaptárban rögzített étkezések száma.</div>
                        </p>
                        <p>
                            <div>A <span style={{fontStyle: 'italic'}}>„Tanév”</span> legördülő menüben az aktuális tanév és a tanuló aktuális iskolája jelenik meg. Ha a tanuló a Debreceni Egyetem másik iskolájába iratkozik át évközben és étkezési igény került leadásra, elfogadásra, akkor lehetősége van a szülőnek a másik iskolát kiválasztani, és ott előre hiányzást lejelenteni, ha szükséges.</div>
                            <div>A menzanaptárban egyszerre egy hónap adatai jelennek meg. A hónapok között navigálni a „&lt;&lt;” és a „&gt;&gt;” ikonnal lehet. A naptárban zöld pipa (<span style={{color: "#004735"}}>&#x2713;</span>) jelöli azokat a napokat, amelyeken a tanuló étkezést kér, piros (<span style={{color: "#d60000"}}>&#x2717;</span>) jelöli azokat a napokat, amelyeken a tanuló számára az étkezés lemondásra került.</div>
                            <div>A menzanaptárban fehér háttérszín jelöli azokat a napokat, amelyeken az étkezés lemondható/a lemondás visszavonható, szürke háttérszín jelöli azokat a napokat, amelyek nem módosíthatók.</div>
                            <div>A cellák jobb felső sarkában lévő narancssárga háromszög ikon jelzi a tanításmentes napokat, vagy áthelyezett munkanapokat.</div>
                            <div>A cellára való kattintással lehet módosítani a napok értékét. A menzanaptárban visszamenőleges módosításra nincs lehetőség!</div>
                            <div>A <span style={{fontStyle: 'italic'}}>„Mentés”</span> gombra kattintva a menzanaptár elmentésre kerül a rendszerben. Mentés nélkül a hónapok közötti navigáláskor (oldalak közötti továbblépés) a módosítások elvesznek, erről figyelmeztető üzenet értesíti a szülőt.</div>
                        </p>
                    </PopUp>
                ) }
                {this.state.popup_show ? <PopUp title={'Megerősítés'} close={this.handlePagingCancel} buttons={[{action: ()=>this.handlePaging(this.state.popup_page), text: 'Ok'},{action: this.handlePagingCancel, text: 'Mégse'}]}>
                    <div className="block">
                        Nem mentett módosítások találhatók az oldalon. Az oldal elhagyásával, (az OK gomb megnyomásával) a módosítások elvesznek.
                    </div>
                </PopUp> : null}
                {this.state.guide ? <div className="page-guide">{this.state.guide}</div> : null}
                {
                    !!this.state.school_years ? <div className="block">
                        <label className="label label-short">Tanév</label>
                        <SelectField onChange={this.handleChangeSchoolYear} className={"field field-simple"} value={this.state.school_year} displayValues={this.state.school_years.reduce((prev, curr) => ({
                            ...prev,
                            [curr.id]: (curr.name || curr.year_name)+ (curr.school_name ? " ("+curr.school_name+")" : '')
                        }), {})}>
                            <option value="" disabled={true} selected={true}>Kérem, válasszon!</option>
                            {this.state.school_years.map(sy => <option value={sy.id} />)}
                        </SelectField>
                    </div> : null
                }
                { this.state.open && this.state.school_year ? <React.Fragment>
                    <div className="calendar-pager">
                        <button disabled={this.state.date_from.year === this.state.date_selected.year && this.state.date_from.month === this.state.date_selected.month} onClick={() => this.handlePaging(-1)}>&lt;&lt;</button>
                        <div>{this.state.date_selected.year + '. ' + month_names[this.state.date_selected.month - 1]}</div>
                        <button disabled={this.state.date_to.year === this.state.date_selected.year && this.state.date_to.month === this.state.date_selected.month} onClick={() => this.handlePaging(+1)}>&gt;&gt;</button>
                    </div>
                    { this.state.student &&
                    <table className="calendar student">
                        <thead>
                        <tr>
                            <th><span className="full-name">Hétfő</span><span className="short-name">H</span></th>
                            <th><span className="full-name">Kedd</span><span className="short-name">K</span></th>
                            <th><span className="full-name">Szerda</span><span className="short-name">Sz</span></th>
                            <th><span className="full-name">Csütörtök</span><span className="short-name">Cs</span></th>
                            <th><span className="full-name">Péntek</span><span className="short-name">P</span></th>
                            <th><span className="full-name">Szombat</span><span className="short-name">Sz</span></th>
                            <th><span className="full-name">Vasárnap</span><span className="short-name">V</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        {[0, 1, 2, 3, 4, 5].map(row =>
                            <tr>
                                {[0, 1, 2, 3, 4, 5, 6].map(col => {
                                    if (!this.state.data_valid) {
                                        return <td className="disabled-day">
                                            <div/>
                                        </td>
                                    }

                                    let day_id = row * 7 + col - (this.state.month_current.first_day_dow !== 1 ? this.state.month_current.first_day_dow : 8) + 2
                                    let day_num = undefined
                                    let day_value = undefined
                                    let day_current_month = undefined

                                    if (day_id <= 0) {
                                        day_num = this.state.month_prev.length + day_id
                                        day_value = this.state.month_prev.data[this.state.student][day_num]
                                        day_current_month = false
                                    } else if (day_id > this.state.month_current.length) {
                                        day_num = (day_id - 1) % this.state.month_current.length + 1
                                        day_value = this.state.month_next.data[this.state.student][day_num]
                                        day_current_month = false
                                    } else {
                                        day_num = day_id
                                        day_value = this.state.month_current.data[this.state.student][day_num]
                                        day_current_month = true
                                    }
                                    let disabled = day_value.disabled || day_value.border_work_day < current_timestamp
                                    let canceled = (disabled ? day_value.original_canceled : undefined ) === undefined ? day_value.canceled : day_value.original_canceled
                                    return <td title={ this.state.month_current.events[day_id] ||null} className={[
                                        this.state.month_current.events[day_id] ? 'event' : null,
                                        !day_current_month ? 'sibling-month-day' : null,
                                        disabled ? 'disabled-day' : null,
                                        canceled ? 'canceled-day' : (canceled===false ? 'hungry-day' : null),
                                        day_value.border_work_day < warning_timestamp && !disabled ? 'close-to-deadline' : null
                                    ].filter(x => x).join(' ')} onClick={(day_current_month && !day_value.disabled ) ? () => this.changeEntityDayCanceled(this.state.student, day_num, !day_value.canceled,'STUDEN') : null}>
                                        <div>
                                            <div>{day_num}</div>
                                            <div></div>
                                        </div>
                                    </td>
                                })}
                            </tr>
                        )}
                        </tbody>
                    </table>
                    }
                    { !this.state.student && this.state.class &&
                    <div className="calendar-container">
                        <table className="calendar class">
                            <thead>
                            <tr>
                                <th>Név</th>
                                { [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31].filter(x => x <= this.state.month_current.length).map(day => <th title={ this.state.month_current.events[day] ||null} className={[this.state.data_valid && this.state.month_current.events[day] ? 'event' : null, !this.state.month_current.group_data[day].work_day ? 'disabled-day' : null, this.state.month_current.group_data[day].canceled ? 'canceled-day' : null].filter(x => x).join(' ')} onClick={this.state.month_current.group_data[day].disabled ? null : () => this.changeDayCanceled(day,'CLASS')}>{day}</th>) }
                            </tr>
                            </thead>
                            <tbody>
                            { Object.keys(this.state.month_current.data).map((student, i) =>
                                {
                                    return <tr>
                                        <td title={this.state.month_current.entities[student].om}><Link to={"/tanulo-kezelese/T"+(-1*student)+'/menzanaptar'+(this.state.school_year ? '/'+this.state.school_year : '')}>{this.state.month_current.entities[student].name}</Link></td>
                                        { this.state.paging ? (i==0 ?<td className='disabled-day' rowSpan={Object.keys(this.state.month_current.data).length} colSpan={this.state.month_current.length}><Loading loading_text=''/></td> : null) : Object.keys(this.state.month_current.data[student]).map(day =>
                                            {
                                                let disabled = !this.state.data_valid || this.state.month_current.data[student][day].disabled || this.state.month_current.data[student][day].another_class || (this.state.month_current.data[student][day].border_work_day &&this.state.month_current.data[student][day].border_work_day < current_timestamp)
                                                let original_value = this.state.month_current.data[student][day].original_canceled === undefined ? this.state.month_current.data[student][day].canceled : this.state.month_current.data[student][day].original_canceled
                                                original_value = original_value === undefined ? null : original_value
                                                return <td className={[
                                                    disabled ? 'disabled-day' : null,
                                                    (this.state.month_current.data[student][day].another_class ? 'another-class' : null),
                                                    this.state.month_current.data[student][day].border_work_day < warning_timestamp && !disabled ? 'close-to-deadline' : null,
                                                    !disabled || !this.state.data_valid ? (this.state.data_valid ? (
                                                        this.state.month_current.data[student][day].canceled || this.state.month_current.data[student][day].mixed ? 'canceled-day' : (
                                                            this.state.month_current.data[student][day].canceled === false ? 'hungry-day' : null
                                                        )) : null):(original_value ? 'canceled-day' : (original_value === false ? 'hungry-day' : null))
                                                ].filter(x => x).join(' ')} title={this.state.month_current.data[student][day].class_name} onClick={!this.state.month_current.data[student][day].disabled && !this.state.month_current.data[student][day].another_class  ? () => this.changeEntityDayCanceled(student, day, !this.state.month_current.data[student][day].canceled, 'STUDEN') : null}>
                                                    <div><div></div></div>
                                                </td>
                                            }
                                        ) }
                                    </tr>
                                }
                            ) }
                            </tbody>
                        </table>
                    </div>
                    }
                    { !this.state.student && !this.state.class && this.state.school &&
                    <div className="calendar-container">
                        <table className="calendar class">
                            <thead>
                            <tr>
                                <th>Név</th>
                                { [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31].filter(x => x <= this.state.month_current.length).map(day => <th title={ this.state.month_current.events[day] ||null} className={[this.state.data_valid && this.state.month_current.events[day] ? 'event' : null, !this.state.month_current.group_data[day].work_day ? 'disabled-day' : null, this.state.month_current.group_data[day].canceled ? 'canceled-day' : null].filter(x => x).join(' ')} onClick={this.state.month_current.group_data[day].disabled ? null : () => this.changeDayCanceled(day, 'SCHOOL')}>{day}</th>) }
                            </tr>
                            </thead>
                            <tbody>
                            { Object.keys(this.state.month_current.data).sort((a,b) => {
                                let aname = this.state.month_current.entities[a].name
                                let bname = this.state.month_current.entities[b].name
                                let anumber = 1 * aname.split('/')[0]
                                let bnumber = 1 * bname.split('/')[0]
                                if(anumber !== bnumber){
                                    return isNaN(anumber) ? 1 : isNaN(bnumber) ? -1 : anumber > bnumber ? 1 : -1
                                }
                                return  aname.localeCompare(bname)
                            }).map((student,i) =>
                                <tr>
                                    <td><Link to={'/osztaly-kezelese/'+student+'/menzanaptar'+(this.state.school_year ? '/'+this.state.school_year : '')}>{this.state.month_current.entities[student].name}</Link></td>
                                    { this.state.paging ? (i==0 ?<td className='disabled-day' rowSpan={Object.keys(this.state.month_current.data).length} colSpan={this.state.month_current.length}><Loading loading_text=''/></td> : null) : Object.keys(this.state.month_current.data[student]).map(day =>
                                        {
                                            let disabled = !this.state.data_valid || this.state.month_current.data[student][day].disabled || (this.state.month_current.data[student][day].border_work_day  && this.state.month_current.data[student][day].border_work_day < current_timestamp)
                                            let original_value = this.state.month_current.data[student][day].original_canceled === undefined ? this.state.month_current.data[student][day].canceled : this.state.month_current.data[student][day].original_canceled
                                            original_value = original_value === undefined ? null : original_value
                                            return<td className={[
                                                disabled ? 'disabled-day' : null,
                                                this.state.month_current.data[student][day].border_work_day < warning_timestamp && !disabled ? 'close-to-deadline' : null,
                                                !disabled || !this.state.data_valid ? (this.state.data_valid ? (
                                                    this.state.month_current.data[student][day].canceled ? 'canceled-day' : (
                                                        this.state.month_current.data[student][day].mixed ? 'mixed-day' : (
                                                            this.state.month_current.data[student][day].canceled === false ? 'hungry-day' : null
                                                        )
                                                    )
                                                ) : null):(original_value ? 'canceled-day' : (original_value === false ? 'hungry-day' : null))
                                            ].filter(x => x).join(' ')} onClick={!this.state.month_current.data[student][day].disabled ? () => this.changeEntityDayCanceled(student, day, !this.state.month_current.data[student][day].canceled, 'CLASS') : null}>
                                                <div><div></div></div>
                                            </td>
                                        }
                                    ) }
                                </tr>
                            ) }
                            </tbody>
                        </table>
                    </div>
                    }
                    <div className="actions">
                        { haveEditRight && <LoadingButton loading={this.state.save_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton> }
                        {Object.keys(this.props.rights.rights).length !== 0 && false ? <React.Fragment>
                            {this.state.class ? <button onClick={()=>this.props.history.push('/iskola-kezelese/'+this.state.school+'/menzanaptar/'+this.state.school_year)}>Iskola hiányzásai</button> : null}
                            {this.state.student ? <button onClick={()=>this.props.history.push('/osztaly-kezelese/'+this.state.student_register_data.class.id+'/menzanaptar/'+this.state.school_year)}>{this.state.student_register_data.class.name} hiányzásai</button> : null}
                        </React.Fragment> : null}
                    </div>
                    {haveEditRightWithoutUseCalendar && !this.state.school_data.use_calendar && !this.state.class ? <div>
                        <div className="page-subtitle">Tömeges betöltés</div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Fájl</label>
                            <input type="file" className="field field-simple" ref={this.file_input} key={'file_input.'+this.state.file_input_key}/>
                        </div>
                        <div className="actions">
                            <LoadingButton onClick={this.uploadFile} disabled={this.state.download_in_progress} loading={this.state.upload_in_progress}>Betöltés</LoadingButton>
                            <LoadingButton onClick={this.downloadErrorReport} loading={this.state.download_in_progress} disabled={this.state.upload_in_progress}>Ellenőrző állomány letöltése</LoadingButton>
                        </div>
                    </div> : null}
                </React.Fragment> : (this.state.fetch_in_progress ? <Loading loading_text=''/> : null)}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    addAction: addAction,
    removeAction: removeAction
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(Menzanaptar))
