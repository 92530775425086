import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import AdatModositasok from "../TanuloKezelese/AdatModositasok"
import AdatAllapot from "../TanuloKezelese/AdatAllapot"

class AdatmodositasiKerelemKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            school: this.props.school,
            student: -1 * this.props.match.params.student,
            student_data_id: this.props.match.params.student_data_id,
            fetch_in_progress: true
        }
    }

    componentDidMount() {
        this.handleFetch()
    }

    componentWillUnmount(){
        clearTimeout(this.state.timer)
    }

    static getDerivedStateFromProps(props, state) {
        if (-1 * props.match.params.student !== state.student) {
            return {
                student: -1 * props.match.params.student,
                student_data_id: props.match.params.student_data_id,
            }
        } else if (props.match.params.student_data_id !== state.student_data_id) {
            return {
                student_data_id: props.match.params.student_data_id
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.student !== this.state.student || prevState.student_data_id !== this.state.student_data_id && !this.state.student_data_id) {
            this.handleFetch()
        }
    }

    handleFetch = () => {
        this.setState({
            fetch_in_progress: true,
            after_determined: false
        }, () => {
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getRightsForStudent(this.state.student),
                CanteenClient.listStudentDataInformation({
                    authentic: [{ operator: 3 }],
                    student_id: [{ operator: 9, value: this.state.student }],
                    school_id_at_creation: [{operator: 9, value: this.state.school}],
                    school_id: [{operator: 9, value: this.state.school}]
                }, { limit: 1, info: true }, [
                ], ['original_school_id','school_id', 'student_id', 'prev_id', 'id', 'authentic']),
                CanteenClient.getNextAndPrevStudentDataIDForSchool(this.state.student, this.state.school, {
                    authentic: [{ operator: 3 }]
                }, {}, ['school_id', 'student_id']).catch(e => {
                    return {prev_id: null, next_id: null}
                })
            ]).then(([rights, student_data, next_and_prev]) => {
                if(student_data.result.length === 0){
                    this.handleGoBackToAll()
                } else{
                    this.setState({
                        rights: rights,
                        student_data: student_data.result,
                        student_data_count: student_data.result_summary.total,
                        next_and_prev: next_and_prev
                    },()=>{
                        if(student_data.result_summary.total==1){
                            this.props.history.replace(this.getLinkForRequest(this.state.student_data[0]))
                        }
                        this.setState({
                            fetch_in_progress: false
                        })
                    })
                }
            }))
        })
    }

    handleGoPrev = () => {
        this.props.history.push('/iskola-kezelese/' + this.state.school + '/feldolgozatlan-torzsadatmodositasok/T' + (-1 * this.state.next_and_prev.prev_id))
    }

    handleGoNext = () => {
        this.props.history.push('/iskola-kezelese/' + this.state.school + '/feldolgozatlan-torzsadatmodositasok/T' + (-1 * this.state.next_and_prev.next_id))
    }

    getLinkForRequest = (row = {}) => {
        return '/iskola-kezelese/' + this.state.school + '/feldolgozatlan-torzsadatmodositasok/T' + (-1 * this.state.student) + '/' + row.id
    }

    handleGoBack = () => {
        this.props.history.push('/iskola-kezelese/' + this.state.school + '/feldolgozatlan-torzsadatmodositasok/T' + (-1 * this.state.student))
    }

    handleGoBackToAll = () => {
        this.props.history.push('/iskola-kezelese/' + this.state.school + '/feldolgozatlan-torzsadatmodositasok')    }

    handleDetermined = () => {
        this.props.promise_error_handler(CanteenClient.listStudentDataInformation({
            authentic: [{ operator: 3 }],
            student_id: [{ operator: 9, value: this.state.student }],
            school_id: [{operator: 9, value: this.state.school}]
        }, { limit: 1, info: true }, [
        ], ['original_school_id','school_id', 'student_id', 'prev_id', 'id', 'authentic']).then(student_data_information => {
            if (student_data_information.result.length) {
                this.setState({
                    student_data: student_data_information.result,
                    student_data_count: student_data_information.result_summary.total,
                    after_determined: true,
                    timer: setTimeout(this.handleGoBack,2000)
                })
            } else {
                this.setState({
                    after_determined: true,
                    timer: setTimeout(this.handleGoBackToAll,2000)
                })
            }
        }))
    }

    render() {
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
                <div class="calendar-pager">
                    <button disabled={!this.state.next_and_prev || this.state.next_and_prev.prev_id == null} onClick={this.handleGoPrev}>&lt;&lt;</button>
                    <div>{this.state.student_data[0].student_name + ' elfogadatlan módosításai (' + this.state.student_data_count + ' db)'}</div>
                    <button disabled={!this.state.next_and_prev || this.state.next_and_prev.next_id == null} onClick={this.handleGoNext}>&gt;&gt;</button>
                </div>
                {this.state.student_data_id ? <React.Fragment>
                    {this.state.student_data_count > 1 ? <button onClick={this.handleGoBack}>Vissza a tanuló elfogadatlan kérelmeihez</button> : null}
                    <AdatAllapot handleDetermined={this.handleDetermined} student={this.state.student} school={this.state.school} student_data_id={this.state.student_data_id} rights_for_student={this.state.rights} />
                </React.Fragment> : <AdatModositasok conditions={{ authentic: [{ operator: 3 }],school_id_at_creation: [{operator: 9, value: this.state.school}] }} embded={true} student={this.state.student} school={this.state.school} getLinkForRequest={this.getLinkForRequest} rights_for_student={this.state.rights} />}
            </React.Fragment> : null}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdatmodositasiKerelemKezelese))