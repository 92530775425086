import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage, setActions } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import SelectField from "@sapkk/app/components/SelectField"
import SimpleField from "@sapkk/app/components/SimpleField"
import Igenyles from "../Igenyles/Igenyles"
import IgenylesAltalanosAdatai from "../Igenyles/IgenylesAltalanosAdatai"
import Uzenetek from "../Igenyles/Uzenetek"
import EtkezesFelveteleTanulohoz from "./EtkezesFelveteleTanulohoz"
import PopUp from "../../components/PopUp"
import Dokumentumok from "./Dokumentumok"
import Uzenet from "../../components/Uzenet"
import { addAction, removeAction, setSystemMessagesCount } from "../../actions"

class TanuloiIgenyles extends Component {
    static START_INTERVAL_TIME = 60000

    constructor(props) {
        super(props)

        this.file_input = React.createRef()

        this.state = {
            school: this.props.school,
            student: this.props.student,
            meal_request_id: this.props.meal_request_id || this.props.match.params.meal_request_id,
            fetch_in_progress: true,
            meal_request_key: 0,
            upload_in_progress: false,
            rollback_in_progress: false,
            rights: [],
            key: 0,
            interval: undefined,
            interval_time: TanuloiIgenyles.START_INTERVAL_TIME,
            popup_messages: []
        }
    }

    componentDidMount() {
        this.handleFetch()
        this.updateSeconds()
        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
    }
    componentWillUnmount() {
        this.props.removeAction('help')
        clearInterval(this.state.interval)
        clearTimeout(this.state.update_seconds_timeout)
    }

    updateSeconds = ()=> {
        let date = new Date(Date.now())
        let timeout = 1000 - date.getMilliseconds()
        this.setState((prevState)=> ({
            act_datetime: date,
            update_seconds_timeout: setTimeout(this.updateSeconds, timeout > 500 ? timeout - 500 : timeout + 500)
        }))
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }
    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    static getDerivedStateFromProps(props, state) {
        if ((props.meal_request_id || props.match.params.meal_request_id) !== state.meal_request_id) {
            return {
                meal_request_id: (props.meal_request_id || props.match.params.meal_request_id),
                fetch_in_progress: true
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.meal_request_id !== this.state.meal_request_id) {
            this.handleFetch()
        }
    }

    setNewIntervalTiem = (new_time)=>{
        clearInterval(this.state.interval)
        this.setState({
            interval: setInterval(this.getUnseenMessageCount,new_time),
            interval_time: new_time
        })
    }

    getUnseenMessageCount = (open_menu_after = false)=>{
        if(this.state.meal_request && this.state.meal_request.id){
            CanteenClient.getMealRequestUnSeenMessageCount(this.state.meal_request.id).then(count => {
                if(this.state.interval_time !== TanuloiIgenyles.START_INTERVAL_TIME){
                    this.setNewIntervalTiem(TanuloiIgenyles.START_INTERVAL_TIME)
                }
                let last_unseen_message_count = this.state.unseen_message_count
                this.setState({
                    unseen_message_count: count
                },()=>{
                    if(last_unseen_message_count !== count){
                        this.props.setSystemMessagesCount(null)
                    }
                    if(open_menu_after){
                        this.props.history.push({
                            pathname: '/tanulo-kezelese/T'+(-1*this.state.meal_request.student_id)+'/menu',
                            state: {
                                school_year_id: this.state.meal_request.school_year_id,
                                student_id: this.state.meal_request.student_id,
                                meal_group_id: this.state.meal_request.meal_group_id,
                                period: this.state.meal_request.year+'-'+(''+this.state.meal_request.month).padStart(2, '0')
                            }
                        })
                    }
                })
            }).catch(() => {
                this.setNewIntervalTiem(this.state.interval_time*2)
            })
        }
    }

    handleFetch = () => {
        clearInterval(this.state.interval)
        this.setState({
            meal_request: null,
            template: null,
            school_years: [],
            active_tabs: [],
            dining_state: null,
            fetch_in_progress: true,
            determination: false,
            edit: false,
            warning_popup: null,
            rights: []
        }, () => {
            this.props.promise_error_handler(Promise.all([
                (() => {
                    if (!isNaN(this.state.meal_request_id)) {
                        return CanteenClient.getTemplateForMealRequest(this.state.meal_request_id)
                    } else {
                        return new Promise((resolve, reject) => resolve(null))
                    }
                })(),
                (() => {
                    if (!isNaN(this.state.meal_request_id)) {
                        return CanteenClient.getMealRequest(this.state.meal_request_id, this.props.school_level ? this.state.school : null)
                    } else {
                        return new Promise((resolve, reject) => resolve(null))
                    }
                })().then((meal_request) => {
                    return Promise.all([
                        meal_request == null ? new Promise((resolve, reject) => resolve(this.props.rights_for_student || null))  : CanteenClient.getRightsForStudent(this.state.student || meal_request.student_id, meal_request.school_year_id),
                        meal_request == null ? new Promise((resolve, reject) => resolve(null)) : CanteenClient.getMealRequestUnSeenMessageCount(meal_request.id),
                        CanteenClient.getSchoolYearsForStudent(this.state.student || (meal_request||{}).student_id, meal_request == null || null)
                    ]).then(([rights,unseen_message_count,school_years])=>{
                        return [meal_request,rights,unseen_message_count,school_years]
                    })
                })
            ]).then(([template, [meal_request, rights, unseen_message_count,school_years]]) => {
                let active_tabs = this.getActiveTabs(meal_request)
                this.setState((prevState) => ({
                    school_years: school_years,
                    interval: setInterval(this.getUnseenMessageCount,this.state.interval_time),
                    student: prevState.student || meal_request.student_id,
                    meal_request: meal_request,
                    original_meal_request: meal_request,
                    template: template,
                    edit: !meal_request,
                    active_tabs: active_tabs,
                    page: active_tabs[0].name,
                    rights: rights,
                    fetch_in_progress: false,
                    unseen_message_count: unseen_message_count
                }), () => {
                    if(!this.state.meal_request && this.state.school_years.length === 1){
                        this.handleChangeMealRequestInformation('school_year_id',this.state.school_years[0].id)
                    }
                    let active_tabs = this.getActiveTabs(meal_request)
                    this.setState({
                        active_tabs: active_tabs
                    })
                    if (this.props.setOriginalMealRequest) {
                        this.props.setOriginalMealRequest(meal_request)
                    }
                })
            })).catch(error => {
                this.setState({
                    fetch_in_progress: false
                })
                throw error
            })
        })
    }

    getActiveTabs = (meal_request, determination) => {
        return [
            { name: 'igenyles', alias: meal_request?.cancelling_dining_student ? 'Lemondás' : 'Igénylés' },
            meal_request && meal_request.id ? { name: 'altalanos_adat', alias: 'Általános adatok' } : null,
            this.props.school_level ? { name: 'dokumentumok', alias: 'Dokumentumtár' } : null,
            meal_request && meal_request.id ? { name: 'uzenetek', alias: 'Üzenetek'} : null,
            this.state.rights.includes('HANDLE_MEAL_REQUEST') && (determination !== undefined ? determination : this.state.determination) && meal_request ? { name: 'jovahagyas', alias: 'Feldolgozás' } : null
        ].filter(p => p)
    }

    handleTabChange = (page) => {
        this.setState((prevState) => ({
            page: page,
            key: prevState.key + 1
        }))
    }

    handleFileUpload = (result) => {
        this.setState((prevState) =>({
            meal_request: {
                ...prevState.meal_request,
                documents: {
                    ...prevState.meal_request.documents,
                    [result.hash]: {file_name: result.name}
                }
            }
        }))
    }

    handleBlur = (field, event, index = null) => {
        if(field.name === 'date_from'){
            CanteenClient.getMealRequestBorderForDate(this.state.student, this.state.meal_request.school_year_id, event.target.value, this.state.meal_request.cancelling_dining_student).then((result)=>{
                this.setState({
                    border_date: result.border_date,
                    acceptable_date: result.acceptable_date,
                })
            })
        }
    }

    handleChange = (field, event, index = null) => {

        if (this.state.edit) {
            let value = ""
            if (field.multiple && field.possible_values !== null) {
                value = (this.state.meal_request.data[field.name].value || [])
                if (event.target.checked) {
                    value.push(event.target.value)
                } else {
                    value = value.filter(function (item) {
                        return item != event.target.value
                    })
                }
            } else if (event.target.type != 'checkbox') {
                value = event.target.value
            } else {
                value = event.target.checked
            }
            this.setState((prevState) => ({
                meal_request: {
                    ...prevState.meal_request,
                    data: {
                        ...prevState.meal_request.data,
                        [field.name]: {
                            ...prevState.meal_request.data[field.name],
                            value: (index === null ? value : (prevState.meal_request.data[field.name].value || []).slice(0, index).concat(Array.isArray(value) ? value : [value]).concat((prevState.meal_request.data[field.name].value || []).slice(index + 1)))
                        }
                    }
                },
            }))
        }
    }

    handleAddLine = (field, value = null) => {
        if (this.state.edit) {
            this.setState((prevState, props) => ({
                meal_request: {
                    ...prevState.meal_request,
                    data: {
                        ...prevState.meal_request.data,
                        [field.name]: {
                            ...prevState.meal_request.data[field.name],
                            value: (prevState.meal_request.data[field.name].value || []).concat([value])
                        }
                    }
                },
            }))
        }
    }

    handleRemoveLine = (field, index) => {
        if (this.state.edit) {
            this.setState((prevState, props) => ({
                meal_request: {
                    ...prevState.meal_request,
                    data: {
                        ...prevState.meal_request.data,
                        [field.name]: {
                            ...prevState.meal_request.data[field.name],
                            value: prevState.meal_request.data[field.name].value.slice(0, index).concat(prevState.meal_request.data[field.name].value.slice(index + 1))
                        }
                    }
                },
            }))
        }
    }

    handleEdit = (determination, copy_meal_request) => {
        this.setState((prevState) => {
            let new_meal_request = {
                ...prevState.meal_request,
                ...(copy_meal_request ? {
                    id: null,
                    document_hash: null,
                    state: null
                } : {}),
            }
            let active_tabs = this.getActiveTabs(new_meal_request, determination)
            //TODO nem kéne templatet is másolni? Ha copy létezni fog
            return {
                edit: !determination,
                determination: determination,
                original_meal_request: prevState.meal_request,
                meal_request: {
                    ...prevState.meal_request,
                    ...(copy_meal_request ? {
                        id: null,
                        document_hash: null,
                        state: null
                    } : {}),
                },
                active_tabs: active_tabs,
                page: determination ? 'jovahagyas' : active_tabs[0].name,
                dining_state: null
            }
        })
    }

    handleCancel = () => {
        this.setState((prevState) => {
            let active_tabs = this.getActiveTabs(prevState.original_meal_request, false)
            return {
                edit: false,
                determination: false,
                meal_request: prevState.original_meal_request,
                original_meal_request: undefined,
                active_tabs: active_tabs,
                page: active_tabs[0].name,
                dining_state: null
            }
        })
    }

    handleSave = () => {
        if (this.state.edit) {
            this.props.clearMessages()

            this.setState({
                save_in_progress: true
            }, () => {
                this.props.promise_error_handler((() => {
                    if (this.state.meal_request.id) {
                        return CanteenClient.editMealRequest(this.state.meal_request.id, this.state.meal_request.document_hash, this.state.meal_request.data)
                    } else {
                        return CanteenClient.createMealRequest(this.state.meal_request.school_year_id, this.state.student, this.state.template ? this.state.template.id : null, this.state.meal_request.data, this.state.meal_request.document_hash, this.state.meal_request.in_system, this.state.meal_request.cancelling_dining_student || false)
                    }
                })().then(result => {
                    this.props.loadRights()
                    let active_tabs = this.getActiveTabs(result)
                    this.setState((prevState) =>({
                        meal_request: result['meal_request'],
                        popup_messages: prevState.popup_messages.concat(result['messages']),
                        original_meal_request: undefined,
                        active_tabs: active_tabs,
                        page: active_tabs[0].name,
                        edit: false,
                        dining_state: null,
                        determination: false
                    }))
                    this.props.history.replace('/tanulo-kezelese/T' + (-1 * this.state.student) + '/tanuloi-igenylesek/korabbi/' + result['meal_request'].id)
                    this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                })).then(() => {
                    this.setState({
                        save_in_progress: false
                    })
                })
            })
        }
    }

    handleReject = () => {
        if (this.state.determination) {
            this.props.clearMessages()
            this.setState({
                save_in_progress: true
            }, () => {
                this.props.promise_error_handler(()=> CanteenClient.rejectMealRequest(this.state.meal_request.id, this.state.reason).then(result => {
                    if (this.props.handleReject) {
                        this.props.handleReject()
                    } else {
                        let active_tabs = this.getActiveTabs(result, false)
                        this.setState({
                            meal_request: result,
                            original_meal_request: undefined,
                            active_tabs: active_tabs,
                            page: active_tabs[0].name,
                            edit: false,
                            dining_state: null,
                            determination: false,
                        })
                    }
                    this.setState({
                        save_in_progress: false
                    })
                    this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                })).then(() => {
                    this.setState({
                        save_in_progress: false
                    })
                })
            })
        }
    }

    handleAccept = () => {
        if (this.state.determination) {
            this.props.clearMessages()
            this.setState({
                save_in_progress: true
            }, () => {
                this.props.promise_error_handler(()=> CanteenClient.acceptMealRequest(this.state.meal_request.id, this.state.reason, this.state.dining_state.student, this.state.dining_state.school_year, this.state.dining_state.data.meal_group_id, this.state.dining_state.transfer_orders, this.state.dining_state.data.meal_group_start, this.state.dining_state.reimbursement_students, this.state.dining_state.deleteable_dining_student_ids).then((result) => {
                    this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                    let afterFunction = this.props.handleAccept || this.handleFetch
                    if (result.messages.length > 0) {
                        this.setState({
                            original_meal_request: undefined,
                            warning_popup: {
                                messages: result.messages,
                                buttons: [
                                    { action: afterFunction, text: 'Rendben' }
                                ],
                                title: 'Figyelmeztetés',
                                close: afterFunction
                            },
                            save_in_progress: false,
                        })
                    } else {
                        this.setState({
                            save_in_progress: false
                        }, ()=> {
                            afterFunction()
                        })
                    }
                })).then(() => {
                    this.setState({
                        save_in_progress: false,
                    })
                })
            })
        }
    }

    handleRollback = () => {
        this.props.clearMessages()
        this.setState({
            rollback_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=> CanteenClient.rollbackMealRequest(this.state.meal_request.id).then(result => {
                this.props.loadRights()
                let active_tabs = this.getActiveTabs(result)
                this.setState({
                    meal_request: result,
                    original_meal_request: undefined,
                    active_tabs: active_tabs,
                    page: active_tabs[0].name,
                    edit: false,
                    dining_state: null,
                    determination: false,
                    rollback_in_progress: false
                })
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
            })).then(() => {
                this.setState({
                    rollback_in_progress: false
                })
            })
        })
    }

    handleChangeMealRequestInformation = (name, value) => {
        this.setState((prevState) => ({
            school_year_loading: prevState.school_year_loading || name === 'school_year_id',
            school_year_id:  name === 'school_year_id' ? value : prevState.school_year_id
        }), ()=> {
            this.props.promise_error_handler(Promise.all([
                (()=>{
                    if ((name === 'school_year_id' && this.state.meal_request_id === 'new') || (this.state.meal_request_id === 'lemondas' && name === 'cancelling_dining_student')) {
                        let school_year = this.state.school_years.find(school_year => name === 'school_year_id' ? school_year.id == value : school_year.id == this.state.meal_request?.school_year_id)
                        if (school_year) {
                            return CanteenClient.getActiveMealRequestTemplateForSchool(school_year.school_id,this.state.meal_request_id === 'lemondas' || this.state.meal_request?.cancelling_dining_student)
                        } else {
                            return new Promise((resolve, reject) => resolve(null))
                        }
                    } else {
                        return new Promise((resolve, reject) => resolve(null))
                    }
                })(),
                (() =>{
                    if(name === 'school_year_id'){
                        return CanteenClient.getRightsForStudent(this.state.student, value)
                    } else {
                        return new Promise((resolve, reject) => resolve(null))
                    }
                })()
            ]).then(([template, rights])=>{
                this.setState((prevState) => ({
                    template: template || prevState.template,
                    rights: rights || prevState.rights
                }),()=>{
                    this.props.promise_error_handler(Promise.all([(() => {
                        if (name === 'school_year_id' && this.state.meal_request_id !== 'lemondas' || this.state.meal_request_id === 'lemondas' && name === 'cancelling_dining_student') {
                            let school_year = this.state.school_years.find(school_year => school_year => name === 'school_year_id' ? school_year.id == value : school_year.id == this.state.meal_request?.school_year_id)
                            if (school_year && (this.state.template || this.state.meal_request_id === 'pdf' || this.state.meal_request_id === 'lemondas')) {
                                if (this.state.meal_request_id === 'new') {
                                    return CanteenClient.getDefaultMealRequestForStudent(this.state.template.id, this.state.student, school_year.school_id, school_year.year_id, true, null)
                                }  else if(this.state.meal_request_id === 'lemondas'){
                                    return CanteenClient.getDefaultMealRequestForStudent(this.state.template.id, this.state.student, school_year.school_id, school_year.year_id, true, value)
                                }else {
                                    return CanteenClient.getDefaultMealRequestForStudent(null, this.state.student, school_year.school_id, school_year.year_id, false, null)
                                }
                            } else {
                                return new Promise((resolve, reject) => resolve(null))
                            }
                        } else {
                            return new Promise((resolve, reject) => resolve(null))
                        }
                    })(),
                    ]).then(([meal_request]) => {
                        this.setState((prevState) =>({
                            meal_request_key: prevState.meal_request_key + (name === 'school_year_id' && this.state.meal_request_id !== 'lemondas' || name === 'cancelled_dining_student_id' ? 1 : 0),
                            meal_request: name === 'school_year_id' && !meal_request ?
                                {
                                    school_year_id: value,
                                    id: null
                                } : {
                                    ...(name === 'school_year_id' && this.state.meal_request_id !== 'lemondas' || this.state.meal_request_id === 'lemondas' && name === 'cancelling_dining_student' ? meal_request : prevState.meal_request),
                                    ...(name === 'school_year_id' ? {cancelling_dining_student: false} : {}),
                                    [name]: value
                                },
                            school_year_loading: name === 'school_year_id' ? false : prevState.school_year_loading,
                            school_year_id: name === 'school_year_id' ? value : prevState.school_year_id
                        }))
                    }))
                })
            }))
        })
    }

    handleStateChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    uploadFile = () => {
        if (this.state.edit && this.state.meal_request && !this.state.meal_request.in_system && this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            }, () => {
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0]).then(hash => {
                    this.setState((prevState) => ({
                        meal_request: {
                            ...prevState.meal_request,
                            document_hash: hash
                        }
                    }))
                })).then(() => {
                    this.setState({
                        upload_in_progress: false
                    })
                })
            })
        }
    }

    setDiningsState = (new_state, callback) => {
        this.setState({
            dining_state: new_state
        }, callback)
    }
    handleDetermination = () => {
        if (this.state.determination) {
            if (this.state.meal_request.state == 'ACCEPTED') {
                this.handleAccept()
            } else if (this.state.meal_request.state == 'REJECTED') {
                this.handleReject()
            }
        }
    }

    handleOpenMessages = (callback) => {
        this.props.promise_error_handler(()=> CanteenClient.listMealRequestMessages(this.state.meal_request.id).then(messages => {
            this.setState((prevState) => ({
                meal_request: {
                    ...prevState.meal_request,
                    messages: messages
                }
            }), () => {
                callback()
            })
        }))
    }

    handleReadAllMessage = () => {
        this.props.promise_error_handler(()=> CanteenClient.setSeenMealRequestMessages(this.state.meal_request.id).then(messages => {
            this.setState((prevState) => ({
                meal_request: {
                    ...prevState.meal_request,
                    messages: messages
                }
            }),()=>{
                this.getUnseenMessageCount()
            })
        }))
    }

    handleAddMessageToMealRequest = (message, callback, callbackError) => {
        this.props.promise_error_handler(()=> CanteenClient.addMessageToMealRequest(this.state.meal_request.id, message).then(messages => {
            this.setState((prevState) => ({
                meal_request: {
                    ...prevState.meal_request,
                    messages: messages
                }
            }), () => {
                callback()
            })
        }).catch(error => {
            callbackError()
            throw error
        }))
    }

    handleSeenLastMessage = (message)=>{
        this.props.promise_error_handler(CanteenClient.setSeenMealRequestMessage(message.id).then(message => {
            this.setState((prevState) => ({
                meal_request: {
                    ...prevState.meal_request,
                    last_state_message: message
                }
            }),()=>{
                this.getUnseenMessageCount()
            })
        }))
    }

    readPopupMessage = (ids, open_menu_after = false) => {
        //TODO szebbíteni
        Promise.all(ids.map(id => CanteenClient.setSeenMealRequestMessage(id))).then(()=>{
            this.setState((prevState) => ({
                popup_messages: prevState.popup_messages.filter(m => !ids.includes(m.id))
            }),()=>{
                this.getUnseenMessageCount(open_menu_after)
            })
        })
    }


    render() {
        let original_meal_request = this.state.original_meal_request || this.state.meal_request
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
                {this.state.border_date && this.state.act_datetime && this.state.meal_request_id !== 'lemondas'? (()=>{
                    let current_date = this.state.act_datetime
                    let border_datetime= new Date(this.state.border_date.replace(' ', 'GMT'))
                    let diff = border_datetime - current_date
                    let counting = true
                    let hours
                    let mins
                    let secs
                    if(diff > 0) {
                        hours = Math.floor(diff / (1000 * 3600))
                        mins = Math.floor((diff - hours * 1000 * 3600) / (1000 * 60))
                        secs = Math.floor((diff - hours * 1000 * 3600 - mins * 1000 * 60) / 1000)
                    } else {
                        hours = 0
                        mins = 0
                        secs = 0
                        counting = false
                    }
                    if(mins < 10 && hours === 0){
                        return <div className={"countdown-container meal-request-countdown-container"}>
                            <h4>Adott nappal az étkezés igénylésére {(''+border_datetime.getHours()).padStart(2, '0')}:{(''+border_datetime.getMinutes()).padStart(2, '0')} óráig van lehetőség.</h4>
                            <h4>Az igénylés leadásáig hátralévő idő:</h4>
                            <div className={["countdown", !counting ? 'finished' : null].filter(x => x).join(' ')}>
                                <div className={"digit"} data-value={(hours).toString().padStart(2, '0').charAt(0)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                                <div className={"digit"} data-value={(hours).toString().padStart(2, '0').charAt(1)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                                <div>:</div>
                                <div className={"digit"} data-value={(mins).toString().padStart(2, '0').charAt(0)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                                <div className={"digit"} data-value={(mins).toString().padStart(2, '0').charAt(1)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                                <div>:</div>
                                <div className={"digit"} data-value={(secs).toString().padStart(2, '0').charAt(0)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                                <div className={"digit"} data-value={(secs).toString().padStart(2, '0').charAt(1)}>{ [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(digit => <div>{digit}</div>) }</div>
                            </div>
                        </div>
                    }
                    return null
                })() : null}
                {
                    this.state.warning_popup !== null ? <PopUp buttons={this.state.warning_popup.buttons}
                        title={this.state.warning_popup.title}
                        close={this.state.warning_popup.close}>
                        <div className="page-guide">A művelet során az alábbi figyelmeztetések lettek kiadva</div>
                        <ul>
                            {this.state.warning_popup.messages.map(message => <li><div className="meal-request-warning-text">{message}</div></li>)}
                        </ul>
                    </PopUp> : null
                }
                {
                    this.state.popup_messages.length > 0 ? <PopUp
                        buttons = {[
                            {action: ()=>this.readPopupMessage(this.state.popup_messages.map(m => m.id)), text: 'Rendben'},
                            this.state.meal_request?.have_menu && ['CREATED', 'UPDATED'].includes(this.state.meal_request?.state) ? {action: ()=>this.readPopupMessage(this.state.popup_messages.map(m => m.id), true), text: 'Menü megnyitása'} : null
                        ].filter(x=>x)}
                        title = {'Rendszerüzenet ('+this.state.popup_messages.length+' db)'}
                        close = {()=>this.readPopupMessage(this.state.popup_messages.map(m => m.id))}
                    >
                        <div className="block meal-request-popup-message">
                            {this.state.popup_messages.length === 1 ? this.state.popup_messages[0]['message'] : <ul>
                                {this.state.popup_messages.map(m => <li>{m.message}</li>)}
                            </ul>}
                        </div>
                    </PopUp> : null
                }
                { this.state.popup_help_open && (
                    <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                        {!(this.state.meal_request_id === 'lemondas' || this.state.meal_request?.cancelling_dining_student) ? <p>
                            <div style={{fontWeight: 'bold'}}>Étkezés megrendelése</div>
                            <div>Az online megrendelés létrehozásához ki kell választani, hogy melyik tanévre szeretne új igényt leadni.</div>
                            <div>A tanév kiválasztása után az igénylési felületen a tanuló adatai – az „Adatok” menüpont alatt megadottaknak megfelelően – automatikusan a rendszer által kitöltésre kerülnek. Ezen adatokat a szülő részéről ellenőrizni szükséges, és az igénylési felület kitöltése előtt az „Adatok” menüpont alatt módosítani, ha az szükséges.</div>
                            <div>Az Igénylési felületen meg kell adni az igénylés kezdetét, és az igényelt étkezési csoportot (1x-i étkezés, 2x-i étkezés, stb.). A „Támogatás oka” blokkban meg kell jelölni, ha a tanuló részére étkezési támogatást kell biztosítani, melyhez a megfelelő hivatalos dokumentumot fel kell tölteni. Amennyiben a tanulónak eltérő étrendet kell biztosítani, ezt orvosi igazolással lehet igényelni, melyhez az orvosi igazolást a rendszerbe fel kell tölteni. Ha a dokumentumok adott pillanatban nem állnak rendelkezésre, azt az igénylés benyújtását követően (az ügyintéző általi jóváhagyás előtt) az igényléshez fel lehet tölteni. Az igénylés elfogadása után a hiányzó dokumentumokat, vagy az ügyintéző által kért hiánypótlást a „Dokumentumok” menüpontban lehet feltölteni.</div>
                            <div>Az adatok kitöltése és dokumentumok feltöltése után az igénylést a „Mentés” ikonra lépve lehet elmenteni.  A mentés tényéről vagy esetleges hiba esetén a hiba okáról (kötelező mező nem került kitöltésre) a képernyő felső részében megjelenik egy üzenet.</div>
                            <div>Mentés után a rendszer egy PDF formátumú igénylési lapot generál, mely a képernyőn az „Általános adatok” fülre kattintva a <span style={{textDecoration: 'underline'}}>Dokumentum</span> melletti Letöltés linkre lépve megtekinthető, letölthető.</div>
                            <div>Az Üzenetek fülre lépve esetleges hiánypótlásról, stb. lehet üzenni az iskolai ügyintéző részére az igényléssel kapcsolatban. A szöveg beírása után a Küldés ikonra kattintva az üzenet megjelenik az ügyintéző felületén.</div>
                            <div>Ezzel az igénylés elkészült.</div>
                            <div>Az elküldött igénylés az „Étkezés Igénylése/végleges lemondás” menüpontra kattintva a „Korábbi igénylések” blokkban megtekinthető.</div>
                        </p> :
                        <p>
                            <div style={{fontWeight: 'bold'}}>Étkezés lemondása</div>
                            <div>A korábban megrendelt étkezést a<span style={{fontWeight: 'bold'}}>„Lemondás”</span> gombra kattintva, a megjelenő dátum mezőben a <span style={{fontWeight: 'bold'}}>lemondás dátumának megadásával</span> lehet. A lemondást el kell <span style={{fontWeight: 'bold'}}>menteni</span>.</div>
                        </p>}
                    </PopUp>
                ) }
                <div className={"page-guide"}>
                    {this.state.meal_request_id === 'lemondas' || this.state.meal_request?.cancelling_dining_student ? <React.Fragment>
                        Ezen a felületen az <span style={{fontWeight: 'bold'}}>étkezés végleges lemondására</span> (nem hiányzás) van lehetőség!
                        <br/>
                        Amennyiben a korábban megrendelt étkezést <span style={{fontWeight: 'bold'}}>módosítani</span> szeretné (pl: étkezési csoport, vagy étkezési támogatásra való jogosultság módosul) akkor az étkezést <span style={{fontWeight: 'bold'}}>nem kell lemondani</span>, hanem az "Étkezés igénylése" menüpontban új étkezési igény leadása szükséges.
                    </React.Fragment> : <React.Fragment>
                        Ezen a felületen <span style={{fontWeight: 'bold'}}>étkezés igénylésére</span> van lehetőség!<br/>
                        Amennyiben a korábban megrendelt étkezést <span style={{fontWeight: 'bold'}}>módosítani</span> szeretné (pl: étkezési csoport, vagy étkezési támogatásra való jogosultság módosul) akkor új étkezési igény leadása szükséges a módosított paraméterekkel.<br/>
                        Az étkezés <span style={{fontWeight: 'bold'}}>végleges lemondását</span> (nem hiányzás) az "Étkezés végleges lemondása" menüpontban teheti meg.
                    </React.Fragment>}
                </div>
                {
                    this.state.save_in_progress ? <Loading loading_text=''/> : null
                }
                {this.state.active_tabs.length > 1 ? <div className="page-tabs">
                    <ul>
                        {
                            this.state.active_tabs.length > 1 ? this.state.active_tabs.map(tab => {
                                return <li key={tab.name} className={this.state.page === tab.name ? 'active' : null} onClick={() => this.handleTabChange(tab.name)}>
                                        {tab.alias}
                                        {tab.name === 'uzenetek' && this.state.unseen_message_count ? <span className="unread-message-count"> ({this.state.unseen_message_count})</span> : null}
                                    </li>
                            }) : null
                        }
                    </ul>
                </div> :<div className="page-subtitle">{this.state.meal_request_id === 'lemondas' || this.state.meal_request?.cancelling_dining_student ? 'Étkezés lemondása' : 'Étkezés igénylése'}</div>}
                {
                    this.state.page!== 'uzenetek' && this.state.meal_request && this.state.meal_request.last_state_message && this.state.meal_request.last_state_message.created === this.state.meal_request.supervised_time ? <React.Fragment>
                        <Uzenet message={this.state.meal_request.last_state_message} handleSeen={this.handleSeenLastMessage}/>
                    </React.Fragment> : null
                }
                <React.Fragment key={'subpage.'+this.state.key}>
                    {
                        this.state.page === 'igenyles' && this.state.rights.includes('READ_MEAL_REQUEST') ? <React.Fragment>
                            { !this.state.meal_request || !this.state.meal_request.school_year_id || this.state.school_years.find(sy => sy.id==this.state.meal_request.school_year_id) ? <div className="block">
                                <label className="label label-long required">Tanév</label>
                                <SelectField className={(!this.state.edit || (this.state.meal_request || { id: null }).id !== null) ? "text-container" : "field field-simple"} readOnly={this.state.school_year_loading || !this.state.edit || (this.state.meal_request || { id: null }).id !== null} value={(this.state.meal_request || {}).school_year_id || this.state.school_year_id} onChange={event => this.handleChangeMealRequestInformation('school_year_id', event.target.value)} displayValues={this.state.school_years.reduce((prev, curr) => ({
                                    ...prev,
                                    [curr.id]: curr.year_name + ' ('+curr.school_name+')'
                                }), {})}>
                                    <option value="" disabled={true}selected={true}>Kérem válasszon!</option>
                                    {this.state.school_years.map(sy => <option value={sy.id} />)}
                                </SelectField>
                            </div> : null}
                            {
                                this.state.meal_request_id === 'lemondas' && !this.state.meal_request?.cancelling_dining_student ? <React.Fragment>
                                    {this.state.meal_request?.school_year_id? <React.Fragment>
                                        <EtkezesFelveteleTanulohoz cancellingDiningStudent={()=>this.handleChangeMealRequestInformation('cancelling_dining_student', true)} year={this.state.school_years.find(sy => sy.id == this.state.meal_request?.school_year_id).year_id} school_year_data={this.state.school_years.find(sy => sy.id == this.state.meal_request?.school_year_id) || {}} hide_buttons={true} {...{school: this.state.school, student: this.state.student, rights_for_student: this.props.rights_for_student}} readOnly={true} onlyShowDinings={true} />
                                    </React.Fragment>: null}
                                </React.Fragment> : <React.Fragment>
                                    {(!this.state.template && this.state.meal_request_id !== 'pdf') && this.state.meal_request && this.state.meal_request.school_year_id && this.state.meal_request.in_system !== false? <div className="missing-function">
                                        Ez a funkció az adott iskolában jelenleg nem elérhető.
                                    </div> : <React.Fragment>
                                        <input style={{ display: 'none' }} type="file" ref={this.file_input} onChange={this.uploadFile} />
                                        {
                                            this.state.meal_request && this.state.meal_request.school_year_id && this.state.meal_request.data ? ( this.state.school_years.find(sy => sy.id==this.state.meal_request.school_year_id) ? <Igenyles
                                                key={'igenyles.'+this.state.meal_request_key}
                                                file_input={this.file_input}
                                                readOnly={!this.state.edit}
                                                handleAddLine={this.handleAddLine}
                                                handleRemoveRow={this.handleRemoveLine}
                                                handleChange={this.handleChange}
                                                handleBlur={this.handleBlur}
                                                border_date = {this.state.border_date}
                                                acceptable_date = {this.state.acceptable_date}
                                                handleFileUpload={this.handleFileUpload}
                                                meal_request={this.state.meal_request}
                                                year={(this.state.school_years.find(sy => sy.id == this.state.meal_request.school_year_id) || {}).year_id}
                                                school={(this.state.school_years.find(sy => sy.id == this.state.meal_request.school_year_id) || {}).school_id}
                                                template={this.state.template}
                                                upload_in_progress={this.state.upload_in_progress}
                                                student={this.state.student} />: <h4>A tanuló nem tanul az igényléshez rendelt tanévben.</h4>) : null
                                        }
                                    </React.Fragment>}
                                </React.Fragment>
                            }

                        </React.Fragment> : null
                    }
                    {this.state.page === 'altalanos_adat' ? <IgenylesAltalanosAdatai meal_request={original_meal_request} /> : null}
                    {this.state.page === 'dokumentumok' ? <Dokumentumok student={this.state.student} school={this.state.school} /> : null}
                    {this.state.page === 'uzenetek' ? <Uzenetek unseen_message_count={this.state.unseen_message_count} readOnly={!this.state.rights.includes('WRITE_MEAL_REQUEST_MESSAGE')} addMessageToMealRequest={this.handleAddMessageToMealRequest} meal_request={this.state.meal_request} handleFetch={this.handleOpenMessages} setSeenMealRequestMessages={this.handleReadAllMessage} /> : null}
                    {this.state.page === 'jovahagyas' && this.state.rights.includes('HANDLE_MEAL_REQUEST') ? <React.Fragment>
                        <div className="page-guide">Kérem, adja meg, hogy az igénylést elfogadni, vagy elutasítani szeretné</div>
                        <div className="block">
                            <label className="label label-long required">Új státusz</label>
                            <SelectField className={"field field-simple"} value={(this.state.meal_request || {}).state} onChange={event => this.handleChangeMealRequestInformation('state', event.target.value)} displayValues={Object.keys(this.props.meal_request_states).reduce((prev, curr) => ({
                                ...prev,
                                [curr]: this.props.meal_request_states[curr].name
                            }), {})}>
                                <option value="">Kérem válasszon!</option>
                                {Object.keys(this.props.meal_request_states).filter(state => state === 'REJECTED' || state === 'ACCEPTED').map(state => <option value={state}/>)}
                            </SelectField>
                        </div>
                        {
                            this.state.meal_request.state == 'REJECTED' || this.state.meal_request.state == 'ACCEPTED' ? <React.Fragment>
                                <div className="block">
                                    <label className={['label', 'label-long', this.state.meal_request.state == 'REJECTED' ? 'required' : null].filter(v => v).join(' ')}>Döntés indoklása:</label>
                                    <SimpleField className="field field-simple" value={this.state.reason} onChange={(event) => this.handleStateChange('reason', event.target.value)} />
                                </div>
                                {
                                    this.state.meal_request.state == 'ACCEPTED' ? <EtkezesFelveteleTanulohoz default_meal_group_id={this.state.meal_request?.data?.meal_group_id?.value || null} default_meal_group_start={this.state.meal_request?.data?.date_from?.value || null} rights_for_student={this.state.rights} school_year_data={this.state.school_years.find(sy => sy.id == this.state.meal_request.school_year_id) || {}} setParentState={this.setDiningsState} initial_value={this.state.dining_state} hide_buttons={true} school={(this.state.school_years.find(sy => sy.id == this.state.meal_request.school_year_id) || {}).school_id} student={this.state.meal_request.student_id} year={(this.state.school_years.find(sy => sy.id == this.state.meal_request.school_year_id) || {}).year_id} /> : null
                                }
                            </React.Fragment> : null
                        }
                    </React.Fragment> : null}
                </React.Fragment>
                {(this.state.template || this.state.meal_request_id === 'pdf' || this.state.meal_request && !this.state.meal_request.in_system) && this.state.meal_request && this.state.meal_request.data? <div className="actions">
                    {
                        (this.state.page=='igenyles' || this.state.page=='jovahagyas')  && (this.state.meal_request.in_system ? (this.state.meal_request.id ?this.state.rights.includes('EDIT_MEAL_REQUEST') :  this.state.rights.includes('WRITE_MEAL_REQUEST')) : this.state.rights.includes('WRITE_MEAL_REQUEST_WITH_PDF')) && !this.state.determination && this.state.meal_request && (original_meal_request.state == 'CREATED' || original_meal_request.state == 'UPDATED' || !original_meal_request.state) ?
                            <React.Fragment>
                                {this.state.edit ? <React.Fragment>
                                    <LoadingButton loading={this.state.save_in_progress} disabled={this.state.rollback_in_progress || !this.state.meal_request || this.state.upload_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                                    {(this.state.meal_request || {}).id ? <button disabled={this.state.rollback_in_progress || this.state.save_in_progress} onClick={this.handleCancel}>Mégse</button> : null}
                                </React.Fragment> : ((this.state.meal_request || {}).id ? <React.Fragment>
                                    <button disabled={this.state.save_in_progress || this.state.rollback_in_progress} onClick={() => this.handleEdit(false)}>Szerkesztés</button>
                                    {(this.state.meal_request.in_system ? this.state.rights.includes('ROLLBACK_MEAL_REQUEST') : this.state.rights.includes('WRITE_MEAL_REQUEST_WITH_PDF')) ? <LoadingButton loading={this.state.rollback_in_progress} disabled={this.state.save_in_progress} onClick={this.handleRollback}>{this.state.meal_request?.cancelling_dining_student ? 'Lemondás' : 'Igénylés'} visszavonása</LoadingButton> : null}
                                </React.Fragment> : null)}
                            </React.Fragment> : null
                    }
                    {
                        (this.state.meal_request_id !== 'lemondas' || !this.state.meal_request?.cancelling_dining_student || this.state.page=='igenyles' || this.state.page=='jovahagyas')  && this.state.rights.includes('HANDLE_MEAL_REQUEST') && !this.state.edit && this.state.meal_request && this.state.meal_request.id && (this.state.determination || original_meal_request.state == 'CREATED' || original_meal_request.state == 'UPDATED') && ('alkalmazasgazda' in this.props.rights.rights || (this.props.rights.rights.ugyintezo || []).findIndex(s => s == this.state.school) >= 0) ? (
                            this.state.determination ? <React.Fragment>
                                {
                                     this.state.page=='jovahagyas' ? <React.Fragment>
                                        <LoadingButton loading={this.state.save_in_progress} disabled={this.state.meal_request.state === 'ACCEPTED' && (!this.state.dining_state || this.state.dining_state.fetch_in_progress)} onClick={this.handleDetermination}>Mentés</LoadingButton>
                                        <button disabled={this.state.save_in_progress} onClick={this.handleCancel}>Mégse</button>
                                     </React.Fragment> : null
                                }
                            </React.Fragment> : <React.Fragment>
                                    <button onClick={() => this.handleEdit(true)}>Feldolgozás</button>
                                </React.Fragment>
                        ) : null
                    }
                </div> : null}
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        rights: state.rights,
        meal_request_states: state.meal_request_states,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    setActions: setActions,
    addAction: addAction,
    setSystemMessagesCount: setSystemMessagesCount,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TanuloiIgenyles))