import React, { Component } from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { setTitle, setGuide } from '../actions'
import { Link } from "react-router-dom"
import { getObjectValue } from '../functions';
import CanteenClient from '../CanteenClient';
import FilestoreClient from '../FileStoreClient';
import { clearMessages, addMessage } from '@sapkk/app/actions';
import DateField from '@sapkk/app/components/DateField';
import SeparatedNumberFields from '@sapkk/app/components/SeparatedNumberFields';
import SimpleField from '@sapkk/app/components/SimpleField';
import LoadingButton from '../components/LoadingButton';

class SearchResult extends Component {

    constructor(props) {
        super(props)
        this.state={
            openRow: null
        }
    }

    lastPage() {
        return Math.floor((this.props.result.result_summary.total - 1) / (this.props.parameters||{}).limit)
    }

    page(page){
        this.setState({
            openRow: null
        })
        this.props.search(page)
    }

    orderBy =(event)=> {
        let f = [...this.props.order];
        if (!event.shiftKey) {
            f = f.filter(order => { return order.field === event.target.getAttribute('data-field') })
        }

        let found = false;
        f.forEach(order => {
            if (order.field == event.target.getAttribute('data-field')) {
                order.order = order.order === 'asc' ? 'desc' : 'asc'
                found = true
            }
        })
        if (!found) {
            f.push({ field: event.target.getAttribute('data-field'), order: 'asc' })
        }
        this.props.changeOrder(f)
    }

    exportResults() {
        this.setState({
            export_in_progress: true
        })
        this.props.clearMessages()
        this.props.promise_error_handler(CanteenClient.reportDownload(this.props.report_url, this.props.conditions).then(result => {
            return FilestoreClient.download(result.hash)
        })).then(() => {
            this.setState({
                export_in_progress: false
            })
        })
    }

    formatValue(row, header, value){
        let data = null
        if(header.data_path){
            data = getObjectValue(row,header.data_path)
        }
        if(header.formatValue){
            return header.formatValue(row, value)
        }
        switch(header.type){
            case 'DATE':
                return <DateField data-data={data} readOnly={true} value={value}/>
            case 'INTEGER':
            case 'REAL':
                return <SeparatedNumberFields data-data={data} readOnly={true} value={value}/>
            case 'BOOL':
                return <SimpleField data-data={data} type='checkbox' checked={value} value={value ? 'Igen' : 'Nem'} readOnly={true}/>
            default:
                return <SimpleField data-data={data} value={value} readOnly={true}/>
        }
    }


    static getColSpan = (header) => {
        let colSpan = 0
        header.childrens.forEach(sub_header => {
            colSpan += SearchResult.getColSpan(sub_header)
        })
        return Math.max(colSpan,1)
    }

    render() {
        let orderDictionary = {};
        (this.props.order||[]).forEach(order => {
            orderDictionary[order.field] = order.order
        })
        let max_level = Math.max(...(this.props.result || {headers : []}).headers.map(header=>header.depth))
        let headers = (this.props.result || {headers: []}).headers.reduce(function recred(prev, current){
            if(current.childrens.length > 0){
                let array = prev.concat(current).concat(current.childrens.reduce(recred,[]))
                return array
            } else{
                return prev.concat([current])
            }
        },[])
        let deepest_headers = headers.filter(header=>header.childrens.length===0 && !(this.props.hideHeaders || []).includes(header.name))
        let nextIndexes = {}
        let row_index = -1
        return (
            <React.Fragment>
                {(this.props.params||[]).length ? <div className="block">
                    <ul className="actual-selector search-parameters">
                    {
                        (this.props.params||[]).filter(param => param.name in this.props.conditions && this.props.conditions[param.name]).map(
                            param => <li><span>{param.alias}: {this.props.param_data[param.id] ? (this.props.param_data[param.id].find(data => data.id == this.props.conditions[param.name]) ||{name: this.props.conditions[param.name]}).name : this.props.conditions[param.name]}</span></li>
                            )
                    }
                    </ul>
                </div> : null}
                <div className="page-subtitle">{this.props.title||'Találatok'}</div>
                {(this.props.result || {result: []}).result.length !== 0 ? <React.Fragment><div className="table-shadow-container">
                    <table className={"search-result " + ( this.props.description_fields ? " striped-collapse " : " striped ") +" order " + (this.props.tableClassName ? this.props.tableClassName : '')}>
                        <thead>
                            {
                                [...Array(max_level).keys()].map(level => <tr>
                                    {
                                        headers.filter(header => header.level == level + 1 && !(this.props.hideHeaders || []).includes(header.name)).map(header =>  {
                                            let classList = [];
                                            let props = {}
                                            if(header.can_be_ordered){
                                                if((header.order_field_name || header.name) in orderDictionary){
                                                    classList.push(orderDictionary[(header.order_field_name || header.name)] )
                                                }
                                                props['data-field'] = (header.order_field_name || header.name)
                                                props['onClick']=(event) => { this.orderBy(event) }
                                            }
                                           
                                            return <th
                                                rowSpan = {header.childrens.length === 0 ? max_level - level : 1}
                                                colSpan = {SearchResult.getColSpan(header)}
                                                {...props}
                                                className={ classList.join(' ')}>
                                                {header.alias}
                                            </th>
                                        })
                                    }
                                </tr>)
                            }
                        </thead>
                        <tbody>
                            {
                                this.props.result.result.map((row, index) => {
                                    return <tr>
                                        {
                                            deepest_headers.map((header,ind) => {
                                                if((nextIndexes[header.name] || 0) > index){
                                                    return null
                                                } else{
                                                    let neighbours = nextIndexes[(deepest_headers[ind-1]||{name:""}).name] || this.props.result.result.length
                                                    let next_found  = header.mergeable ? this.props.result.result.findIndex((v,i) => i>index && getObjectValue(v,header.path)!=getObjectValue(row,header.path)) : index+1
                                                    if(next_found===-1){
                                                        next_found = this.props.result.result.length
                                                    }
                                                    if(neighbours===index){
                                                        ++neighbours
                                                    }
                                                    if(ind == 0){
                                                        row_index += 1
                                                    }
                                                    let new_nextIndexes = Math.min(next_found, neighbours)
                                                    let c = new_nextIndexes - (nextIndexes[header.name]||0)
                                                    nextIndexes[header.name] = new_nextIndexes
                                                    let value = this.formatValue(row,header, getObjectValue(row,header.path))
                                                    if((this.props.linkMethods||{})[header.name]){
                                                        value = <Link to={this.props.linkMethods[header.name](row)}>{value}</Link>
                                                    }
                                                    return <td rowSpan={c} style={{whiteSpace: 'nowrap'}} className={row_index % 2 ?'even' : 'odd'}><span className={header.class_method ? (header.class_method(row) || []).join(' ') : null} onClick={(this.props.clickMethods||{})[header.name] ? () =>this.props.clickMethods[header.name](row) : null}>{value}</span></td>
                                                }
                                            })
                                        }
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                {
                    (this.props.parameters||{}).limit && <React.Fragment>
                        <div className="block block-lg-1-3">
                            {this.props.report_url ? <LoadingButton loading={this.state.export_in_progress} onClick={() => this.exportResults()}>Exportálás</LoadingButton> : null }
                        </div>
                        <div className="block block-lg-2-3 pager">
                            <div className="block block-lg-1-3 result-summary">
                                Találatok száma: {this.props.result.result_summary.total}
                            </div>
                            <ul className="pager">
                                <li disabled={this.props.result.result_summary.page == 0} onClick={() => this.page(0)}>« első</li>
                                {
                                    [0, 1, 2, 3, 4].map((number) => {
                                        let pageNum = number - 2 + this.props.result.result_summary.page;
                                        if (pageNum < 0 || pageNum > this.lastPage()) {
                                            return;
                                        }
                                        let props = {}
                                        if (this.props.result.result_summary.page == pageNum) {
                                            props.className = 'active'
                                        } else {
                                            props.onClick = () => {
                                                this.page(pageNum)
                                            }
                                        }
                                        return <li {...props} key={'page.' + pageNum}>{pageNum + 1}</li>
                                    })
                                }
                                <li disabled={this.props.result.result_summary.page >= this.lastPage()} onClick={() => this.page(this.lastPage())}>utolsó »</li>
                            </ul>
                        </div>
                    </React.Fragment>
                }
                    </React.Fragment> : <h4>{this.props.not_found_text || 'Nincs találat.'}</h4>
                }
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        dictionary: state.dictionary,
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResult))