import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import { withRouter } from "react-router"
import FilestoreClient from "../../FileStoreClient"

class IgenylesAltalanosAdatai extends Component {
    constructor(props) {
        super(props)

        this.state = {
            meal_request: this.props.meal_request
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.meal_request !== state.meal_request) {
            return {
                meal_request: props.meal_request
            }
        } else {
            return null
        }
    }

    render() {
        return <React.Fragment>
            <div className="block block-lg-1-2">
                <label className="label label-short">Azonosító</label>
                <div className="text-container">{this.state.meal_request.id}</div>
            </div>
            <div className="block block-lg-1-2">
                <label className="label label-short">Státusz</label>
                <div className="text-container">{this.props.meal_request_states[this.state.meal_request.state].name}</div>
            </div>
            <div className="block block-lg-1-2">
                <label className="label label-short">Dokumentum</label>
                <div className="text-container">{this.state.meal_request.document_hash ? <a onClick={()=>this.props.promise_error_handler(FilestoreClient.download(this.state.meal_request.document_hash))}>Letöltés</a> : 'Nincs PDF'}</div>
            </div>
            <div className="block block-lg-1-2">
                <label className="label label-short">Létrehozta</label>
                <div className="text-container">
                    <span title={!this.state.meal_request.creator_type === 'ADMINISTRATOR' ? this.state.meal_request.created_by : null}>
                        {(this.props.creator_types[this.state.meal_request.creator_type]||{}).name}
                    </span>
                </div>
            </div>
            <div className="block block-lg-1-2">
                <label className="label label-short">Létrehozva</label>
                <div className="text-container">{this.state.meal_request.creation_time}</div>
            </div>
            <div className="block block-lg-1-2">
                <label className="label label-short">Módosítva</label>
                <div className="text-container">{this.state.meal_request.update_time}</div>
            </div>
            <div className="block block-lg-1-2">
                <label className="label label-short">Feldolgozta</label>
                <div className="text-container">{this.state.meal_request.supervisor}</div>
            </div>
            <div className="block block-lg-1-2">
                <label className="label label-short">Feldolgozva</label>
                <div className="text-container">{this.state.meal_request.supervised_time}</div>
            </div>
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        meal_request_states: state.meal_request_states,
        rights: state.rights,
        creator_types: state.creator_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IgenylesAltalanosAdatai))