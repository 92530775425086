import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import SearchResult from "../SearchResult";
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton"
import FilestoreClient from "../../FileStoreClient"

class SzamlazashozAtadottListak extends Component {
    constructor(props) {
        super(props)

        this.state = {
            reports: [],
            report_id: "",
            data: {},
            search_in_progress: true,
            skip_update: false,
            download_riport: null,
            fetch_in_progress: true,
            billing_states: [],
            parameters: {
                limit: 20,
                ...((this.props.history.location.state || {}).parameters || {})
            },
            order: (this.props.history.location.state || {}).order || [],
            result: undefined,
            key: 0,
            page:  (this.props.history.location.state || {}).page || 0
        }
    }

    componentDidMount() {
        if(this.props.history.location.pathname!=='/szamlazas/szamlazashoz-atadott-listak'){
            this.props.history.replace('/szamlazas/szamlazashoz-atadott-listak')
        }
        CanteenClient.listBillingState().then(billing_states => {
            this.setState({
                fetch_in_progress: false,
                billing_states: billing_states
            })
        })
        this.handleSearch()
    }

    static getDerivedStateFromProps(props, state) {
        if (props.history.location.state && !state.skip_update) {
            return {
                order: props.history.location.state.order,
                parameters: props.history.location.state.parameters,
                page: props.history.location.state.page
            }
        } else {
            return null
        }
    }

    
    handleChangeOrder(order) {
        this.setState({
            skip_update: true,
            order: order
        }, () => {
            this.setState({
                skip_update: false
            })
            this.handleSearch()
        })
    }

    handleSearch = (page = undefined) =>{
        this.props.clearMessages()

        this.setState((prevState) =>({
            search_in_progress: true,
            skip_update: true,
            page: page !== undefined ? page : prevState.page
        }), () => {
            this.props.promise_error_handler(CanteenClient.listSavedReports({report_id: [{operator: 13, value: [15,16,17, 25]}]},  {info: true, limit: this.state.parameters.limit, offset: this.state.page* this.state.parameters.limit}, this.state.order).then(result => {
                this.setState((prevState) =>({
                    search_in_progress: false,
                    result: {
                        result: result.result.map(row => {
                            if('billing_state' in (row?.params||{})){
                                row['report_name'] += ' (Számlázás fajtája: '+(this.state.billing_states.find(b => b.id === row.params.billing_state)?.name || 'minden számla')+')'
                            }
                            return row
                        }) || [],
                        result_summary: {
                            ...result.result_summary,
                            page: prevState.page
                        },
                        headers: [
                            {name: 'report_name', alias: 'Riportnév', childrens: [], depth: 1, level: 1, order: 1, path: ['report_name'],parent_header_id: null, mergeable: false},
                            {name: 'creator_full_name', alias: 'Létrehozó teljes neve', childrens: [], depth: 1, level: 1, order: 1, path: ['creator_full_name'],parent_header_id: null, mergeable: false},
                            {name: 'created', alias: 'Létrehozás időpontja', childrens: [], depth: 1, level: 1, order: 1, path: ['created'],parent_header_id: null, mergeable: false},
                            {name: 'hash', formatValue: this.handleHash, alias: 'Letöltés', childrens: [], depth: 1, level: 1, order: 1, path: ['hash'],parent_header_id: null, mergeable: false}
                        ]
                    }
                }), () => {
                    if (!this.props.history.location.state) {
                        this.props.history.push(this.props.history.location.pathname)
                    }
                    this.props.history.replace(this.props.history.location.pathname, {
                        ...this.props.history.location.state,
                        order: this.state.order,
                        parameters: this.state.parameters,
                        page: this.state.page
                    })
                })
            })).then(() =>
                this.setState({
                    search_in_progress: false,
                    skip_update: false
                })
            )
        })
    }

    downloadRiport= (hash) => {
        this.setState({
            download_riport: hash
        },()=>{
            this.props.promise_error_handler(
                FilestoreClient.download(hash)
            ).then(()=>{
                this.setState({
                    download_riport: null
                })
            })
        })
    }

    handleHash = (row, hash) => {
        return <LoadingButton disabled={this.state.download_riport!==null} loading={this.state.download_riport==hash} onClick={()=>this.downloadRiport(hash)}>Letöltés</LoadingButton>
    }


    render() {
        if (this.state.result && this.props.history.location.state && !this.state.fetch_in_progress) {
            return <SearchResult
                result={this.state.result}
                parameters={this.state.parameters}
                conditions={this.state.data}
                params={this.state.params}
                param_data={this.state.param_data}
                order={this.state.order}
                report_url= {this.props.history.location.state.report_url}
                changeOrder={order => this.handleChangeOrder(order)}
                search={page => this.handleSearch(page)}
            />
        } else if(this.state.search_in_progress || this.state.fetch_in_progress){
            return <Loading loading_text = ''/>
        }
        return null
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SzamlazashozAtadottListak))