import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage, setActions } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import { addAction, removeAction } from "../../actions"
import SimpleField from "@sapkk/app/components/SimpleField"
import PopUp from "../../components/PopUp";

class KapcsolattartasiAdatok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            student: this.props.student,
            rights: this.props.rights_for_student,
            fetch_in_progress: true
        }
    }

    getContactInfo = (contact_info) =>{
        return contact_info || {
            email: '',
            use_for_notification: false,
            use_for_contact: false
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(CanteenClient.getContactInfo(this.state.student).catch(error => {
            return null
        }).then((contact_info) => {
            this.setState({
                contact_info: this.getContactInfo(contact_info),
                fetch_in_progress: false
            })
        }))

        if (Object.keys(this.props.rights.rights).length === 0) {
            this.props.addAction({id: 'help', title: 'Súgó', onClick: () => this.handleHelpOpen()})
        }
    }

    componentWillUnmount(){
        this.props.removeAction('help')
    }

    handleHelpOpen = () => {
        this.setState({
            popup_help_open: true
        })
    }

    handleHelpClose = () => {
        this.setState({
            popup_help_open: false
        })
    }

    handleEdit = () => {
        this.setState((prevState) => {
            return {
                edit: true,
                original_concat_info: prevState.contact_info
            }
        })
    }

    handleCancel = () => {
        this.setState((prevState) => {
            return {
                edit: false,
                contact_info: prevState.original_concat_info
            }
        })
    }

    handleSave = () => {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.editContactInfo(this.state.student, this.state.contact_info).then(result => {
                this.props.addMessage('A kapcsolattartási adat elmentése megtörtént.', 'success')
                this.setState({
                    edit: false,
                    contact_info: this.getContactInfo(result),
                    save_in_progress: false
                })
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handleDelete= () => {
        if(window.confirm('A kapcsolattartási adat törlését kezdeményezte. Biztos ebben?')) {
            this.props.clearMessages()

            this.setState({
                delete_in_progress: true
            }, () => {
                this.props.promise_error_handler(CanteenClient.deleteContactInfo(this.state.student).then(result => {
                    this.setState({
                        edit: false,
                        contact_info: this.getContactInfo(null)
                    })

                    this.props.addMessage('A kapcsolattartási adat törlése megtörtént.', 'success')
                })).then(() => {
                    this.setState({
                        delete_in_progress: false
                    })
                })
            })
        }
    }

    handleChange = (event) =>{
        let name = event.target.name
        let value = event.target.type=="checkbox" ? event.target.checked : event.target.value
        this.setState((prevState)=>({
            contact_info: {
                ...prevState.contact_info,
                [name]: value
            }
        }))
    }

    render() {
        return <React.Fragment>
            { this.state.popup_help_open && (
                <PopUp title={'Súgó'} close={this.handleHelpClose} buttons={[{action: this.handleHelpClose, text: 'Ok'}]} contentClass="help-description">
                    <p>
                        <div>Az <span style={{fontWeight: 'bold'}}>„Adatok”</span> -> <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>„Törzsadat”</span> menüpontban a <span style={{fontStyle: 'italic'}}>„Kapcsolattartási adatok”</span> fülön amennyiben a szülő – önkéntes hozzájárulásával - megadja az e-mail címét, a rendszer az étkezés igényléshez, a tanuló törzsadataiban bekövetkezett változással; valamint az étkezési díjról kiállított e-számlával kapcsolatosan rendszerüzenetet küld a szülő részére a megadott e-mail címre.</div>
                        <div>A szülő külön ahhoz is hozzájárulhat, hogy a megadott e-mail cím az iskolai ügyintéző részére is látható legyen, így az e-mail címen felveheti a szülővel a kapcsolatot.</div>
                    </p>
                    <p>
                        <div>Az e-mail-cím megadása nem kötelező!</div>
                    </p>
                    <p>
                        <div>A Szerkesztés gombra lépve megadható az e-mail cím és be kell jelölni, hogy az e-mail címet milyen céllal lehet felhasználni.</div>
                        <div>Majd a Mentés gombra lépve az adatokat elmentésre kerülnek.</div>
                        <div>A korábban megadott e-mail címet és e-mail cím megadásának célját módosítani a Szerkesztés, adatokat törölni pedig a Törlés gombra kattintva lehet.</div>
                    </p>
                </PopUp>
            ) }
            <div className="page-subtitle">Kapcsolattartási adatok</div>
            {!this.state.fetch_in_progress ? <React.Fragment>
                <div className="page-guide">Az intézmény a lenti beviteli mezőben a szülő/gondviselő által önkéntesen megadott e-mail-címet a  kiválasztott adatkezelési célokra használhatja:</div>
                <div className="block">
                    <input id="use_for_contact" disabled={!this.state.edit} className="field field-simple" type="checkbox" name="use_for_contact" onChange={this.handleChange} checked={this.state.contact_info.use_for_contact}/>
                    <label htmlFor="use_for_contact" style={{ paddingLeft: '10px' }}>Hozzájárulok, hogy a megadott e-mail-címre az intézmény kapcsolattartás céljából elektronikus levelet küldjön</label>
                </div>
                <div className="block">
                    <input id="use_for_notification" disabled={!this.state.edit} className="field field-simple" type="checkbox" name="use_for_notification" onChange={this.handleChange} checked={this.state.contact_info.use_for_notification}/>
                    <label htmlFor="use_for_notification" style={{ paddingLeft: '10px' }}>Kérem, hogy a rendszerüzenetekről e-mailben is értesítsenek</label>
                </div>
                <div className="block">
                    <label className="label label-super-long">E-mail-cím</label>
                    <SimpleField readOnly={!this.state.edit} className="field field-simple" name="email" onChange={this.handleChange} value={this.state.contact_info.email}/>
                </div>
                <div className="actions">
                    {
                        this.state.rights.includes('WRITE_NOTIFICATION_DATA') ? <React.Fragment>
                            {this.state.edit ? <React.Fragment>
                                <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave} disabled={this.state.delete_in_progress || this.state.save_in_progress}>Mentés</LoadingButton>
                                <button disabled={this.state.delete_in_progress || this.state.save_in_progress} onClick={this.handleCancel}>Mégse</button>
                            </React.Fragment> : <React.Fragment>
                                    <button onClick={this.handleEdit}>Szerkesztés</button>
                                </React.Fragment>}
                            {this.state.contact_info.student_id ? <React.Fragment>
                                <LoadingButton loading={this.state.delete_in_progress} onClick={this.handleDelete} disabled={this.state.delete_in_progress || this.state.save_in_progress}>Törlés</LoadingButton>
                            </React.Fragment>: null}
                        </React.Fragment> : null
                    }
                </div>
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        promise_error_handler: state.app.promise_error_handler,
        text_styles: state.text_styles,
        rights: state.rights,
        meal_request_states: state.meal_request_states
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    addAction: addAction,
    removeAction: removeAction
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KapcsolattartasiAdatok))