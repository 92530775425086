import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import SelectField from "@sapkk/app/components/SelectField"
import SimpleField from "@sapkk/app/components/SimpleField"
import Igenyles from "../Igenyles/Igenyles"
import SablonDokumentumEloallito from "./SablonDokumentumEloallito"

class IgenylesSablon extends Component {
    constructor(props) {
        super(props)

        this.file_input = React.createRef()

        this.state = {
            school: this.props.school,
            fields: [],
            document_hash: [],
            save_in_progress: false,
            fetch_in_progress: true,
            template: {},
            edit: false,
            data: {
                year_id: null,
                class_id: null,
                student_ids: [],
                all_student_from_school: false
            },
            file_input_key: 0,
            column_number: 0,
            key: 0
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.listMealRequestFields(),
            CanteenClient.getActiveMealRequestTemplateForSchool(this.state.school),
        ]).then(([fields, template]) => {
            this.setState({
                edit: template === null,
                template: template ? {
                    ...template,
                    template_objects: template.template_objects.map((to, index) => ({ ...to, column_identifier: index }))
                } : {
                        id: null,
                        document_hash: null,
                        template_objects: []
                    },
                fields: fields,
                fetch_in_progress: false,
                column_number: template ? template.template_objects.length : 0
            })
        }))
    }

    handleEdit = (new_template) => {
        this.setState((prevState) => ({
            edit: true,
            original_template: prevState.template,
            template: {
                ...prevState.template,
                ...(new_template ? {
                    id: null,
                    document_hash: null
                } : {}),
            },
            data: {
                year_id: null,
                class_id: null,
                student_ids: [],
                all_student_from_school: false
            },
            classes: [],
            students: [],
            key: prevState.key + 1
        }))
    }

    handleCancel = () => {
        this.setState((prevState) => ({
            edit: false,
            template: prevState.original_template,
            original_template: undefined,
            data: {
                year_id: null,
                class_id: null,
                student_ids: [],
                all_student_from_school: false
            },
            classes: [],
            students: [],
            key: prevState.key + 1
        }))
    }

    handleAddLine() {
        if (this.state.edit) {
            this.setState((prevState, props) => ({
                template: {
                    ...prevState.template,
                    template_objects: prevState.template.template_objects.concat({ field_or_text_id: null, is_field: true, is_text: false, text_data: null, column_identifier: prevState.column_number, required_formula: '',multiple_formula: '', disabled_value_formula: ''})
                },
                column_number: prevState.column_number + 1
            }))
        }
    }

    handleRemoveLine(id) {
        if (this.state.edit) {
            this.setState((prevState, props) => ({
                template: {
                    ...prevState.template,
                    template_objects: prevState.template.template_objects.slice(0, id).concat(prevState.template.template_objects.slice(id + 1))
                }
            }))
        }
    }

    handleTemplateObjectsDragEvent(event) {
        let dragged_elem_column_identifier = this.state.dragged_column_identifier
        let dragged_elem_new_pos = parseInt(event.target.parentElement.dataset.key)
        if (!isNaN(dragged_elem_new_pos)) {
            this.setState(prevState => {
                let index = prevState.template.template_objects.findIndex(e => e.column_identifier === dragged_elem_column_identifier)

                return {
                    template: {
                        ...prevState.template,
                        template_objects: prevState.template.template_objects.map((e, i) => {
                            if (i === dragged_elem_new_pos) {
                                return prevState.template.template_objects[index]
                            } else if (index < dragged_elem_new_pos) {
                                return prevState.template.template_objects[i - (i > dragged_elem_new_pos ? 1 : 0) + (i >= index ? 1 : 0)]
                            } else {
                                return prevState.template.template_objects[i - (i > dragged_elem_new_pos ? 1 : 0) + (i > index ? 1 : 0)]
                            }
                        })
                    }
                }
            })
        }
    }

    handleRowChange(id, name, value, parent_name) {
        if (this.state.edit) {
            this.setState((prevState, props) => {
                let new_value = {}
                if (parent_name) {
                    new_value = { ...prevState.template.template_objects[id], [parent_name]: { ...prevState.template.template_objects[id][parent_name], [name]: value }}
                } else if (name == 'is_field') {
                    new_value = { ...prevState.template.template_objects[id], is_field: value, is_text: !value, text_data: value ? null : { text: '', style: '' }, field_or_text_id: null, required_formula: '', multiple_formula: '', disabled_value_formula: ''}
                } else {
                    new_value = { ...prevState.template.template_objects[id], [name]: value }
                }
                return {
                    template: {
                        ...prevState.template,
                        template_objects: prevState.template.template_objects.slice(0, id).concat([new_value]).concat(prevState.template.template_objects.slice(id - (-1)))
                    }
                }
            })
        }
    }

    handleDataChange = (name, value) => {
        this.setState((prevState)=>({
            data: {
                ...prevState.data,
                [name]: value,
            },
            default_meal_request: null
        }),()=>{
            if (name == 'student_ids' && value.filter(v => v).length === 1) {
                this.props.promise_error_handler(CanteenClient.getDefaultValuesForMealRequestFields(this.state.template.template_objects.filter(to => to.is_field).map(to => to.field_or_text_id), this.state.data.student_ids[0], this.state.school, this.state.data.year_id).then(data => {
                    this.setState((prevState) => ({
                        default_meal_request: {
                            in_system: true,
                            data: data,
                            id: null
                        }
                    }))
                }))
            }
        })
    }

    uploadFile() {
        if (this.file_input.current.files.length === 1) {
            this.setState({
                upload_in_progress: true
            }, () => {
                this.props.promise_error_handler(FilestoreClient.upload(this.file_input.current.files[0]).then(hash => {
                    this.setState(prevState => ({
                        upload_in_progress: false,
                        template: {
                            ...prevState.template,
                            document_hash: hash
                        }
                    }))
                }))
            })
        }
    }

    getDownloadMethod = (in_system,data) => {
        let fields = this.state.template.template_objects.filter(to => to.is_field).map(to => to.field_or_text_id)
        if (!data.all_student_from_school && (!data.class_id || data.student_ids.filter(st => st).length == 1)) {
            return CanteenClient.getFilledTemplateHash(this.state.template.document_hash, fields, this.state.school, data.year_id, data.student_ids.length == 1 ? data.student_ids[0] : null,'meal_request', in_system)
        } else {
            return CanteenClient.getMultiFilledTemplateHash(this.state.template.document_hash, fields, this.state.school, data.year_id, data.class_id, data.student_ids,'meal_request', in_system)
        }
    }


    handleSave = () => {
        if (this.state.edit) {
            this.props.clearMessages()

            this.setState({
                save_in_progress: true
            }, () => {
                this.props.promise_error_handler((() => {
                    if (this.state.template.id) {
                        return CanteenClient.editMealRequestTemplate(this.state.template.id, this.state.template.template_objects)
                    } else {
                        return CanteenClient.createMealRequestTemplate(this.state.school, this.state.template.document_hash, this.state.template.template_objects)
                    }
                })().then(result => {
                    this.props.addMessage('Az adatok elmentése megtörtént.', 'success')

                    this.setState((prevState) => ({
                        template: {
                            ...result,
                            template_objects: result.template_objects.map((to, index) => ({ ...to, column_identifier: index }))
                        },
                        original_template: undefined,
                        edit: false,
                        file_input_key: prevState.file_input_key + 1,
                        data: {
                            year_id: null,
                            class_id: null,
                            student_ids: [],
                            all_student_from_school: false
                        },
                        classes: [],
                        students: [],
                        key: prevState.key + 1,
                        column_identifier: result.template_objects.length
                    }))
                })).then(() => {
                    this.setState({
                        save_in_progress: false
                    })
                })
            })
        }
    }

    render() {
        let readOnly = !this.props.rights_for_school.includes('WRITE_MEAL_REQUEST_TEMPLATE')
        return <React.Fragment>
            {!this.state.fetch_in_progress ? <React.Fragment>
                {!readOnly ? <React.Fragment>
                    <div className="page-subtitle">Igényléssablon kezelése</div>
                    <div className="actions">
                    {this.state.edit ? <React.Fragment>
                        <LoadingButton loading={this.state.save_in_progress} onClick={this.handleSave}>Mentés</LoadingButton>
                        {this.state.original_template ? <button onClick={this.handleCancel}>Mégse</button> : null}
                    </React.Fragment> : <React.Fragment>
                            <button onClick={() => this.handleEdit(true)}>Létrehozás</button>
                            <button onClick={() => this.handleEdit(false)}>Szerkesztés</button>
                        </React.Fragment>}
                    </div>
                </React.Fragment> : null}
                <div className="page-subtitle">{this.state.edit ? (this.state.template.id !== null ? 'Igényléssablon szerkesztése' : 'Új igényléssablon létrehozása') : 'Aktív igényléssablon adatai'}</div>
                <div className="block">
                    <label className="label label-long required">Sablon dokumentum</label>
                    {!this.state.template.id ? <input key={'file_input_' + this.state.file_input_key} ref={this.file_input} disabled={this.state.upload_in_progress} className="field" type="file" onChange={() => this.uploadFile()} /> : <div><button onClick={() => this.props.promise_error_handler(FilestoreClient.download(this.state.template.document_hash))}>Letöltés</button></div>}
                </div>
                {this.props.rights_for_school.includes('WRITE_MEAL_REQUEST_TEMPLATE') ? <React.Fragment>
                    <div className="page-subtitle">Az igényléssablon objektumai</div>
                    <div className="table-container">
                        <table className="meal-request-template-objects striped modify-table">
                            <thead>
                                <tr>
                                    <th>Igénylésmező használata</th>
                                    <th>Igénylésmező</th>
                                    <th>Igénylésmező típusa</th>
                                    <th>Szövegmező tartalma</th>
                                    <th>Szövegmező stílusa</th>
                                    <th>Kötelezőségformula</th>
                                    <th>Többszörösségformula</th>
                                    <th>Letiltottértékformula</th>
                                    <th>Normális működés során megjelenik</th>
                                    <th>Lemondás során megjelenik</th>
                                    <th className="col-actions" />
                                </tr>
                            </thead>
                            <tbody onDragOver={event => event.preventDefault()} onDragEnter={event => this.handleTemplateObjectsDragEvent(event)}>
                                {this.state.template.template_objects.map((data, id) => {
                                    return <tr draggable={this.state.edit} key={data.column_identifier} data-key={id} style={this.state.dragged_column_identifier === data.column_identifier ? { color: '#cccccc' } : {}} onDragStart={event => this.setState({ dragged_column_identifier: data.column_identifier })} onDragEnd={event => this.setState({ dragged_column_identifier: null })}>
                                        <td><input type="checkbox" checked={data.is_field} disabled={!this.state.edit || !!data.id} onChange={event => this.handleRowChange(id, 'is_field', event.target.checked)} /></td>
                                        <td>{data.is_field ? <SelectField readOnly={!this.state.edit || !!data.id} value={data.field_or_text_id} onChange={event => this.handleRowChange(id, 'field_or_text_id', event.target.value)} displayValues={this.state.fields.reduce((prev, curr) => ({
                                            ...prev,
                                            [curr.id]: curr.alias
                                        }), {})}>
                                            <option value="">Kérem válasszon!</option>
                                            {this.state.fields.map(field => <option value={field.id} />)}
                                        </SelectField>
                                            : null}
                                        </td>
                                        <td>{data.is_field && data.field_or_text_id ? <SimpleField readOnly={true} value={(this.props.field_types[(this.state.fields.find(f => f.id == data.field_or_text_id) || {}).type] || {}).name} /> : null}</td>
                                        <td>{!data.is_field ? <SimpleField readOnly={!this.state.edit} value={data.text_data.text} onChange={event => this.handleRowChange(id, 'text', event.target.value, 'text_data')} /> : null}</td>
                                        <td>{!data.is_field ? <SelectField readOnly={!this.state.edit} value={data.text_data.style} onChange={event => this.handleRowChange(id, 'style', event.target.value, 'text_data')} displayValues={Object.keys(this.props.text_styles).reduce((prev, curr) => ({
                                            ...prev,
                                            [curr]: this.props.text_styles[curr].name
                                        }), {})}>
                                            <option value="">Kérem válasszon!</option>
                                            {Object.keys(this.props.text_styles).map(style => <option value={style} />)}
                                        </SelectField> : null}</td>
                                        <td>{data.is_field ? <SimpleField readOnly={!this.state.edit} value={data.required_formula} onChange={event => this.handleRowChange(id, 'required_formula', event.target.value)} /> : null}</td>
                                        <td>{data.is_field ? <SimpleField readOnly={!this.state.edit} value={data.multiple_formula} onChange={event => this.handleRowChange(id, 'multiple_formula', event.target.value)} /> : null}</td>
                                        <td>{data.is_field ? <SimpleField readOnly={!this.state.edit} value={data.disabled_value_formula} onChange={event => this.handleRowChange(id, 'disabled_value_formula', event.target.value)} /> : null}</td>
                                        <td><SimpleField readOnly={!this.state.edit} checked={data.is_show_for_normal} value={data.is_show_for_normal ? 'Igen' : 'Nem'} onChange={event => this.handleRowChange(id, 'is_show_for_normal', event.target.checked)} type={"checkbox"} /></td>
                                        <td><SimpleField readOnly={!this.state.edit} checked={data.is_show_for_cancel} value={data.is_show_for_cancel ? 'Igen' : 'Nem'} onChange={event => this.handleRowChange(id, 'is_show_for_cancel', event.target.checked)} type={"checkbox"} /></td>
                                        <td className="col-actions">{this.state.edit ? <button className="remove" onClick={() => this.handleRemoveLine(id)} /> : null}</td>
                                    </tr>
                                }
                                )}
                                <tr className="table-new-row">
                                    <td colSpan={10}>{this.state.template.template_objects.length === 0 && "Nincs megadva igényléssablon objektum."}</td>
                                    {!readOnly && this.state.edit ? <td className="col-actions"><button onClick={() => this.handleAddLine()} /></td> : null}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </React.Fragment> : null}
                <SablonDokumentumEloallito
                    template={this.state.template}
                    school={this.props.school}
                    rights_for_school={this.props.rights_for_school}
                    can_download_online={false}
                    handleDataChange={this.handleDataChange}
                    getDownloadMethod={this.getDownloadMethod}/>
                {
                    this.state.data.student_ids.filter(v=>v).length === 1 && this.state.default_meal_request ? <React.Fragment key={this.state.data.student_ids[0]}>
                        <div className="page-subtitle">Mintafelület</div>
                        <Igenyles meal_request={this.state.default_meal_request} year={this.state.data.year_id} school={this.state.school} template={this.state.template} student={this.state.data.student_ids[0]} />
                    </React.Fragment> : null
                }
            </React.Fragment> : <Loading loading_text='' />}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        promise_error_handler: state.app.promise_error_handler,
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IgenylesSablon))