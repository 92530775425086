import React, { Component } from 'react'
import { setGuide, setTitle} from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import { withRouter } from "react-router"
import TipusKezelese from './TamogatasKezelese/TipusKezelese';
import TamogatoKezelese from './TamogatasKezelese/TamogatoKezelese';
import TamogatasokKezelese from './TamogatasKezelese/TamogatasokKezelese';
import Loading from '@sapkk/app/components/Loading'
import ContentWithSideMenu from './ContentWithSideMenu'

class TamogatasKezelese extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: 0,
            loaded: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = ()=>{
        this.props.setTitle("Támogatások")
        this.setState({
            loaded: true
        })
    }

    render() {
        let menus = [
            {id:1, url: '/tamogatas-kezelese/tamogatasok', name: 'Támogatások', render: <TamogatasokKezelese/>},
            {id:2, url: '/tamogatas-kezelese/tamogatok', name: 'Támogatók', render: <TamogatoKezelese/>},
            {id:3, url: '/tamogatas-kezelese/tipusok', name: 'Típusok', render: <TipusKezelese/>}
        ]
        let menu_objects = [
            {title:'Támogatás menü', menu_elements: menus, with_route: true, active_method: (menu_data)=>menu_data.url == this.props.history.location.pathname || this.props.history.location.pathname.indexOf(menu_data.url+'/')===0}
        ]
        return <React.Fragment key={'tamogatas.'+this.state.key}>
            {
                this.state.loaded ? <ContentWithSideMenu key={this.state.keys} menus={menu_objects} default_path={'/tamogatas-kezelese'} url_base={'tamogatas-kezelese'}>
                </ContentWithSideMenu> : <Loading loading_text=""/>}
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TamogatasKezelese))
