import React, { Component } from 'react'
import { connect } from "react-redux"
import { withRouter } from "react-router"
class Uzenet extends Component {

    constructor(props) {
        super(props)
   }
   componentDidMount = ()=>{
       if(this.props.handleSeen){
           this.props.handleSeen(this.props.message)
       }
   }
    render() {
        return <div className={['speech-bubble', (this.props.message.user_id == this.props.user.id ? 'right' : 'left')].join(' ')}>
            <div className="sender">
                {this.props.message.sender}
            </div>
            <div className="body">
                {this.props.message.message}
            </div>
            <div className="state">
                {this.props.message.new_state ? (this.props.message.new_state == 'REJECTED' ? 'Elutasított igénylés' : (this.props.message.new_state == 'ACCEPTED' ? 'Elfogadott igénylés' : null)) : null}
            </div>
            <div className="date">
                {this.props.message.created}
            </div>
        </div>
    }
}
function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Uzenet))
