import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import DateField from "@sapkk/app/components/DateField"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import SeparatedNumberField from "@sapkk/app/components/SeparatedNumberFields"
import SimpleField from "@sapkk/app/components/SimpleField"

class AtalanySzabalyok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            meal_groups: [],
            reimbursements: [],
            rule_intervals: [],
            classes: [],
            data: undefined,
            school_id: this.props.school,
            date: undefined,
            fetch_in_progress: true,
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getMealGroupsForSchool(this.state.school_id),
            CanteenClient.getReimbursements(),
            CanteenClient.listTransferOrderRuleIntervalsForSchool(this.state.school_id),
            CanteenClient.getSchoolClasses(this.state.school_id)
        ]).then(([meal_groups, reimbursements,rule_intervals, classes])=>{
            this.setState({
                meal_groups: meal_groups,
                reimbursements: reimbursements,
                rule_intervals: rule_intervals,
                classes: classes,
                date: rule_intervals.length > 0 ? rule_intervals[rule_intervals.length - 1].date_from : 0
            }, ()=>{
                this.handleFetch()
            })
        }))
    }

    handleDateChange(date) {
        this.setState({
            date: date,
            data: undefined
        })
    }

    convertRulesToData = (result)=>{
        return {
            rules: result.rule_groups.reduce((prev, rule_group_data) =>({
                ...prev,
                [rule_group_data.members == undefined ? -1 : rule_group_data.group_id] : rule_group_data.rules.reduce((pr, rule_data)=> ({
                    ...pr,
                    [rule_data.meal_group_id] : {
                        ...pr[rule_data.meal_group_id],
                        [!rule_data.reimbursement_id ? -1 : rule_data.reimbursement_id]: {
                            ...rule_data,
                            reimbursement_id: rule_data.reimbursement_id || -1
                        }
                    }
                }),{})
            }),{}),
            rule_groups: result.rule_groups.map(rg => ({
                group_id: rg.group_id,
                members: rg.members || null,
                original: rg.members === undefined
            })),
            interval: result.interval
        }
    }

    convertDataToRuleGroups = (data) => {
        return data.rule_groups.map(rg => ({
            ...rg,
            rules: Object.keys(data.rules[rg.original ? -1 : rg.group_id])
                .reduce((prev,meal_group_id) =>
                    prev.concat(Object.keys(data.rules[rg.original ? -1 : rg.group_id][meal_group_id])
                        .reduce((pr,reimbursement_id)=>{
                            return pr.concat([(data.rules[rg.original ? -1 : rg.group_id][meal_group_id]||{})[reimbursement_id]])},[])),[])
        }))
    }

    handleNew() {
        this.setState((prevState) =>({
            data: {
                rule_groups: [
                    {
                        group_id: null,
                        rules: [],
                        members: null,
                        original: true
                    }
                ],
                rules: {},
                ...prevState.data,
                interval: {
                    date_from: "",
                    date_to: ""
                }
            },
            new_interval_from: "",
            new: true,
            date: '',
            fetch_in_progress: false
        }))
    }

    handleFetch() {
        if(this.state.date){
            this.setState({
                fetch_in_progress: true,
            }, () => {
                this.props.promise_error_handler(CanteenClient.getTransferOrderRulesForDate(this.state.school_id, this.state.date).then(rules => {
                    this.setState({
                        data: this.convertRulesToData(rules),
                        new_interval_from: rules.interval.date_from,
                        new: false,
                        fetch_in_progress: false
                    })
                }))
            })
        } else{
            this.setState({
                data: undefined,
                new_interval_from: '',
                new: false,
                fetch_in_progress: false
            })
        }
    }

    handleIntervalFromChange(value) {
        this.setState({
            new_interval_from: value
        })
    }


    handlePriceChange(group_id, meal_group_id, reimbursement_id, price) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                rules: {
                    ...prevState.data.rules,
                    [group_id]: {
                        ...prevState.data.rules[group_id] || {},
                        [meal_group_id]: {
                            ...(prevState.data.rules[group_id] ||{})[meal_group_id] || {},
                            [reimbursement_id]: {
                                ...((prevState.data.rules[group_id] ||{})[meal_group_id] ||{})[reimbursement_id]||{},
                                group_id: group_id,
                                meal_group_id: meal_group_id,
                                reimbursement_id: reimbursement_id,
                                price: price
                            }
                        }
                    }
                }
            }
        }))
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=>CanteenClient.setTransferOrderRulesForDate(this.state.school_id, this.state.new_interval_from,  this.convertDataToRuleGroups(this.state.data)).then((rules) => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.props.promise_error_handler(Promise.all([
                    CanteenClient.listTransferOrderRuleIntervalsForSchool(this.state.school_id)
                ]).then(([rule_intervals])=>{
                    this.setState({
                        data: this.convertRulesToData(rules),
                        new_interval_from: rules.interval.date_from,
                        date: rules.interval.date_from,
                        new: false,
                        fetch_in_progress: false,
                        save_in_progress: false,
                        rule_intervals: rule_intervals
                    }, ()=>{
                        this.handleFetch()
                    })
                }))
            })).then(() => {
                this.setState({
                    save_in_progress: false
                })
            })
        })
    }

    handleDelete() {
        if(window.confirm('Az átalányszabály eltávolítására készül. Biztos, hogy folytatni szeretné?')) {
            this.props.clearMessages()

            this.setState({
                delete_in_progress: true
            }, () => {
                this.props.promise_error_handler(()=>CanteenClient.deleteRulesByDate(this.state.school_id, this.state.new_interval_from).then((rule_intervals) => {
                    this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                    this.setState({
                        date: rule_intervals.length > 0 ? rule_intervals[rule_intervals.length - 1].date_from : 0,
                        rule_intervals: rule_intervals,
                        new: false,
                        delete_in_progress: false
                    }, () => {
                        this.handleFetch()
                    })
                })).then(() => {
                    this.setState({
                        delete_in_progress: false
                    })
                })
            })
        }
    }

    handleAddDifferentRule = () => {
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                rule_groups: prevState.data.rule_groups.concat([{group_id: null, rules: [], members: [], original: false}]),
                rules: {
                    ...prevState.data.rules,
                    [null]: {}
                }
            }
        }))
    }

    handleRemoveDifferentRule = () => {
        this.setState((prevState) => {
            let new_rule_groups = prevState.data.rule_groups.filter(rg => rg.original)
            return {
                data: {
                    ...prevState.data,
                    rule_groups: new_rule_groups,
                    rules: new_rule_groups.reduce((prev, curr) => ({
                        ...prev,
                        [curr.original ? -1 : curr.group_id]: prevState.data.rules[curr.original ? -1 : curr.group_id]
                    }),{})
                }
            }
        })
    }

    handleToggleMember = (group_id, class_id) => {
        this.setState((prevState) => {
            let rule_groups_index = prevState.data.rule_groups.findIndex((rg)=>rg.group_id==group_id && !rg.original)
            var new_members = prevState.data.rule_groups[rule_groups_index].members
            if(!new_members.includes(class_id)){
                new_members.push(class_id)
            } else {
                new_members.splice(new_members.indexOf(class_id), 1)
            }
            return {
                data: {
                    ...prevState.data,
                    rule_groups: prevState.data.rule_groups.slice(0,rule_groups_index).concat([{...prevState.data.rule_groups[rule_groups_index], members: new_members}]).concat(prevState.data.rule_groups.slice(rule_groups_index + 1)),
                }
            }
        })
    }

    render() {
        let have_write_transfer_order_rule = this.props.rights_for_school.includes('WRITE_TRANSFER_RULE')
        let can_edit = have_write_transfer_order_rule && this.state.data && (this.state.new || !this.state.data.interval.date_to)
        let rule_interval = this.state.rule_intervals.find(ri => ri.id == this.state.date)
        return <React.Fragment>
            {
                <React.Fragment><div className="page-subtitle">Árdátum kiválasztása</div>
                    <div className={have_write_transfer_order_rule ? "entity-select-block" : 'student-select-block'}>
                        <div>
                            <div className="block block-lg-1-1">
                                <select className="field field-simple" value={this.state.date} onChange={event => this.handleDateChange(event.target.value)}>
                                    <option>Kérem, válasszon!</option>
                                    {this.state.rule_intervals.map(interval_data => <option value={interval_data.id}>{interval_data.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div>
                            <LoadingButton disabled={this.state.fetch_in_progress || !this.state.school_id || !this.state.date} loading={this.state.fetch_in_progress} onClick={() => this.handleFetch()}>Lekérdez</LoadingButton>
                            {have_write_transfer_order_rule ? <button disabled={this.state.fetch_in_progress || !this.state.school_id} onClick={() => this.handleNew()}>Új</button> : null}
                        </div>
                    </div>
            </React.Fragment> }
            { this.state.data && (this.state.new || this.state.data.interval.date_from) ?
                <React.Fragment>
                    <div className="page-subtitle">Árak érvényessége</div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Érvényesség kezdete</label>
                        <DateField placeholder="éééé.hh.nn." className="field field-simple" readOnly={this.state.new !== true} value={this.state.new_interval_from} onChange={event => this.handleIntervalFromChange(event.target.value)}/>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Érvényesség vége</label>
                        <DateField placeholder="éééé.hh.nn." className="field field-simple" readOnly={true} value={this.state.data.interval.date_to}/>
                    </div>
                    {
                        rule_interval && rule_interval.last_modified_by_name ? <div className="block block-lg-1-2">
                            <label className="label label-long">Legutoljára módosította</label>
                            <SimpleField className="text-container" readOnly={true} value = {rule_interval.last_modified_by_name}/>
                        </div> : null
                    }
                    {
                        this.state.data.rule_groups.map((rg, index) => <React.Fragment>
                            {
                                !rg.original ? <React.Fragment>
                                    <div className="page-subtitle">Eltérő átalányszabály tagjai </div>
                                    {
                                        !rg.group_id ? <div className="table-container">
                                            <table className="different-rule-class-table">
                                                <thead>
                                                    <tr>
                                                        <th>Osztálynév</th>
                                                        <th>Szerepel-e</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.classes.map(c => <tr>
                                                            <td>{c.name}</td>
                                                            <td><input type="checkbox" checked={rg.members.includes(c.id)} onClick={()=>this.handleToggleMember(rg.group_id,c.id)}/></td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                            </div> : <div className="block">{rg.members.map(m => this.state.classes.find(c => c.id == m).name).join(', ')}</div>
                                    }
                                </React.Fragment> : null
                            }
                            <div className="page-subtitle">Szabályok</div>
                            <div className="transfer-order-rule-table-container">
                                <table className="transfer-order-rule-table striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div>Támogatás</div>
                                                <div>Étkezési csoport</div>
                                            </th>
                                            <th>Nincs támogatás</th>
                                            {this.state.reimbursements.map(rb => <th>{rb.name}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.meal_groups.map(mg => <tr>
                                            <td>{mg.name}</td>
                                            <td><SeparatedNumberField readOnly={!can_edit} value = {(((this.state.data.rules[rg.original ? -1 : rg.group_id]||{})[mg.id]||{})[-1] || {}).price} onChange={(event) => this.handlePriceChange(rg.original ? -1 : rg.group_id, mg.id,-1, event.target.value)}/></td>
                                            {this.state.reimbursements.map(rb => <td><SeparatedNumberField readOnly={!can_edit} value = {(((this.state.data.rules[rg.original ? -1 : rg.group_id]||{})[mg.id]||{})[rb.id] || {}).price} onChange={(event) => this.handlePriceChange(rg.original ? -1 : rg.group_id, mg.id,rb.id, event.target.value)}/></td>)}
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>)
                    }
                    <div className="actions">
                        {can_edit ? (this.state.data.rule_groups.length === 1 ? <button onClick={this.handleAddDifferentRule}>Eltérő átalányszabály rögzítése</button> : <button onClick={this.handleRemoveDifferentRule}>Eltérő átalányszabály eltávolítása</button>) : null}
                        { can_edit && <LoadingButton loading={this.state.save_in_progress} disabled={this.state.delete_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton> }
                        { can_edit && !this.state.new ? <LoadingButton disabled={this.state.save_in_progress} loading={this.state.delete_in_progress} onClick={() => this.handleDelete()}>Törlés</LoadingButton> : null}
                    </div>
                </React.Fragment> : null
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        class_types: state.class_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AtalanySzabalyok))
