import React, { Component } from 'react'
import { setGuide, setTitle, setSystemMessagesCount} from "../../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import TanuloAdat from './TanuloAdat'
import LoadingButton from '../../components/LoadingButton'
import PopUp from '../../components/PopUp'
import Uzenet from '../../components/Uzenet'
import SimpleField from '@sapkk/app/components/SimpleField'

class AdatAllapot extends Component {
    constructor(props) {
        super(props)
        this.container_div = React.createRef()

        this.state = {
            school: this.props.match.params.school_id || this.props.school || null,
            student: this.props.student || null,
            student_data_id: this.props.student_data_id || this.props.match.params.student_data_id,
            countries: undefined,
            street_types: undefined,
            student_data: undefined,
            prev_student_data: undefined,
            payment_methods: undefined,
            fetch_in_progress: true,
            save_in_progress: false,
            error: false,
            key: 0,
            rights: this.props.rights_for_student || [],
            message: '',
            accept_in_progress: false,
            reject_in_progress: false,
            rejecting: false,
            reject_messages: []
        }
    }

    componentDidMount() {
        this.loadData()
    }

    static getDerivedStateFromProps(props, state) {
        if ((props.student_data_id || props.match.params.student_data_id) !== state.student_data_id) {
            return {
                student_data_id: (props.student_data_id || props.match.params.student_data_id),
                error: false
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.student_data_id !== this.state.student_data_id) {
            this.loadData()
        }
    }

    static convertStudentData = (student_data) => {
        return {
            ...student_data,
            student_data: {
                ...student_data.student_data,
                address_data: {
                    ...(student_data.student_data.address_data||{})
                },
                invoice_recipient_data: student_data.student_data.invoice_recipient_data||null,
                account_number: [student_data.student_data.bank_key, student_data.student_data.bank_account_number].filter(v => v).join('-')
            }
        }
    }

    loadData = () => {
        if(this.props.pathname && this.props.history.location.pathname!==this.props.pathname){
            this.props.history.replace(this.props.pathname)
        }
        this.setState({
            fetch_in_progress: true
        }, ()=>{
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getCountries(),
                CanteenClient.getStreetTypes(),
                CanteenClient.getPaymentMethods(),
                CanteenClient.listStudentDataInformation(
                    {
                        id:[{operator: 9, value: this.state.student_data_id}],
                        school_id:[this.state.school ? {operator: 9, value: this.state.school} : undefined]
                    },
                    {limit: 1},
                    [],
                    ['id','original_school_id', 'school_id', 'prev_id', 'student_id']
                ).then(student_data_informations => {
                    return Promise.all([
                        CanteenClient.getStudentByStudentData(this.state.student, this.state.student_data_id,this.state.school),
                        (student_data_informations[0] ||{}).prev_id ? CanteenClient.getStudentByStudentData(this.state.student, student_data_informations[0].prev_id,this.state.school) : new Promise((resolve, reject) => resolve(null))
                    ])
                })
            ]).then(([countries,street_types,payment_methods,[student_data, prev_student_data]])=>{
                this.setState((prevState)=>({
                    key: prevState.key + 1,
                    countries: countries,
                    street_types: street_types,
                    payment_methods: payment_methods,
                    student_data: AdatAllapot.convertStudentData(student_data),
                    prev_student_data: prev_student_data ? AdatAllapot.convertStudentData(prev_student_data) : undefined,
                    fetch_in_progress: false
                }),()=>{
                    this.setSeen()
                })
            }).catch(error => {
                this.setState({
                    fetch_in_progress: false,
                    error: true
                })
                throw error
            }))
        })
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleAccept = () =>{
        this.props.clearMessages()

        this.setState({
            accept_in_progress: true
        }, () => {
            this.props.promise_error_handler(()=> CanteenClient.acceptStudentData(this.state.student_data_id).then(result => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.setState({
                    student_data: AdatAllapot.convertStudentData(result),
                    accept_in_progress: false
                }, ()=>{
                    this.setSeen()
                    if(this.props.handleDetermined){
                        this.props.handleDetermined()
                    }
                })
            })).then(()=>{
                this.setState({
                    accept_in_progress: false
                })
            })
        })
    }

    setSeen = () => {
        this.props.promise_error_handler(CanteenClient.setStudentDataSeen(this.state.student_data.student_data.id).then(()=>{
            this.props.setSystemMessagesCount(null)
            if(this.props.countUnseenStudentData){
                this.props.countUnseenStudentData()
            }
        }))
    }

    handleReject = () =>{
        this.props.clearMessages()

        this.setState({
            reject_in_progress: true
        }, () => {
            CanteenClient.rejectStudentData(this.state.student_data_id,this.state.message, this.state.school).then(result => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.setState({
                    student_data: AdatAllapot.convertStudentData(result),
                    rejecting: false
                }, ()=>{
                    this.setSeen()
                    if(this.props.handleDetermined){
                        this.props.handleDetermined()
                    }
                })
            }).catch(error => {
                this.setState({
                    reject_messages: (error.additional_info || []).length > 0 ? error.additional_info : [error.message]
                })
            }).then(()=>{
                this.setState({
                    reject_in_progress: false
                })
            })
        })
    }

    openPopUpReject = () => {
        this.setState({
            reject_messages: [],
            rejecting: true
        })
    }

    closePopUpReject = () => {
        this.setState({
            reject_messages: [],
            rejecting: false
        })
    }

    handleSend = () => {
        this.setState({
            send_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.addMessageToStudentData(this.state.student_data.student_data.id, this.state.message, this.state.school).then(()=>{
                this.setState({
                    send_in_progress: false,
                    message: '',
                }, () => {
                    this.props.promise_error_handler(CanteenClient.setStudentDataSeen(this.state.student_data.student_data.id).then(messages => {
                        this.setState((prevState) => ({
                            student_data: {
                                ...prevState.student_data,
                                student_data: {
                                    ...prevState.student_data.student_data,
                                    messages: messages
                                }
                            }
                        }), ()=>{
                            this.container_div.current.scrollTop = this.container_div.current.scrollHeight
                        })
                    }))
                })
            }).catch((e)=>{
                this.setState({
                    send_in_progress: false
                })
                throw e
            }))
        })
    }

    render() {
        let readOnly = !this.state.student_data || !this.state.rights.includes('HANDLE_STUDENT_DATA_CHANGE_REQUEST') || this.state.student_data.student_data.state != 'WAITING' || (!Object.keys(this.props.rights.rights).includes('alkalmazasgazda') && !(this.props.rights.rights['ugyintezo']||[]).includes(''+this.state.student_data.student_data.school_id))
        let can_write_message = this.state.rights.includes('WRITE_STUDENT_DATA_MESSSAGE')
        return (
            <React.Fragment>
                {
                    this.state.rejecting ? <PopUp buttons={[
                        { action: this.handleReject, text: 'Elutasítás' },
                        { action: this.closePopUpReject, text: 'Vissza' }
                    ]}
                        title={'Adatmódosítási kérelem elutasítása'}
                        close={this.closePopUpReject}>
                        {this.state.reject_messages.length > 0 ? <div className={"messages error"}>
                            { this.state.reject_messages.length === 1 ? (
                                    this.state.reject_messages[0]
                            ) : (
                                <ul>{  this.state.reject_messages.map(((message, index) => <li key={index}>{ message }</li>)) }</ul>
                            ) }
                        </div> : null}
                        <div className="block">
                            <label className="label label-long">Üzenet</label>
                            <textarea name="message" onChange={this.handleChange} value={this.state.message}>{this.state.message}</textarea>
                        </div>
                    </PopUp> : null
                }
                { this.state.student_data && this.state.countries && this.state.street_types && <React.Fragment>
                    <div className="page-subtitle">Módosításinformációk</div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Módosítást létrehozta</label>
                        <div className="text-container">{this.state.student_data.student_data.created_by}</div>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Módosítás időpontja</label>
                        <div className="text-container">{this.state.student_data.student_data.created}</div>
                    </div>
                    {
                        this.state.student_data.student_data.supervised_by ? <React.Fragment>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Jóváhagyta</label>
                            <div className="text-container">{this.state.student_data.student_data.supervised_by}</div>
                        </div>
                        <div className="block block-lg-1-2">
                            <label className="label label-long">Jóváhagyás időpontja</label>
                            <div className="text-container">{this.state.student_data.student_data.supervised}</div>
                        </div>
                    </React.Fragment> : null}
                    {
                        this.state.student_data.student_data.state !== 'REJECTED' ? <React.Fragment>
                            <div className="block block-lg-1-2">
                                <label className="label label-long">Érvényesség kezdete</label>
                                <div className="text-container">{this.state.student_data.student_data.date_from}</div>
                            </div>
                            <div className="block block-lg-1-2">
                                <label className="label label-long">Érvényesség vége</label>
                                <div className="text-container">{this.state.student_data.student_data.date_to}</div>
                            </div>
                        </React.Fragment> : null
                    }
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Módosítás állapota</label>
                        <div className="text-container">{(this.props.student_data_request_states[this.state.student_data.student_data.state]||{}).name}</div>
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Létrehozás típusa</label>
                        <div className="text-container">{(this.props.creator_types[this.state.student_data.student_data.creator_type]||{}).name}</div>
                    </div>
                    <TanuloAdat
                        key={'tanulo-adat.'+this.state.key}
                        school={this.state.school}
                        student={this.state.student || null}
                        countries={this.state.countries}
                        street_types={this.state.street_types}
                        student_data={this.state.student_data}
                        prev_student_data={this.state.prev_student_data}
                        payment_methods={this.state.payment_methods}
                        write_invoice_recipient_data={true}
                        read_only={true}
                        read_actual_financial_data={ this.state.rights.includes('READ_ACTUAL_FINANCIAL_DATA')}
                    />
                    {
                        !readOnly ? <React.Fragment>
                            <div className="page-subtitle">Döntés a módosításról</div>
                            <div className="actions">
                                <LoadingButton loading={this.state.accept_in_progress} disabled={this.state.reject_in_progress} onClick={this.handleAccept}>Elfogadás</LoadingButton>
                                <LoadingButton disabled={this.state.accept_in_progress} loading={this.state.reject_in_progress} onClick={this.openPopUpReject}>Elutasítás</LoadingButton>
                            </div>
                        </React.Fragment> : null
                    }
                    <div className="page-subtitle">Üzenetek</div>
                    <div ref={this.container_div} className="speech-bubble-container">
                    {
                        this.state.student_data.student_data.messages.length!==0 ? this.state.student_data.student_data.messages.map((message, i) => <React.Fragment>
                            <Uzenet message={message}/>
                        </React.Fragment>) : <div className="empty-messages">
                            Az igényléshez nem tartozik üzenet
                        </div>
                    }
                    </div>
                    {can_write_message ? <div className="student-select-block">
                        <div className="block">
                            <label className="label label-long">Új üzenet</label>
                            {(this.state.student_data.student_data.state === 'WAITING' ) ? <textarea className="field field-text" onChange={this.handleChange} name="message" value={this.state.message}>
                                {this.state.message}
                            </textarea> : <SimpleField className="field field-text" readOnly="true" value="Az üzenetküldés nem lehetséges, a kommunikáció lezárásra került."></SimpleField>}
                        </div>
                        <div>
                            <LoadingButton loading={this.state.send_in_progress} onClick={this.handleSend} disabled={this.state.student_data.student_data.state !== 'WAITING'}>Küldés</LoadingButton>
                        </div>
                    </div> : null}
                </React.Fragment> }
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        rights: state.rights,
        user: state.user,
        student_data_request_states: state.student_data_request_states,
        creator_types: state.creator_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages,
    setSystemMessagesCount: setSystemMessagesCount
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdatAllapot))
