import React, { Component } from 'react'
import PopUp from "../components/PopUp"
import {connect} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {setPromptCallback} from "../actions";

class MyBrowserRouter extends Component {
    constructor(props) {
        super(props)
        this.state= {
            popup_show: false,
            message: null,
            callback: null
        }
    }

    handleOk = () => {
        this.state.callback(true)
        if(this.props.prompt_callback){
            this.props.prompt_callback(true)
        }
        this.props.setPromptCallback(()=>{})
        this.setState({
            popup_show: false,
            message: null,
            callback: null
        })
    }

    handleCancel = () => {
        if(this.props.prompt_callback){
            this.props.prompt_callback(false)
        }
        this.props.setPromptCallback(()=>{})
        this.setState({
            popup_show: false,
            message: null,
            callback: null
        })
    }

    render() {
        return <BrowserRouter basename={this.props.basename} getUserConfirmation={(message, callback) => {
            this.setState({
                popup_show: true,
                message: message,
                callback: callback
            })
        }}>
            {this.state.popup_show ?  <PopUp title={'Megerősítés'} close={this.handleCancel} buttons={[{action: this.handleOk, text: 'Ok'},{action: this.handleCancel, text: 'Mégse'}]}>
                <div className="block">
                    {this.state.message}
                </div>
            </PopUp> : null}
            {this.props.children}
        </BrowserRouter>
    }
}
function mapStateToProps(state) {
    return {
        prompt_callback: state.prompt_info.callback
    }
}

const mapDispatchToProps = {
    setPromptCallback: setPromptCallback
}

export default  connect(mapStateToProps, mapDispatchToProps)(MyBrowserRouter)