export const APP_CHANGE = 'APP_CHANGE'
export const RIGHTS_CHANGE = 'RIGHTS_CHANGE'
export const MASQUARADE_STUDENT = 'MASQUARADE_STUDENT'
export const SET_SYSTEM_MESSAGES_COUNT = 'SET_SYSTEM_MESSAGES_COUNT'
export const ADD_ACTION = 'ADD_ACTION'
export const REMOVE_ACTION = 'REMOVE_ACTION'
export const CHANGE_PROMPT_CALLBACK = 'CHANGE_PROMPT_CALLBACK'
export const SET_PROMPT_BLOCK = 'SET_PROMPT_BLOCK'

export {
    setUserData,
    setTitle,
    setGuide,
    setPageData,
    addMessage,
    clearMessages,
    setContactURL,
    setUserManualURL,
    setPrivacyPolicyURL
} from "@sapkk/app/actions"

export function setPromiseErrorHandler(error_handler) {
    return {
        type: APP_CHANGE,
        promise_error_handler: error_handler
    }
}

export function setRights(rights, schools, students, can_have_children) {
    return {
        type: RIGHTS_CHANGE,
        rights: rights,
        schools: schools,
        students: students,
        can_have_children: can_have_children
    }
}

export function setMasquaradedStudent(student_id) {
    return {
        type: MASQUARADE_STUDENT,
        student_id: student_id
    }
}

export function setSystemMessagesCount(count){
    return {
        type: SET_SYSTEM_MESSAGES_COUNT,
        system_messages_count: count
    }
}

export function addAction(action){
    return {
        type: ADD_ACTION,
        action: action
    }
}

export function removeAction(action_id){
    return {
        type: REMOVE_ACTION,
        action_id: action_id
    }
}

export function setPromptCallback(callback) {
    return {
        type: CHANGE_PROMPT_CALLBACK,
        callback: callback
    }
}

export function setPromptBlock(block) {
    return {
        type: SET_PROMPT_BLOCK,
        block: block
    }
}