import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import CanteenClient from "../../CanteenClient"
import Loading from "@sapkk/app/components/Loading"
import { clearMessages, addMessage } from "@sapkk/app/actions"
import SeparatedNumberField from "@sapkk/app/components/SeparatedNumberFields"
import FilestoreClient from "../../FileStoreClient"
import LoadingButton from "../../components/LoadingButton"
import { setSystemMessagesCount } from "../../actions"

class SzamlatortenetEv extends Component {
    constructor(props) {
        super(props)

        this.columns_descriptions = {
            amount_paid: 'A kiegyenlített összeg az átutalt összegtől eltérhet, amennyiben a befizetés összege nem egyezik meg az átalánydíjjal.'
        }

        this.state = {
            student: this.props.student || null,
            year: this.props.year,
            balances: [],
            fetching: true
        }
    }

    componentDidMount() {
        this.listSAPBalances()
    }

    listSAPBalances = ()=>{
        this.props.promise_error_handler(Promise.all([
            CanteenClient.listSAPBalances(this.state.student, this.state.year)
        ])).then(([balances]) => {
            this.setState({
                balances: balances,
                fetching: false
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.count_unseen_e_invoices !== prevProps.count_unseen_e_invoices){
            this.listSAPBalances()
        }
    }
    
    mouseEnter = (column_name, event) =>{
        event.stopPropagation()
        this.setState({
            hovered_info_column: column_name,
            hovered_info_column_bottom: document.body.getBoundingClientRect().height - event.target.getBoundingClientRect().top + document.body.getBoundingClientRect().top,
            hovered_info_column_right: document.body.getBoundingClientRect().width - event.target.getBoundingClientRect().right < 250 ? 212 :  document.body.getBoundingClientRect().right - event.target.getBoundingClientRect().right 
        })
    }

    mouseLeave = () => {
        this.setState({
            hovered_info_column: null
        })
    }

    downloadEInvoice = (balance, id) => {
        this.setState({
            downloading_in_progress: balance.invoice_number
        }, () => {
            this.props.promise_error_handler(CanteenClient.downloadEInvoice(balance.invoice_number).then(result => this.props.promise_error_handler(FilestoreClient.download(result.hash, result.service, result.temp)))).then(()=>{
                if(this.props.rights_for_student.includes('MARK_E_INVOICE') && !balance.received){
                    this.setState((prevState) => ({
                        balances: prevState.balances.slice(0, id).concat([{ ...prevState.balances[id], received: true }]).concat(prevState.balances.slice(id + 1))
                    }), ()=>{
                        this.props.setSystemMessagesCount(null)
                    })
                }
                this.setState({
                    downloading_in_progress: null
                })
            })
        })
    }

    render() {
        let sum_invoiced_amount = 0
        let sum_amount_paid = 0
        return this.state.fetching ? <Loading loading_text=''/> : <div className="block">
            <div className="info-bubble" style={this.state.hovered_info_column ? {
                    bottom: this.state.hovered_info_column_bottom + 10,
                    right: this.state.hovered_info_column_right - 200,
            }: {display: 'none'}}>
                {(this.columns_descriptions[this.state.hovered_info_column]||undefined)}
            </div>
            <div className="table-container">
                <table className={"modify-table balance-table"}>
                    <thead>
                        <tr>
                            <th>Iskolakód</th>
                            <th>Időszak</th>
                            <th>Számla sorszám</th>
                            <th>Számla összege<br/>(HUF)</th>
                            <th className="header-with-info" onClick={(event)=>this.mouseEnter('amount_paid', event)}  onMouseEnter={(event)=>this.mouseEnter('amount_paid', event)} onMouseLeave={this.mouseLeave} >Kiegyenlített összeg<br/>(HUF)<div className="header-info"></div></th>
                            <th>e-számla</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.balances.map((balance,id) => {
                            sum_invoiced_amount += balance.invoiced_amount
                            sum_amount_paid += balance.amount_paid
                            return <tr>
                                <td className="school-abbr-cell">{balance.school_abbr}</td>
                                <td>{balance.period}</td>
                                <td className={balance.is_e_invoice && !balance.received && this.props.rights_for_student.includes('MARK_E_INVOICE') ? 'unseen-e-invoice' : ''}>{balance.invoice_number}</td>
                                <td><SeparatedNumberField value = {balance.invoiced_amount} readOnly={true}/></td>
                                <td><SeparatedNumberField value = {balance.amount_paid} readOnly={true}/></td>
                                <td>{balance.is_e_invoice ? <LoadingButton loading={this.state.downloading_in_progress==balance.invoice_number} disabled={this.state.downloading_in_progress} className="download_epdf" onClick={()=>this.downloadEInvoice(balance, id)}></LoadingButton> : null}</td>
                            </tr>
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Összesen:</td>
                            <td colSpan={2}>{null}</td>
                            <td><SeparatedNumberField readOnly={true} value = {sum_invoiced_amount}/></td>
                            <td><SeparatedNumberField readOnly={true} value = {sum_amount_paid}/></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler
    }
}

const mapDispatchToProps = {
    addMessage: addMessage,
    clearMessages: clearMessages,
    setSystemMessagesCount: setSystemMessagesCount
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SzamlatortenetEv))