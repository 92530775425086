import React, { Component } from 'react'
import { setGuide, setTitle } from "../actions"
import { connect } from "react-redux"
import {addMessage, clearMessages} from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import LoadingButton from '../components/LoadingButton';
import Loading from '@sapkk/app/components/Loading'

class TanevAktivalasa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            new_year: {
                name: "",
                active: true
            },
            years: [],
            fetch_in_progress: true,
            create_in_progress: false,
            save_in_progress: false
        }
    }

    componentDidMount() {
        this.props.setTitle("Tanév aktiválása")
        this.props.setGuide(null)

        this.props.promise_error_handler(CanteenClient.getYears().then(years => {
            this.setState({
                years: years,
                fetch_in_progress: false
            })
        }))
    }

    handleNewYearChange(key, value) {
        this.setState(prevState => ({
            new_year: {
                ...prevState.new_year,
                [key]: value
            }
        }))
    }

    handleChange(id, active) {
        this.setState((prevState, props) => ({
            years: prevState.years.map(year => year.id === id ? {...year, active: active} : year)
        }))
    }

    handleCreate() {
        this.props.clearMessages()

        this.setState({
            create_in_progress: true
        })

        this.props.promise_error_handler(CanteenClient.createYear(this.state.new_year.name, this.state.new_year.active).then(() => {
            this.props.addMessage('A tanév létrehozása megtörtént.', 'success')
        })).then(() =>
            this.props.promise_error_handler(CanteenClient.getYears())
        ).then(years => {
            this.setState({
                new_year: {
                    name: "",
                    active: true
                },
                years: years,
                create_in_progress: false
            })
        })
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        })

        this.props.promise_error_handler(CanteenClient.updateYears(this.state.years.reduce((o,v) => ({...o, [v.id]: {active: v.active}}), {})).then(years => {
            this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
        })).then(() => {
            this.setState({
                save_in_progress: false
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-subtitle">Új tanév felvétele</div>
                <div className="block">
                    <label className="label label-long">Név</label>
                    <input value={this.state.new_year.name} onChange={event => this.handleNewYearChange('name', event.target.value)}/>
                </div>
                <div className="block">
                    <label className="label label-long">Aktív tanév</label>
                    <select className="field field-simple" value={ this.state.new_year.active ? "1" : "0" } onChange={event => this.handleNewYearChange('active', event.target.value === "1")}>
                        <option value="1">Igen</option>
                        <option value="0">Nem</option>
                    </select>
                </div>
                <div className="actions">
                    <LoadingButton loading={this.state.create_in_progress} disabled={this.state.save_in_progress} onClick={() => this.handleCreate()}>Létrehoz</LoadingButton>
                </div>
                <div className="page-subtitle">Tanévek</div>
                {this.state.fetch_in_progress ? <Loading loading_text=''/> : <div className="table-container">
                <table className="striped modify-table">
                    <thead>
                    <tr>
                        <th style={{width:'70%'}}>Név</th>
                        <th style={{width:'30%'}}>Aktív tanév</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.years.map(data =>
                        <tr key={data.id}>
                            <td>{ data.name }</td>
                            <td>
                                <select className="field field-simple" value={ data.active ? "1" : "0" } onChange={event => this.handleChange(data.id, event.target.value === "1")}>
                                    <option value="1">Igen</option>
                                    <option value="0">Nem</option>
                                </select>
                            </td>
                        </tr>
                    ) }
                    </tbody>
                </table>
                </div>}
                <div className="actions">
                    <LoadingButton loading={this.state.save_in_progress} disabled={this.state.create_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    addMessage: addMessage,
    clearMessages: clearMessages
}

export default connect(mapStateToProps, mapDispatchToProps)(TanevAktivalasa)
