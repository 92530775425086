import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage, setActions } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";
import FilestoreClient from "../../FileStoreClient"
import PopUp from "../../components/PopUp"
import { addAction, removeAction, setSystemMessagesCount } from "../../actions"
import { Link } from "react-router-dom"
import SelectField from "@sapkk/app/components/SelectField"

class DokumentumNyomtatvanyok extends Component {
    constructor(props) {
        super(props)

        this.file_input = React.createRef()

        this.state = {
            school: this.props.school,
            student: this.props.student,
            fetch_in_progress: true,
            docuhub_documents: []
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(Promise.all([
            CanteenClient.getDocuhubDocumentsForSchool(this.state.school),
            CanteenClient.getSchoolYearsForStudent(this.state.student, true)
        ]).then(([docuhub_documents, school_years]) => {
            this.setState({
                docuhub_documents: docuhub_documents,
                school_years: school_years
            })
        })).then(() => {
            this.setState({
                fetch_in_progress: false
            })
        })
    }

    handleDownloadFromDocuhub = (docuhub_id) => {
        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.downloadDocuhubDocumentsForSchool(this.state.school, docuhub_id).then((result) => {
                FilestoreClient.downloadWithoutTempCheck(result.download_hash, result.filestore_url)
            })).then(()=>{
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    handleMealRequestDownload = () => {
        let school_year = this.state.school_years.find(sy => sy.id == this.state.school_year_id)||{}
        this.props.promise_error_handler(()=> CanteenClient.getActiveMealRequestTemplateForSchool(school_year.school_id,false).then((template) => {
            this.setState({
                downloading_in_progress: true
            }, () => {
                if(!template){
                    this.props.addMessage('Ez a funkció jelenleg nem elérhető', 'error')
                    this.setState({
                        downloading_in_progress: false
                    })
                } else {
                    this.props.promise_error_handler(CanteenClient.getFilledTemplateHash(template.document_hash, template.fields, school_year.school_id, school_year.year_id, this.state.empty ? null : this.state.student,'meal_request').then((result) => {
                        return FilestoreClient.download(result.hash)
                    })).then(() => {
                        this.setState({
                            downloading_in_progress: false
                        })
                    })
                }
            })
        }))
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.type =='checkbox' ? event.target.checked : event.target.value
        })
    }

    render() {
        return !this.state.fetch_in_progress ? <React.Fragment>
                {this.state.docuhub_documents.length > 0 ? <React.Fragment>
                    <table className={"striped"}>
                        <thead>
                            <tr>
                                <th>Nyomtatványok</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.docuhub_documents.sort((a, b) => a.alias.localeCompare(b.alias)).map(document => <tr>
                            <td><a onClick={()=>this.handleDownloadFromDocuhub(document.id)}>{document.alias}</a></td>
                        </tr>)}
                        </tbody>
                    </table>
                    {
                        (new Date()).toISOString().split('T')[0] <= (this.props.student_register||{}).date_to ? <div className="empty-document-downloader">
                            <div className="page-subtitle">Új igénylés - papíralapú</div>
                            <div className="page-subguide">
                                Amennyiben az igénylést nem elektronikus formában kívánja benyújtani, lehetősége van az üres igénylő lap letöltésére.
                            </div>
                            <div className="block block-lg-1-2">
                                <label className="label label-long">Tanév</label>
                                <SelectField className="field field-simple"  value={this.state.school_year_id} name="school_year_id" onChange={this.handleChange} displayValues={this.state.school_years.reduce((prev, curr) => ({
                                    ...prev,
                                    [curr.id]: curr.year_name+' ('+curr.school_name+')'
                                }), {})}>
                                    <option value="">Kérem válasszon!</option>
                                    {this.state.school_years.map(sy => <option value={sy.id} />)}
                                </SelectField>
                            </div>
                            <div className="actions">
                                <LoadingButton onClick={this.handleMealRequestDownload} disabled={!this.state.school_year_id} loading={this.state.downloading_in_progress}>Letöltés</LoadingButton>
                            </div>
                        </div> : null
                    }
                </React.Fragment>: <h4>Az Ön számára nincs megjelenítendő nyomtatvány!</h4>}
            </React.Fragment> : <Loading loading_text='' />
    }
}

function mapStateToProps(state) {
    return {
        field_types: state.field_types,
        text_styles: state.text_styles,
        rights: state.rights,
        meal_request_states: state.meal_request_states,
        creator_types: state.creator_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage,
    addAction: addAction,
    removeAction: removeAction,
    setSystemMessagesCount: setSystemMessagesCount
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DokumentumNyomtatvanyok))