import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import FilestoreClient from "../FileStoreClient";
import DateField from "@sapkk/app/components/DateField";
import SearchResult from "./SearchResult";
import LoadingButton from "../components/LoadingButton";
import PopUpSelector from "@sapkk/app/components/PopUpSelector";

class Riportok extends Component {
    constructor(props) {
        super(props)

        this.state = {
            reports: [],
            report_id: "",
            data: {},
            params: (this.props.history.location.state || {}).params || [],
            param_data: (this.props.history.location.state || {}).param_data || {},
            save_in_progress: false,

            skip_update: false,
            parameters: {
                limit: 20,
                ...((this.props.history.location.state || {}).parameters || {})
            },
            order: (this.props.history.location.state || {}).order || [],
            result: (this.props.history.location.state || {}).result || undefined,
            key: 0
        }
    }

    componentDidMount() {
        this.props.promise_error_handler(CanteenClient.getReports().then((reports)=>{
            this.setState({reports: reports}, ()=>{
                if (!this.state.result) {
                    this.props.setTitle('Riportok')
                    this.props.setGuide(null)
                } else {
                    this.props.setTitle((reports.find(report=>report.id==this.state.report_id)||{}).name)
                    this.props.setGuide(null)
                }
            })
        }))
    }

    static getDerivedStateFromProps(props, state) {
        if (props.history.location.state && !state.skip_update) {
            return {
                order: props.history.location.state.order,
                result: props.history.location.state.result,
                data: props.history.location.state.data,
                filter: props.history.location.state.filter,
                report_id: props.history.location.state.report_id,
                param_data: props.history.location.state.param_data,
                params: props.history.location.state.params
            }
        } else {
            return null
        }
    }

    
    handleChangeOrder(order) {
        this.setState({
            skip_update: true,
            order: order
        }, () => {
            this.setState({
                skip_update: false
            })
            this.handleSearch(0)
        })
    }

    handleSearch = (page) =>{
        this.props.clearMessages()

        this.setState({
            search_in_progress: true,
            skip_update: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.openReport((this.state.reports.find(report=>report.id==this.state.report_id)||{}).open_url, this.state.data, this.state.parameters.limit, page* this.state.parameters.limit, this.state.order).then(result => {
                this.setState({
                    search_in_progress: false,
                    result: {
                        result: result.result || [],
                        result_summary: {
                            ...result.result_summary,
                            page: page
                        },
                        headers: result.headers
                    }
                }, () => {
                    this.props.setTitle((this.state.reports.find(report=>report.id==this.state.report_id)||{}).name)
                    this.props.setGuide(null)

                    if (!this.props.history.location.state) {
                        this.props.history.push(this.props.history.location.pathname)
                    }
                    this.props.history.replace(this.props.history.location.pathname, {
                        ...this.props.history.location.state,
                        order: this.state.order,
                        result: this.state.result,
                        parameters: this.state.parameters,
                        data: this.state.data,
                        report_id: this.state.report_id,
                        report_url: (this.state.reports.find(report=>report.id==this.state.report_id)||{}).url,
                        param_data: this.state.param_data,
                        params: this.state.params
                    })
                })
            })).then(() =>
                this.setState({
                    search_in_progress: false,
                    skip_update: false
                })
            )
        })
    }

    handleReportChange(value) {
        this.setState((prevState) => ({
            report_id: value,
            showed: false,
            data: {},
            key: prevState.key + 1
        }))
    }

    clearParamList = (param, clear_param_data)=>{
        this.state.params.filter((val)=>val.required_params.find(rp => rp.required_param_id===param.id)).map(p => this.clearParamList(p,true))
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [param.name]: "",
            },
            ...(clear_param_data ? {
                param_data: {
                    ...prevState.param_data,
                    [param.id]: []
                }
            }: {})
        }))
    }

    handleDataChange(event, name) {
        let value = ""
        if(event.target.multiple){
            value = [].filter.call(event.target.options, o => o.selected).map(o => o.value)
        } else if(event.target.type!='checkbox'){
            value = event.target.value
        } else{
            value = event.target.checked
        }
        let param = this.state.params.find(p=>p.name===name)||{}
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                [name]: param.multiple && !Array.isArray(value) ? [value] : value
            },
        }),()=>{
            let callables =  this.state.params.filter((val)=>val.required_params.find(rp => rp.required_param_id===param.id))
            callables.map(p => this.clearParamList(p,true))
            callables.filter((val)=>val.default_value).map(p =>{
                this.handleDataChange({ target: { value: p.default_value } }, p.name)
            })
            callables = callables.filter((c) => c.url && c.required_params.every(rp => this.state.data[(this.state.params.find(p=>p.id==rp.required_param_id)||{}).name]))
            if(value){
                Promise.all(
                    callables.map(call => CanteenClient.reportCall(call.url,
                        call.required_params.reduce((prev, curr)=>({...prev,[curr.alias]: this.state.data[(this.state.params.find(p=>p.id===curr.required_param_id)||{}).name]}),{})
                    ))
                ).then(vals=>{
                    vals.map((val, i) =>{
                        this.setState((prevState)=>({
                            param_data: {
                                ...prevState.param_data,
                                [callables[i].id]: val
                            }
                        }), () => {
                            if (val.length === 1) {
                                this.handleDataChange({ target: { value: val[0].id } }, callables[i].name)
                            } else{
                                let default_value = val.find(v => v.default)
                                if(default_value){
                                    this.handleDataChange({ target: { value: default_value.id } }, callables[i].name)
                                }
                            }
                        })
                    })
                }).catch(error => {
                    this.props.clearMessages()
                    if ((error.additional_info || []).length > 0) {
                        error.additional_info.forEach(message =>
                            this.props.addMessage(message, 'error')
                        )
                    } else {
                        this.props.addMessage(error.message, 'error')
                    }
                })
            }
        })
    }

    handleDownload = () => {
        this.props.clearMessages()

        this.setState({
            downloading_in_progress: true
        }, () => {
            this.props.promise_error_handler(CanteenClient.reportDownload((this.state.reports.find(report=>report.id==this.state.report_id)||{}).url, this.state.data).then((result) => {
                return FilestoreClient.download(result.hash)
            })).then(()=>{
                this.setState({
                    downloading_in_progress: false
                })
            })
        })
    }

    handleFetch = () => {
        this.props.clearMessages()

        this.setState((prevState) =>({
            showed: false,
            data: {},
            key: prevState.key + 1
        }), () => {
            this.props.promise_error_handler(CanteenClient.getReportParams(this.state.report_id).then(params =>{
                this.setState({
                    params: params
                }, ()=>{
                    params.filter((val)=>val.default_value&&val.required_params.length===0).map(param =>{
                        this.handleDataChange({ target: { value: param.default_value } }, param.name)
                    })
                    let callables = params.filter((val)=>val.url&&val.required_params.length===0)
                    let data = {}
                    if(callables.length){
                        this.props.promise_error_handler(Promise.all(
                            callables.map(call => CanteenClient.reportCall(call.url))
                        ).then(vals=>{
                            vals.map((val, i) =>{
                                this.setState((prevState)=>({
                                    param_data: {
                                        ...prevState.param_data,
                                        [callables[i].id]: val
                                    }
                                }), () => {
                                    if (val.length === 1) {
                                        this.handleDataChange({ target: { value: val[0].id } }, callables[i].name)
                                    } else{
                                        let default_value = val.find(v => v.default)
                                        if(default_value){
                                            this.handleDataChange({ target: { value: default_value.id } }, callables[i].name)
                                        }
                                    }
                                })
                            })
                        }))
                    }

                    params.map(param => {
                        if(param.type=="BOOL"){
                            data[param.name] = false
                        }
                    })
                    this.setState((prevState)=>({
                        data: {
                            ...prevState.data,
                            ...data
                        },
                        showed: true,
                        param_data: {},
                    }))
                })
            }))
        })
    }

    selectMultiValueFromPopUp = (act_field, rows) => {
        this.handleDataChange({
            target: {
                value: rows.map(row => row.id)
            }
        }, act_field)
    }

    unselectValue = (param,index) => {
        let data = this.state.param_data[param.id]||[]
        let act_field = param.name
        let actual_index = this.state.data[act_field].indexOf(data.filter(pd => (this.state.data[act_field]||[]).includes(pd.id))[index].id)
        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                [act_field]: prevState.data[act_field].slice(0, actual_index).concat(prevState.data[act_field].slice(actual_index+1))
            }
        }))
    }


    getInput = (param) =>{
        let params = {}
        params['value'] = this.state.data[param.name]
        params['className'] = "field field-simple"
        params['onChange'] = event => this.handleDataChange(event, param.name)
        if(param.focus){
            params['autoFocus'] = true
        }
        if(param.url){
            if(param.multiple){
                let data = this.state.param_data[param.id]||[]
                return <PopUpSelector
                multi_select_field_props= {{
                    multi_selected_values: data.filter(pd => (this.state.data[param.name]||[]).includes(pd.id)),
                    unselect: (index)=>this.unselectValue(param, index),
                    multi_selected_field_names: ['name']
                }}
                popup_props={{
                    data: data,
                    selectable: data.map(pd => pd.id),
                    main_fields: ['name'],
                    select: (rows)=>this.selectMultiValueFromPopUp(param.name,rows),
                    default_multiple_selected_values:  (this.state.data[param.name]||[]).map(v => data.find(d => d.id == v)),
                    multi_selected_id_fields: ['id'],
                }}
                type='flat'
            />
            }
            return <select multiple={param.multiple} {...params}>
                <option value="">Kérem válasszon!</option>
                {(this.state.param_data[param.id]||[]).map(pd => <option value={pd.id}>{pd.name}</option>)}
            </select>
        }

        switch(param.type){
            case 'DATE':
                params['placeholder'] = 'éééé.hh.nn'
                return <DateField {...params}/>
            case 'INTEGER':
                params['type'] = 'number'
                break
            case 'REAL':
                params['type'] = 'number'
                break
            case 'BOOL':
                params['type'] = 'checkbox'
                break
        }
        return <SimpleField {...params}/>
    }


    render() {
        if (this.state.result && this.props.history.location.state) {
            return <SearchResult
                result={this.state.result}
                parameters={this.state.parameters}
                conditions={this.state.data}
                params={this.state.params}
                param_data={this.state.param_data}
                order={this.state.order}
                report_url= {this.props.history.location.state.report_url}
                changeOrder={order => this.handleChangeOrder(order)}
                search={page => this.handleSearch(page)}
            />
        }
        return <React.Fragment>
            <div className="page-subtitle">Riport kiválasztása</div>
            <div className="student-select-block">
                <div>
                    <div className="block block-lg-1-1">
                        <select className="field field-simple" value={this.state.report_id} onChange={event => this.handleReportChange(event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {this.state.reports.map(report => <option value={report.id}>{report.name}</option>)}
                        </select>
                    </div>
                </div>
                <div>
                    <button disabled={!this.state.report_id} onClick={this.handleFetch}>Kiválaszt</button>
                </div>
            </div>
            {this.state.report_id && this.state.showed ? <React.Fragment key={'riport.'+this.state.key}>
                {
                    this.state.params.length ? <React.Fragment>
                        <div className="page-subtitle">Adatok megadása</div>
                        {
                            this.state.params.map(param=>{
                                return <div className="block block-lg-1-1">
                                    <label className={"label label-long" + (param.required ? ' required' : '')}>{param.alias}</label>
                                    {
                                        this.getInput(param)
                                    }
                                </div>
                            })
                        }
                    </React.Fragment> :null
                }
                <div className="actions">
                    <LoadingButton loading={this.state.downloading_in_progress} onClick={this.handleDownload}>Letöltés</LoadingButton>
                    {
                        (this.state.reports.find(report=>report.id==this.state.report_id)||{}).open_url ?
                            <LoadingButton disabled={this.state.downloading_in_progress} onClick={()=>this.handleSearch(0)}>Megnyitás</LoadingButton> : null
                    }
                </div>
            </React.Fragment> : null}
        </React.Fragment>
    }
}
function mapStateToProps(state) {
    return {
        promise_error_handler: state.app.promise_error_handler,
    }
}
const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Riportok))