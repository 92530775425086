import React, { Component } from "react"
import { connect } from "react-redux"
import { setTitle, setGuide, clearMessages, addMessage } from "@sapkk/app/actions"
import CanteenClient from "../../CanteenClient"
import SimpleField from "@sapkk/app/components/SimpleField"
import { withRouter } from "react-router"
import Loading from "@sapkk/app/components/Loading"
import LoadingButton from "../../components/LoadingButton";

class EtkezesiCsoportokKezelese extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                name: "",
                description: "",
                active: true,
                is_dietary: false,
                have_menu: false,
                class_type: "",
                xmenza_code: "",
                menu_items: [],
                meals: []
            },
            error: false,
            loaded: false,
            meal_groups: [],
            school_id: this.props.school,
            meal_group_id: ""
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.setState({
            loaded: false,
            error: false,
            meal_group_id: "",
            showed: false,
            fetching_meal_groups: true
        }, () => {
            this.props.promise_error_handler(Promise.all([
                CanteenClient.getMeals(),
                CanteenClient.getMealGroupsForSchool(this.state.school_id),
                CanteenClient.getClassTypesForSchool(this.state.school_id),
                CanteenClient.getMealPrices(this.state.school_id)
            ]).then(([meals,meal_groups, class_types,meal_prices]) => {
                this.setState({
                    meals: meals,
                    meal_groups: meal_groups,
                    class_types: class_types,
                    meal_prices: meal_prices['prices'],
                    fetching_meal_groups: false,
                })
            }).catch(error => {
                this.props.clearMessages()
                this.setState({
                    error: true,
                })
                throw error
            })).then(() => {
                this.setState({
                    loaded: true
                })
            })
        })
    }

    handleMealGroupChange(meal_group_id) {
        this.setState({
            meal_group_id: meal_group_id,
            showed: false,
        })
    }

    handleNew() {
        if(this.state.school_id){
            this.setState({
                meal_group_id: "",
                data: {
                    name: "",
                    description: "",
                    active: true,
                    is_dietary: false,
                    have_menu: false,
                    class_type: "",
                    xmenza_code: "",
                    meals: [],
                    menu_items: []
                },
                showed: true,
            })
        }
    }

    handleFetch() {
        if(this.state.meal_group_id){
            this.setState({
                fetching_meal_group: true,
                showed: false
            }, ()=>{
                this.props.promise_error_handler(CanteenClient.getMealGroup(this.state.meal_group_id).then((meal_group) => {
                    this.setState({
                        data: {
                            ...meal_group,
                            menu_items: meal_group.menu_items?.filter(mi => mi.active)
                        },
                        fetching_meal_group: false,
                        showed: true
                    })
                }))
            })
        }
    }

    handleDataChange(name, value) {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                menu_items: name !== 'have_menu' || value ? prevState.data.menu_items : [],
                meals: name!=='class_type' ? prevState.data.meals : prevState.data.meals.filter(meal => (value||"") !=="" ? ((meal.class_type||"")==="" || meal.class_type==value) : !meal.class_type),
                [name]: value !== '' ? value : null,
            },
        }))
    }

    handleSave() {
        this.props.clearMessages()

        this.setState({
            save_in_progress: true
        }, () => {
            this.props.promise_error_handler((() => {
                if (!this.state.meal_group_id) {
                    return CanteenClient.createMealGroup(this.state.data.name, this.state.data.description, this.state.school_id, this.state.data.active, this.state.data.is_dietary, this.state.data.have_menu, this.state.data.class_type, this.state.data.xmenza_code, this.state.data.meals, this.state.data.menu_items)
                } else {
                    return CanteenClient.editMealGroup(this.state.meal_group_id,this.state.data.name, this.state.data.description, this.state.data.active, this.state.data.is_dietary, this.state.data.have_menu, this.state.data.class_type, this.state.data.xmenza_code, this.state.data.meals, this.state.data.menu_items)
                }
            })()
            .then((meal_group) => {
                this.props.addMessage('Az adatok elmentése megtörtént.', 'success')
                this.setState(prevState => ({
                    data: {
                        ...meal_group,
                        menu_items: meal_group.menu_items?.filter(mi => mi.active)
                    }
                }))
                return meal_group
            })).then((meal_group) => {
                this.props.promise_error_handler(CanteenClient.getMealGroupsForSchool(this.state.school_id).then((meal_groups) => {
                    this.setState((prevState)=>({
                        meal_group_id: meal_group ? meal_group.id : prevState.meal_group_id,
                        meal_groups: meal_groups,
                        save_in_progress: false
                    }))
                }))
            })
        })
    }

    handleMealChange(meal, checked) {
        if (!checked) {
            this.setState(prevState => {
                let f = [...prevState.data.meals]
                f.splice(f.findIndex((m)=>m.id==meal.id), 1)
                return {
                    data: {
                        ...prevState.data,
                        meals: f
                    }
                }
            })
        } else {
            this.setState(prevState => {
                return {
                    data: {
                        ...prevState.data,
                        meals: prevState.data.meals.concat([meal])
                    }
                }
            })
        }
    }

    handleAddLine() {
        this.setState(prevState => ({
            data: {
                ...prevState.data,
                menu_items: prevState.data.menu_items.concat({item_name: "", is_default: false})
            },
        }))
    }

    handleRemoveLine(id) {
        this.setState((prevState, props) => ({
            data: {
                ...prevState.data,
                menu_items: prevState.data.menu_items.slice(0, id).concat(prevState.data.menu_items.slice(id+1))
            },
        }))
    }

    handleRowChange(id, name, value) {
        this.setState((prevState, props) => ({
            data: {
                ...prevState.data,
                menu_items: prevState.data.menu_items.slice(0, id).concat([{...prevState.data.menu_items[id], [name]: value}]).concat(prevState.data.menu_items.slice(id+1))
            },
        }))
    }

    render() {
        if (!this.state.loaded || this.state.error) {
            return <Loading loading_text = ''/>
        }
        let act_prices = this.state.meal_prices
        let readOnly = !this.props.rights_for_school.includes('WRITE_MEAL_GROUP')
        let can_edit_menu = this.props.rights_for_school.includes('WRITE_MEAL_GROUP_MENU')
        return <React.Fragment>
            {
                <React.Fragment><div className="page-subtitle">Étkezési csoport kiválasztása</div>
            <div className={readOnly ? 'student-select-block': "entity-select-block"}>
            <div>
                    <div className="block block-lg-1-1">
                        <select disabled={this.state.disable_menu} className="field field-simple" value={this.state.meal_group_id} onChange={event => this.handleMealGroupChange(event.target.value)}>
                            <option value="">Kérem válasszon!</option>
                            {this.state.meal_groups.map(meal_group => <option value={meal_group.id}>{meal_group.name}</option>)}
                        </select>
                    </div>
                </div>
                <div>
                    <LoadingButton disabled={!this.state.meal_group_id || this.state.fetching_meal_groups} loading={this.state.fetching_meal_group} onClick={() => this.handleFetch()}>Lekérdez</LoadingButton>
                    {!readOnly ? <button disabled={this.state.fetching_meal_groups} onClick={() => this.handleNew()}>Új</button> : null}
                </div>
            </div>
            </React.Fragment> }
            {
                this.state.showed ? <React.Fragment>
                    <div className="page-subtitle">Étkezési csoportok kezelése</div>
                    <div className="block block-lg-1-1">
                        <label className="label label-long">Név</label>
                        <SimpleField readOnly={readOnly} className="field field-simple" value={this.state.data.name} onChange={event => this.handleDataChange('name', event.target.value)} />
                    </div>

                    <div className="block block-lg-1-1">
                        <label className="label label-long">Típus</label>
                        <select disabled={readOnly} className="field field-simple" value={this.state.data.class_type} onChange={event => this.handleDataChange('class_type', event.target.value)}>
                            <option value="">Nincs megadva</option>
                            {Object.keys(this.props.class_types).filter(type => this.state.class_types.includes(type)).map(type => <option value={type}>{this.props.class_types[type].name}</option>)}
                        </select>
                    </div>

                    <div className="block block-lg-1-1">
                        <label className="label label-long">Leírás</label>
                        <SimpleField readOnly={readOnly} className="field field-simple" value={this.state.data.description} onChange={event => this.handleDataChange('description', event.target.value)} />
                    </div>
                    <div className="block block-lg-1-2">
                        <label className="label label-long">Azonosító</label>
                        <SimpleField className="field field-simple" value={this.state.data.id} readOnly={true} />
                    </div>
                    <div className="block block-lg-1-1">
                        <label className="label label-long">Aktív</label>
                        <SimpleField readOnly={readOnly} className="field field-simple" checked={this.state.data.active} value={this.state.data.active ? 'Igen' : 'Nem'} onChange={event => this.handleDataChange('active', event.target.checked)} type="checkbox" />
                    </div>
                    <div className="block block-lg-1-1">
                        <label className="label label-long">Diétás</label>
                        <SimpleField readOnly={readOnly} className="field field-simple" checked={this.state.data.is_dietary} value={this.state.data.is_dietary ? 'Igen' : 'Nem'} onChange={event => this.handleDataChange('is_dietary', event.target.checked)} type="checkbox" />
                    </div>
                    {
                        can_edit_menu ? <div className="block block-lg-1-1">
                            <label className="label label-long">Menü választható</label>
                            <SimpleField className="field field-simple" checked={this.state.data.have_menu} value={this.state.data.have_menu ? 'Igen' : 'Nem'} onChange={event => this.handleDataChange('have_menu', event.target.checked)} type="checkbox" />
                        </div> : null
                    }
                    <div className="page-subsubtitle">Étkezések</div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Étkezés</th>
                                    <th>Típus</th>
                                    <th>Ár (Ft)</th>
                                    <th>Az étkezési csoporthoz tartozik-e</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.meals.length !== 0 ? this.state.meals.map(meal => {
                                        let act_price = act_prices.find(ap => ap.meal.id==meal.id)
                                        let can_be_selected = (this.state.data.class_type||"") !=="" ? ((meal.class_type||"")==="" || meal.class_type==this.state.data.class_type) : !meal.class_type
                                        return <tr className={!can_be_selected? "non-selectable-row" : ""}>
                                            <td>{meal.name}</td>
                                            <td>{meal.class_type ? ((this.props.class_types[meal.class_type] || {}).name || 'Ismeretlen') : ''}</td>
                                            <td>{act_price ? act_price.price.amount : "Nincs ár megadva"}</td>
                                            <td>
                                                <SimpleField disabled={readOnly || !can_be_selected} checked={this.state.data.meals.some(m => meal.id == m.id)} onChange={event =>this.handleMealChange(meal, event.target.checked)} type="checkbox" />
                                            </td>
                                        </tr>
                                    }) : <tr className="table-empty-indicator">
                                            <td colSpan="2">Nincs étkezés</td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {
                        can_edit_menu && this.state.data.have_menu ? <React.Fragment>
                            <div className="page-subsubtitle">Menü</div>
                            <div className="table-container">
                                <table className="striped modify-table meal_group-menu-table">
                                    <thead>
                                    <tr>
                                        <th>Név</th>
                                        <th>Alapértelmezett</th>
                                        <th className="col-actions"/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    { this.state.data.menu_items.map((data, id) =>{
                                        return <tr key={id}>
                                            <td><SimpleField value={data.item_name} onChange={event => this.handleRowChange(id, 'item_name', event.target.value)}/></td>
                                            <td><input type="checkbox" checked={ data.is_default } onChange={event => this.handleRowChange(id, 'is_default', event.target.checked)}/></td>
                                            <td className="col-actions"><button className="remove" onClick={() => this.handleRemoveLine(id)}/></td>
                                        </tr>}
                                    ) }
                                    {!readOnly ? <tr className="table-new-row">
                                        <td colSpan={2}>{this.state.data.menu_items.length === 0 && "Nincs megadva menü."}</td>
                                        <td className="col-actions"><button onClick={() => this.handleAddLine()}/></td>
                                    </tr> : null }
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment> : null
                    }

                    <div className="actions">
                        {!readOnly ? <LoadingButton loading={this.state.save_in_progress} onClick={() => this.handleSave()}>Mentés</LoadingButton> : null}
                    </div>

                </React.Fragment> : (!this.state.loaded ? <Loading loading_text=''/> : null)
            }
        </React.Fragment>
    }
}

function mapStateToProps(state) {
    return {
        class_types: state.class_types,
        promise_error_handler: state.app.promise_error_handler,
    }
}

const mapDispatchToProps = {
    setTitle: setTitle,
    setGuide: setGuide,
    clearMessages: clearMessages,
    addMessage: addMessage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EtkezesiCsoportokKezelese))